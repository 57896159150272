import {
    applySnapshot,
    flow,
    getParent,
    getSnapshot,
    types,
} from 'mobx-state-tree';
import TaskTransport from '../Services/Transport/TaskTransport';
import { DateTime } from 'luxon';
import { toISO } from '../Services/Date';

const BusinessTask = types
    .model('BusinessTask', {
        uuid: types.identifier,
        label: types.string,
        group: types.string,
        order: types.number,
        taskType: types.literal('business'),
        disabled: true,
        count: types.maybe(types.number),
        time: types.maybe(types.string),
        state: '',
        href: '',
    })
    .preProcessSnapshot((snapshot) => {
        let processed = {
            ...snapshot,
            disabled: snapshot.taskType === 'business',
            state: snapshot.sref ?? '',
        };

        if (!snapshot.data) return processed;

        if (typeof snapshot.data.count === 'string') {
            processed.time = snapshot.data.count;
        } else {
            processed.count = snapshot.data.count;
        }

        return processed;
    });

const CustomTask = types
    .model('CustomTask', {
        uuid: types.identifier,
        label: types.string,
        taskType: types.literal('custom'),
        priority: types.integer,
        completed: false,
        editing: false,
        newLabel: '',
    })

    .preProcessSnapshot((snapshot) => ({
        ...snapshot,
        completed: snapshot.completed_at !== null,
    }))

    .actions((self) => ({
        toggleCompleted: flow(function* toggleCompleted() {
            const originalState = self.completed;
            self.completed = !originalState;

            try {
                yield TaskTransport.update(self.uuid, {
                    label: self.label,
                    completed_at: self.completed
                        ? toISO(DateTime.local())
                        : null,
                });
            } catch (e) {
                // @todo: Error here
                console.error(e);
                self.completed = originalState;
            }
        }),

        beginEditing() {
            self.editing = true;
            self.newLabel = self.label;
        },

        cancelEditing() {
            self.editing = false;
            self.newLabel = '';
        },

        updateNewLabel(newLabel) {
            self.newLabel = newLabel;
        },

        save: flow(function* save() {
            const originalState = getSnapshot(self);
            self.label = self.newLabel;
            self.editing = false;
            self.newLabel = '';

            try {
                yield TaskTransport.update(self.uuid, {
                    label: self.label,
                });
            } catch (e) {
                // @todo: Error here
                console.error(e);
                applySnapshot(self, originalState);
            }
        }),

        remove: flow(function* remove() {
            const originalState = getSnapshot(self);

            try {
                yield TaskTransport.remove(self.uuid);
                getParent(self, 2).removeTask(self);
            } catch (e) {
                // @todo: Error here
                console.error(e);
                applySnapshot(self, originalState);
            }
        }),
    }));

export const Task = types.union(BusinessTask, CustomTask);
