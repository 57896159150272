import React from 'react';

const UICheck = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            fill={props.fill}
            d="M6 0c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6zm2.93 2.886a.75.75 0 00-.974.098l-.07.086-2.838 4.052-.905-1.508-.058-.084a.75.75 0 00-1.228.856L4.348 8.87a.749.749 0 00.585.376l.05.003.077-.002a.741.741 0 00.484-.232l.012-.013a.745.745 0 00.05-.06l.008-.013 3.5-5 .057-.095a.75.75 0 00-.24-.95z"
            fillRule="evenodd"
        />
    </svg>
);

export default UICheck;
