import useTeam from '@/Hooks/useTeam';
import React from 'react';

export type ScheduleEventAvailabilityWarningEventType =
    | 'session'
    | 'date'
    | 'appointment';

function useScheduleEventAvailabilityWarning(
    eventType: ScheduleEventAvailabilityWarningEventType,
    hasConflicts: boolean,
    hasHolidays: boolean
) {
    const { hasTeamMembers } = useTeam();

    const ucEventType = eventType.charAt(0).toUpperCase() + eventType.slice(1);

    return {
        title: `${
            hasTeamMembers && eventType !== 'appointment' ? 'Teachers' : 'You'
        } may be unavailable for ${eventType}s you are planning.`,
        body: (
            <ul>
                {hasConflicts && (
                    <li className="mt-2 list-disc">
                        {hasTeamMembers && eventType !== 'appointment'
                            ? `${ucEventType}s planned for times ${
                                  hasTeamMembers
                                      ? 'the selected teacher is'
                                      : 'you are'
                              } unavailable will be saved as unassigned.`
                            : `${ucEventType}s planned for times you are unavailable cannot be saved.`}
                    </li>
                )}
                {hasHolidays && (
                    <li className="mt-2 list-disc">
                        {ucEventType}s planned over a holiday will be saved, but
                        you should check availability.
                    </li>
                )}
            </ul>
        ),
    };
}

export default useScheduleEventAvailabilityWarning;
