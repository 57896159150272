const destructive = '#e16d63';
const success = '#91c46c';
const warning = '#fab04b';

export default {
    core: {
        brand: '#3097a7',
        brandLight: '#76b7c2',
        primary: '#19ae8e',
        success,
        destructive,
        warning,
        highlight: '#ffca43',
        elementHighlight: '#3dace7',
        elementHighlightLight: '#afdef7',
    },
    grey: {
        icon: '#a9b2b5',
        midDark: '#999999',
        default: '#b3bcbf',
        midLight: '#cfd0d4',
        light: '#e8e8e8',
        xLight: '#e1e2e7',
        xxLight: '#eff1f5',
        xxxLight: '#fafbfe',
    },
    text: {
        default: '#363636',
        midLight: '#979797',
        light: '#a3a3a3',
        brandDark: '#00485f',
        link: '#008bb9',
        positive: '#61a47b',
        negative: '#bc5c46',
    },
    icons: {
        default: '#999999',
    },

    scheduleEntities: {
        session: '#3dace7',
        sessionLight: '#c8e7f8',
        sessionDark: '#2e81ad',
        consultation: '#b467b4',
        consultationLight: '#ebcde9',
        consultationDark: '#84357e',
        diary: '#5d6b79',
        diaryLight: '#dee2e6',
        diaryDark: '#3f4953',
        holiday: '#e6776e',
        attended: success,
        forfeited: destructive,
    },
};
