import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import { Client } from '@/Models/Client';
import {
    PrimaryButton,
    PrimaryResponsiveButton,
    WhiteButton,
} from '@/Components/Button';
import { Consultation } from '@/Models/Consultation';

interface Props {
    variant?: 'default' | 'selectable' | 'inline';
    isResponsive?: boolean;
    navigateOnSuccess?: boolean;
    onCreate?(client: Client): void;
    className?: string;
    consultation?: Consultation;
    closeStackOnCreate?: boolean;
    removeBeneath?: boolean;
}

const Component: FunctionComponent<Props> = ({
    variant = 'default',
    className,
    consultation,
    onCreate,
    isResponsive,
    navigateOnSuccess = true,
    closeStackOnCreate,
    removeBeneath = false,
    ...props
}) => {
    const { uiStateStore, currentUserStore, agreementStore } = RootStore.stores;

    const handleClick = async () => {
        await currentUserStore.refreshActiveClientCount();
        await agreementStore.list();

        if (!currentUserStore.canIncreaseClientCount) {
            uiStateStore.pushModal('ACCOUNT_UPGRADE');
            return;
        }

        uiStateStore.pushModal(
            'CLIENT_CREATE',
            {
                onCreate,
                navigateOnSuccess,
                consultation,
                closeStackOnCreate,
            },
            removeBeneath
        );
    };

    const buttonProps = {
        type: 'button' as 'button' | 'submit' | undefined,
        onClick: handleClick,
        icon: 'Plus',
        children: 'Client',
        className,
        'data-testid': 'create-client-button',
    };

    if (variant === 'inline') {
        return (
            <WhiteButton {...buttonProps}>
                {props.children ?? 'New'}
            </WhiteButton>
        );
    }

    if (variant === 'selectable') {
        return (
            <a
                className="button --selectable-option mt-2"
                onClick={handleClick}
            >
                <span className="button__title">Add details yourself</span>
                <span className="button__sub-title">
                    Enter your new client's basic information now.
                </span>
            </a>
        );
    }

    return (
        <>
            {isResponsive && <PrimaryResponsiveButton {...buttonProps} />}
            {!isResponsive && <PrimaryButton {...buttonProps} />}
        </>
    );
};

export const CreateClientButton = observer(Component);
