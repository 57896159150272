import React from 'react';

const UIClassPassCredit = (props) => (
    <svg viewBox="0 0 16 12" {...props}>
        <path
            fill={props.fill}
            d="M8.401 0C9.284 0 10 .716 10 1.599V4a2 2 0 000 4v2.401C10 11.284 9.284 12 8.401 12H1.6A1.599 1.599 0 010 10.401V8a2 2 0 000-4V1.599C0 .716.716 0 1.599 0H8.4zm6.992 1a.198.198 0 01.14.336L14.5 2.388l.125.132c1.874 2.045 1.832 5.28-.125 7.272-.727.74-1.59 1.157-2.5 1.434V9.838c.545-.185 1.136-.509 1.59-.971 1.5-1.528 1.5-4.026 0-5.553l-1.024 1.043a.198.198 0 01-.339-.138V1.395c0-.218.177-.395.395-.395h2.77zm-9.48 3.695l-.055.067-1.425 1.851-.202-.201-.048-.042a.429.429 0 00-.612.581l.055.067.571.571.046.04a.43.43 0 00.555-.035l.059-.07L6.57 5.237l.031-.055a.429.429 0 00-.69-.488z"
            fillRule="evenodd"
        />
    </svg>
);

export default UIClassPassCredit;
