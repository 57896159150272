import { flow, Instance, types } from 'mobx-state-tree';
import { Role } from '@/Models/Role';
import { Role as RoleInterface } from '@/Features/Roles/Types';
import RolesTransport from '@/Services/Transport/RolesTransport';

export interface RolesStoreInterface extends Instance<typeof RolesStore> {}

export const RolesStore = types
    .model('RolesStore', {
        roles: types.map(Role),
    })
    .actions((self) => ({
        list: flow(function* list() {
            const { data } = yield RolesTransport.list();

            return data.data.map((role: RoleInterface) => self.roles.put(role));
        }),
    }));
