import BaseTransport from './BaseTransport';

class TransactionTransport extends BaseTransport {
    async fetch(uuid, params = {}) {
        return await this.get(`/v3/transactions/${uuid}`, params);
    }

    async list(params) {
        return await this.get('/v3/transactions', params);
    }

    async store(type, data) {
        return await this.post(`/v3/transactions/${type}`, data);
    }

    async update(uuid, type, data) {
        return await this.put(`/v3/transactions/${type}/${uuid}`, data);
    }

    async destroy(uuid, type) {
        return await this.delete(`/v3/transactions/${type}/${uuid}`);
    }

    async sendReceipt(uuid) {
        return await this.post(`/v3/transactions/${uuid}/receipt`);
    }
}

export default new TransactionTransport();
