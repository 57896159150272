import { View } from '.';
import { DateTime } from 'luxon';

export class Day extends View {
    static get nameSingular() {
        return 'Day';
    }

    static get namePlural() {
        return 'Days';
    }

    static createFromView(view) {
        return view.day;
    }

    constructor(calendar, date) {
        super(calendar, date);

        this.isPopulated = false;
        this.type = 'day';
    }

    get periodTitle() {
        return this.cache.add('Views.Day.periodTitle', () => {
            return this.date.toFormat('ccc, d LLL yyyy');
        });
    }

    get active() {
        return this.calendar.getDay(DateTime.local());
    }

    get previous() {
        return this.cache.add('Views.Day.previous', () => {
            return this.calendar.getDay(this.date.minus({ days: 1 }));
        });
    }

    get next() {
        return this.cache.add('Views.Day.next', () => {
            return this.calendar.getDay(this.date.plus({ days: 1 }));
        });
    }

    get nextWeek() {
        return this.cache.add('Views.Week.next', () => {
            return this.calendar.getWeek(this.date.plus({ weeks: 1 }));
        });
    }

    get day() {
        return this;
    }

    get week() {
        return this.cache.add('Views.Day.week', () => {
            return this.weeks[0];
        });
    }

    get index() {
        return this.cache.add('Views.Day.index', () => {
            return this.calendar.view.days.indexOf(this);
        });
    }

    get weekday() {
        return this.cache.add('Views.Day.weekday', () => {
            return this.date.weekday - 1;
        });
    }

    get startDate() {
        return this.date.startOf('day');
    }

    get endDate() {
        return this.date.endOf('day');
    }

    get urlParameters() {
        return {
            year: this.date.toFormat('yyyy'),
            month: this.date.toFormat('MM'),
            day: this.date.toFormat('dd'),
        };
    }

    get isToday() {
        return this.cache.add('Views.Day.isToday', () => {
            return this.date.toISODate() === DateTime.local().toISODate();
        });
    }
}
