import React, { FunctionComponent } from 'react';

import EventConfirmationModalContext, {
    ConfirmationModalConfig,
} from '../Contexts/EventConfirmationModalContext';
import RootStore from '@/Stores/RootStore';

interface Props {}

const EventConfirmationModalContextProvider: FunctionComponent<Props> = ({
    children,
}) => {
    function requireConfirmation(config: ConfirmationModalConfig) {
        const onConfirmed =
            typeof config.onConfirmed === 'function'
                ? config.onConfirmed
                : () => {};

        RootStore.stores.uiStateStore.pushModal('EVENT_CANCELLATION', {
            title: 'Are you sure?',
            ...config,
            onConfirmed: () => Promise.all([onConfirmed()]),
        });
    }

    return (
        <EventConfirmationModalContext.Provider value={{ requireConfirmation }}>
            {children}
        </EventConfirmationModalContext.Provider>
    );
};

export default EventConfirmationModalContextProvider;
