import React, { FunctionComponent, useMemo } from 'react';
import { FormikProps, withFormik } from 'formik';
import Modal from '@/Components/Modal';
import { PrimaryButton } from '@/Components/Button';
import { observer } from 'mobx-react';
import { StackableModalProps } from '@/Components/RootModal';
import { possessive } from '@/Services/String';
import { Client } from '@/Models/Client';
import FormField from '@/Components/FormField';
import SelectableOptionList from '@/Components/SelectableOptionList';
import FormRadioField from '@/Components/FormRadioField';
import FormSection from '@/Components/FormSection';
import Alert from '@/Components/Alert';
import { ClientParqResults } from '@/Features/Clients';
import RootStore from '@/Stores/RootStore';
import { PortalInvite } from '@/Models/PortalInvite';
import FormAgreementSelectField from '@/Components/FormAgreementSelectField';
import Notification from '@/Services/Notification';
import ClientTransport from '@/Services/Transport/ClientTransport';
import queryClient from '@/Modules/QueryClient';
import { queryKeys } from '@/Services/QueryKeys';
import handleServerValidation from '@/Utilities/handleServerValidation';
import DOMPurify from 'dompurify';

interface Props extends StackableModalProps {
    client: Client;
    signUpInvite: PortalInvite;
}

interface FormValues {
    canTrain: boolean | undefined;
    signNow: boolean | undefined;
    agreement?: string;
}

const Component: FunctionComponent<Props & FormikProps<FormValues>> = (
    props
) => {
    const { client, signUpInvite, values, handleSubmit, isSubmitting } = props;

    const isDisabled = useMemo(() => {
        return (
            values.canTrain === undefined ||
            (values.canTrain &&
                values.signNow === undefined &&
                !client.hasAgreement)
        );
    }, [values.canTrain, values.signNow]);

    const buttonText = useMemo(() => {
        if (values.canTrain === false || values.signNow === false) {
            return 'Finish';
        }
        if (values.signNow) {
            return 'Send';
        }

        return 'Continue';
    }, [values.canTrain, values.signNow]);

    const hasParq = (signUpInvite?.scope ?? []).includes('parq');
    const hasCompletedParq = (client.currentParq ?? []).length > 0;

    return (
        <Modal
            title={`${possessive(client.firstName)} details`}
            onSubmit={handleSubmit}
            rightAction={() => (
                <PrimaryButton isLoading={isSubmitting} disabled={isDisabled}>
                    {buttonText}
                </PrimaryButton>
            )}
            stackProps={props.stackProps}
        >
            <fieldset className="dialog__panel">
                <FormField.Row>
                    <FormField>
                        <FormField.Label>Personal details</FormField.Label>
                        <p className="m-0 font-bold">
                            {client.fullName} ({client.age})
                        </p>
                        <p className="m-0 font-bold">{client.email}</p>
                        <p className="m-0 font-bold">{client.mobilePhone}</p>
                    </FormField>
                    <FormField>
                        <FormField.Label>Emergency contact</FormField.Label>
                        <p className="m-0 font-bold">
                            {client.emergencyFullName} (
                            {client.emergencyRelationship})
                        </p>
                        <p className="m-0 font-bold">
                            {client.emergencyDaytimePhone} /
                            {client.emergencyEveningPhone}
                        </p>
                    </FormField>
                </FormField.Row>

                {(client.trainingNotes || hasParq || hasCompletedParq) && (
                    <>
                        {client.trainingNotes && (
                            <FormField>
                                <FormField.Label>Intentions</FormField.Label>
                                <div
                                    className="prose prose-sm max-w-none"
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            client.trainingNotes,
                                            {}
                                        ),
                                    }}
                                />
                            </FormField>
                        )}

                        {(hasParq || hasCompletedParq) && (
                            <FormField>
                                <FormField.Label className="mb-2">
                                    PAR-Q
                                </FormField.Label>

                                {hasCompletedParq && (
                                    <ClientParqResults client={client} />
                                )}
                                {hasParq && !hasCompletedParq && (
                                    <Alert
                                        type="warning"
                                        title={`${client.firstName} has not yet completed their PAR-Q`}
                                        className="mb-1"
                                    />
                                )}
                            </FormField>
                        )}

                        <FormSection>
                            <FormField className="m-0">
                                <SelectableOptionList horizontal>
                                    <FormRadioField
                                        name="canTrain"
                                        value={true}
                                        label={
                                            <span>
                                                <strong>Accept</strong>
                                                <br />
                                                <span className="font-normal">
                                                    {client.firstName} will be
                                                    added to your roster
                                                </span>
                                            </span>
                                        }
                                    />
                                    <FormRadioField
                                        name="canTrain"
                                        value={false}
                                        label={
                                            <span>
                                                <strong>Reject</strong>
                                                <br />
                                                <span className="font-normal">
                                                    {client.firstName} will be
                                                    added to your archive
                                                </span>
                                            </span>
                                        }
                                    />
                                </SelectableOptionList>
                            </FormField>
                        </FormSection>

                        {values.canTrain && (
                            <>
                                <FormSection title="Training agreement">
                                    {client.hasAgreement && (
                                        <Alert
                                            type="success"
                                            title={`${client.firstName} has already signed '${client.currentAgreement.name}' agreement`}
                                        />
                                    )}
                                    {!client.hasAgreement && (
                                        <>
                                            <FormField>
                                                <SelectableOptionList
                                                    horizontal
                                                >
                                                    <FormRadioField
                                                        name="signNow"
                                                        value={true}
                                                        label={`Send ${client.firstName} an agreement to sign`}
                                                    />
                                                    <FormRadioField
                                                        name="signNow"
                                                        value={false}
                                                        label="Not right now"
                                                    />
                                                </SelectableOptionList>
                                            </FormField>

                                            {values.signNow && (
                                                <FormField.Row>
                                                    <FormAgreementSelectField
                                                        name="agreement"
                                                        label="Select an agreement"
                                                        withCreateButton
                                                        isRequired
                                                    />
                                                    <FormField />
                                                </FormField.Row>
                                            )}
                                        </>
                                    )}
                                </FormSection>
                            </>
                        )}
                    </>
                )}
            </fieldset>
        </Modal>
    );
};
export const ReviewClientSignUpModal = withFormik<Props, FormValues>({
    mapPropsToValues: (props) => ({
        canTrain: undefined,
        signNow: undefined,
        agreement: undefined,
    }),
    handleSubmit: async (values, { props, setErrors }) => {
        const { uiStateStore } = RootStore.stores;
        const { client } = props;

        if (!values.canTrain) {
            await client.archive();
            await ClientTransport.completeDataReview(
                props.client.eid,
                'signed-up'
            );

            Notification.success('Client reviewed.');
        } else if (values.signNow === true) {
            /**
             * If the client is signing now, but the agreement is being sent via a link, handle that now
             */
            try {
                await ClientTransport.sendDocumentInviteLink(
                    client.eid,
                    'agreement',
                    { agreement: values.agreement }
                );
            } catch (e) {
                handleServerValidation(e, setErrors);
                return;
            }

            await ClientTransport.completeDataReview(
                props.client.eid,
                'signed-up'
            );

            Notification.success('Agreement request sent');
        } else {
            await ClientTransport.completeDataReview(
                props.client.eid,
                'signed-up'
            );

            Notification.success('Client reviewed.');
        }

        await queryClient.invalidateQueries(queryKeys.clients.all);

        uiStateStore.popModal();
    },
})(observer(Component));
