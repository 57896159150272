import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import { StackableModalProps } from '@/Components/RootModal';
import { Agreement } from '@/Models/Agreement';
import { CreateUpdateAgreementModal } from './CreateUpdateAgreementModal';
import { AgreementFormValues } from '@/Features/Agreements';
import history from '@/Modules/History';
import CustomerSupport from '@/Services/CustomerSupport';

interface Props extends StackableModalProps {
    from?: Agreement;
    onCreate?(client: Agreement): void;
    navigateOnSuccess?: boolean;
}

const Component: FunctionComponent<Props> = ({ from, ...props }) => {
    const { agreementStore } = RootStore.stores;

    const mapPropsToValues = (): AgreementFormValues => {
        return {
            name: (from || {}).name ?? '',
            default: false,
            content: (from || {}).content ?? '',
        };
    };

    const handleSave = async (values: AgreementFormValues) => {
        const agreement = await agreementStore.store(values);

        if (typeof props.onCreate === 'function') {
            props.onCreate(agreement);
        }

        CustomerSupport.trackEvent('Agreement Template Created');

        if (props.navigateOnSuccess) {
            history.push(agreement.url);
        }
    };

    return (
        <CreateUpdateAgreementModal
            isCreating
            stackProps={props.stackProps}
            mapPropsToValues={mapPropsToValues}
            onSave={handleSave}
        />
    );
};

export const CreateAgreementModal = observer(Component);
