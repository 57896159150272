import { types } from 'mobx-state-tree';
import { ClientReference } from '@/Models/Client';
import { SessionReference } from '@/Models/Session';

export const SessionParticipation = types.model('SessionParticipation', {
    type: 'session-participation',
    client_eid: types.identifier,
    status: types.maybeNull(types.string),
    client: types.maybe(types.late(() => ClientReference)),
    session: types.maybe(types.late(() => SessionReference)),
});
