import React, { FunctionComponent, useMemo } from 'react';
import { ClearButton } from '@/Components/Button';
import { Session } from '@/Models/Session';
import { ClassDate } from '@/Models/ClassDate';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import { OrganisationUser } from '@/Models/OrganisationUser';

interface Props {
    entry: Session | ClassDate;

    onUpdate?(entry: Session | ClassDate): void;
}

const Component: FunctionComponent<Props> = ({ entry, onUpdate }) => {
    const currentlyAssigned = useMemo(() => {
        return (entry.assignedUsers ?? []).map(
            (orgUser: OrganisationUser) => orgUser.uuid
        );
    }, [entry]);

    async function handleClick() {
        RootStore.stores.uiStateStore.pushModal('SCHEDULE_ENTRY_CLAIM', {
            entry,
            onUpdate,
        });
    }

    if (currentlyAssigned.length > 0 || !entry.isAvailableToAssign) {
        return null;
    }

    return (
        <ClearButton icon="Check" onClick={handleClick}>
            Claim
        </ClearButton>
    );
};

export const AvailableEntryClaimButton = observer(Component);
