import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import EntryCard from '../EntryCard/EntryCard';
import { StyledAvatarContainer, StyledAvatarIcon } from './Style';

const DiaryEntryCard = ({
    withAvatar,
    withLink,
    withLabel,
    diary,
    accessory,
    ...props
}) => {
    const getTitle = () => {
        if (withLabel) {
            return diary.label;
        }
    };

    const renderAvatar = () => {
        if (!withAvatar) {
            return null;
        }

        return () => (
            <StyledAvatarContainer>
                <StyledAvatarIcon />
            </StyledAvatarContainer>
        );
    };

    return (
        <EntryCard
            type="diary"
            entry={diary}
            title={getTitle()}
            subtitle={props.subtitle}
            subtitleTypes={props.subtitleTypes}
            withLink={withLink}
            avatar={renderAvatar()}
            accessory={accessory}
        />
    );
};

DiaryEntryCard.propTypes = {
    withAvatar: PropTypes.bool,
    withLabel: PropTypes.bool,
    withLink: PropTypes.bool,
    diary: PropTypes.object,
    accessory: PropTypes.func,
};

DiaryEntryCard.defaultProps = {
    withLink: true,
};

export default observer(DiaryEntryCard);
