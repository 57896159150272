import { types } from 'mobx-state-tree';
import ClassPrivate from './ClassPrivate';
import ClassEmployed from './ClassEmployed';

const ClassContainer = types.union(
    {
        dispatcher: (snapshot) => {
            switch (snapshot.classType) {
                case 'employed':
                    return ClassEmployed;
                case 'private':
                    return ClassPrivate;
                default:
                    throw new Error();
            }
        },
    },
    ClassPrivate,
    ClassEmployed
);

export default ClassContainer;
