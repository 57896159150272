import React, { CSSProperties, FunctionComponent } from 'react';
import classNames from 'classnames';

interface Props {
    right?: boolean;
    grow?: boolean;
    className?: string;
    style?: CSSProperties;
}

const ActionBarGroup: FunctionComponent<Props> = (props) => {
    function getClassNames() {
        return classNames(
            'ui-action-bar__group',
            {
                '--align-right': props.right,
                '--grow': props.grow,
            },
            props.className
        );
    }

    return (
        <div className={getClassNames()} style={props.style}>
            {props.children}
        </div>
    );
};

export default ActionBarGroup;
