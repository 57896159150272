import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import UsersSelectField from '@/Components/UsersSelectField';
import useAuth from '@/Hooks/useAuth';
import useTeam from '@/Hooks/useTeam';
import withQueryClient from '@/HOCs/WithQueryClient';

interface Props {
    enabled?: boolean;
    selected: string;
    onChange: (value: string) => void;
}

const ScheduleAssignedUserSelect: FunctionComponent<Props> = ({
    enabled,
    selected,
    onChange,
}) => {
    const { hasRole } = useAuth();
    const { hasTeamMembers } = useTeam();

    if (!hasTeamMembers || !hasRole('Owner')) {
        return null;
    }

    return (
        <div className="flex flex-1 items-center">
            <span className="mr-2 hidden xl:inline">Viewing as</span>
            <UsersSelectField
                value={selected}
                onChange={(selected) => onChange(selected?.value)}
                isDisabled={!enabled}
                className="h-10 w-full max-w-none flex-1"
                size="default"
                additionalOptions={[
                    {
                        label: 'Unassigned events',
                        value: 'unassigned',
                    },
                ]}
            />
        </div>
    );
};

export default withQueryClient(observer(ScheduleAssignedUserSelect));
