import angular from 'angular';

angular
    .module('component.wizard.success-pane', [])

    .component('wizardSuccessPane', {
        template: `
            <div class="dialog__overlay" ng-if="$ctrl.selected && $ctrl.ready">
                <div class="dialog__wrapper" ng-class="{'--wide': $ctrl.isWide}">
                    <div class="wizard-success-pane" ng-class="{'--new-ui': $ctrl.newUi}" ng-transclude></div>
                </div>
            </div>
        `,
        transclude: true,
        bindings: {
            canNavigateTo: '<',
            canNavigateBackwardsToCustom: '<canNavigateBackwardsTo',
            canNavigateForwardsToCustom: '<canNavigateForwardsTo',
            onBackwards: '=',
            onCancel: '=',
            onForwards: '=',
            onNavigateTo: '=',
        },
        require: {
            wizard: '^wizard',
        },
        controller: [
            '$scope',
            '$attrs',
            function ($scope, $attrs) {
                var $ctrl = this;

                function canNavigateToPane(navigatableDefault) {
                    if (angular.isDefined($ctrl.canNavigateTo)) {
                        return angular.isFunction($ctrl.canNavigateTo)
                            ? $ctrl.canNavigateTo()
                            : $ctrl.canNavigateTo;
                    }

                    return navigatableDefault;
                }

                $ctrl.canNavigateBackwardsTo = function () {
                    if (angular.isDefined($ctrl.canNavigateBackwardsToCustom)) {
                        return angular.isFunction(
                            $ctrl.canNavigateBackwardsToCustom
                        )
                            ? $ctrl.canNavigateBackwardsToCustom()
                            : $ctrl.canNavigateBackwardsToCustom;
                    }

                    return canNavigateToPane(false);
                };

                $ctrl.canNavigateForwardsTo = function () {
                    if (angular.isDefined($ctrl.canNavigateForwardsToCustom)) {
                        return angular.isFunction(
                            $ctrl.canNavigateForwardsToCustom
                        )
                            ? $ctrl.canNavigateForwardsToCustom()
                            : $ctrl.canNavigateForwardsToCustom;
                    }

                    return canNavigateToPane(true);
                };

                $ctrl.$onInit = function () {
                    $ctrl.wizard.addPane($ctrl);
                };

                $ctrl.isWide = angular.isDefined($attrs.wide);
                $ctrl.newUi = angular.isDefined($attrs.newUi);
            },
        ],
    })

    .component('wizardSuccessFooter', {
        template: `
            <div class="wizard-footer action-bar +mtop-30">
                <div class="action-bar__group" ng-transclude>
                    <wizard-forwards-button></wizard-forwards-button>
                </div>
            </div>
        `,
        transclude: true,
    });
