import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface Props {
    className?: string;
}

const ItemListStickyHeader: FunctionComponent<Props> = ({
    className,
    children,
}) => {
    // const style = {
    //     gridTemplateColumns: definitions.map(def => def.width ?? 'auto').join(' ')
    // };

    return (
        <header
            className={classNames(
                'sticky top-0 z-10 hidden rounded-t-lg border-b border-gray-200 bg-white px-4 py-4 xl:grid xl:gap-x-2 xl:px-6',
                className
            )}
        >
            {children}
        </header>
    );
};

export default ItemListStickyHeader;
