import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { alertColors } from '@/Services/Alert';
import { AlertIcon } from '@/Components/AlertIcon';

export type AlertType = 'success' | 'error' | 'warning' | 'info' | 'grey';

interface AlertProps {
    type: AlertType;
    title?: string;
    subtitle?: string | ReactNode;
    rightAction?: ReactNode;
    className?: string;
    onClick?(): void;
    smallTitle?: boolean;
}

const Alert: FC<AlertProps> = ({
    type,
    title = 'There was a problem. Please try again.',
    subtitle,
    rightAction,
    className,
    onClick,
    smallTitle = false,
}) => {
    const styles = alertColors(type);

    return (
        <div
            className={classNames(
                'p-4 md:rounded-md',
                styles.wrapper,
                className
            )}
            onClick={onClick}
        >
            <div className="flex">
                <div className="w-5 flex-shrink-0">
                    <AlertIcon alertType={type} className="h-5 w-5" />
                </div>
                {(title || subtitle) && (
                    <div className="ml-2.5 flex-1">
                        <h3
                            className={classNames({
                                'text-sm font-medium': !subtitle || smallTitle,
                                'text-base font-bold': !!subtitle,
                            })}
                        >
                            {title}
                        </h3>
                        {subtitle && (
                            <>
                                {typeof subtitle === 'string' && (
                                    <p className="text-sm font-medium">
                                        {subtitle}
                                    </p>
                                )}
                                {typeof subtitle !== 'string' && subtitle}
                            </>
                        )}
                    </div>
                )}
                {rightAction}
            </div>
        </div>
    );
};

export default observer(Alert);
