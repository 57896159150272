import { createContext, ReactNode } from 'react';

interface ContextState {
    requireConfirmation: (config: ConfirmationModalConfig) => void;
}

export interface ConfirmationModalConfig {
    onConfirmed(): void;
    onCancelled?(): void;

    /**
     * The content of the modal.
     */
    content(): ReactNode;

    /**
     * Determines if the submit button should be a positive CTA.
     */
    isPositive?: boolean;

    /**
     * The text of the submit button.
     */
    confirmText?: string;

    isDisabled?: boolean;
    shouldAutoClose?: boolean;
}

const EventConfirmationModalContext = createContext<ContextState>({
    requireConfirmation: () => {},
});

export default EventConfirmationModalContext;
