import {
    applySnapshot,
    destroy,
    flow,
    getRoot,
    getSnapshot,
    types,
} from 'mobx-state-tree';
import ClassContainer from '../Models/Class';
import ClassTransport from '../Services/Transport/ClassTransport';
import RootStore from './RootStore';

export const ClassStore = types
    .model('ClassStore', {
        classes: types.map(ClassContainer),
    })

    .volatile((self) => ({
        inFlight: new Set(),
    }))

    .views((self) => {
        const fetchClass = async (uuid, params = {}) => {
            if (self.inFlight.has(uuid) || self.classes.has(uuid)) {
                return;
            }

            self.inFlight.add(uuid);

            try {
                await self.fetch(uuid, params);
            } catch (error) {
                console.log({ error });
            }

            self.inFlight.delete(uuid);
        };

        return {
            find(uuid) {
                return self.classes.get(uuid);
            },

            findOrFetch(uuid) {
                fetchClass(uuid);
                return self.classes.get(uuid) ?? uuid;
            },

            get available() {
                return Array.from(self.classes.values())
                    .filter(
                        (classModel) =>
                            !classModel.isArchived && !classModel.isDeleted
                    )
                    .sort((a, b) => a.name.localeCompare(b.name));
            },

            get hasClasses() {
                return self.available.length > 0;
            },

            get new() {
                return self.available.filter(({ isNew }) => isNew);
            },
        };
    })

    .actions((self) => ({
        list: flow(function* list(params, withMeta = false) {
            const { data } = yield ClassTransport.list(params);
            const items = data.data.map((classContainer) =>
                self.createOrUpdate(classContainer)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        fetch: flow(function* fetch(uuid, params = {}) {
            try {
                const { data } = yield ClassTransport.fetch(uuid, params);
                return self.createOrUpdate(data.data);
            } catch (e) {
                console.error('error: ', e);
            }
        }),

        store: flow(function* store(values) {
            const { data } = yield ClassTransport.store(values);

            return self.createOrUpdate(data.data);
        }),

        createOrUpdate(classData) {
            classData = self.processNestedResources(classData);

            if (self.classes.has(classData.uuid)) {
                const existing = self.classes.get(classData.uuid);
                applySnapshot(existing, {
                    ...getSnapshot(existing),
                    ...classData,
                });
            } else {
                self.classes.set(classData.uuid, classData);
            }

            return self.classes.get(classData.uuid);
        },

        processNestedResources(classData) {
            if (
                typeof classData.dates !== 'undefined' &&
                Array.isArray(classData.dates)
            ) {
                classData.dates = classData.dates.map(
                    (date) =>
                        getRoot(self).stores.entryStore.createOrUpdate(date)
                            .identifier
                );
            }

            if (
                typeof classData.assignedUsers !== 'undefined' &&
                Array.isArray(classData.assignedUsers)
            ) {
                classData.assignedUsers = classData.assignedUsers.map(
                    (u) => u.identifier
                );
            }

            return classData;
        },

        /**
         * Find and update the given class' delivery, if it exists in the store
         * @param uuid
         * @param delivery
         */
        updateDelivery(uuid, delivery) {
            const classContainer = self.find(uuid);

            if (classContainer) {
                classContainer.updateDelivery(delivery);
            }
        },

        /**
         *
         * @param uuid
         * @param deleteDates
         */
        delete(uuid, deleteDates = false) {
            let entry = self.find(uuid);

            if (!!entry) {
                if (deleteDates) {
                    entry.dates.forEach((date) => {
                        RootStore.stores.entryStore.delete(date.uuid);
                    });
                }
                destroy(entry);
            }
        },

        deleteNew() {
            self.new.forEach(({ uuid }) => self.delete(uuid, true));
        },

        deleteNewDates() {
            self.new.forEach((classContainer) => (classContainer.dates = []));
        },
    }));

export const ClassReference = types.maybeNull(
    types.reference(ClassContainer, {
        get(uuid, parent) {
            return getRoot(parent).stores.classStore.findOrFetch(uuid);
        },
        set({ uuid }) {
            return uuid;
        },
    })
);
