import React, { FunctionComponent } from 'react';
import UploadZone from '@/Components/UploadZone';
import VaporInstance from 'laravel-vapor';
import { AxiosResponse } from 'axios';

export interface UploadedFile {
    uuid: string;
    key: string;
    name: string;
    contentType: string;
}

interface Props {
    onPreview?: (preview: string) => void;
    onUploaded: (file: UploadedFile, preview: string) => void;
    onUploadFailed?: (response: AxiosResponse) => void;
    uploadMessage?: string;
}

const ImageUploadZone: FunctionComponent<Props> = ({
    onUploaded,
    onUploadFailed,
    onPreview,
    ...props
}) => {
    /**
     * Handle the files being uploaded
     * @param files
     * @returns {Promise<void>}
     */
    const handleFilesRead = async (files: any[]) => {
        try {
            const file = files[0].file;

            if (typeof onPreview === 'function') {
                onPreview(file.preview);
            }

            const response = await VaporInstance.store(file, {
                options: { withCredentials: true },
            });

            await onUploaded(
                {
                    uuid: response.uuid,
                    key: response.key,
                    name: files[0].file.name,
                    contentType: files[0].file.type,
                },
                file.preview
            );
        } catch (error) {
            console.log(error.response);
            if (onUploadFailed) onUploadFailed(error.response);
        }
    };

    return (
        <UploadZone
            accept={{
                'image/jpeg': [],
                'image/png': [],
                'image/heic': [],
            }}
            multiple={false}
            onFilesRead={handleFilesRead}
            {...props}
        />
    );
};

export default ImageUploadZone;
