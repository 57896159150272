import { Instance, types } from 'mobx-state-tree';

export interface WorkingPeriodInstance extends Instance<typeof WorkingPeriod> {}

export const WorkingPeriod = types
    .model('WorkingPeriod', {
        dayOfWeek: types.integer,
        start: types.string,
        startMinutes: types.integer,
        end: types.string,
        endMinutes: types.integer,
        locations: types.array(types.string),
    })

    .views((self) => ({}))

    .actions((self) => ({}));
