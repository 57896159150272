import React from 'react';
import ReactDOM from 'react-dom';
import ReactApp from './ReactApp';
import { bootAngular } from '@/AngularApp';

import '../assets/sass/app.scss';

import '@/Config/boot';

declare global {
    interface Window {
        App: any;
        stores: any;
    }
}

bootAngular().then(function () {
    ReactDOM.render(<ReactApp />, document.getElementById('react-root'));
});
