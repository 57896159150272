import BaseTransport from './BaseTransport';

class MetricTransport extends BaseTransport {
    async getValue(params) {
        return await this.get('/v3/metrics/value', params);
    }

    async getDashboardMetrics() {
        return await this.get('/v3/metrics/dashboard');
    }
}

export default new MetricTransport();
