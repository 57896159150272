import React from 'react';

const UIPackage = (props) => (
    <svg viewBox="0 0 14 14" {...props}>
        <path
            fill={props.fill}
            d="M12.133 0C13.164 0 14 .836 14 1.867v10.266C14 13.164 13.164 14 12.133 14H1.867A1.867 1.867 0 010 12.133V1.867C0 .836.836 0 1.867 0h10.266zm-8.05 8.167a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5zm5.834 0a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5zM4.083 2.333a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5zm5.834 0a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5z"
            fillRule="evenodd"
        />
    </svg>
);

export default UIPackage;
