import angular from 'angular';

angular
    .module('resource.session', [])

    .service('Session', [
        '$resource',
        'TransformerService',
        function ($resource, TransformerService) {
            // Create the service
            return $resource(
                '/v1/sessions/:eid',
                { eid: '@eid' },
                {
                    query: {
                        isArray: true,
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                    get: {
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                    update: {
                        method: 'PUT',
                    },
                    setStatus: {
                        action: 'setStatus',
                        method: 'POST',
                        url: '/v1/sessions/set-status',
                    },
                    removeSessions: {
                        action: 'removeSessions',
                        method: 'POST',
                        url: '/v1/sessions/delete',
                    },
                    unschedule: {
                        action: 'unschedule',
                        method: 'POST',
                        url: '/v1/sessions/unschedule',
                    },
                }
            );
        },
    ]);
