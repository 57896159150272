import { useQuery, UseQueryOptions } from 'react-query';
import { ClassDateListFilter, classDateQueryKeys } from '@/Features/ClassDates';
import RootStore from '@/Stores/RootStore';
import { EntityListParams } from '@/Types/Query';

export function useClassDateListQuery(
    params: EntityListParams<ClassDateListFilter>,
    options?: UseQueryOptions
) {
    return useQuery(
        classDateQueryKeys.list(params),
        () => RootStore.stores.entryStore.listClassDates(params),
        options
    );
}
