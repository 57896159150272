import React, { Fragment, ReactNode } from 'react';
import Loader from '@/Components/Loader';

export interface ItemListProps<ItemType> {
    items: ItemType[];
    isLoading?: boolean;
    isError?: boolean;
    renderHeader?: JSX.Element | null;
    renderFooter?: JSX.Element | null;
    renderItem(item: ItemType, classes?: string): JSX.Element;
    zeroContent?: ReactNode;
}

const ItemList = <ItemType extends any>({
    items,
    isLoading = false,
    isError = false,
    renderHeader,
    renderFooter,
    renderItem,
    zeroContent,
}: ItemListProps<ItemType>) => {
    const itemClasses =
        'grid gap-2 p-4 relative min-h-[78px] border-b border-gray-200 xl:items-center xl:px-6 last:border-0 last:rounded-br-lg last:rounded-bl-lg';

    return (
        <>
            <section className="border-b border-t border-gray-200 bg-white xl:mx-0 xl:rounded-lg xl:border">
                {renderHeader}

                {isLoading && <Loader className="mx-auto py-8" withPosition />}

                {!isLoading && items.length > 0 && (
                    <>
                        {items.map(
                            (item: ItemType, index) =>
                                item && (
                                    <Fragment key={index}>
                                        {renderItem(item, itemClasses)}
                                    </Fragment>
                                )
                        )}
                    </>
                )}
                {!isLoading && items.length === 0 && zeroContent}
            </section>
            {renderFooter}
        </>
    );
};

export default ItemList;
