import React, { FunctionComponent, HTMLProps } from 'react';

export type OptionsType = FunctionComponent<HTMLProps<HTMLDivElement>>;

const Options: OptionsType = (props) => (
    <div className="form-field__options" {...props}>
        {props.children}
    </div>
);

export default Options;
