import { applySnapshot, flow, getSnapshot, types } from 'mobx-state-tree';
import { Membership } from '../Models/Membership';
import MembershipTransport from '../Services/Transport/MembershipTransport';

export const MembershipStore = types
    .model('MembershipStore', {
        memberships: types.map(Membership),
    })
    .volatile((self) => ({
        inFlight: new Set(),
    }))

    .views((self) => {
        const fetchMembership = async (uuid, params = {}) => {
            if (self.inFlight.has(uuid) || self.memberships.has(uuid)) {
                return;
            }

            self.inFlight.add(uuid);

            try {
                await self.fetch(uuid, params);
            } catch (error) {
                console.log({ error });
            }

            self.inFlight.delete(uuid);
        };

        return {
            find(uuid) {
                return self.memberships.get(uuid);
            },

            findOrFetch(uuid, params = {}) {
                fetchMembership(uuid, params);
                return self.memberships.get(uuid);
            },

            get available() {
                return Array.from(self.memberships.values()).filter(
                    (membership) => !membership.isDeleted
                );
            },

            get hasAvailable() {
                return self.available.length > 0;
            },
        };
    })

    .actions((self) => ({
        fetch: flow(function* fetch(uuid, params = {}) {
            const { data } = yield MembershipTransport.fetch(uuid, params);
            return self.createOrUpdate(data.data);
        }),

        list: flow(function* list(requestData, withMeta = false) {
            const { data } = yield MembershipTransport.list(requestData);
            const items = data.data.map((membershipData) =>
                self.createOrUpdate(membershipData)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        store: flow(function* store(membershipData) {
            const { data } = yield MembershipTransport.store(membershipData);
            return self.createOrUpdate(data.data);
        }),

        update: flow(function* update(uuid, membershipData) {
            const { data } = yield MembershipTransport.update(
                uuid,
                membershipData
            );
            return self.createOrUpdate(data.data);
        }),

        destroy: flow(function* destroy(uuid) {
            const { data } = yield MembershipTransport.destroy(uuid);
            return self.createOrUpdate(data.data);
        }),

        /**
         *
         * @param membershipData
         * @returns {*}
         */
        createOrUpdate(membership) {
            if (self.memberships.has(membership.uuid)) {
                const existing = self.memberships.get(membership.uuid);
                applySnapshot(existing, {
                    ...getSnapshot(existing),
                    ...membership,
                });
            } else {
                self.memberships.set(membership.uuid, membership);
            }

            return self.memberships.get(membership.uuid);
        },

        /**
         * Add the membership to the store, and return the identifier
         * @param membership
         * @returns {*}
         */
        processAsNested(membership) {
            if (!!membership?.uuid) {
                self.createOrUpdate(membership);
                return membership.uuid;
            }

            return membership;
        },
    }));
