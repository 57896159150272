import React, { cloneElement, useEffect, useState } from 'react';
import {
    autoUpdate,
    DetectOverflowOptions,
    flip,
    FloatingOverlay,
    FloatingPortal,
    offset,
    Placement,
    shift,
    Strategy,
    useClick,
    useDismiss,
    useFloating,
    useId,
    useInteractions,
    useRole,
} from '@floating-ui/react-dom-interactions';
import { Options as OffsetOptions } from '@floating-ui/core/src/middleware/offset';
import { Options as DefaultFlipOptions } from '@floating-ui/core/src/middleware/flip';
import { Transition } from '@headlessui/react';
import { Button } from '@/Components/Button';
import RootStore from '@/Stores/RootStore';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { ConditionalWrapper } from '@/Components/ConditionalWrapper';

export type FlipOptions = Partial<DefaultFlipOptions & DetectOverflowOptions>;

interface WrapperProps {
    children: (data: {
        close: () => void;
        labelId: string;
        descriptionId: string;
    }) => React.ReactNode;
    placement?: Placement;
    anchor?: JSX.Element;
    strategy?: Strategy;
    wrapperClassName?: string;
    buttonWrapperClassName?: string;
    floatingClassName?: string;
    paperClassName?: string;
    isClosable?: boolean;
    initialOpen?: boolean;
    withPortal?: boolean;
    withOverlay?: boolean;
    offsetOptions?: OffsetOptions;
    flipOptions?: FlipOptions;
}

const Component = ({
    children,
    anchor,
    placement,
    strategy: providedStrategy,
    wrapperClassName,
    buttonWrapperClassName,
    floatingClassName,
    paperClassName,
    isClosable = true,
    initialOpen = false,
    withPortal = false,
    withOverlay = false,
    offsetOptions = 8,
    flipOptions,
}: WrapperProps) => {
    const { uiStateStore } = RootStore.stores;
    const [open, setOpen] = useState(initialOpen);

    const { x, y, refs, reference, floating, strategy, context, update } =
        useFloating({
            open,
            onOpenChange: (newState) => {
                if (isClosable || newState) {
                    setOpen(newState);
                }
            },
            middleware: [offset(offsetOptions), flip(flipOptions), shift()],
            placement,
            whileElementsMounted: autoUpdate,
            strategy: providedStrategy,
        });

    const id = useId();
    const labelId = `${id}-label`;
    const descriptionId = `${id}-description`;

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useRole(context),
        useClick(context),
        useDismiss(context),
    ]);

    useEffect(() => {
        if (open) {
            uiStateStore.setPopperOpen(true);
        }
    }, [open]);

    useEffect(() => {
        if (!uiStateStore.popperOpen && !isClosable) {
            uiStateStore.setPopperOpen(true);
        } else if (!uiStateStore.popperOpen && isClosable) {
            setOpen(false);
        }
    }, [uiStateStore.popperOpen]);

    function getAnchor() {
        if (typeof anchor === 'undefined') {
            return (
                <Button
                    icon="DotsVertical"
                    {...getReferenceProps({ ref: reference })}
                />
            );
        }

        return cloneElement(
            anchor,
            getReferenceProps({ ref: reference, ...anchor.props })
        );
    }

    function getContent() {
        return (
            <Transition
                show={open}
                className={classNames('relative', wrapperClassName, {
                    'z-30': !wrapperClassName?.includes('z-'),
                })}
            >
                <ConditionalWrapper
                    condition={withOverlay}
                    wrapper={(children) => (
                        <FloatingOverlay>{children}</FloatingOverlay>
                    )}
                >
                    <div
                        {...getFloatingProps({
                            className: floatingClassName,
                            ref: floating,
                            style: {
                                position: strategy,
                                top: y ?? '',
                                left: x ?? '',
                            },
                            'aria-labelledby': labelId,
                            'aria-describedby': descriptionId,
                        })}
                    >
                        <Transition.Child
                            as="div"
                            enter="transition ease-out duration-100"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                            className={classNames(
                                'rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5',
                                paperClassName
                            )}
                        >
                            {children({
                                labelId,
                                descriptionId,
                                close: () => {
                                    setOpen(false);
                                },
                            })}
                        </Transition.Child>
                    </div>
                </ConditionalWrapper>
            </Transition>
        );
    }

    return (
        <div className={buttonWrapperClassName}>
            {getAnchor()}

            {withPortal && <FloatingPortal>{getContent()}</FloatingPortal>}
            {!withPortal && getContent()}
        </div>
    );
};

export const NewPopper = observer(Component);
