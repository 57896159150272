import React from 'react';

const UIPayment = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            fill={props.fill}
            d="M10.2 0A1.8 1.8 0 0112 1.8v8.4a1.8 1.8 0 01-1.8 1.8H1.8A1.8 1.8 0 010 10.2V1.8A1.8 1.8 0 011.8 0h8.4zM10 6H2v3.5a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V6zM5 7v2H3V7h2zm4.5-5h-7a.5.5 0 00-.5.5V3h8v-.5a.5.5 0 00-.5-.5z"
            fillRule="evenodd"
        />
    </svg>
);

export default UIPayment;
