import React from 'react';

const MessageText = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fill={props.fill}
            d="M20 2H4a2 2 0 00-2 2v18l4-4h14a2 2 0 002-2V4a2 2 0 00-2-2M6 9h12v2H6m8 3H6v-2h8m4-4H6V6h12"
        />
    </svg>
);

export default MessageText;
