import React, { FunctionComponent } from 'react';
import { PrimaryButton } from '@/Components/Button';
import RootStore from '@/Stores/RootStore';

interface Props {
    view: any;
    disabled?: boolean;
}

export const SaveAdditionalEntriesButton: FunctionComponent<Props> = ({
    view,
    disabled,
}) => {
    async function handleClick() {
        RootStore.stores.uiStateStore.pushModal('SCHEDULE_ADDITIONAL_EVENTS', {
            newEvents: view.action.newCards,
            view,
            parent: view.action.parent,
        });
    }

    return (
        <PrimaryButton onClick={handleClick} disabled={disabled}>
            Review & save
        </PrimaryButton>
    );
};
