import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

interface Props {
    icon?: string;
    title?: string;
    content?: string | JSX.Element;
    inline?: boolean;
    className?: string;
}

const ZeroContent: FunctionComponent<Props> = ({
    icon,
    title,
    content,
    inline = false,
    className,
}) => {
    return (
        <div
            className={classNames(
                'zero-content',
                { '--positioned': !inline },
                className
            )}
        >
            <div
                className={classNames('zero-content__message', {
                    '--without-border': inline,
                })}
            >
                {icon && (
                    <div className="zero-content__icon">
                        <Icon name={icon} />
                    </div>
                )}

                {!!title && <h2>{title}</h2>}
                {content}
            </div>
        </div>
    );
};

export default ZeroContent;
