export default {
    core: {
        upgrade: '#ffca43',
        primary: '#19ae8e',
        primaryDark: '#159076',
        highlight: '#35abe8',
        higlightBright: '#36D1E6',
    },

    positive: {
        xLight: '#ecf4e5',
        light: '#b9d7a8',
        default: '#90c46b',
        dark: '#468d16',
    },

    negative: {
        xLight: '#f9e4e1',
        light: '#e0aeab',
        default: '#e26c63',
        dark: '#77241c',
    },

    warning: {
        xLight: '#fef7ed',
        light: '#f3d6b0',
        default: '#fab04b',
        dark: '#85520e',
    },

    info: {
        xLight: '#c1d8ef',
        light: '#9ecce5',
        default: '#35abe9',
        dark: '#12628d',
    },

    header: {
        background: '#006bff',
        navItemIcon: '#8db0bc',
    },

    grey: {
        xLight: '#f7f7f8',
        light: '#ebeced',
        midLight: '#dadada',
        mid: '#b6b6b6',
        midDark: '#787878',
        dark: '#333',
    },

    border: {
        default: '#d8d8d8',
        midDark: '#c7c7c7',
    },

    text: {
        xLight: '#b6b6b6',
        default: '#333333',
        light: '#787878',
        heading: '#005671',
        button: '#146de7',
        link: '#146de7',
        linkDark: '#0042ff',
        negative: '#ca6058',
        warning: '#b77011',
    },

    button: {
        default: '#f7f7f8',
        hover: '#ebeced',
        icon: '#7fb5ff',
        highlightBorder: '#8bcdf1',
        highlightShadow: '#d2e8f4',
    },

    scheduleEntities: {
        new: '#03cde6',
        session: '#35abea',
        sessionDark: '#2f99d2',
        sessionCompleted: '#90c46b',
        sessionForfeited: '#ca6058',
        consultation: '#b564b6',
        consultationDark: '#a259a3',
        class: '#7289d1',
        classDark: '#4c69c4',
        diary: '#5d6b7a',
        diaryDark: '#4e5a67',
        holiday: '#e6776e',
    },
};
