import {
    applySnapshot,
    destroy,
    flow,
    getRoot,
    getSnapshot,
    types,
} from 'mobx-state-tree';
import { Enrolment } from '../Models/Enrolment';
import EnrolmentTransport from '../Services/Transport/EnrolmentTransport';
import SessionTransport from '@/Services/Transport/SessionTransport';

export const EnrolmentStore = types
    .model('EnrolmentStore', {
        enrolments: types.map(Enrolment),
    })

    .volatile((self) => ({
        inFlight: new Set(),
    }))

    .views((self) => {
        const fetchEnrolment = async (uuid, params = {}) => {
            if (self.inFlight.has(uuid) || self.enrolments.has(uuid)) {
                return;
            }

            self.inFlight.add(uuid);

            try {
                await self.fetch(uuid, params);
            } catch (error) {
                console.log({ error });
            }

            self.inFlight.delete(uuid);
        };

        return {
            find(uuid) {
                return self.enrolments.get(uuid);
            },

            findOrFetch(uuid, params = {}) {
                fetchEnrolment(uuid, params);
                return self.enrolments.get(uuid);
            },
        };
    })

    .actions((self) => ({
        list: flow(function* list(requestData, withMeta = false) {
            const { data } = yield EnrolmentTransport.list(requestData);
            const items = data.data.map((enrolmentData) =>
                self.createOrUpdate(enrolmentData)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        fetch: flow(function* fetch(uuid, params = {}) {
            const { data } = yield EnrolmentTransport.fetch(uuid, params);
            return self.createOrUpdate(data.data);
        }),

        cancel: flow(function* cancel(enrolment, params = {}) {
            const { data } = yield EnrolmentTransport.destroy(
                enrolment.uuid,
                params
            );

            if (!!data) {
                return self.createOrUpdate(data.data);
            }

            destroy(enrolment);
        }),

        /**
         *
         * @param enrolmentData
         * @returns {*}
         */
        createOrUpdate(enrolmentData) {
            const enrolment = self.processNestedResources(enrolmentData);

            if (self.enrolments.has(enrolment.uuid)) {
                const existing = self.enrolments.get(enrolment.uuid);
                applySnapshot(existing, {
                    ...getSnapshot(existing),
                    ...enrolment,
                });
            } else {
                self.enrolments.set(enrolment.uuid, enrolment);
            }

            return self.enrolments.get(enrolment.uuid);
        },

        /**
         * Add the enrolment to the store, and return the identifier
         * @param enrolment
         * @returns {*}
         */
        processAsNested(enrolment) {
            if (!!enrolment?.uuid) {
                self.createOrUpdate(enrolment);
                return enrolment.uuid;
            }

            return enrolment;
        },

        /**
         * @param enrolmentData
         * @returns {*}
         */
        processNestedResources(enrolmentData) {
            if (typeof enrolmentData.client !== 'undefined') {
                enrolmentData.client = getRoot(
                    self
                ).stores.clientStore.processAsNested(enrolmentData.client);
            }

            if (typeof enrolmentData.classDate !== 'undefined') {
                enrolmentData.classDate = getRoot(
                    self
                ).stores.entryStore.processAsNested(enrolmentData.classDate);
            }

            if (!!enrolmentData.classPass?.uuid) {
                enrolmentData.classPass = getRoot(
                    self
                ).stores.classPassStore.processAsNested(
                    enrolmentData.classPass
                );
            }

            if (typeof enrolmentData.membershipSubscription !== 'undefined') {
                enrolmentData.membershipSubscription = getRoot(
                    self
                ).stores.membershipSubscriptionStore.processAsNested(
                    enrolmentData.membershipSubscription
                );
            }

            return enrolmentData;
        },

        setAttendance: flow(function* setAttendance(enrolment, attendance) {
            try {
                const { data } = yield SessionTransport.setAttendance(
                    enrolment.uuid,
                    attendance
                );

                return self.find(enrolment.identifier);
            } catch (e) {
                console.error('error: ', e);
            }
        }),

        getWithinInterval(interval) {
            return Array.from(self.enrolments.values()).filter((enrolment) =>
                interval.contains(enrolment.createdAt)
            );
        },

        setIssuedCredit(identifiers) {
            identifiers.forEach((uuid) => {
                const enrolment = self.find(uuid);

                if (enrolment) {
                    enrolment.updateData({ wasIssuedCredit: true });
                }
            });
        },
    }));

// export const EnrolmentStore = types
//     .model('EnrolmentStore', {
//         enrolments: types.array(Enrolment),
//     })
//
//     .views(self => ({
//         find(uuid) {
//             return self.enrolments.find(e => e.uuid === uuid);
//         },
//     }))
//
//     .actions(self => ({
//         list: flow(function* list(params = {}, withMeta = false) {
//             const { data } = yield EnrolmentTransport.list(params);
//             const items =  data.data.map(enrolment => self.createOrUpdate(enrolment));
//
//             return withMeta ? { data: items, meta: data.meta } : items;
//         }),
//
//         fetch: flow(function* fetch(uuid, params = {}) {
//             const { data } = yield EnrolmentTransport.fetch(uuid, params);
//             return self.createOrUpdate(data.data);
//         }),
//
//         findOrFetch: flow(function* findOrFetch(uuid, params = {}) {
//             const existing = self.find(uuid);
//
//             if (existing) {
//                 return existing;
//             }
//
//             return yield self.fetch(uuid, params);
//         }),
//
//         createOrUpdate(enrolmentData) {
//             let existing = self.find(enrolmentData.uuid);
//
//             if (existing) {
//                 applySnapshot(existing, {
//                     ...getSnapshot(existing),
//                     ...enrolmentData
//                 });
//             } else {
//                 self.enrolments.push(enrolmentData);
//             }
//
//             return self.find(enrolmentData.uuid);
//         },
//
//         createOrUpdateBatch(enrolmentData) {
//             return enrolmentData.map(enrolment => self.createOrUpdate(enrolment));
//         },
//     }));
