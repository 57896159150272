import { applySnapshot, flow, types } from 'mobx-state-tree';
import { ExpenseCategory } from '../Models/ExpenseCategory';
import ExpenseCategoryTransport from '../Services/Transport/ExpenseCategoryTransport';

export const ExpenseCategoryStore = types
    .model('ExpenseCategoryStore', {
        categories: types.array(ExpenseCategory),
    })

    .views((self) => ({
        find(uuid) {
            return self.categories.find((cat) => cat.uuid === uuid);
        },

        get available() {
            return self.categories.filter((cat) => !cat.isDeleted);
        },
    }))

    .actions((self) => ({
        init(categories) {
            applySnapshot(self.categories, categories);
        },

        list: flow(function* list(params = {}, withMeta = false) {
            const { data } = yield ExpenseCategoryTransport.list(params);
            const items = data.data.map((category) =>
                self.createOrUpdate(category)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        createOrUpdate(category) {
            let existing = self.find(category.uuid);

            if (existing) {
                applySnapshot(existing, category);
            } else {
                self.categories.push(category);
            }

            return self.find(category.uuid);
        },

        /**
         * Add the category to the store, and return the identifier
         * @param category
         * @returns {*}
         */
        processAsNested(category) {
            if (!!category?.uuid) {
                self.createOrUpdate(category);
                return category.uuid;
            }

            return category;
        },
    }));
