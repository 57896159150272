import { CurrencyInstance } from '@/Models/Currency';

export const getCurrencyDivisor = (currency: CurrencyInstance): number => {
    const power = Math.max(currency.decimals, 0);
    return Math.pow(10, power);
};

/**
 * Return the value divided by the correct power
 * @param value
 * @param currency
 * @returns {number}
 */
export const decimalisedCurrency = (
    value: number,
    currency: CurrencyInstance
): number => {
    return value / getCurrencyDivisor(currency)!;
};

/**
 * Display the given value as a currency in the given style
 * @param value
 * @param simplify
 * @param withSymbol
 * @param absolute
 * @param currency
 */
export const currencyDisplay = (
    value: number,
    currency: CurrencyInstance,
    simplify = true,
    withSymbol = true,
    absolute = true
): string | undefined => {
    const divisor = getCurrencyDivisor(currency);

    let decimals = currency.decimals;
    const symbol = withSymbol ? currency.symbol : '';

    if (absolute) {
        value = Math.abs(value);
    }

    if (simplify && value % divisor! === 0) {
        decimals = 0;
    }

    const formatted = decimalisedCurrency(value, currency)
        .toFixed(decimals)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,');

    return `${symbol}${formatted}`;
};
