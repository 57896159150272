import {
    applySnapshot,
    destroy,
    flow,
    getSnapshot,
    types,
} from 'mobx-state-tree';
import TaskTransport from '../Services/Transport/TaskTransport';
import { Task } from '../Models/Task';

export const TaskStore = types
    .model('TaskStore', {
        busy: false,
        tasks: types.array(Task),
        editing: false,
        newTask: '',
        newTaskBusy: false,
    })

    .views((self) => ({
        get businessTasks() {
            return self.tasks
                .filter((task) => task.taskType === 'business')
                .sort((a, b) => a.order - b.order);
        },
        get groupedBusinessTasks() {
            const groups = {};

            self.businessTasks.forEach((task) => {
                if (typeof groups[task.group] === 'undefined') {
                    groups[task.group] = {
                        label: task.group,
                        tasks: [],
                    };
                }

                groups[task.group].tasks.push(task);
            });

            return Object.values(groups);
        },
        get customTasks() {
            return self.tasks
                .filter((task) => task.taskType === 'custom')
                .sort((a, b) => a.priority - b.priority);
        },
    }))

    .actions((self) => ({
        load: flow(function* load() {
            try {
                self.busy = true;
                const { data } = yield TaskTransport.load();
                applySnapshot(self.tasks, data);
                self.busy = false;
            } catch (e) {
                console.error('error: ', e);
            }
        }),

        createTask: flow(function* createTask() {
            if (!self.newTask) return;
            self.newTaskBusy = true;

            const {
                data: { data },
            } = yield TaskTransport.create({
                label: self.newTask,
                priority: self.customTasks.length,
            });

            self.newTaskBusy = false;
            self.tasks.push(data);
            self.newTask = '';
        }),

        toggleEditing() {
            self.editing = !self.editing;
        },

        updateNewTask(value) {
            self.newTask = value;
        },

        removeTask(task) {
            destroy(task);

            if (!self.customTasks.length) {
                self.editing = false;
            }
        },

        reorder: flow(function* reorder(changed) {
            const originalState = getSnapshot(self.tasks);

            // Apply UI change immediately
            changed.forEach(({ task, priority }) => {
                task.priority = priority;
            });

            try {
                yield TaskTransport.batchReorder({
                    tasks: changed.map(({ task, priority }) => ({
                        uuid: task.uuid,
                        priority,
                    })),
                });
            } catch (e) {
                // @todo: error here
                console.error('error: ', e);
                applySnapshot(self.tasks, originalState);
            }
        }),
    }));
