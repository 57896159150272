import React, { Fragment, FunctionComponent, useContext, useMemo } from 'react';
// @ts-ignore
import { Button, ClearButton } from '../Button';
// @ts-ignore
import { uiColors } from '../../Theme';
import { Session } from '@/Models/Session';
import { ClassDate } from '@/Models/ClassDate';
import useAuth from '@/Hooks/useAuth';
import EventConfirmationModalContext from '@/Contexts/EventConfirmationModalContext';
// @ts-ignore
import SessionTransport from '@/Services/Transport/SessionTransport';
// @ts-ignore
import ClassDateTransport from '@/Services/Transport/ClassDateTransport';
import Notification from '@/Services/Notification';
import { OrganisationUser } from '@/Models/OrganisationUser';
import RootStore from '@/Stores/RootStore';

export type CalendarSetRelinquishAction = (params: any) => void;

interface Props {
    entry: Session | ClassDate;
    variant?: 'icon' | 'menu';
    action?: CalendarSetRelinquishAction;
    onUpdate?: (entry: Session | ClassDate) => void;
}

const EntryRelinquishButton: FunctionComponent<Props> = ({
    entry,
    variant = 'icon',
    onUpdate,
}) => {
    const { hasRole } = useAuth();
    const { organisation } = RootStore.stores.currentUserStore;
    const confirmationContext = useContext(EventConfirmationModalContext);

    const buttonParams = {
        icon: 'CloseCircle',
        fill: uiColors.negative.default,
        onClick: handleClick,
    };

    const currentlyAssigned = useMemo(() => {
        return (entry.assignedUsers ?? []).map(
            (orgUser: OrganisationUser) => orgUser.uuid
        );
    }, [entry]);

    function handleClick() {
        confirmationContext.requireConfirmation({
            content: () => (
                <>
                    <p className="mb-2">
                        Are you sure you want relinquish this event?
                    </p>
                    <p className="mb-2">
                        Doing so will send a request to the organisation owner
                        to complete this.
                    </p>
                </>
            ),
            onConfirmed: async () => {
                if (entry.type === 'session') {
                    await SessionTransport.relinquish(entry.eid).then(
                        handleUpdate
                    );
                    return;
                }

                await ClassDateTransport.relinquish(entry.uuid).then(
                    handleUpdate
                );
            },
        });
    }

    const handleUpdate = () => {
        Notification.success('Relinquish request sent');
        if (onUpdate) onUpdate(entry);
    };

    if (
        !currentlyAssigned.length ||
        !currentlyAssigned.includes(organisation.organisationUser.uuid) ||
        hasRole('Owner')
    ) {
        return null;
    }

    return (
        <>
            {variant === 'icon' && <Button {...buttonParams} />}
            {variant === 'menu' && (
                <ClearButton {...buttonParams}>Relinquish</ClearButton>
            )}
        </>
    );
};

export default EntryRelinquishButton;
