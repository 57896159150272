import angular from 'angular';

angular
    .module('service.resource-scroller', [])

    .service('ResourceScroller', [
        '$q',
        'TransformerService',
        function ($q, TransformerService) {
            var cache = {};

            var ResourceScroller = function (name, resource, query, defaults) {
                query = query || resource.scroll;
                defaults = defaults || {};
                name = defaults.name || name;

                if (!cache[name]) {
                    var result;
                    var scroller = angular.merge(
                        {
                            busy: false,
                            done: false,
                            filters: {
                                page: 1,
                            },
                            items: [],
                        },
                        defaults
                    );

                    var importItems = function (response) {
                        angular.forEach(response.data, function (item) {
                            item = angular.merge(new resource(), item);
                            scroller.items.push(item);
                        });

                        if (response.data.length) {
                            scroller.filters.page++;
                        } else {
                            scroller.done = true;
                        }

                        scroller.busy = false;

                        return response;
                    };

                    var importMeta = function (response) {
                        if (!!response.meta) {
                            scroller.meta = response.meta;
                        }

                        return response;
                    };

                    /**
                     * Reload the scroller
                     */
                    scroller.reload = function (params) {
                        if (!!result) {
                            result.$cancelRequest();
                        }

                        scroller.busy = false;
                        scroller.done = false;
                        scroller.filters.page = 1;
                        scroller.items = [];

                        angular.merge(scroller, params);

                        scroller.nextPage();

                        return result.$promise;
                    };

                    /**
                     * Get the next page
                     */
                    scroller.nextPage = function () {
                        // Don't continue if at end or busy
                        if (scroller.done || scroller.busy) return;

                        // Set the service as busy
                        scroller.busy = true;

                        // Fetch the items
                        result = query(scroller.filters);

                        result.$promise.then(importItems).then(importMeta);

                        return result.$promise;
                    };

                    scroller.autoload = function () {
                        var deferred = $q.defer();

                        var nextPage = function () {
                            scroller.nextPage().then(() => {
                                if (scroller.done) {
                                    deferred.resolve();
                                } else {
                                    nextPage();
                                }
                            });
                        };

                        nextPage();

                        return deferred.promise;
                    };

                    scroller.purgeCache = function () {
                        cache = {};
                    };

                    cache[name] = scroller;
                }

                return cache[name];
            };

            ResourceScroller.createAction = function (params = {}) {
                return {
                    cancellable: true,
                    transformResponse: TransformerService.jsonTransformer,
                    params,
                };
            };

            ResourceScroller.createFactory = function (name, resource) {
                return function (params) {
                    if (!params) params = {};

                    return ResourceScroller(
                        name,
                        resource,
                        resource.scroll,
                        params
                    );
                };
            };

            ResourceScroller.purgeCache = function () {
                cache = {};
            };

            return ResourceScroller;
        },
    ]);
