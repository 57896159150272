import { types } from 'mobx-state-tree';

export const DecimalType = types.custom({
    name: 'Decimal',
    fromSnapshot(value) {
        return Number(value);
    },
    toSnapshot(value) {
        return Number(value);
    },
    isTargetType(value) {
        return !Number.isNaN(value);
    },
    getValidationMessage(value) {
        return '';
    },
});
