import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { FormikProps, withFormik } from 'formik';
import { Client } from '@/Models/Client';
import { StackableModalProps } from '@/Components/RootModal';
import RootStore from '@/Stores/RootStore';
import Modal from '@/Components/Modal';
import FormField from '@/Components/FormField';
import FormTextField from '@/Domain/Form/FormTextField';
import FormSelectField from '@/Components/FormSelectField';
import { emergencyContactRelationships } from '@/Services/Client';
import { PrimaryButton } from '@/Components/Button';
import Notification from '@/Services/Notification';
import { AxiosError } from 'axios';

interface FormValues {
    emergencyFirstName: string;
    emergencyLastName: string;
    emergencyRelationship: string;
    emergencyDaytimePhone: string;
    emergencyEveningPhone: string;
}

interface Props extends StackableModalProps {
    client: Client;
}

const Component: FunctionComponent<Props & FormikProps<FormValues>> = ({
    client,
    ...otherProps
}) => {
    const { isSubmitting, handleSubmit } = otherProps;

    return (
        <Modal
            title="Update emergency contact"
            stackProps={otherProps.stackProps}
            onSubmit={handleSubmit}
            rightAction={() => (
                <PrimaryButton type="submit" isLoading={isSubmitting}>
                    Save
                </PrimaryButton>
            )}
        >
            <fieldset className="dialog__panel">
                <FormField.Row>
                    <FormTextField
                        name="emergencyFirstName"
                        label="First name"
                    />
                    <FormTextField name="emergencyLastName" label="Last name" />
                </FormField.Row>

                <FormField.Row>
                    <FormSelectField
                        options={emergencyContactRelationships}
                        name="emergencyRelationship"
                        label="Relationship"
                    />
                    <FormField />
                </FormField.Row>

                <FormField.Row>
                    <FormTextField
                        name="emergencyDaytimePhone"
                        label="Daytime phone number"
                    />
                    <FormTextField
                        name="emergencyEveningPhone"
                        label="Evening phone number"
                    />
                </FormField.Row>
            </fieldset>
        </Modal>
    );
};

export const UpdateClientEmergencyContactModal = withFormik<Props, FormValues>({
    mapPropsToValues: ({ client }) => ({
        emergencyFirstName: client.emergencyFirstName ?? '',
        emergencyLastName: client.emergencyLastName ?? '',
        emergencyRelationship: client.emergencyRelationship ?? '',
        emergencyDaytimePhone: client.emergencyDaytimePhone ?? '',
        emergencyEveningPhone: client.emergencyEveningPhone ?? '',
    }),
    handleSubmit: async (values, { props, setErrors }) => {
        try {
            await props.client.updateEmergencyContact(values);
        } catch (e) {
            console.log(e);
            if ((e as AxiosError).response?.status === 422) {
                setErrors((e as AxiosError).response?.data.errors);
                return;
            }

            return;
        }

        Notification.success('Contact updated');
        RootStore.stores.uiStateStore.closeStack();
    },
})(observer(Component));
