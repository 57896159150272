import React from 'react';

const UISubscriptionSimple = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            fill={props.fill}
            d="M10.4 0A1.6 1.6 0 0112 1.6v8.8a1.6 1.6 0 01-1.6 1.6H1.6A1.6 1.6 0 010 10.4V1.6A1.6 1.6 0 011.6 0h8.8zM8.645 3.027H7.027l-.061.008a.23.23 0 00-.17.223v1.65l.006.036a.115.115 0 00.192.044l.599-.61.12.132a2.331 2.331 0 01-.12 3.112c-.266.27-.61.46-.93.567V9l.198-.065c.459-.16.891-.394 1.263-.773a3.107 3.107 0 00.073-4.247l-.073-.077.604-.615.025-.038a.115.115 0 00-.108-.158zM5.336 3c-.53.162-1.035.405-1.46.838a3.106 3.106 0 000 4.324l-.604.615-.025.038a.115.115 0 00.108.158h1.618l.061-.008a.23.23 0 00.17-.223v-1.65l-.006-.036a.115.115 0 00-.192-.044l-.599.61-.12-.132a2.331 2.331 0 01.12-3.112c.266-.27.61-.46.93-.567z"
            fillRule="evenodd"
        />
    </svg>
);

export default UISubscriptionSimple;
