import angular from 'angular';

angular
    .module('service.resource-fetcher', [])

    .service('ResourceFetcher', [
        'TransformerService',
        function (TransformerService) {
            var cache = {};

            var ResourceFetcher = function (name, resource, defaults) {
                name = defaults.name || name;

                if (!cache[name]) {
                    var result;
                    var fetcher = angular.merge(
                        {
                            busy: false,
                            done: false,
                            filters: {},
                            data: {},
                        },
                        defaults
                    );

                    var execute = function () {
                        result = resource.fetch(fetcher.filters);
                        result.$promise
                            .then(importData)
                            .then(importMeta)
                            .then(complete);
                    };

                    var importData = function (response) {
                        fetcher.data = new resource(response.data);

                        return response;
                    };

                    var importMeta = function (response) {
                        if (!!response.meta) {
                            fetcher.meta = response.meta;
                        }

                        return response;
                    };

                    var complete = function (response) {
                        fetcher.done = true;
                        fetcher.busy = false;

                        return response;
                    };

                    fetcher.reload = function (params) {
                        if (fetcher.busy) {
                            return result.$promise;
                        }

                        fetcher.busy = true;
                        fetcher.done = false;
                        fetcher.data = {};

                        angular.merge(fetcher, params);

                        execute();

                        return result.$promise;
                    };

                    fetcher.purgeCache = function () {
                        cache = {};
                    };

                    cache[name] = fetcher;
                }

                return cache[name];
            };

            ResourceFetcher.createAction = function () {
                return {
                    cancellable: true,
                    transformResponse: TransformerService.jsonTransformer,
                };
            };

            ResourceFetcher.createFactory = function (
                name,
                resource,
                idColumn = 'eid'
            ) {
                return function (eid, params) {
                    var id = [name, eid].join('.');

                    if (!params) params = {};
                    if (!params.filters) params.filters = {};

                    params.filters[idColumn] = eid;

                    return ResourceFetcher(id, resource, params);
                };
            };

            ResourceFetcher.purgeCache = function () {
                cache = {};
            };

            return ResourceFetcher;
        },
    ]);
