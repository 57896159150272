import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Loader from '@/Components/Loader';

interface Props {
    className?: string;
    isLoading?: boolean;
    hideWhileLoading?: boolean;
    rounded?: 'md' | 'lg' | 'xl' | 'always';
}

const Card: FunctionComponent<Props> = ({
    className,
    isLoading = false,
    hideWhileLoading = false,
    rounded = 'xl',
    children,
}) => {
    className = classNames(
        'relative bg-white border-t border-b border-gray-200',
        {
            'border rounded-xl': rounded === 'always',
            'md:border md:rounded-xl': rounded === 'md',
            'lg:border lg:rounded-xl': rounded === 'lg',
            'xl:border xl:rounded-xl': rounded === 'xl',
        },
        className
    );

    return (
        <div className={className}>
            {isLoading && <Loader withPosition withShroud />}
            {(!hideWhileLoading || !isLoading) && children}
        </div>
    );
};

export default Card;
