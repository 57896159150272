import React, { FunctionComponent } from 'react';
import RootStore from '../../../Stores/RootStore';
import { uiColors } from '../../../Theme';
import { WhiteButton } from '@/Components/Button';
import ZeroContentButton from '@/Components/ZeroContentButton';
import CreateDiary from '../../../../assets/js/actions/CreateDiary';
import history from '@/Modules/History';
import { getFormattedUrl } from '@/Services/Schedule';
import { observer } from 'mobx-react';

interface Props {
    view?: any;
    calendar?: any;
    variant?: 'default' | 'zero-content';
    beforeClick?(): void;
    selectedCalendar?: string;
}

const Component: FunctionComponent<Props> = ({
    view,
    calendar,
    variant = 'default',
    beforeClick,
    selectedCalendar,
}) => {
    const { currentUserStore } = RootStore.stores;

    const handleClick = (event) => {
        if (typeof beforeClick === 'function') {
            beforeClick();
        }

        if (!view) {
            history.push(
                getFormattedUrl(null, 'responsive', {
                    action: 'add',
                    type: 'diary-entry',
                    ...(selectedCalendar
                        ? {
                              calendar:
                                  currentUserStore.organisation.organisationUser
                                      .uuid,
                          }
                        : {}),
                })
            );
        } else {
            view.setAction(new CreateDiary(calendar));
        }

        RootStore.stores.uiStateStore.closeStack();
    };

    const commonProps = {
        type: 'button',
        onClick: handleClick,
        children: 'Dates',
    };

    return (
        <>
            {variant === 'zero-content' && (
                <ZeroContentButton {...commonProps} />
            )}
            {variant === 'default' && (
                <WhiteButton
                    icon="Plus"
                    fill={uiColors.core.primary}
                    {...commonProps}
                />
            )}
        </>
    );
};

export const SchedulePlaceDiaryEntriesButton = observer(Component);
