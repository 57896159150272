import React from 'react';

const CustomSessionPack = (props) => (
    <svg viewBox="0 0 35 21" {...props}>
        <path
            fill={props.fill}
            d="M32.202 0A2.798 2.798 0 0135 2.798v15.404A2.798 2.798 0 0132.202 21H2.798A2.798 2.798 0 010 18.202V2.798A2.798 2.798 0 012.798 0h29.404zM8 5a3 3 0 100 6 3 3 0 000-6zm-2 9h23a1 1 0 110 2H6a1 1 0 110-2z"
            fillRule="evenodd"
        />
    </svg>
);

export default CustomSessionPack;
