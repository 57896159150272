import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import { Client } from '@/Models/Client';
import { ClearButton } from '@/Components/Button';

interface Props {
    client: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    const { uiStateStore } = RootStore.stores;

    const handleClick = () => {
        uiStateStore.pushModal('CLIENT_UPDATE', { client });
    };

    return (
        <ClearButton onClick={handleClick} icon="Pencil">
            Edit
        </ClearButton>
    );
};

export const UpdateClientButton = observer(Component);
