import angular from 'angular';

angular
    .module('resource.consultation', [])

    .service('Consultation', [
        '$resource',
        'ResourceFetcher',
        'ResourceScroller',
        'TransformerService',
        function (
            $resource,
            ResourceFetcher,
            ResourceScroller,
            TransformerService
        ) {
            var Consultation = $resource(
                '/v1/consultations/:eid',
                { eid: '@eid' },
                {
                    fetch: ResourceFetcher.createAction(),
                    scroll: ResourceScroller.createAction(),
                    query: {
                        isArray: true,
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                    get: {
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                    update: {
                        method: 'PUT',
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                    cancel: {
                        method: 'PUT',
                        url: '/v1/consultations/:eid/cancel',
                    },
                    dismiss: {
                        method: 'POST',
                        url: '/v1/consultations/:eid/dismiss',
                    },
                }
            );

            /**
             * Get the client's name in 'J. Smith' format
             * @returns {string}
             */
            Consultation.prototype.getInitialedName = function () {
                return `${this.firstName} ${this.lastName
                    .charAt(0)
                    .toUpperCase()}`;
            };

            Consultation.createFetcher = ResourceFetcher.createFactory(
                'consultation',
                Consultation
            );

            Consultation.createScroller = ResourceScroller.createFactory(
                'consultations',
                Consultation
            );

            return Consultation;
        },
    ]);
