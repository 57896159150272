import { components } from 'react-select';
import classNames from 'classnames';
import Avatar from '@/Components/Avatar';
import MissingUserAvatar from '@/Components/MissingUserAvatar';
import { UserAvatar } from '@/Features/User/Components/UserAvatar';
import React from 'react';

const UserSelectFieldOption = (props) => {
    return (
        <components.Option
            {...props}
            className={classNames(
                props.className,
                '!flex !items-center !gap-2'
            )}
        >
            {!props.data.user && (
                <Avatar
                    src={undefined}
                    alt="Unassigned"
                    missingIcon={<MissingUserAvatar />}
                    className="h-7 w-7 min-w-[28px]"
                />
            )}
            {props.data.user && (
                <UserAvatar
                    user={props.data.user}
                    className="h-7 w-7 min-w-[28px]"
                />
            )}
            <span>{props.data.label}</span>
        </components.Option>
    );
};

export default UserSelectFieldOption;
