import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import CreateEntityButton, {
    CreateEntityButtonProps,
} from '@/Components/CreateEntityButton';
import classNames from 'classnames';
import RootStore from '@/Stores/RootStore';

interface Props extends CreateEntityButtonProps {
    onCreate?(client: Client): void;
    variant?: 'default' | 'selectable';
    removeBeneath?: boolean;
}

const Component: FunctionComponent<Props> = ({
    variant = 'default',
    removeBeneath = false,
    ...props
}) => {
    function handleClick() {
        RootStore.stores.uiStateStore.pushModal(
            'CLIENT_CREATE_OPTIONS',
            {},
            removeBeneath
        );
    }

    if (variant === 'selectable') {
        return (
            <a
                {...props}
                onClick={handleClick}
                className={classNames(
                    'button --selectable-option --flash-clients',
                    props.className
                )}
            >
                <span className="button__title">Clients</span>
                <span className="button__sub-title">
                    Invite or add a new client
                </span>
            </a>
        );
    }

    return (
        <CreateEntityButton modal="CLIENT_CREATE_OPTIONS" {...props}>
            Client
        </CreateEntityButton>
    );
};

export const CreateClientOptionsButton = observer(Component);
