import { applySnapshot, flow, getRoot, types } from 'mobx-state-tree';
import { Location } from '../Models/Location';
import { sortAlphabeticallyByKey } from '../Services/String';
import RootStore from '../Stores/RootStore';
import PhysicalLocationTransport from '../Services/Transport/PhysicalLocationTransport';
import VirtualLocationTransport from '@/Services/Transport/VirtualLocationTransport';
import LocationTransport from '@/Services/Transport/LocationTransport';

const optionMapper = (loc) => ({
    label: loc.name,
    value: loc.uuid,
});

export const LocationStore = types
    .model('LocationStore', {
        locations: types.array(Location),
    })

    .views((self) => ({
        get hasAvailable() {
            return !!self.available.length;
        },

        get hasLocations() {
            return self.hasAvailable;
        },

        find(uuid) {
            return self.locations.find((loc) => loc.uuid === uuid);
        },

        get default() {
            return RootStore.stores.currentUserStore.organisation
                ?.defaultLocation;
        },

        get available() {
            return self.locations
                .slice()
                .filter((loc) => !loc.isDeleted)
                .sort((a, b) => (a.name > b.name ? 1 : -1));
        },

        get physical() {
            return self.available.filter(
                (loc) => loc.locationType === 'physical'
            );
        },

        get virtual() {
            return self.available.filter(
                (loc) => loc.locationType === 'virtual'
            );
        },

        get hasPhysical() {
            return self.physical.length > 0;
        },

        physicalIncluding(include = null) {
            const items = self.physical.slice();
            include = self.locations
                .slice()
                .find(
                    (loc) =>
                        loc.locationType === 'physical' && loc.uuid === include
                );

            if (include && !items.find((loc) => loc.uuid === include.uuid)) {
                items.push(include);
            }

            return sortAlphabeticallyByKey(items, 'name');
        },

        get groupedVirtual() {
            const grouped = self.virtual
                .sort((a, b) => (a.group > b.group ? 1 : -1))
                .reduce((acc, curr) => {
                    if (!acc[curr.group]) {
                        acc[curr.group] = { options: [] };
                    }

                    acc[curr.group].options.push(optionMapper(curr));

                    return acc;
                }, {});

            return Object.values(grouped).map((group) => ({
                options: sortAlphabeticallyByKey(group.options, 'label'),
            }));
        },

        selectableOptions(include = null) {
            let physicalOptions = self
                .physicalIncluding(include)
                .map(optionMapper);

            if (!physicalOptions.length) {
                physicalOptions = [
                    {
                        value: '',
                        label: 'None added yet',
                        disabled: true,
                    },
                ];
            }

            return [
                {
                    label: 'Your locations',
                    options: physicalOptions,
                },
                ...self.groupedVirtual,
            ];
        },
    }))

    .actions((self) => ({
        clearStore() {
            applySnapshot(self, {});
        },

        init(locations) {
            applySnapshot(self.locations, locations);
        },

        list: flow(function* list(params = {}, withMeta = false) {
            const { data } = yield PhysicalLocationTransport.list(params);
            const items = data.data.map((location) =>
                self.createOrUpdate(location)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        listCombined: flow(function* listCombined(
            params = {},
            withMeta = false
        ) {
            const { data } = yield LocationTransport.listCombined(params);

            const items = data.data.map((location) =>
                self.createOrUpdate(location)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        listVirtual: flow(function* listVirtual(params = {}, withMeta = false) {
            const { data } = yield VirtualLocationTransport.list(params);
            const items = data.data.map((location) =>
                self.createOrUpdate(location)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        store: flow(function* store(locationData) {
            const { data } = yield PhysicalLocationTransport.store(
                locationData
            );

            return self.createOrUpdate(data.data);
        }),

        update: flow(function* update(location, formData) {
            if (location.isPhysical) {
                const { data } = yield PhysicalLocationTransport.update(
                    location.uuid,
                    formData
                );
                return self.createOrUpdate(data.data);
            }

            const { data } = yield LocationTransport.updateVirtual(
                location.uuid,
                {
                    isRequestable: formData.isRequestable,
                }
            );
            return self.createOrUpdate(data.data);
        }),

        destroy: flow(function* destroy(uuid) {
            const { data } = yield PhysicalLocationTransport.destroy(uuid);
            getRoot(self).stores.currentUserStore.updateOrganisationData({
                defaultLocation: data.defaultLocation,
            });
            return self.createOrUpdate(data.resource);
        }),

        createOrUpdate(locationData) {
            let existing = self.find(locationData.uuid);

            if (existing) {
                applySnapshot(existing, locationData);
            } else {
                self.locations.push(locationData);
            }

            return self.find(locationData.uuid);
        },

        processAsNested(location) {
            if (!!location?.uuid) {
                self.createOrUpdate(location);
                return location.uuid;
            }

            return location;
        },

        add(data) {
            if (!Array.isArray(data)) {
                data = [data];
            }

            data.forEach((location) => {
                let existing = self.find(location.uuid);

                if (existing) {
                    applySnapshot(existing, location);
                } else {
                    self.locations.push(location);
                }
            });
        },

        delete(uuid) {
            let location = self.find(uuid);

            if (!!location) {
                location.updateDeleted(true);
            }
        },
    }));
