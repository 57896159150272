import { Editor } from '@tiptap/react';
import React, { FunctionComponent, SyntheticEvent } from 'react';
import { Button } from '@/Components/Button';
import uiColors from '@/Theme/uiColors';
import classNames from 'classnames';

interface Props {
    editor: Editor | null;
    withLists?: boolean;
}

const MenuBar: FunctionComponent<Props> = ({ editor, withLists }) => {
    if (!editor) {
        return null;
    }

    function handleBold(ev: SyntheticEvent) {
        ev.preventDefault();
        editor!.chain().focus().toggleBold().run();
    }

    function handleItalic(ev: SyntheticEvent) {
        ev.preventDefault();
        editor!.chain().focus().toggleItalic().run();
    }

    function handleUnderline(ev: SyntheticEvent) {
        ev.preventDefault();
        editor!.chain().focus().toggleUnderline().run();
    }

    function handleBulletList(ev: SyntheticEvent) {
        ev.preventDefault();
        editor!.chain().focus().toggleBulletList().run();
    }

    function handleOrderedList(ev: SyntheticEvent) {
        ev.preventDefault();
        editor!.chain().focus().toggleOrderedList().run();
    }

    return (
        <div className="grid auto-cols-min grid-flow-col gap-1 p-3 pb-0">
            <Button
                size="small"
                icon="FormatBold"
                fill={uiColors.grey.dark}
                onClick={handleBold}
                className={classNames('', {
                    'bg-gray-300': editor.isActive('bold'),
                })}
            />
            <Button
                type="button"
                size="small"
                icon="FormatItalic"
                fill={uiColors.grey.dark}
                onClick={handleItalic}
                className={classNames('', {
                    'bg-gray-300': editor.isActive('italic'),
                })}
            />
            <Button
                type="button"
                size="small"
                icon="FormatUnderline"
                fill={uiColors.grey.dark}
                onClick={handleUnderline}
                className={classNames('', {
                    'bg-gray-300': editor.isActive('underline'),
                })}
            />
            {withLists && (
                <>
                    <Button
                        type="button"
                        size="small"
                        icon="FormatListBulleted"
                        fill={uiColors.grey.dark}
                        onClick={handleBulletList}
                        className={classNames('', {
                            'bg-gray-300': editor.isActive('bulletList'),
                        })}
                    />
                    <Button
                        type="button"
                        size="small"
                        icon="FormatListNumbered"
                        fill={uiColors.grey.dark}
                        onClick={handleOrderedList}
                        className={classNames('', {
                            'bg-gray-300': editor.isActive('orderedList'),
                        })}
                    />
                </>
            )}
        </div>
    );
};

export default MenuBar;
