import {
    applySnapshot,
    flow,
    getParent,
    getRoot,
    getSnapshot,
    types,
} from 'mobx-state-tree';
import { ClientReference } from './Client';
import { ClassDateReference } from './ClassDate';
import { ClassPassReference } from './ClassPass';
import EnrolmentTransport from '../Services/Transport/EnrolmentTransport';
import Notification from '../Services/Notification';
import { DateTimeType } from '../Types/DateTime';
import { MembershipSubscriptionReference } from './MembershipSubscription';
import queryClient from '../Modules/QueryClient';
import { queryKeys } from '../Services/QueryKeys';

export const Enrolment = types
    .model('Enrolment', {
        uuid: types.identifier,
        type: types.literal('enrolment'),
        reference: types.string,
        cost: types.maybeNull(types.integer),
        attendance: types.maybeNull(
            types.enumeration(['attended', 'forfeited'])
        ),
        usedClassPass: types.boolean,
        usedMembership: types.boolean,
        wasIssuedCredit: types.boolean,

        isDeleted: types.boolean,
        purchasedAt: DateTimeType,
        createdAt: DateTimeType,

        // Relationships
        client: types.late(() => ClientReference),
        classDate: types.late(() => ClassDateReference),
        classPass: types.late(() => ClassPassReference),
        membershipSubscription: types.late(
            () => MembershipSubscriptionReference
        ),
    })

    .views((self) => ({
        get humanType() {
            return 'class enrolment';
        },

        get wasAttended() {
            return self.attendance === 'attended';
        },

        get wasForfeited() {
            return self.attendance === 'forfeited';
        },

        get hasAttendance() {
            return self.attendance !== null;
        },
    }))

    .actions((self) => ({
        updateAttendance: flow(function* updateAttendance(status, params = {}) {
            const wasForfeited = self.wasForfeited;
            const { data } = yield EnrolmentTransport.update(self.uuid, {
                attendance: status,
                ...params,
            });

            yield queryClient.invalidateQueries(
                queryKeys.classDates.view(self.uuid)
            );

            Notification.success('Enrolment attendance updated.');

            return getParent(self, 2).createOrUpdate(data.data);
        }),

        updateData(data = {}) {
            applySnapshot(self, {
                ...getSnapshot(self),
                ...data,
            });
        },
    }));

export const EnrolmentReference = types.maybeNull(
    types.reference(Enrolment, {
        get(uuid, parent) {
            return getRoot(parent).stores.enrolmentStore.findOrFetch(uuid, {
                withTrashed: true,
            });
        },
        set({ uuid }) {
            return uuid;
        },
    })
);
