import angular from 'angular';

angular
    .module('service.transformer', [])

    .service('TransformerService', [
        function () {
            // Service object
            var service = {};

            service.defaultTransformer = function (response, headers, status) {
                if (status !== 200) return response;
                return angular.fromJson(response).data;
            };

            service.jsonTransformer = function (response, headers, status) {
                if (status !== 200) return response;
                return angular.fromJson(response);
            };

            return service;
        },
    ]);
