import React, { Fragment, FunctionComponent } from 'react';
import RootStore from '@/Stores/RootStore';
import SessionEntryCard from '@/Components/SessionEntryCard';
import ClassDateEntryCard from '@/Components/ClassDateEntryCard';
import { WhiteButton } from '@/Components/Button';
import { useInfiniteQuery } from 'react-query';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import Card from '@/Components/Card';
import { getNextPageParam } from '@/Services/Api';

interface Props {
    client: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    const { entryStore } = RootStore.stores;
    const query = useInfiniteQuery(
        ['sessions', { client: client.eid }],
        async ({ pageParam = 1 }) =>
            await entryStore.listForClient(
                client.eid,
                {
                    page: pageParam,
                    perPage: 3,
                },
                true
            ),
        {
            getNextPageParam,
            cacheTime: 0,
        }
    );

    return (
        <Card isLoading={query.isLoading}>
            <header className="ui-content-card__header">
                <div className="ui-action-bar">
                    <h2 className="ui-action-bar__title">
                        {query.isLoading
                            ? 'Loading next events...'
                            : `Next events`}
                    </h2>
                </div>
            </header>

            <div className="ui-content-card__content">
                {query.isSuccess && query.data.pages[0]?.data?.length === 0 && (
                    <p>{client.firstName} has no upcoming events.</p>
                )}
                {query.data?.pages?.map((page: any) => (
                    <Fragment key={page.meta.current_page}>
                        {page.data.map((item: any) => (
                            <div
                                key={item.identifier}
                                className="mb-2 last:mb-0"
                            >
                                {item.type === 'session' && (
                                    // @ts-ignore
                                    <SessionEntryCard
                                        session={item}
                                        titleType="datetime"
                                        subtitleTypes={['location']}
                                        withLink
                                        withReminderAccessory
                                    />
                                )}
                                {item.type === 'class-date' && (
                                    <ClassDateEntryCard
                                        withLink
                                        subtitleTypes={[
                                            'date',
                                            'time',
                                            'location',
                                        ]}
                                        classDate={item}
                                    />
                                )}
                            </div>
                        ))}
                    </Fragment>
                ))}
                {query.hasNextPage && (
                    <footer className="mt-4 flex justify-center">
                        <WhiteButton
                            onClick={() => query.fetchNextPage()}
                            isLoading={query.isFetchingNextPage}
                        >
                            Load more
                        </WhiteButton>
                    </footer>
                )}
            </div>
        </Card>
    );
};

export const ClientNextEventDetails = observer(Component);
