import React from 'react';
import { Button } from '../Button';

const DatePickerNavBar = (props) => {
    return (
        <div className="absolute right-[14px] top-[14px] space-x-2">
            <Button
                type="button"
                icon="ChevronLeft"
                onClick={() => props.onPreviousClick()}
                className="!h-8 !w-8"
            />
            <Button
                type="button"
                icon="ChevronRight"
                onClick={() => props.onNextClick()}
                className="!h-8 !w-8"
            />
        </div>
    );
};

export default DatePickerNavBar;
