import React, { FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
    title?: string;
    description?: string | ReactNode;
    className?: string;
}

const FormSection: FunctionComponent<Props> = ({
    title,
    description,
    className,
    children,
}) => {
    return (
        <section className={classNames('mb-4 2xl:mb-12', className)}>
            {title && (
                <header className="mb-4">
                    <h2 className="ui-action-bar__title">{title}</h2>
                    {typeof description === 'string' && (
                        <p className="mt-4">{description}</p>
                    )}
                    {typeof description !== 'string' && description}
                </header>
            )}
            {children}
        </section>
    );
};

export default FormSection;
