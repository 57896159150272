import React, { FunctionComponent, Suspense } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/browser';
import { ErrorBoundary } from 'react-error-boundary';
import { PrivateRoute } from '@/Modules/Routes';
import history from './Modules/History';
import ToastContainer from '@/Components/ToastContainer';
import { AppError } from '@/Components/AppError';
import GistChatBubbleProvider from '@/Providers/GistChatBubbleProvider';
import lazyRetry from '@/Utilities/lazyRetry';
import queryClient from '@/Modules/QueryClient';
import { ReactComponent as Logo } from '../images/logo-trans.svg';
import Loader from '@/Components/Loader';

interface Props {}

const AuthScreen = React.lazy(() =>
    lazyRetry(() => import('./Screens/Auth'), 'AuthScreen')
);
const AppScreen = React.lazy(() =>
    lazyRetry(() => import('./Screens/App'), 'AppScreen')
);

const App: FunctionComponent<Props> = (props) => {
    const myErrorHandler = (error: Error, info: { componentStack: string }) => {
        Sentry.captureException(error);
    };

    return (
        <ErrorBoundary FallbackComponent={AppError} onError={myErrorHandler}>
            <Suspense
                fallback={
                    <div className="flex flex-col items-center p-12">
                        <Logo className="mb-8 max-w-[140px]" />
                        <Loader />
                    </div>
                }
            >
                <QueryClientProvider client={queryClient}>
                    <Router history={history}>
                        <GistChatBubbleProvider>
                            <Switch>
                                <Route path="/auth" component={AuthScreen} />
                                <PrivateRoute path="/" component={AppScreen} />
                            </Switch>
                        </GistChatBubbleProvider>
                    </Router>
                    <ToastContainer />
                </QueryClientProvider>
            </Suspense>
        </ErrorBoundary>
    );
};

export default App;
