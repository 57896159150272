import React, { FunctionComponent } from 'react';
import { useInfiniteQuery } from 'react-query';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import InfiniteItemList from '@/Components/InfiniteItemList';
import ItemListStickyHeader from '@/Components/ItemList/StickyHeader';
import SmallCaps from '@/Components/SmallCaps';
import ZeroContent from '@/Components/ZeroContent';
import { ClientReviewListItem } from '@/Features/Clients';
import { queryKeys } from '@/Services/QueryKeys';
import { Client } from '@/Models/Client';
import { getNextPageParam } from '@/Services/Api';

interface Props {}

const Component: FunctionComponent<Props> = () => {
    const { clientStore } = RootStore.stores;

    const queryResult = useInfiniteQuery(
        queryKeys.clients.toReview.list,
        async ({ pageParam = 1 }) => {
            return await clientStore.list(
                {
                    page: pageParam,
                    type: 'to-review',
                    include: ['dataReviews'],
                    append: ['currentAgreement', 'currentParq'],
                },
                true
            );
        },
        {
            getNextPageParam,
            refetchOnMount: 'always',
        }
    );

    return (
        <InfiniteItemList<Client>
            queryResult={queryResult}
            renderHeader={
                <ItemListStickyHeader className="xl:grid-cols-[36px_50%_minmax(min-content,auto)]">
                    <SmallCaps className="col-span-2">Email</SmallCaps>
                    <SmallCaps></SmallCaps>
                </ItemListStickyHeader>
            }
            renderItem={(client, classes) => (
                <ClientReviewListItem client={client} className={classes} />
            )}
            zeroContent={
                <ZeroContent
                    inline
                    icon="CustomClients"
                    title="Nothing to review"
                    content={
                        <p>
                            Details requiring your attention appear here when a
                            client signs up or completes a new PAR-Q.
                        </p>
                    }
                />
            }
        />
    );
};

export const ClientReviewList = observer(Component);
