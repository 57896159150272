import { applySnapshot, flow, getSnapshot, types } from 'mobx-state-tree';
import { PortalInvite } from '../Models/PortalInvite';
import PortalInviteTransport from '../Services/Transport/PortalInviteTransport';

export const PortalInviteStore = types
    .model('PortalInviteStore', {
        invites: types.map(PortalInvite),
    })

    .actions((self) => ({
        list: flow(function* list(requestData, withMeta = false) {
            const { data } = yield PortalInviteTransport.list(requestData);
            const items = data.data.map((item) => self.createOrUpdate(item));

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        createOrUpdate(invite) {
            if (self.invites.has(invite.uuid)) {
                const existing = self.invites.get(invite.uuid);
                applySnapshot(existing, {
                    ...getSnapshot(existing),
                    ...invite,
                });
            } else {
                self.invites.set(invite.uuid, invite);
            }

            return self.invites.get(invite.uuid);
        },

        delete(uuid) {
            self.invites.delete(uuid);
        },
    }));
