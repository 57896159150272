import React, { FunctionComponent, useContext } from 'react';
import {
    ScheduleEventAvailabilityView,
    SchedulePlannedEvent,
} from '@/Features/Schedule';
import PlanScheduleEventsContext from '@/Contexts/PlanScheduleEventsContext';
import { uiColors } from '@/Theme';
import { ClearButton } from '@/Components/Button';
import RootStore from '@/Stores/RootStore';
import { ReactComponent as BinIcon } from '../../../../images/icon-bin.svg';

export interface ScheduleEventAvailabilityListItemProps {
    event: SchedulePlannedEvent;
    onBeforeVisitSchedule?(): void;
    allowRemove?: boolean;
    allowAssignment?: boolean;
    view?: any; // Comes from AngularJS
}

export const ScheduleEventAvailabilityListItem: FunctionComponent<
    ScheduleEventAvailabilityListItemProps
> = ({ event, onBeforeVisitSchedule, view, allowRemove, allowAssignment }) => {
    const { setAssignedUser, removeEvent } = useContext(
        PlanScheduleEventsContext
    );

    function viewEvent(newCardId: string, user?: string) {
        const card = view.findCard(newCardId);

        if (card) {
            if (typeof onBeforeVisitSchedule === 'function') {
                onBeforeVisitSchedule();
            }

            if (typeof user === 'string') {
                view.setCurrentCalendar(!!user ? user : 'unassigned');
            }

            view.selectCard(card);
            RootStore.stores.uiStateStore.closeStack();
        }
    }

    function removeNewEvent(newCardId: string) {
        const card = view.findCard(newCardId);

        if (card) {
            view.removeCard(card);
        }

        removeEvent(newCardId);
    }

    function handleUserChange(value: string) {
        setAssignedUser(value, event.id);

        const card = view.findCard(event.id);

        if (card) {
            card.setAssignedUser(value);
            view.action.updateCard(card);
            card.clearCache();
            view.calendar.clearCache();
            view.applyScope();
        }
    }

    return (
        <div className="mb-2 flex flex-wrap items-stretch lg:flex-nowrap">
            <ScheduleEventAvailabilityView
                view={view}
                event={event}
                onViewEvent={() => viewEvent(event.id, event.assignedUser)}
                assignedUser={event.assignedUser}
                onUserChange={handleUserChange}
                allowAssignment={allowAssignment}
            />
            {allowRemove && (
                <ClearButton
                    icon={
                        <BinIcon className="fill-current text-gray-300 transition-colors group-hover:text-negative" />
                    }
                    onClick={() => removeNewEvent(event.id)}
                    fill={uiColors.negative.default}
                    className="group !h-12 !w-12 transition-opacity hover:opacity-75"
                />
            )}
        </div>
    );
};
