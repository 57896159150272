import React, { Fragment, FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { StyledAvatarContainer, StyledAvatarIcon } from './Style';
import EntryCard from '../EntryCard/EntryCard';
import ClassesDateConfirmButton from '../Classes/DateConfirmButton';
import ClassDateCancelButton from '../ClassDate/CancelButton';
import { ClassDateEnrolmentCountPill } from '@/Features/ClassDates';
import { formatDate, formatTime } from '../../Services/Date';
import { ClassDate } from '@/Models/ClassDate';
import OptionsButton from '@/Components/ClassDate/OptionsButton';

interface Props {
    classDate: ClassDate;
    withAvatar?: boolean;
    withLink?: boolean;
    withAttendingCount?: boolean;
    titleType?: 'className' | 'datetime';
    subtitle?: string;
    subtitleTypes?: ('date' | 'time' | 'location' | 'assignedUsers')[];
    withOptionsAccessory?: boolean;
    withConfirmationAccessory?: boolean;
    className?: string;
    onRemoved?(): void;
    onUpdated?(): void;
    onViewClosed?(): void;
    accessory?(): JSX.Element;
}

const ClassDateEntryCard: FunctionComponent<Props> = (props) => {
    const {
        withAvatar,
        withLink,
        withAttendingCount,
        classDate,
        titleType = 'className',
        accessory,
        onRemoved,
        onUpdated,
        onViewClosed,
        ...otherProps
    } = props;

    const getAccessory = (): any => {
        if (typeof accessory === 'function') {
            return accessory();
        } else if (props.withAttendingCount === true && classDate.isPrivate) {
            return () => <ClassDateEnrolmentCountPill classDate={classDate} />;
        } else if (props.withOptionsAccessory === true) {
            return () => <OptionsButton classDate={classDate} />;
        } else if (props.withConfirmationAccessory === true) {
            return () => (
                <Fragment>
                    <div className="mr-1">
                        <ClassesDateConfirmButton classDate={classDate} />
                    </div>
                    <ClassDateCancelButton
                        variant="icon"
                        classDate={classDate}
                        onSuccess={onRemoved}
                    />
                </Fragment>
            );
        }
    };

    const renderAvatar = () => {
        if (!withAvatar) return null;

        return () => (
            <StyledAvatarContainer>
                <StyledAvatarIcon />
            </StyledAvatarContainer>
        );
    };

    return (
        <EntryCard
            entry={classDate}
            withLocation
            withDuration
            avatar={renderAvatar()}
            type="class-date"
            title={
                <>
                    {titleType === 'className' && <span>{classDate.name}</span>}
                    {titleType === 'datetime' &&
                        `${formatDate(
                            classDate.start,
                            true,
                            true
                        )} (${formatTime(classDate.start)})`}
                </>
            }
            withLink={withLink}
            accessory={getAccessory()}
            handleUpdate={onUpdated}
            handleViewClosed={onViewClosed}
            {...otherProps}
        />
    );
};

export default observer(ClassDateEntryCard);
