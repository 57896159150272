import React, { FunctionComponent } from 'react';
import DayPicker, { DayPickerProps } from 'react-day-picker';
import classNames from 'classnames';
import DatePickerNavBar from '@/Components/DatePickerInput/NavBar';
import { Button } from '@/Components/Button';
import { inUserZone } from '@/Services/Date';
import { ScheduleEntryPopperActionType } from '@/Features/Schedule';

interface Props extends DayPickerProps {
    card: any;
    view: any;
    action: any;
    calendar: any;
    onDisplayChange(display: ScheduleEntryPopperActionType): void;
}

export const ScheduleEntryPopperContentDatePicker: FunctionComponent<Props> = ({
    card,
    view,
    action,
    calendar,
    onDisplayChange,
    ...props
}) => {
    function handleDayClick(day: Date) {
        const date = inUserZone(day);
        const dayView = calendar.getDay(date);
        const calendarView =
            view.view === 'week' ? calendar.getWeek(date) : dayView;

        onDisplayChange('options');

        view.show(calendarView);
        card.moveToView(dayView);
        action.updateCard(card);
        view.applyScope();
    }

    return (
        <>
            <div className="flex h-14 items-center gap-2 p-2">
                <Button
                    icon="Close"
                    onClick={() => onDisplayChange('options')}
                />
                <div className="flex-1 text-center font-bold">Move to date</div>
                <div className="w-10" />
            </div>

            <div className="-mt-2 pb-3 pt-1">
                <DayPicker
                    className={classNames('w-full', {
                        SchedulePopper: true,
                    })}
                    showWeekNumbers
                    showOutsideDays
                    firstDayOfWeek={1}
                    navbarElement={DatePickerNavBar}
                    initialMonth={card.entry.start_at.toJSDate()}
                    selectedDays={[card.entry.start_at.toJSDate()]}
                    onDayClick={handleDayClick}
                    {...props}
                />
            </div>
        </>
    );
};
