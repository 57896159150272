import angular from 'angular';
import { react2angular } from 'react2angular';
import ScheduleAssignedUserSelect from '../../../js/Components/ScheduleAssignedUserSelect';

angular
    .module('component.react.schedule-assigned-user-select', [])
    .component(
        'reactScheduleAssignedUserSelect',
        react2angular(ScheduleAssignedUserSelect, [
            'enabled',
            'selected',
            'onChange',
        ])
    );
