import { AxiosError } from 'axios';
import { FormikErrors } from 'formik/dist/types';
import Notification from '@/Services/Notification';

type FormikErrorSetter<Values> = (errors: FormikErrors<Values>) => void;

export default function <Values>(
    e: AxiosError,
    setErrors: FormikErrorSetter<Values>
) {
    if (e.response?.status === 422) {
        setErrors((e as AxiosError).response?.data.errors);

        return true;
    }

    Notification.error(
        e.response?.status === 429
            ? 'Too many requests. Please try again later.'
            : undefined
    );

    return false;
}
