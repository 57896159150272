import React from 'react';
import styled from 'styled-components';
import { fonts, uiColors } from '../../Theme';

const Subtitle = styled.div`
    margin: 0;
    font-size: ${fonts.size(13)};
    color: ${uiColors.text.light};
`;

Subtitle.propTypes = {};

export default Subtitle;
