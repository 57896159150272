import angular from 'angular';

import '../wizard/backwards-button/button';
import '../wizard/cancel-button/button';
import '../wizard/forwards-button/button';
import '../wizard/header/header';
import '../wizard/failure-pane/pane';
import '../wizard/form-pane/pane';
import '../wizard/success-pane/pane';

angular
    .module('component.wizard', [
        'component.wizard.backwards-button',
        'component.wizard.cancel-button',
        'component.wizard.forwards-button',
        'component.wizard.header',
        'component.wizard.failure-pane',
        'component.wizard.form-pane',
        'component.wizard.success-pane',
    ])

    .component('wizard', {
        template: `<div class="wizard" ng-transclude></div>`,
        transclude: true,
        bindings: {
            wizard: '=?',
            closeWizard: '=backwards',
            cancelDialog: '=cancel',
            completeWizard: '=complete',
        },
        controller: [
            '$q',
            '$scope',
            function ($q, $scope) {
                var $ctrl = this;
                var panes = [];

                $ctrl.wizard = $ctrl;

                $ctrl.addPane = function (pane) {
                    pane.index = panes.length;
                    pane.selected = false;
                    pane.ready = true;
                    panes.push(pane);

                    if (!$ctrl.pane && pane.canNavigateForwardsTo()) {
                        $ctrl.select(pane);
                    }
                };

                $ctrl.select = function (pane) {
                    if ($ctrl.pane) {
                        $ctrl.pane.selected = false;
                    }

                    $ctrl.pane = pane;
                    $ctrl.pane.selected = true;

                    if (!!$ctrl.pane.onNavigateTo) {
                        $ctrl.pane.onNavigateTo();
                    }
                };

                $ctrl.canNavigateBackwards = function () {
                    var closestPane;

                    for (var pane of panes) {
                        if (
                            pane.index < $ctrl.pane.index &&
                            pane.canNavigateBackwardsTo()
                        ) {
                            closestPane = pane;
                        }
                    }

                    return !!closestPane || $ctrl.closeWizard;
                };

                $ctrl.backwards = function () {
                    if ($ctrl.navigatingBackwards) return;

                    if ($ctrl.pane.onBackwards) {
                        $ctrl.navigatingBackwards = true;

                        $q.when($ctrl.pane.onBackwards())
                            .then($ctrl.backwardsAction)
                            .finally(() => {
                                $ctrl.navigatingBackwards = false;
                            });
                    } else {
                        $ctrl.backwardsAction();
                    }
                };

                $ctrl.backwardsAction = function () {
                    var closestPane;

                    for (var pane of panes) {
                        if (
                            pane.index < $ctrl.pane.index &&
                            pane.canNavigateBackwardsTo()
                        ) {
                            closestPane = pane;
                        }
                    }

                    if (!!closestPane) {
                        $ctrl.select(closestPane);
                    } else if (!!$ctrl.closeWizard) {
                        $ctrl.closeWizard();
                    }
                };

                $ctrl.forwards = function () {
                    if ($ctrl.navigatingForwards) return;

                    if (!!$ctrl.pane.onForwards) {
                        $ctrl.navigatingForwards = true;

                        $q.when($ctrl.pane.onForwards())
                            .then($ctrl.forwardsAction)
                            .finally(() => {
                                $ctrl.navigatingForwards = false;
                            });
                    } else {
                        $ctrl.forwardsAction();
                    }
                };

                $ctrl.forwardsAction = function () {
                    var closestPane;

                    for (var pane of panes) {
                        if (
                            pane.index > $ctrl.pane.index &&
                            pane.canNavigateForwardsTo()
                        ) {
                            closestPane = pane;
                            break;
                        }
                    }

                    if (!!closestPane) {
                        $ctrl.select(closestPane);
                    } else {
                        if (
                            pane.index === $ctrl.pane.index &&
                            !!$ctrl.completeWizard
                        ) {
                            $ctrl.completeWizard();
                        } else {
                            $ctrl.cancel();
                        }
                    }
                };

                $ctrl.cancel = function () {
                    if (!!$ctrl.pane.onCancel) {
                        $ctrl.pane.onCancel();
                    }

                    $ctrl.cancelDialog();
                };

                $ctrl.isLastPane = () => {
                    return $ctrl.pane.index === panes.length - 1;
                };

                $ctrl.pane = null;
                $ctrl.navigatingBackwards = false;
                $ctrl.navigatingForwards = false;
            },
        ],
    });
