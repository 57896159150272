import React, { Fragment, FunctionComponent, useContext } from 'react';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import { Agreement } from '@/Models/Agreement';
import { ClearButton } from '@/Components/Button';
import EventConfirmationModalContext from '@/Contexts/EventConfirmationModalContext';
import Notification from '@/Services/Notification';
import { uiColors } from '@/Theme';
import queryClient from '@/Modules/QueryClient';

interface Props {
    agreement: Agreement;
    className?: string;
}

const Component: FunctionComponent<Props> = ({ agreement, className }) => {
    const { agreementStore } = RootStore.stores;

    const confirmationContext = useContext(EventConfirmationModalContext);

    async function deleteAgreement() {
        try {
            await agreementStore.destroy(agreement.identifier);
            await queryClient.invalidateQueries(['agreements', 'list']);
            Notification.success('Agreement deleted');
        } catch (e) {
            console.log(e);
            Notification.error();
            return;
        }
    }

    function handleClick() {
        confirmationContext.requireConfirmation({
            content: () => (
                <>
                    <p className="mb-2">
                        Are you sure you want to remove this agreement?
                    </p>
                    <p>
                        You will no longer be able to sign up clients to this
                        agreement (those already signed will remain unchanged).
                    </p>
                </>
            ),
            onConfirmed: deleteAgreement,
        });
    }

    return (
        <ClearButton
            icon="Delete"
            fill={uiColors.negative.default}
            onClick={handleClick}
            className={className}
        >
            Delete
        </ClearButton>
    );
};

export const DeleteAgreementButton = observer(Component);
