import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { observer } from 'mobx-react';
import withQueryClient from '@/HOCs/WithQueryClient';
import { StackableModalProps } from '@/Components/RootModal';
import Modal from '@/Components/Modal';
import { PrimaryButton } from '@/Components/Button';
import useModalPreload from '@/Hooks/useModalPreload';
import RootStore from '@/Stores/RootStore';
import { DateTime } from 'luxon';
import { Session } from '@/Models/Session';
import { ClassDate } from '@/Models/ClassDate';
import { ScheduleEventAvailabilityList } from '@/Features/Schedule';
import Alert from '@/Components/Alert';
import Checkbox from '@/Components/Checkbox';
import {
    ResultRescheduled,
    ResultRescheduledError,
} from '../../../../assets/js/actions/RescheduleAction';
import NotificationOptions from '@/Components/NotificationOptions';
import { EventConfirmationNotificationMap } from '@/Types/EventConfirmationNotificationMap';
import PlanScheduleEventsContext from '@/Contexts/PlanScheduleEventsContext';
import useTeam from '@/Hooks/useTeam';
import FormField from '@/Components/FormField';
import ModalFormError from '@/Components/ModalFormError';

interface Props extends StackableModalProps {
    scheduleId: string;
    calendarEvent: {
        type: 'session' | 'class-date' | 'diary-entry';
        start: DateTime;
        end: DateTime;
        assignedUser: string;
    };
    view: any;
}

const Component: FunctionComponent<Props> = ({
    scheduleId,
    calendarEvent,
    ...props
}) => {
    const { entryStore } = RootStore.stores;
    const { shouldDisplayPreload, LoaderModal } = useModalPreload(
        props.stackProps,
        {},
        () =>
            new Promise(async (resolve) => {
                const resolved = await entryStore.fetchEntry(
                    scheduleId,
                    calendarEvent.type,
                    {},
                    true
                );
                setEntry(resolved);

                resolve(true);
            })
    );
    const {
        events,
        setEvents,
        getFormattedEvents,
        makeUnassignedAvailable,
        reset: resetContext,
    } = useContext(PlanScheduleEventsContext);
    const { hasTeamMembers } = useTeam();
    const [entry, setEntry] = useState<Session | ClassDate>();
    const [teamNotifications, setTeamNotifications] = useState(true);
    const [isBusy, setIsBusy] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [clientNotifications, setClientNotifications] =
        useState<EventConfirmationNotificationMap>();
    const [classNotifications, setClassNotifications] = useState(true);

    useEffect(() => {
        setEvents([
            {
                id: scheduleId,
                start: calendarEvent.start,
                end: calendarEvent.end,
                isAvailable: true,
                assignedUser: calendarEvent.assignedUser,
            },
        ]);
    }, [calendarEvent]);

    const teamNotificationsAvailable = useMemo(() => {
        return (
            calendarEvent.start > DateTime.local() &&
            !!calendarEvent.assignedUser
        );
    }, [calendarEvent.start, calendarEvent.assignedUser]);

    const clientNotificationsAvailable = useMemo(() => {
        return calendarEvent.start > DateTime.local();
    }, [calendarEvent.start]);

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        setIsBusy(true);

        const result = await props.view.action.save(
            getFormattedEvents(events),
            teamNotifications,
            calendarEvent.type === 'class-date'
                ? classNotifications
                : clientNotifications,
            makeUnassignedAvailable
        );

        if (result instanceof ResultRescheduledError) {
            setErrors(result.errors);
            setIsBusy(false);
        } else if (result instanceof ResultRescheduled) {
            props.view.closeAction();
            resetContext();
        } else {
            setIsBusy(false);
        }
    }

    function handleNotificationChange(
        notify: EventConfirmationNotificationMap
    ) {
        setClientNotifications(notify);
    }

    if (shouldDisplayPreload) {
        return LoaderModal;
    }

    return (
        <Modal
            stackProps={props.stackProps}
            title="Reschedule event"
            rightAction={() => (
                <PrimaryButton type="submit" isLoading={isBusy}>
                    Save
                </PrimaryButton>
            )}
            onSubmit={handleSubmit}
            headerContent={
                <ModalFormError isValid={!Object.keys(errors ?? {}).length} />
            }
        >
            <section className="dialog__panel">
                <ScheduleEventAvailabilityList
                    title="Availability"
                    allowRemove={false}
                    eventType={
                        calendarEvent.type === 'session' ? 'session' : 'date'
                    }
                    allowAssignment={calendarEvent.type !== 'diary-entry'}
                    view={props.view}
                />

                {!!errors?.events?.[0] && (
                    <FormField.Error>
                        <p>{errors?.events?.[0]}</p>
                    </FormField.Error>
                )}
            </section>

            {calendarEvent.type !== 'diary-entry' && (
                <>
                    {hasTeamMembers && (
                        <section className="dialog__panel">
                            <h2 className="ui-action-bar__title mb-2">
                                Team notifications
                            </h2>

                            {!teamNotificationsAvailable && (
                                <Alert
                                    type="warning"
                                    title="You cannot send notifications for an entry in the past"
                                    className="mt-3"
                                />
                            )}
                            {teamNotificationsAvailable && (
                                <Checkbox
                                    checked={teamNotifications}
                                    onChange={() =>
                                        setTeamNotifications(!teamNotifications)
                                    }
                                    label="Send email notifications to teachers affected by this change"
                                />
                            )}
                        </section>
                    )}

                    <section className="dialog__panel">
                        <h2 className="ui-action-bar__title mb-2">
                            Client notifications
                        </h2>
                        {!clientNotificationsAvailable && (
                            <Alert
                                type="warning"
                                title="You cannot send notifications for an entry in the past"
                                className="mt-3"
                            />
                        )}
                        {clientNotificationsAvailable &&
                            calendarEvent.type === 'session' && (
                                <NotificationOptions
                                    initialEmail
                                    sendsAppNotification
                                    withTitle={false}
                                    recipients={entry.clients}
                                    onChange={handleNotificationChange}
                                />
                            )}
                        {clientNotificationsAvailable &&
                            calendarEvent.type === 'class-date' && (
                                <Checkbox
                                    checked={classNotifications}
                                    onChange={() =>
                                        setClassNotifications(
                                            !classNotifications
                                        )
                                    }
                                    label="Send email / app notifications to enrolled clients affected by this change"
                                />
                            )}
                    </section>
                </>
            )}
        </Modal>
    );
};

export const ScheduleEntryRescheduleModal = withQueryClient(
    observer(Component)
);
