import React, { FunctionComponent, useMemo, useState } from 'react';
import { formatDate } from '@/Services/Date';
import { countInflect } from '@/Services/String';
import { Button, PrimaryButton, WhiteButton } from '@/Components/Button';
import { ScheduleEntryPopperActionType } from '@/Features/Schedule';
import classNames from 'classnames';
import UpdateSessionPack from '../../../../assets/js/actions/UpdateSessionPack';

interface Props {
    card: any;
    view: any;
    calendar: any;
    onDisplayChange(display: ScheduleEntryPopperActionType): void;
}

export const ScheduleEntryPopperContentRepeat: FunctionComponent<Props> = ({
    card,
    view,
    calendar,
    onDisplayChange,
}) => {
    const isUpdatingSessionPack = view.action instanceof UpdateSessionPack;
    const [count, setCount] = useState(2);
    const [success, setSuccess] = useState(false);
    const endDate = useMemo(
        () => formatDate(card.startDate.plus({ weeks: count - 1 }), true, true),
        [count]
    );
    const repeatLimit = useMemo(() => {
        if (isUpdatingSessionPack) {
            return (
                Math.floor(view.action.getAvailablePeriods() / card.length) + 1
            );
        }

        return 26;
    }, [view.action, card.length, isUpdatingSessionPack]);

    function handleConfirmed() {
        let calendarView = calendar.view;

        for (let i = 1; i < count; i++) {
            calendarView =
                calendarView.nameSingular === 'Day'
                    ? calendarView.nextWeek
                    : calendarView.next;

            const newCard = card.clone(true);
            newCard.moveToView(calendarView);
            newCard.clearCache();

            calendar.addItem(newCard);

            view.applyScope();
        }

        setSuccess(true);
        setTimeout(() => onDisplayChange('options'), 1500);
    }

    return (
        <>
            <div className="flex h-14 items-center gap-2 p-2">
                <Button
                    icon="Close"
                    onClick={() => onDisplayChange('options')}
                />
                <div className="relative flex h-8 flex-1 items-center justify-center text-center font-bold">
                    <span
                        className={classNames(
                            'opacity-100 transition-opacity',
                            { '!opacity-0': success }
                        )}
                    >
                        Repeat for
                    </span>
                    <span
                        className={classNames(
                            'absolute left-0 h-full w-full rounded bg-legacy-positive-300 p-1 text-legacy-positive-900 opacity-0 transition-opacity',
                            { 'opacity-100': success }
                        )}
                    >
                        Success!
                    </span>
                </div>
                <PrimaryButton
                    icon="Check"
                    disabled={success}
                    onClick={handleConfirmed}
                />
            </div>
            <div className="pt-4">
                <div className="flex items-center justify-center gap-4">
                    <WhiteButton
                        className="!h-12 !w-12"
                        type="button"
                        icon="Minus"
                        onClick={() => setCount(count - 1)}
                        disabled={count <= 2}
                    />
                    <p className="text-center text-lg">
                        {countInflect(count, 'week', 'weeks')}
                    </p>
                    <WhiteButton
                        className="!h-12 !w-12"
                        type="button"
                        icon="Plus"
                        onClick={() => setCount(count + 1)}
                        disabled={count >= repeatLimit}
                    />
                </div>
                <p className="mt-4 text-center">
                    Until <strong>{endDate}</strong>
                </p>
            </div>
            {count === repeatLimit && (
                <div className="mt-2 px-2 text-center text-xs text-negative">
                    {isUpdatingSessionPack ? 'Pack time' : 'Repeat'} limit
                    reached.
                </div>
            )}
        </>
    );
};
