import angular from 'angular';
import Action from './Action.js';
import * as Views from '../Calendar/Views';
import { lazyInjector } from '../lazy-injector';
import { DateTime } from 'luxon';
import Notification from '../../../js/Services/Notification';

export default class RescheduleAction extends Action {
    static get action() {
        return 'move';
    }

    static get type() {
        return 'unknown';
    }

    static get identifierName() {
        return 'eid';
    }

    get displaysSummaryPanel() {
        return true;
    }

    get isUpdating() {
        return true;
    }

    get type() {
        return this.constructor.type;
    }

    constructor(calendar, parameters = {}) {
        super(calendar);

        this.identifier = parameters[this.constructor.identifierName];
        this.parentIdentifier =
            parameters[this.constructor.parentIdentifierName];

        // const identifierName = this.constructor.identifierName;

        this.notifyVia = {};
        this.hasUpdatedCard = false;
        this.parameters = {
            action: this.constructor.action,
            type: this.constructor.type.nameSingular,
            [this.constructor.identifierName]: this.identifier,
            [this.constructor.parentIdentifierName]: this.parentIdentifier,
            date: parameters.date,
        };

        // if (!!this.identifier && !!this.parameters.date) {
        //     this.replaceCard();
        // }
    }

    postPopulate() {
        console.log('RescheduleAction.postPopulate');
        if (!!this.identifier && !!this.parameters.date) {
            this.replaceCard();
        }
    }

    static canCreateFromParameters(parameters) {
        return (
            angular.isDefined(parameters.action) &&
            angular.isDefined(parameters.type) &&
            angular.isDefined(parameters[this.identifierName]) &&
            parameters.action === this.action &&
            parameters.type === this.type.nameSingular &&
            DateTime.fromFormat(parameters.date, 'yyyy-MM-dd').isValid
        );
    }

    get canSelectCards() {
        return false;
    }

    get canPopperCards() {
        return false;
    }

    get isAvailable() {
        return true;
    }

    get isCancellable() {
        return true;
    }

    get isRescheduling() {
        return true;
    }

    get isReady() {
        return this.hasUpdatedCard;
    }

    get hasUnsavedChanges() {
        return this.hasUpdatedCard;
    }

    get cards() {
        return this.calendar.selectedCards;
    }

    get canSendNotifications() {
        return Object.keys(this.notifyVia).length;
    }

    replaceCard() {
        console.log('RescheduleAction.replaceCard');
        for (let card of this.calendar.cards) {
            if (
                card[this.constructor.identifierName] ===
                    this.parameters[this.constructor.identifierName] &&
                card.type === this.parameters.type
            ) {
                this.original = card;

                this.card = card.clone();
                this.card.select();
                this.card.isFocused = true;
                this.card.isMovable = true;
                this.card.isResizable = true;

                this.calendar.removeItem(this.original);
                this.calendar.addItem(this.card);

                break;
            }
        }

        if (!this.card) {
            return;
        }

        if (
            (this.calendar.view instanceof Views.Day &&
                this.card.day != this.calendar.view) ||
            (this.calendar.view instanceof Views.Week &&
                this.card.week != this.calendar.view)
        ) {
            this.onDateChange(this.calendar.view.date);
        }
    }

    restoreCard() {
        if (this.card && this.original) {
            this.calendar.removeItem(this.card);
            this.calendar.addItem(this.original);
        }
    }

    updateCard(card) {
        this.hasUpdatedCard =
            this.card.day != this.original.day ||
            this.card.start != this.original.start ||
            this.card.end != this.original.end ||
            this.card.entry?.assignedUser != this.original.entry?.assignedUser;
    }

    cancel() {
        return new Promise((resolve) => {
            this.restoreCard();
            resolve();
        });
    }

    finalise() {
        return new Promise((resolve) => {
            this.card.isFocused = false;
            this.card.isSelected = false;
            this.card.isMovable = false;
            this.card.isResizable = false;
            resolve();
        });
    }

    verify() {
        return new Promise((resolve) => {
            resolve(new ResultVerified());
        });
    }

    save(events, teamNotifications, clientNotifications) {
        let $http = lazyInjector.$injector.get('$http');

        return new Promise((resolve) => {
            $http
                .post(
                    `/v1/${this.type.namePlural}/${this.card.eid}/reschedule`,
                    {
                        entry: events[0],
                        teamNotifications,
                        clientNotifications,
                    }
                )
                .then(({ data }) => {
                    resolve(new ResultRescheduled(this.type));
                })
                .catch((error) => {
                    resolve(new ResultRescheduledError(error?.data?.errors));
                });
        });
    }

    successNotification() {
        new ResultRescheduled(this.type);
    }
}

export class ResultRescheduled {
    constructor(type) {
        Notification.success(`${type.titleSingular} rescheduled.`);
    }
}

export class ResultRescheduledError {
    constructor(errors) {
        this._errors = errors;
        Notification.error();
    }

    get errors() {
        return this._errors;
    }
}

export class ResultRescheduledAndNotified extends ResultRescheduled {}
