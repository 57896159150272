import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import { Message } from '@/Models/Message';
import {
    Button,
    ClearButton,
    PrimaryButton,
    PrimaryResponsiveButton,
    ResponsiveButton,
} from '@/Components/Button';
import classNames from 'classnames';

interface Props {
    variant?: 'profile' | 'default' | 'primary' | 'menu';
    isResponsive?: boolean;
    selected?: string[];
    icon?: 'MessageText';
    iconOnly?: boolean;
    onCreate?(message: Message): void;
    className?: string;
    subject?: string;
}

const Component: FunctionComponent<Props> = ({
    className,
    onCreate,
    isResponsive,
    selected,
    children,
    icon,
    iconOnly = false,
    subject,
    variant = 'default',
}) => {
    const { uiStateStore } = RootStore.stores;

    const handleClick = () => {
        uiStateStore.pushModal('MESSAGE_CREATE', {
            onCreate,
            selected,
            subject,
        });
    };

    const buttonProps = {
        type: 'button' as 'button' | 'submit' | undefined,
        onClick: handleClick,
        icon: icon ?? 'Plus',
        children: iconOnly ? undefined : children ?? 'Message',
        className,
    };

    if (variant === 'profile') {
        return (
            <button
                onClick={handleClick}
                className={classNames(
                    'text-base font-bold text-link',
                    className
                )}
            >
                {children}
            </button>
        );
    }

    if (variant === 'menu') {
        return <ClearButton {...buttonProps} />;
    }

    if (variant === 'primary') {
        return (
            <>
                {isResponsive && <PrimaryResponsiveButton {...buttonProps} />}
                {!isResponsive && <PrimaryButton {...buttonProps} />}
            </>
        );
    }

    return (
        <>
            {isResponsive && <ResponsiveButton {...buttonProps} />}
            {!isResponsive && <Button {...buttonProps} />}
        </>
    );
};

export const CreateMessageButton = observer(Component);
