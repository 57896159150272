export default class Action {
    static get action() {
        return 'unknown';
    }

    static get type() {
        return 'unknown';
    }

    constructor(calendar) {
        this.calendar = calendar;
        this.parameters = {};
        this.isLoading = false;
        this.isInteractingWithCard = false;
        this.isUpdatingCard = false;
        this.hasAddedCard = false;
        this.hasUpdatedCard = false;
        this.periodsAvailable = 96;
        this.periodsUnscheduled = 96;
    }

    get displaysSummaryPanel() {
        return false;
    }

    get type() {
        return this.constructor.type;
    }

    get cancelText() {
        return 'Cancel';
    }

    get canPlaceCards() {
        return false;
    }

    get canSelectCards() {
        return false;
    }

    get canPopperCards() {
        return true;
    }

    get isAvailable() {
        return false;
    }

    get isCancellable() {
        return this.isAvailable;
    }

    get isCreating() {
        return false;
    }

    get isRescheduling() {
        return false;
    }

    get isUpdating() {
        return false;
    }

    get hasUnsavedChanges() {
        return false;
    }

    get hidesExisting() {
        return false;
    }

    prepare() {}

    processCardUsing(card) {
        return card;
    }

    postPopulate() {}

    beginAddingCard(card) {}

    findCard(internalId) {
        return this.calendar.findCard(internalId);
    }

    addCard(card) {
        this.calendar.addItem(card);
        this.hasAddedCard = true;
    }

    endAddingCard(card) {}

    beginRemovingCard(card) {}

    removeCard(card) {
        this.calendar.removeItem(card);
    }

    endRemovingCard(card) {}

    beginUpdatingCard(card) {
        this.isUpdatingCard = true;
        this.hasUpdatedCard = false;
    }

    updateCard(card) {
        this.hasUpdatedCard = true;
    }

    endUpdatingCard(card) {
        this.isUpdatingCard = false;
        // this.calendar.validateItems([card]);
    }

    onDateChange() {}
}
