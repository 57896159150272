import React, { FunctionComponent } from 'react';
import { Field, FieldProps, FormikProps, withFormik } from 'formik';
import Modal from '@/Components/Modal';
import { PrimaryButton } from '@/Components/Button';
import FormField from '@/Components/FormField';
import Checkbox from '@/Components/Checkbox';
import PortalTransport from '../../../Services/Transport/PortalTransport';
import Notification from '../../../Services/Notification';
import RootStore from '../../../Stores/RootStore';
import Notice from '@/Components/Notice';
import { observer } from 'mobx-react';
import queryClient from '@/Modules/QueryClient';
import FormAgreementSelectField from '@/Components/FormAgreementSelectField';
import { ClientSignUpInviteFormValues } from '@/Features/Clients';
import { StackableModalProps } from '@/Components/RootModal';
import FormTextField from '@/Domain/Form/FormTextField';
import { AxiosError } from 'axios';
import history from '../../../Modules/History';
import CustomerSupport from '@/Services/CustomerSupport';
import { useQuery } from 'react-query';
import { queryKeys } from '@/Services/QueryKeys';
import handleServerValidation from '@/Utilities/handleServerValidation';
import FormCheckboxField from '@/Components/FormCheckboxField';
import FormTextAreaField from '@/Components/FormTextAreaField';

interface Props extends StackableModalProps {
    email?: string;
}

const Component: FunctionComponent<
    Props & FormikProps<ClientSignUpInviteFormValues>
> = (props) => {
    const { currentUserStore, agreementStore } = RootStore.stores;
    const { organisation } = currentUserStore;
    const { values, handleSubmit, isSubmitting } = props;
    const portalIsAccessible =
        organisation.portalEnabled && organisation.portalLoginEnabled;

    useQuery(queryKeys.agreements.list, () => agreementStore.list(), {
        onSuccess: () => {
            const defaultAgreement = agreementStore.default;

            props.setFieldValue(
                'agreement',
                defaultAgreement?.eid ?? agreementStore.available[0]?.eid
            );
        },
    });

    return (
        <Modal
            title="Sign-up invite"
            onSubmit={handleSubmit}
            rightAction={() => (
                <PrimaryButton isLoading={isSubmitting}>Send</PrimaryButton>
            )}
            stackProps={props.stackProps}
        >
            <div className="dialog__panel">
                <p>
                    Invite a new client to sign themselves up remotely. We'll
                    let you know when they have.
                </p>
            </div>

            <fieldset className="dialog__panel">
                <h2 className="ui-action-bar__title mb-2">Email address</h2>

                <FormField.Row className="!mb-0">
                    <FormTextField
                        name="email"
                        inputProps={{ type: 'email' }}
                    />
                    <FormField className="m-0" />
                </FormField.Row>
            </fieldset>

            <fieldset className="dialog__panel">
                <h2 className="ui-action-bar__title mb-2">Options</h2>

                <FormField>
                    <FormCheckboxField
                        name="includeInviteMessage"
                        label="Include personal message"
                    />

                    <FormField.Children isHidden={!values.includeInviteMessage}>
                        <FormTextAreaField
                            name="inviteMessage"
                            isDisabled={!values.includeInviteMessage}
                            displayErrors="after-submit"
                        />
                    </FormField.Children>
                </FormField>

                <FormField>
                    <FormCheckboxField
                        name="signAgreement"
                        label="Include agreement signing"
                    />

                    <FormField.Children isHidden={!values.signAgreement}>
                        <FormField.Row>
                            <FormAgreementSelectField
                                name="agreement"
                                withCreateButton
                                displayErrors="after-submit"
                                fieldProps={{
                                    isDisabled: !values.signAgreement,
                                }}
                            />
                            <FormField className="m-0" />
                        </FormField.Row>
                    </FormField.Children>
                </FormField>

                <FormField.Row>
                    <Field name="portalEnabled">
                        {({ field }: FieldProps) => (
                            <FormField>
                                <Checkbox
                                    checked={values.portalEnabled}
                                    label="Include access to client area and app"
                                    isDisabled={!portalIsAccessible}
                                    {...field}
                                />
                                {!portalIsAccessible && (
                                    <div className="mt-2">
                                        <Notice type="warning">
                                            Client area disabled in brand
                                            settings.
                                        </Notice>
                                    </div>
                                )}
                            </FormField>
                        )}
                    </Field>
                    <FormField className="m-0" />
                </FormField.Row>
            </fieldset>
        </Modal>
    );
};
export const SendClientSignUpInviteModal = withFormik<
    Props,
    ClientSignUpInviteFormValues
>({
    mapPropsToValues: (props) => ({
        email: props.email || '',
        signAgreement: false,
        agreement: '',
        portalEnabled: false,
    }),
    handleSubmit: async (values, { props, setErrors }) => {
        try {
            await PortalTransport.signUp(values);
            await queryClient.invalidateQueries(queryKeys.clientInvites.list);
            await queryClient.invalidateQueries(['clients', 'counts']);

            Notification.success('Signup invite sent');

            CustomerSupport.trackEvent('Client Sign Up Invite Sent');

            RootStore.stores.uiStateStore.closeStack();

            history.push('/clients/invites');
        } catch (e) {
            if ((e as AxiosError).response?.status === 429) {
                Notification.error(
                    'Please wait a minute before sending further invites.'
                );
                return;
            } else {
                handleServerValidation(e as AxiosError, setErrors);
            }

            return;
        }
    },
})(observer(Component));
