import React, { FunctionComponent, useContext } from 'react';
import { observer } from 'mobx-react';
import { uiColors } from '@/Theme';
import { ClearButton } from '@/Components/Button';
import EventConfirmationModalContext from '@/Contexts/EventConfirmationModalContext';
import { WIDTHS } from '@/Components/Modal';
import Notification from '@/Services/Notification';
import queryClient from '@/Modules/QueryClient';
import { classDateQueryKeys } from '@/Features/ClassDates';
import ClassTransport from '@/Services/Transport/ClassTransport';
import { Class } from '@/Models/Class';
import { queryKeys } from '@/Services/QueryKeys';

interface Props {
    classContainer: Class;
    selected: string[];
    onSuccess?: () => void;
}

const Component: FunctionComponent<Props> = ({
    classContainer,
    selected,
    onSuccess,
}) => {
    const confirmationContext = useContext(EventConfirmationModalContext);

    const cancel = async () => {
        try {
            await ClassTransport.cancelSelectedClassDates(
                classContainer.uuid,
                selected
            );

            await queryClient.invalidateQueries(classDateQueryKeys.all);
            await queryClient.invalidateQueries(
                queryKeys.classes.view(classContainer.uuid)
            );

            Notification.success('Class dates cancelled successfully.');

            if (typeof onSuccess === 'function') {
                onSuccess();
            }
        } catch (error) {
            Notification.error();
        }
    };

    const handleClick = () => {
        confirmationContext.requireConfirmation({
            title: null,
            content: () => (
                <>
                    <p className="mb-2">
                        Are you sure you want to cancel these class dates?
                    </p>
                    <p className="mb-2">
                        Any clients who paid to enrol onto these classes will
                        receive a credit to enrol onto another{' '}
                        {classContainer.name} date in the future.
                    </p>
                    <p>
                        Clients who used class passes will have credits returned
                        to them.
                    </p>
                </>
            ),
            onConfirmed: cancel,
            width: WIDTHS.medium,
        });
    };

    return (
        <ClearButton
            type="button"
            icon="CloseCircle"
            fill={uiColors.negative.default}
            className="min-w-[120px] !justify-start"
            onClick={handleClick}
        >
            Cancel
        </ClearButton>
    );
};

export const CancelSelectedClassDatesButton = observer(Component);
