import { types } from 'mobx-state-tree';
import RootStore from '../Stores/RootStore';

export const Employer = types
    .model('Employer', {
        uuid: types.identifier,
        name: types.string,
        contactFirstName: types.string,
        contactLastName: types.string,
        contactEmail: types.maybeNull(types.string),
        contactPhone: types.maybeNull(types.string),
        contactSmsPhone: types.maybeNull(types.string),
        contactAddress: types.maybeNull(types.string),
        isDeleted: types.boolean,

        usage: types.maybe(
            types.model({
                classes: types.number,
            })
        ),
    })

    .views((self) => ({
        get contactFullName() {
            return `${self.contactFirstName} ${self.contactLastName}`;
        },

        get hasUsage() {
            return !!self.usage;
        },

        get notificationIdentifier() {
            return self.uuid;
        },

        get fullName() {
            return self.contactFullName;
        },

        get hasEmail() {
            return !!self.contactEmail;
        },

        get email() {
            return self.contactEmail;
        },

        get hasSmsPhone() {
            return !!self.contactSmsPhone;
        },

        get smsPhone() {
            return self.contactSmsPhone;
        },
    }))

    .actions((self) => ({}));

export const EmployerReference = types.reference(Employer, {
    get(uuid) {
        return RootStore.stores.employerStore.find(uuid) || null;
    },
    set(employer) {
        return employer.uuid;
    },
});
