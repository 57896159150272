import React, { FunctionComponent } from 'react';
import Alert from '@/Components/Alert';

interface Props {
    type?: 'error' | 'warning' | 'info';
    message?: string;
}

const ModalHeaderAlert: FunctionComponent<Props> = ({
    type = 'error',
    message,
}) => {
    return (
        <div className="dialog__header__message -mx-4 mt-3 md:mx-0 md:pb-3">
            <Alert
                type={type}
                title={
                    message ??
                    'There was a problem with the details you entered.'
                }
            />
        </div>
    );
};

export default ModalHeaderAlert;
