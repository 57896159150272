import angular from 'angular';
import moment from 'moment';

angular
    .module('resource.client', [])

    .service('Client', [
        '$resource',
        '$http',
        'TransformerService',
        'ResourceFetcher',
        'ResourceScroller',
        function (
            $resource,
            $http,
            TransformerService,
            ResourceFetcher,
            ResourceScroller
        ) {
            let dates = ['dob', 'signedUpAt', 'agreementAcceptedAt'];

            var Client = $resource(
                '/v1/clients/:eid',
                { eid: '@eid' },
                {
                    fetch: ResourceFetcher.createAction(),
                    scroll: ResourceScroller.createAction(),
                    query: {
                        isArray: true,
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                    get: {
                        transformResponse: function (
                            response,
                            headers,
                            status
                        ) {
                            if (status !== 200) {
                                return response;
                            }

                            response = angular.fromJson(response);

                            // If there's meta
                            if (response.meta) {
                                meta = response.meta;
                            }

                            // Return the data
                            return response.data;
                        },
                    },
                    save: {
                        method: 'POST',
                        transformRequest: function (data) {
                            // Format dates
                            dates.forEach((col) => {
                                if (!!data[col]) {
                                    data[col] = moment(data[col]).format(
                                        'YYYY-MM-DD'
                                    );
                                }
                            });

                            return angular.toJson(data);
                        },
                    },
                    update: {
                        method: 'PUT',
                    },
                    delete: {
                        method: 'DELETE',
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                }
            );

            Client.createFetcher = ResourceFetcher.createFactory(
                'client',
                Client
            );

            Client.createScroller = ResourceScroller.createFactory(
                'clients',
                Client
            );

            return Client;
        },
    ]);
