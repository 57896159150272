import BaseTransport from './BaseTransport';

class MembershipTransport extends BaseTransport {
    async fetch(uuid, params = {}) {
        return await this.get(`/v3/memberships/${uuid}`, params);
    }

    async list(params = {}) {
        return await this.get('/v3/memberships', params);
    }

    async store(data) {
        return await this.post('/v3/memberships', data);
    }

    async update(uuid, data) {
        return await this.put(`/v3/memberships/${uuid}`, data);
    }

    async destroy(uuid) {
        return await this.delete(`/v3/memberships/${uuid}`);
    }

    async archive(uuid) {
        return await this.post(`/v3/memberships/${uuid}/archive`);
    }

    async restore(uuid) {
        return await this.post(`/v3/memberships/${uuid}/restore`);
    }
}

export default new MembershipTransport();
