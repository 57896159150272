import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface Props {
    colStart?: string;
    className?: string;
}

const ItemListActionCell: FunctionComponent<Props> = ({
    className,
    children,
    colStart = 'col-start-3',
}) => {
    return (
        <div
            className={classNames(
                'row-start-1 self-center justify-self-end xl:col-auto xl:row-auto',
                colStart,
                className
            )}
        >
            {children}
        </div>
    );
};

export default ItemListActionCell;
