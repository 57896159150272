import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { ClassDate } from '@/Models/ClassDate';
import { Session } from '@/Models/Session';
import { observer } from 'mobx-react';
import { StackableModalProps } from '@/Components/RootModal';
import {
    ScheduleEventAvailabilityView,
    SchedulePlannedEvent,
} from '@/Features/Schedule';
import ScheduleTransport from '@/Services/Transport/ScheduleTransport';
import ConfirmationModal from '@/Components/ConfirmationModal';
import Checkbox from '@/Components/Checkbox';
import RootStore from '@/Stores/RootStore';
import queryClient from '@/Modules/QueryClient';
import { queryKeys } from '@/Services/QueryKeys';
import Notification from '@/Services/Notification';
import { classDateQueryKeys } from '@/Features/ClassDates';

interface Props extends StackableModalProps {
    event: ClassDate | Session;
    variant?: 'icon' | 'full';
    onAssignedUserChange?(assignedUser: string | undefined): void;
}

const Component: FunctionComponent<Props> = ({
    event,
    onAssignedUserChange,
    ...props
}) => {
    const [assignedUser, setAssignedUser] = useState<string | undefined>(
        event.assignedUsers?.[0]?.uuid
    );
    const [isAvailable, setIsAvailable] = useState(true);
    const [isOnHoliday, setIsOnHoliday] = useState(false);
    const [isCheckingAvailability, setIsCheckingAvailability] = useState(false);
    const [allowUnassigned, setAllowUnassigned] = useState(false);
    const [makeUnassignedAvailable, setMakeUnassignedAvailable] =
        useState(false);

    const formattedEvent: SchedulePlannedEvent | undefined = useMemo(() => {
        if (!event) {
            return undefined;
        }

        return {
            id: event.scheduleId,
            start: event.start,
            end: event.end,
            assignedUser,
            isAvailable,
            isOnHoliday,
        };
    }, [event, assignedUser, isAvailable, isOnHoliday]);

    useEffect(() => {
        setAllowUnassigned(false);
        setMakeUnassignedAvailable(false);

        if (event && assignedUser) {
            checkConflicts(assignedUser);
        }
    }, [assignedUser]);

    useEffect(() => {
        setMakeUnassignedAvailable(!assignedUser);
    }, [allowUnassigned]);

    async function checkConflicts(assignedUser: string) {
        if (!formattedEvent) {
            return;
        }

        setIsCheckingAvailability(true);

        const { data } = await ScheduleTransport.determineConflicts(
            [
                {
                    ...formattedEvent,
                    assignedUser,
                },
            ],
            !!formattedEvent ? [formattedEvent.id] : [undefined]
        );

        setIsAvailable(
            (data ?? []).some((ev: SchedulePlannedEvent) => ev.isAvailable)
        );
        setIsOnHoliday(
            (data ?? []).some((ev: SchedulePlannedEvent) => ev.isOnHoliday)
        );

        setIsCheckingAvailability(false);
    }

    async function saveEntry() {
        const model = RootStore.stores.entryStore.find(event.identifier);

        await model.assignUsers(
            assignedUser ? [assignedUser] : [],
            makeUnassignedAvailable
        );

        await queryClient.invalidateQueries(classDateQueryKeys.all);
        await queryClient.invalidateQueries(queryKeys.dashboard.timeline);
        await queryClient.invalidateQueries(queryKeys.availableEvents);

        Notification.success('Updated successfully');

        if (typeof onAssignedUserChange === 'function') {
            onAssignedUserChange(assignedUser);
        }

        RootStore.stores.uiStateStore.popModal('ASSIGN_TEACHER');
    }

    return (
        <ConfirmationModal
            stackProps={props.stackProps}
            content={() => (
                <section className="dialog__panel space-y-6 text-left">
                    <ScheduleEventAvailabilityView
                        event={formattedEvent}
                        assignedUser={assignedUser}
                        userClassName="w-full"
                        onUserChange={setAssignedUser}
                        variant="stacked"
                    />

                    {event && assignedUser && !isAvailable && (
                        <div>
                            <Checkbox
                                label="Save date as unassigned."
                                checked={allowUnassigned}
                                onChange={() =>
                                    setAllowUnassigned(!allowUnassigned)
                                }
                            />
                        </div>
                    )}

                    {event &&
                        ((!isAvailable && allowUnassigned) ||
                            !assignedUser) && (
                            <div>
                                <Checkbox
                                    label="Add date to the cover list."
                                    checked={makeUnassignedAvailable}
                                    onChange={() =>
                                        setMakeUnassignedAvailable(
                                            !makeUnassignedAvailable
                                        )
                                    }
                                />
                            </div>
                        )}
                </section>
            )}
            title="Change assigned teacher"
            width={500}
            isPositive
            confirmText="Save"
            onConfirmed={saveEntry}
            isDisabled={
                isCheckingAvailability ||
                (assignedUser && !isAvailable && !allowUnassigned)
            }
        />
    );
};

export const ScheduleEventTeachersModal = observer(Component);
