import React from 'react';

const CustomSessionPacks = (props) => (
    <svg viewBox="0 0 20 20" {...props}>
        <path
            fill={props.fill}
            d="M18 0a2 2 0 012 2v7H0V2a2 2 0 012-2h16zM4.5 2a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM17 3.5h-7a1 1 0 100 2h7a1 1 0 000-2zM20 11v7a2 2 0 01-2 2H2a2 2 0 01-2-2v-7h20zM4.5 13a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM17 14.5h-7a1 1 0 000 2h7a1 1 0 000-2z"
            fillRule="evenodd"
        />
    </svg>
);

export default CustomSessionPacks;
