import BaseTransport from './BaseTransport';

class ScheduleTransport extends BaseTransport {
    async listUpcomingEvents(params = {}) {
        return await this.get('/v3/schedule/timeline', params);
    }

    async listEntriesBetween(params = {}) {
        return await this.get('/v3/schedule/entries', params);
    }

    async determineConflicts(events: any[] = [], exclude: any[] = []) {
        return await this.post('/v3/schedule/conflicts', {
            // users,
            events,
            exclude,
        });
    }

    async listUnrecordedAttendances(params = {}) {
        return this.get('/v3/schedule/unrecorded-attendances', params);
    }
}

export default new ScheduleTransport();
