import { types } from 'mobx-state-tree';
import { inUserZone } from '../Services/Date';

export default types
    .model({})
    .views((self) => ({
        get start_at() {
            return self.start;
        },
        get end_at() {
            return self.end;
        },
        get startInFuture() {
            return inUserZone(null, false) < self.start;
        },
    }))

    .actions((self) => ({
        getStartDate() {
            return self.start;
        },
        getEndDate() {
            return self.end;
        },

        endInPast() {
            return inUserZone(null, false) > self.end;
        },
    }));
