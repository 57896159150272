import React, { FunctionComponent } from 'react';
import {
    ScheduleEventAvailabilityViewIcon,
    ScheduleEventPlaceholderCard,
    SchedulePlannedEvent,
} from '@/Features/Schedule';
import classNames from 'classnames';
import UsersSelectField from '@/Components/UsersSelectField';
import useTeam from '@/Hooks/useTeam';
import { Tooltip } from '@/Components/Tooltip';
import { ConditionalWrapper } from '@/Components/ConditionalWrapper';

export interface Props {
    event?: SchedulePlannedEvent;
    onViewEvent?(eventId: string): void;
    assignedUser?: string;
    view?: any;
    userClassName?: string;
    onUserChange?(value: string): void;
    allowAssignment?: boolean;
    variant?: 'default' | 'stacked';
}

export const ScheduleEventAvailabilityView: FunctionComponent<Props> = ({
    event,
    onViewEvent,
    assignedUser,
    view,
    userClassName,
    onUserChange,
    allowAssignment = true,
    variant = 'default',
}) => {
    const { hasTeamMembers } = useTeam();

    return (
        <div
            className={classNames('flex flex-1 gap-2', {
                'flex-wrap xl:flex-nowrap': hasTeamMembers,
                'flex-col': variant === 'stacked',
            })}
        >
            <div className="flex-1">
                <ConditionalWrapper
                    condition={typeof onViewEvent === 'function'}
                    wrapper={(children) => (
                        <Tooltip label="View on calendar">{children}</Tooltip>
                    )}
                >
                    <ScheduleEventPlaceholderCard
                        event={event}
                        view={view}
                        onViewEvent={onViewEvent}
                        className="w-full"
                    />
                </ConditionalWrapper>
            </div>

            <div
                className={classNames('flex min-h-[48px] gap-2', {
                    'w-full xl:w-auto': hasTeamMembers,
                })}
            >
                {hasTeamMembers && allowAssignment && (
                    <div className="w-full">
                        <UsersSelectField
                            className={classNames(
                                'min-w-[180px] xl:min-w-[200px]',
                                userClassName
                            )}
                            value={assignedUser ?? ''}
                            onChange={({ value }: { value: string }) =>
                                onUserChange!(value)
                            }
                            additionalOptions={[
                                { label: 'Unassigned', value: '' },
                            ]}
                        />
                    </div>
                )}

                {event && (
                    <ScheduleEventAvailabilityViewIcon
                        event={event}
                        view={view}
                        onViewEvent={onViewEvent}
                    />
                )}
            </div>
        </div>
    );
};
