import {
    applySnapshot,
    flow,
    getRoot,
    getSnapshot,
    types,
} from 'mobx-state-tree';
import ClassPassTransport from '../Services/Transport/ClassPassTransport';
import { ClassPass } from '../Models/ClassPass';

export const ClassPassStore = types
    .model('ClassPassStore', {
        classPasses: types.map(ClassPass),
    })

    .volatile((self) => ({
        inFlight: new Set(),
    }))

    .views((self) => {
        const fetchClassPass = async (uuid, params = {}) => {
            if (self.inFlight.has(uuid) || self.classPasses.has(uuid)) {
                return;
            }

            self.inFlight.add(uuid);

            try {
                await self.fetch(uuid, params);
            } catch (error) {
                console.log({ error });
            }

            self.inFlight.delete(uuid);
        };

        return {
            find(uuid, params = {}) {
                return self.classPasses.get(uuid, params);
            },

            findOrFetch(uuid) {
                fetchClassPass(uuid);
                return self.classPasses.get(uuid);
            },

            get available() {
                return Array.from(self.classPasses.values()).filter(
                    (classPass) => !classPass.isDeleted
                );
            },

            get active() {
                return self.available.filter((classPass) => classPass.isActive);
            },

            get inactive() {
                return self.available.filter(
                    (classPass) => !classPass.isActive
                );
            },
        };
    })

    .actions((self) => ({
        fetch: flow(function* fetch(uuid, params = {}) {
            try {
                const { data } = yield ClassPassTransport.fetch(uuid, params);
                return self.createOrUpdate(data.data);
            } catch (e) {
                console.error('error: ', e);
            }
        }),

        list: flow(function* list(requestData, withMeta = false) {
            const { data } = yield ClassPassTransport.list(requestData);
            const items = data.data.map((classPass) =>
                self.createOrUpdate(classPass)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        store: flow(function* store(type, classPassData) {
            const { data } = yield ClassPassTransport.store(
                type,
                classPassData
            );
            return self.createOrUpdate(data.data);
        }),

        update: flow(function* update(uuid, classPassData) {
            const { data } = yield ClassPassTransport.update(
                uuid,
                classPassData
            );
            return self.createOrUpdate(data.data);
        }),

        createOrUpdate(classPassData) {
            const classPass = self.processNestedResources(classPassData);

            if (self.classPasses.has(classPass.uuid)) {
                const existing = self.classPasses.get(classPass.uuid);

                applySnapshot(existing, {
                    ...getSnapshot(existing),
                    ...classPass,
                });
            } else {
                self.classPasses.set(classPass.uuid, classPass);
            }

            return self.classPasses.get(classPass.uuid);
        },

        /**
         * Add the class pass to the store, and return the identifier
         * @param classPass
         * @returns {*}
         */
        processAsNested(classPass) {
            if (!!classPass?.uuid) {
                self.createOrUpdate(classPass);
                return classPass.uuid;
            }

            return classPass;
        },

        /**
         * @param classPassData¡
         * @returns {*}
         */
        processNestedResources(classPassData) {
            if (typeof classPassData.client !== 'undefined') {
                classPassData.client = getRoot(
                    self
                ).stores.clientStore.processAsNested(classPassData.client);
            }

            if (
                typeof classPassData.transactions !== 'undefined' &&
                Array.isArray(classPassData.transactions)
            ) {
                const transactions = classPassData.transactions.map(
                    (transaction) => {
                        return getRoot(
                            self
                        ).stores.transactionStore.createOrUpdate(transaction)
                            .uuid;
                    }
                );
                classPassData.transactions = transactions;
            }
            if (typeof classPassData.creditedClassDate !== 'undefined') {
                classPassData.creditedClassDate = getRoot(
                    self
                ).stores.entryStore.createOrUpdate(
                    classPassData.creditedClassDate
                ).uuid;
            }

            return classPassData;
        },

        filteredByType(filter) {
            switch (filter) {
                case 'all':
                    return self.available;
                case 'active':
                    return self.active;
                case 'inactive':
                    return self.inactive;
            }
        },

        getWithinInterval(interval) {
            return self.available.filter((classPass) =>
                interval.contains(classPass.createdAt)
            );
        },
    }));
