import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Message } from '@/Models/Message';
import ItemListTextCell from '@/Components/ItemList/TextCell';
import RootStore from '@/Stores/RootStore';

interface Props {
    message: Message;
}

const Component: FunctionComponent<Props> = ({ message }) => {
    const { uiStateStore } = RootStore.stores;

    function handleClick() {
        uiStateStore.pushModal('MESSAGE_VIEW', { message });
    }
    function getContent() {
        if (!message.hasContent) {
            return message.subject;
        }
        return <a onClick={handleClick}>{message.subject}</a>;
    }

    return (
        <ItemListTextCell
            content={getContent()}
            secondaryContent={
                message.hasContent ? message.strippedContent : 'Sending...'
            }
            className="col-span-2 xl:col-span-1"
            secondaryContentClassName="hidden truncate xl:block"
        />
    );
};

export const MessageListItemContentCell = observer(Component);
