import React, { FunctionComponent } from 'react';
import { FormFieldElementProps } from '../FormFieldElements';
import FormSelectField, { FormSelectFieldProps } from '../FormSelectField';
import { Client } from '@/Models/Client';
import RootStore from '../../Stores/RootStore';
import { observer } from 'mobx-react';
import { useQuery } from 'react-query';
import { CreateClientButton } from '@/Features/Clients';
import { useFormikContext } from 'formik';
import useAuth from '@/Hooks/useAuth';

interface Props extends FormFieldElementProps, FormSelectFieldProps {
    clients?: Client[];
    optionIsDisabled?(client: Client): boolean;
    isCreatable?: boolean;
}

const FormClientSelectField: FunctionComponent<Props> = ({
    isCreatable,
    optionIsDisabled,
    ...props
}) => {
    const { clientStore } = RootStore.stores;
    const { hasRole } = useAuth();
    const { setFieldValue } = useFormikContext();

    const clients =
        typeof props.clients === 'undefined'
            ? clientStore.available
            : props.clients;

    const options = clients.map((client: Client) => ({
        value: client.eid,
        label: client.fullName,
        disabled:
            typeof optionIsDisabled === 'function'
                ? optionIsDisabled(client)
                : false,
    }));

    const { isLoading } = useQuery(
        ['clients', 'list'],
        async () => await clientStore.list(),
        {
            cacheTime: 0,
        }
    );

    const handleCreate = (client: Client) => {
        setFieldValue(props.name, client.eid);
    };

    return (
        <FormSelectField
            options={options}
            fieldAccessoryAfter={
                isCreatable && hasRole('Owner') ? (
                    <CreateClientButton
                        className="ml-2"
                        variant="inline"
                        onCreate={handleCreate}
                        navigateOnSuccess={false}
                    />
                ) : undefined
            }
            {...props}
            fieldProps={{
                isSearchable: true,
                ...(props.fieldProps ?? {}),
                isLoading,
            }}
        />
    );
};

export default observer(FormClientSelectField);
