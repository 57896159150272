import BaseTransport from './BaseTransport';

class ClientTransport extends BaseTransport {
    async list(params = {}) {
        return await this.get('/v3/clients', params);
    }

    async listEntries(eid, params) {
        return await this.get(`/v3/clients/${eid}/entries`, params);
    }

    async fetch(eid, params = {}) {
        return await this.get(`/v3/clients/${eid}`, params);
    }

    async store(data) {
        return await this.post('/v3/clients', data);
    }

    async getCounts() {
        return await this.get('/v3/clients/counts');
    }

    async dismissReview(eid) {
        return await this.post(`/v3/clients/${eid}/dismiss-review`);
    }

    async sendDocumentInviteLink(eid, type, params = {}) {
        return await this.post(
            `/v3/clients/${eid}/send-${type}-invite`,
            params
        );
    }

    async update(eid, input = {}) {
        return await this.put(`/v3/clients/${eid}`, input);
    }

    async updateNotes(eid, trainingNotes) {
        return await this.put(`/v3/clients/${eid}/notes`, { trainingNotes });
    }

    async updateParqAnswer(eid, questionId, notes) {
        return await this.put(`/v3/clients/${eid}/parq/${questionId}`, {
            notes,
        });
    }

    async updateEmergencyContact(eid, data = {}) {
        return await this.put(`/v3/clients/${eid}/emergency-contact`, data);
    }

    /**
     * Complete a data review for the given client
     * @returns {*}
     */
    async completeDataReview(eid, action) {
        return await this.post(`/v3/clients/${eid}/complete-data-review`, {
            action,
        });
    }

    async archive(eid) {
        return await this.post(`/v3/clients/${eid}/archive`);
    }

    async restore(eid) {
        return await this.post(`/v3/clients/${eid}/restore`);
    }

    async fetchSignUpInvite(eid) {
        return await this.get(`/v3/clients/${eid}/sign-up-invite`);
    }
}

export default new ClientTransport();
