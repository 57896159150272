import React, { forwardRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledContainer } from './Style';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import useRootClose from '../../Utilities/useRootClose';
import DatePickerNavBar from './NavBar';
import TextInputField from '../TextInputField';

const DatePickerInput = ({
    closeOverlay,
    inputRef,
    inputProps,
    pickerProps,
    maxWidth,
    ...otherProps
}) => {
    const containerRef = useRef(null);
    const [dayPickerOpen, setDayPickerOpen] = useState(false);

    const { value, onDayPickerHide, className, ...inputRestProps } = inputProps;

    pickerProps = {
        showOutsideDays: true,
        firstDayOfWeek: 1,
        navbarElement: DatePickerNavBar,
        ...pickerProps,
    };

    const handleDayPickerShow = () => {
        setDayPickerOpen(true);
    };

    const handleDayPickerHide = () => {
        if (typeof onDayPickerHide === 'function') {
            onDayPickerHide();
        }

        setDayPickerOpen(false);
    };

    useRootClose(containerRef, closeOverlay, {
        disabled: !dayPickerOpen,
    });

    const renderInput = (props, ref) => {
        // const inputProps = {
        //     ...props,
        //     isFocused: dayPickerOpen,
        // };

        if (otherProps.renderInput) {
            return otherProps.renderInput(props, ref);
        }

        return (
            <TextInputField
                {...props}
                className={className}
                value={value}
                ref={ref}
                readOnly
            />
        );
    };

    return (
        <StyledContainer
            ref={containerRef}
            hasSelectableWeeks={otherProps.hasSelectableWeeks}
            maxWidth={maxWidth}
        >
            <DayPickerInput
                ref={inputRef}
                dayPickerProps={pickerProps}
                component={forwardRef(renderInput)}
                onDayPickerShow={handleDayPickerShow}
                onDayPickerHide={handleDayPickerHide}
                {...inputRestProps}
            />
        </StyledContainer>
    );
};

DatePickerInput.propTypes = {
    closeOverlay: PropTypes.func,
    inputProps: PropTypes.object,
    pickerProps: PropTypes.object,
    hasSelectableWeeks: PropTypes.bool,
    renderInput: PropTypes.func,
    variant: PropTypes.oneOf(['default', 'grey']),
    maxWidth: PropTypes.oneOf(['default', 'none', 'xl']),
};

DatePickerInput.defaultProps = {
    hasSelectableWeeks: false,
    variant: 'default',
};

export default DatePickerInput;
