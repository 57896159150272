import React, { FunctionComponent, useEffect } from 'react';
import RootStore from '../../Stores/RootStore';
import { AgreementInstance } from '@/Models/Agreement';
import FormSelectField, { FormSelectFieldProps } from '../FormSelectField';
import { CreateAgreementButton } from '@/Features/Agreements';
import { useField } from 'formik';
import { useQuery } from 'react-query';
import uniqBy from 'lodash-es/uniqBy';
import useAuth from '@/Hooks/useAuth';

interface Props extends FormSelectFieldProps {
    withCreateButton?: boolean;
    setDefault?: boolean;
    initialData?: AgreementInstance[];
}

const FormAgreementSelectField: FunctionComponent<Props> = ({
    withCreateButton,
    setDefault = true,
    initialData = [],
    ...props
}) => {
    const { agreementStore } = RootStore.stores;
    const { hasRole } = useAuth();
    const [field, _, { setValue }] = useField(props.name);

    const { isLoading, isSuccess } = useQuery(
        ['data', 'timezones'],
        async () => await agreementStore.list()
    );

    const handleCreate = (agreement: AgreementInstance) => {
        setValue(agreement.eid);
    };

    useEffect(() => {
        if (isSuccess && setDefault && !field.value && agreementStore.default) {
            setValue(agreementStore.default.eid);
        }
    }, [
        agreementStore.available,
        agreementStore.default,
        initialData,
        isSuccess,
    ]);

    return (
        <FormSelectField
            options={uniqBy(
                [...agreementStore.available, ...initialData],
                'eid'
            ).map((agreement: AgreementInstance) => ({
                value: agreement.eid,
                label: agreement.name,
            }))}
            fieldAccessoryAfter={
                withCreateButton && hasRole('Owner') ? (
                    <CreateAgreementButton
                        className="ml-2"
                        variant="inline"
                        onCreate={handleCreate}
                        navigateOnSuccess={false}
                    />
                ) : undefined
            }
            {...props}
            fieldProps={{
                ...(props.fieldProps ?? {}),
                isLoading,
            }}
        />
    );
};

export default FormAgreementSelectField;
