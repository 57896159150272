import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { StackableModalProps } from '@/Components/RootModal';
import Modal, { WIDTHS } from '@/Components/Modal';
import {
    CreateClientButton,
    SendClientSignUpInviteButton,
} from '@/Features/Clients';
import { Consultation } from '@/Models/Consultation';
import { HelpButton } from '@/Components/HelpButton';

interface Props extends StackableModalProps {
    consultation?: Consultation;
}

const Component: FunctionComponent<Props> = ({ stackProps }) => {
    return (
        <Modal
            title="New client"
            stackProps={stackProps}
            width={WIDTHS.medium}
            cancelType="stack"
        >
            <HelpButton
                href="https://help.striive.co/article/7-adding-clients-to-your-striive-account"
                className="-mt-3 flex justify-end"
            />

            <SendClientSignUpInviteButton variant="selectable" removeBeneath />
            <CreateClientButton
                variant="selectable"
                closeStackOnCreate
                removeBeneath
            />
        </Modal>
    );
};

export const CreateClientOptionsModal = observer(Component);
