import { Item } from '.';
import { Day, Week } from '../Views';
import { toISO } from '../../../../js/Services/Date';

export class Card extends Item {
    constructor(calendar, entry) {
        super(calendar, entry);

        this._isSelected = false;
        this.isFocused = false;
        this.isMovable = false;
        this.isRemovable = false;
        this.isRepeatable = false;
        this.isSelectable = false;

        this.initialiseRange();
    }

    clone(replaceId = false) {
        let card = new Card(
            this.calendar,
            angular.copy({
                ...this.entry,
                scheduleId: replaceId
                    ? `new-${this.entry.type}-${this.calendar.getNextNewId()}`
                    : this.entry.scheduleId,
            })
        );

        card.isFocused = this.isFocused;
        card.isMovable = this.isMovable;
        card.isResizable = this.isResizable;
        card.isRemovable = this.isRemovable;
        card.isRepeatable = this.isRepeatable;
        card.isSelectable = this.isSelectable;
        card.isNew = this.isNew;

        return card;
    }

    get label() {
        return this.entry.label;
    }

    set label(label) {
        this.entry.label = label;
    }

    get length() {
        return this.end - this.start;
    }

    get resizeLimit() {
        return this.entry.maxSize ?? null;

        return this.type === 'session' && !this.isNew
            ? this.entry.sessionPack.unscheduled
            : null;
    }

    get isMovable() {
        return !!this.entry.movable;
    }

    set isMovable(value) {
        this.entry.movable = !!value;
    }

    get isNew() {
        return !!this.entry.new;
    }

    set isNew(value) {
        this.entry.new = !!value;
    }

    get isResizable() {
        return !!this.entry.resizable;
    }

    set isResizable(value) {
        this.entry.resizable = !!value;
    }

    get isSick() {
        return !!this.entry.sick;
    }

    hasLocation() {
        return this.type === 'diary-entry' ? false : !!this.entry.locationName;
    }

    getLocation() {
        return this.entry.locationName;
    }

    initialiseRange() {
        this.start =
            this.entry.start_at.hour * 4 + this.entry.start_at.minute / 15;
        this.end = this.entry.end_at.hour * 4 + this.entry.end_at.minute / 15;
    }

    remove() {
        this.calendar.removeItem(this);
    }

    // validate() {
    //     this.calendar.validateItems([this]);
    // }

    get isSelected() {
        return this._isSelected;
    }

    set isSelected(value) {
        if (!!value) {
            this.calendar.deselectAllCards();
        }

        this._isSelected = !!value;
    }

    select() {
        this.calendar.deselectAllCards();
        this._isSelected = true;
    }

    deselect() {
        this._isSelected = false;
    }

    getSavable() {
        return {
            ...this.entry,
            start: toISO(this.entry.start_at),
            end: toISO(this.entry.end_at),
            length: this.length,
        };
    }

    canMoveToRange(range) {
        return typeof range.day !== 'undefined';
    }

    moveToRange(range) {
        if (this.day != range.day) {
            this.cache.remove('Items.Item.day');
        }

        if (
            this.day != range.day ||
            this.start != range.start ||
            this.end != range.end
        ) {
            let date = range.day.date;

            this.start = range.start;
            this.end = range.end;

            const startHour = Math.floor(this.start / 4);
            const startMinute = (this.start % 4) * 15;
            const endHour = Math.floor(this.end / 4);
            const endMinute = (this.end % 4) * 15;

            this.entry.start_at = date.set({
                hour: startHour,
                minute: startMinute,
            });
            this.entry.end_at = date.set({ hour: endHour, minute: endMinute });

            this.calendar.sortItems();
        }
    }

    canMoveToView(view) {
        if (view instanceof Day) {
            this.canMoveToRange({
                day: view,
                start: this.start,
                end: this.end,
            });
        } else if (view instanceof Week) {
            return this.canMoveToView(view.days[this.day.weekday]);
        }
    }

    moveToView(view) {
        if (view instanceof Day) {
            this.moveToRange({
                day: view,
                start: this.start,
                end: this.end,
            });
            view.clearCache(true);
        } else if (view instanceof Week) {
            return this.moveToView(view.days[this.day.weekday]);
        }
    }

    setAssignedUser(user) {
        this.entry.assignedUser = user;
    }

    setFlash(flash) {
        this.entry.flash = flash;
    }

    setEntryData(data) {
        this.entry = {
            ...this.entry,
            ...data,
        };
    }

    clearCache() {
        super.clearCache();
    }
}
