import { types } from 'mobx-state-tree';
import get from 'lodash-es/get';
import { Delivery } from './Delivery';

export default types
    .model()

    .views((self) => ({
        hasPermission(permission, defaultValue = false) {
            const permissionValue = get(self.permissions, permission);

            if (!self.permissions || !permissionValue) {
                return defaultValue;
            }

            return permissionValue;
        },
    }))

    .actions((self) => ({
        updateDelivery(delivery) {
            self.delivery = delivery?.location
                ? Delivery.create(delivery)
                : undefined;
        },
    }));
