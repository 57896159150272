import { types } from 'mobx-state-tree';
import { currencyDisplay } from '../Services/Currency';

export const MetricValue = types
    .model('MetricValue', {
        identifier: types.identifier,
        type: types.literal('metric-value'),
        value: types.number,
        displayType: types.string,
        prefix: types.maybe(types.string),
        suffix: types.maybe(types.string),
    })

    .views((self) => ({
        get formattedValue() {
            switch (self.displayType) {
                case 'currency':
                    return currencyDisplay(self.value);

                default:
                    return self.value;
            }
        },
        get fullValue() {
            return (
                (self.prefix || '') + self.formattedValue + (self.suffix || '')
            );
        },
    }));
