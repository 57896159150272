import RootStore from '../Stores/RootStore';
import {
    applySnapshot,
    destroy,
    flow,
    getSnapshot,
    types,
} from 'mobx-state-tree';
import { DateTimeType } from '../Types/DateTime';
import { SessionPackReference } from './SessionPack';
import { SessionParticipation } from './SessionParticipation';
import { ClientReference } from './Client';
import { SessionPosition } from './SessionPosition';
import WithLegacyDates from './WithLegacyDates';
import ScheduleEntry from './ScheduleEntry';
import { communicationWarning as communicationWarningString } from '../Services/String';
import { DateTime } from 'luxon';
import WithLocation from './WithLocation';
import { toISO, toLuxon } from '../Services/Date';
import SessionTransport from '../Services/Transport/SessionTransport';
import { deliveryFromFormValues } from '../Services/Delivery';
import { OrganisationUser } from './OrganisationUser';

const SessionModel = types
    .model('Session', {
        eid: types.string,
        type: types.literal('session'),
        identifier: types.identifier,
        resourceVersion: 2,

        label: types.string,
        start: DateTimeType,
        end: DateTimeType,
        length: types.integer,
        description: types.maybeNull(types.string),
        clients: types.array(types.late(() => ClientReference)),
        sessionPack: types.late(() => SessionPackReference),
        // sessionPack: types.maybe(types.reference(types.late(() => SessionPack), {
        //     get(identifier) { return RootStore.stores.sessionPackStore.find(identifier) || null },
        //     set({ identifier }) { return identifier }
        // })),
        position: types.maybe(SessionPosition),
        participation: types.array(SessionParticipation),
        reminderSent: false,
        isCancelled: false,

        assignedUsers: types.array(
            types.late(() => types.safeReference(OrganisationUser))
        ),
        isAvailableToAssign: types.maybe(types.boolean),

        references: types.maybe(
            types.model({
                sessionPack: types.string,
            })
        ),

        isNew: false,
    })

    .preProcessSnapshot((snapshot) => {
        let sessionPack = snapshot.sessionPack;

        // Process sessionPack
        if (!!snapshot.sessionPack) {
            sessionPack =
                typeof snapshot.sessionPack === 'string'
                    ? snapshot.sessionPack
                    : snapshot.sessionPack.identifier;
        }

        // Process clients
        const clients = snapshot.clients?.data ?? snapshot.clients;
        if (Array.isArray(clients)) {
            snapshot.clients = clients.map((client) => {
                if (typeof client === 'string') {
                    return client;
                }
                RootStore.stores.clientStore.createOrUpdate(client);
                return client.eid;
            });
        }

        if (snapshot.resourceVersion === 1) {
            snapshot = {
                ...snapshot,
                index: snapshot.position.index,
                start: toLuxon(snapshot.start_at),
                end: toLuxon(snapshot.end_at),
                participation: !!snapshot.participation
                    ? snapshot.participation.data
                    : [],
            };
        }

        return {
            ...snapshot,
            sessionPack,
        };
    })

    .views((self) => ({
        get scheduleId() {
            return `session-${self.eid}`;
        },

        get humanEntityType() {
            if (self.participation.length > 0) {
                return self.participation.length > 1
                    ? 'Group session'
                    : 'Private session';
            }
            return 'Session';
        },

        get scheduleResourceData() {
            return {
                locationName: self.hasLocation ? self.locationName : null,
                label: self.label,
            };
            //
            // return {
            //     id: self.eid,
            //     type: 'session',
            //     start_at: self.start,
            //     end_at: self.end,
            //     locationName: self.hasLocation ? self.locationName : null,
            //     label: self.label,
            //     sessionPack: self.sessionPack.eid,
            //     group: {
            //         index: self.position.index,
            //         total: self.position.total,
            //     },
            // };
        },

        get saveData() {
            return {
                start: toISO(self.start),
                end: toISO(self.end),
                length: self.length,
                delivery: self.delivery,
                assignedUsers: self.assignedUsers,
            };
        },

        /**
         * Identify this item on the schedule, when creating
         * @returns {*}
         */
        get newCardId() {
            return self.identifier;
        },

        get isHappeningNow() {
            const now = DateTime.local();
            return self.start < now && self.end > now;
        },

        get notes() {
            return self.description;
        },

        get attendance() {
            let incomplete = 0;
            let completed = 0;
            let forfeited = 0;

            self.participation.forEach((participation) => {
                if (!participation.status) {
                    incomplete++;
                }
                if (participation.status === 'completed') {
                    completed++;
                }
                if (participation.status === 'forfeited') {
                    forfeited++;
                }
            });

            // If any incomplete, don't mark the session
            if (incomplete) {
                return null;
            }

            // If any completed
            if (completed) {
                return 'completed';
            }

            // If all were forfeited, return so
            if (forfeited === self.participation.length) {
                return 'forfeited';
            }
        },

        /**
         * Determine if this session has been marked as attended
         * @returns {boolean}
         */
        get wasAttended() {
            return self.attendance === 'completed';
        },

        /**
         * Determine if this session has been marked at forfeited
         * @returns {boolean}
         */
        get wasForfeited() {
            return self.attendance === 'forfeited';
        },

        attendanceForClient(client) {
            if (!self.participation || !self.participation.length) {
                return null;
            }

            return self.participation.find(
                (participation) => participation.client_eid === client
            ).status;
        },

        communicationWarning(email = true, phone = true) {
            let warnings = [];

            self.clients.forEach((client) => {
                const emailMissing = email && !client.hasEmail;
                const phoneMissing = phone && !client.hasSmsPhone;
                const displayName =
                    self.clients.length > 1 ? client.firstName : '';

                const warning = communicationWarningString(
                    emailMissing,
                    phoneMissing,
                    displayName
                );

                if (!!warning) {
                    warnings.push(warning);
                }
            });

            return warnings;
        },
    }))

    .actions((self) => ({
        update: flow(function* update(input = {}) {
            self.busy = true;

            const { data } = yield SessionTransport.update(self.eid, {
                ...getSnapshot(self),
                ...input,
            });

            self.updateData({
                ...data.data,
                busy: false,
            });

            return {
                session: self,
                otherSessions:
                    data.sessions?.map((date) =>
                        RootStore.stores.entryStore.createOrUpdate(date)
                    ) ?? [],
            };
        }),

        updateNotes: flow(function* updateNotes(description = null) {
            const { data } = yield SessionTransport.updateNotes(
                self.eid,
                description
            );

            return self.updateData(data.data);
        }),

        sendReminder: flow(function* sendReminder(notify) {
            const { data } = yield SessionTransport.sendReminder(
                self.eid,
                notify
            );

            self.setReminderSent();
        }),

        updateData(data = {}) {
            data.assignedUsers =
                data.assignedUsers?.map((user) => user.identifier || user) ||
                self.assignedUsers;

            applySnapshot(self, {
                ...getSnapshot(self),
                ...data,
            });

            return self;
        },

        assignUsers: flow(function* assignUsers(
            assignedUsers = [],
            isAvailableToAssign
        ) {
            const { data } = yield SessionTransport.assignUsers(self.eid, {
                assignedUsers,
                isAvailableToAssign,
            });

            return self.updateData(data.data);
        }),

        setFormData(values) {
            self.updateData(values);
            self.updateDelivery(deliveryFromFormValues(values));
        },

        setAttendance(attendance, client) {
            self.participation = self.participation.map((participation) => {
                const clientEid = participation.client_eid;

                if (client && client !== participation.client_eid) {
                    return participation;
                }

                if (!client || client === participation.client_eid) {
                    destroy(participation);
                }

                return SessionParticipation.create({
                    client_eid: clientEid,
                    status: attendance,
                });
            });
        },

        appendParticipation(clientEid, attendance) {
            self.participation.forEach((participation) => {
                if (clientEid === participation.client_eid) {
                    destroy(participation);
                }
            });

            self.participation = [
                ...self.participation,
                SessionParticipation.create({
                    client_eid: clientEid,
                    status: attendance,
                }),
            ];
        },

        setReminderSent() {
            self.reminderSent = true;
        },
    }));

export const Session = types.compose(
    SessionModel,
    ScheduleEntry,
    WithLegacyDates,
    WithLocation
);

export const SessionReference = types.safeReference(Session, {
    get(identifier) {
        return RootStore.stores.entryStore.find(identifier) || null;
    },
    set({ identifier }) {
        return identifier;
    },
});
