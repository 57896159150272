import React from 'react';

const UITransactionInSimple = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            fill={props.fill}
            d="M5.953 0C2.907 0 .372 2.3 0 5.25h6.852L5.377 3.48l-.065-.09a.75.75 0 011.217-.87l2.5 3 .011.013a1.041 1.041 0 01.048.067l.006.01a.825.825 0 01.066.14l.012.037c.005.016.01.033.013.05l.008.043.003.018.005.052.002.034v.048L9.2 6.05a.716.716 0 01-.012.093l-.005.026a.788.788 0 01-.046.139l-.023.045a.744.744 0 01-.014.027l-.007.01-.006.01-.009.014a.595.595 0 01-.04.055l-.01.012-2.5 3-.077.08a.75.75 0 01-1.14-.95l.065-.09L6.85 6.75 0 6.751C.372 9.7 2.906 12 5.952 12c3.3 0 6-2.7 6-6s-2.7-6-6-6z"
            fillRule="evenodd"
        />
    </svg>
);

export default UITransactionInSimple;
