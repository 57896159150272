import { v4 as uuidv4 } from 'uuid';

export const assetUrl = (path: string) => {
    return (
        window.App.assetUrl.replace(/\/$/, '') + '/' + path.replace(/^\//, '')
    );
};

/**
 * Display the correct string based on count
 * @returns {string}
 * @param count
 * @param singular
 * @param plural
 * @param includeCount
 */
export const countInflect = (
    count: number,
    singular: string,
    plural: string | null = null,
    includeCount = true
) => {
    let string = includeCount ? count : '';
    plural = plural ?? `${singular}s`;
    return `${string} ${+count === 1 ? singular : plural}`;
};

export interface GenerateTextFromCountProps {
    count: number;
    singular: string;
    plural: string;
}

export const generateTextFromCount = ({
    count,
    plural,
    singular,
}: GenerateTextFromCountProps): string => {
    if (count === 1) {
        return singular;
    } else {
        return plural;
    }
};

/**
 * Return a human readable list
 * @param arr
 */
export const arrayToSentence = (arr: string[]) => {
    const formatter = new Intl.ListFormat('en');
    return formatter.format(arr);
};

/**
 * Capitalise the first character given string
 * @param string
 * @returns {string}
 */
export const capitaliseWord = (string: any) => {
    if (typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitaliseWords = (string: string) => {
    return string.split(' ').map(capitaliseWord).join(' ');
};

export const possessive = (string: string) => {
    if (!string) {
        return '';
    }

    return `${string}'${string.substring(-1) === 's' ? '' : 's'}`;
};

export const sortAlphabetically = (items: string[]) => {
    return items.sort((a, b) => a.localeCompare(b));
};

export const sortAlphabeticallyByKey = (items: any[], key: string) => {
    return items.sort((a: any, b: any) => a[key].localeCompare(b[key]));
};

/**
 * Construct a warning string based on missing communication methods
 * @param email
 * @param phone
 * @param name
 * @returns {string|null}
 */
export const communicationWarning = (
    email = false,
    phone = false,
    name = ''
) => {
    if (!email && !phone) {
        return null;
    }

    const warnings = [];

    if (email) warnings.push('email');
    if (phone) warnings.push('phone');

    if (name) {
        name = ` for ${name}`;
    }

    return `No ${warnings.join(' or ')}${name} to send notification`;
};

/**
 * Generate a v4 UUID
 * @returns {*}
 */
export const generateUuid = () => {
    return uuidv4();
};

/**
 * Return a unicode fraction character if one exists for the input
 * @param input
 * @returns {string|*}
 */
export const unicodeFraction = (input: number) => {
    const integer = Math.floor(input);

    if (integer == input) return input;

    let fraction;
    let decimal = input - integer;

    if (decimal === 0.25) {
        fraction = '¼';
    } else if (decimal === 0.5) {
        fraction = '½';
    } else {
        fraction = '¾';
    }

    return input > -1 && input < 1 ? fraction : integer + fraction;
};
