import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import {
    PrimaryButton,
    PrimaryResponsiveButton,
    WhiteButton,
} from '@/Components/Button';
import { Agreement } from '@/Models/Agreement';

interface Props {
    variant?: 'default' | 'inline';
    isResponsive?: boolean;
    navigateOnSuccess?: boolean;
    onCreate?(agreement: Agreement): void;
    className?: string;
}

const Component: FunctionComponent<Props> = ({
    variant = 'default',
    className,
    onCreate,
    isResponsive,
    navigateOnSuccess = true,
    ...props
}) => {
    const { uiStateStore } = RootStore.stores;

    const handleClick = () => {
        uiStateStore.pushModal('AGREEMENT_CREATE', {
            onCreate,
            navigateOnSuccess,
        });
    };

    const buttonProps = {
        type: 'button' as 'button' | 'submit' | undefined,
        onClick: handleClick,
        icon: 'Plus',
        children: 'Agreement',
        className,
        'data-testid': 'create-agreement-button',
    };

    if (variant === 'inline') {
        return (
            <WhiteButton {...buttonProps}>
                {props.children ?? 'New'}
            </WhiteButton>
        );
    }

    return (
        <>
            {isResponsive && <PrimaryResponsiveButton {...buttonProps} />}
            {!isResponsive && <PrimaryButton {...buttonProps} />}
        </>
    );
};

export const CreateAgreementButton = observer(Component);
