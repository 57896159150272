import { types } from 'mobx-state-tree';
import { Session } from './Session';
import { Consultation } from './Consultation';
import { ClassDate } from './ClassDate';
import { DiaryEntry } from './DiaryEntry';

export const Entry = types.union(
    {
        dispatcher: (snapshot) => {
            switch (snapshot.type) {
                case 'session':
                    return Session;
                case 'consultation':
                    return Consultation;
                case 'class-date':
                    return ClassDate;
                case 'diary-entry':
                    return DiaryEntry;
                default:
                    throw new Error();
            }
        },
    },
    Session,
    Consultation,
    ClassDate,
    DiaryEntry
);
