import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import { Button } from '@/Components/Button';
import { Client } from '@/Models/Client';

interface Props {
    client: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    const handleClick = () => {
        RootStore.stores.uiStateStore.pushModal('CLIENT_UPDATE_EMERGENCY', {
            client,
        });
    };

    return <Button icon="Pencil" onClick={handleClick} />;
};

export const UpdateClientEmergencyContactButton = observer(Component);
