import angular from 'angular';

angular
    .module('resource.client-session-pack', [])

    .service('SessionPackParticipation', [
        '$resource',
        'TransformerService',
        function ($resource, TransformerService) {
            var SessionPackParticipation = $resource(
                '/v1/participation/:eid',
                { eid: '@eid' },
                {
                    query: {
                        isArray: true,
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                    get: {
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                    update: {
                        method: 'PUT',
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                }
            );

            return SessionPackParticipation;
        },
    ]);
