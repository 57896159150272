import angular from 'angular';

angular
    .module('utilities.buttons', [])

    .directive('loadButton', function () {
        return {
            restrict: 'A',
            scope: {
                loadButton: '=',
                loaderStyle: '@',
                disabled: '<ngDisabled',
            },
            controller: [
                '$scope',
                '$element',
                '$attrs',
                function ($scope, $element, $attrs) {
                    var style = $scope.loaderStyle || 'light';

                    $element.append('<span class="loader --' + style + '"/>');

                    $scope.$watch('loadButton', function (newValue) {
                        if (newValue) {
                            $attrs.$set('disabled', 'disabled');
                            $element.addClass('--with-loader');
                        } else {
                            $attrs.$set('disabled', $scope.disabled);
                            $element.removeClass('--with-loader');
                        }
                    });
                },
            ],
        };
    });
