import CreateAction from './CreateAction.js';
import SessionPack from './SessionPack.js';

export default class CreateSessionPack extends CreateAction {
    static get type() {
        return new SessionPack();
    }

    get isReady() {
        return true;
    }

    get calendarTitle() {
        return 'Plan sessions';
    }

    endAddingCard(card) {
        super.endAddingCard(card);
    }

    endUpdatingCard(card) {
        super.endUpdatingCard(card);
        // card.group.clearGroupCache();
    }

    endRemovingCard(card) {
        super.endRemovingCard(card);
        // card.group.clearGroupCache();
    }
}
