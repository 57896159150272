import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import ItemListTitleCell from '@/Components/ItemList/TitleCell';
import ItemListActionCell from '@/Components/ItemList/ActionCell';
import ItemListItemIcon from '@/Components/ItemList/ItemIcon';
import ItemListTextCell from '@/Components/ItemList/TextCell';
import { Agreement } from '@/Models/Agreement';
import { AgreementOptionsMenu } from '@/Features/Agreements';
import Icon from '@/Components/Icon/Icon';
import { uiColors } from '@/Theme';

interface Props {
    agreement: Agreement;
    className?: string;
}

const Component: FunctionComponent<Props> = ({ agreement, className }) => {
    return (
        <div
            className={classNames(
                className,
                'grid-cols-[36px_auto_auto] xl:grid-cols-agreements-xl'
            )}
        >
            <ItemListItemIcon
                iconName="CustomAgreements"
                className="row-span-3 xl:row-auto"
            />
            <ItemListTitleCell
                title={agreement.name}
                subtitle={`Last changed ${agreement.createdAtFormatted}`}
                url={agreement.url}
            />
            <ItemListTextCell
                content={agreement.isDefault && 'Default'}
                icon={
                    agreement.isDefault && (
                        <Icon
                            name="Star"
                            width={16}
                            height={16}
                            fill={uiColors.core.upgrade}
                        />
                    )
                }
            />
            <ItemListActionCell className="row-span-3">
                <AgreementOptionsMenu agreement={agreement} />
            </ItemListActionCell>
        </div>
    );
};

export const AgreementListItem = observer(Component);
