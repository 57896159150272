import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import { uiColors } from '@/Theme';
import Icon from '@/Components/Icon/Icon';

interface Props {
    client: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    return (
        <NavLink
            to={`/clients/roster/${client.eid}/parq`}
            className="flex min-h-[110px] flex-col items-center justify-center font-bold transition-colors hover:bg-blue-50"
        >
            <Icon
                name={
                    !client.hasParq || client.parQOutOfDate
                        ? 'WarningTriangle'
                        : 'CheckCircle'
                }
                fill={
                    !client.hasParq || client.parQOutOfDate
                        ? uiColors.warning.default
                        : uiColors.positive.default
                }
                width={18}
                height={18}
                className="mb-1"
            />
            {!client.hasParq && <p>PAR-Q missing</p>}
            {client.hasParq && client.parQOutOfDate && <p>PAR-Q out of date</p>}
            {client.hasParq && !client.parQOutOfDate && <p>PAR-Q up to date</p>}
        </NavLink>
    );
};

export const ClientParqStatus = observer(Component);
