import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Button, PrimaryButton } from '@/Components/Button';
import { Client } from '@/Models/Client';
import RootStore from '@/Stores/RootStore';

interface Props {
    client: Client;
    documentType: 'agreement' | 'parq';
    variant?: 'default' | 'primary';
    icon?: string;
    text?: string; // Needed for angular
}

const Component: FunctionComponent<Props> = ({
    client,
    documentType,
    variant = 'default',
    icon,
    text,
    children,
}) => {
    function handleClick() {
        RootStore.stores.uiStateStore.pushModal('CLIENT_UPDATE_DOCUMENTS', {
            client,
            documentType,
        });
    }

    const buttonProps = {
        onClick: handleClick,
        children: text ?? children,
        icon,
    };

    return (
        <>
            {variant === 'default' && <Button {...buttonProps} />}
            {variant === 'primary' && <PrimaryButton {...buttonProps} />}
        </>
    );
};

export const UpdateClientDocumentsButton = observer(Component);
