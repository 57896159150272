import ClassContainer from './ClassContainer';
import UpdateAction, {
    ResultUpdated as DefaultResultUpdated,
    ResultUpdatedError as DefaultResultUpdatedError,
} from './UpdateAction';
import Notification from '@/Services/Notification';
import ClassTransport from '@/Services/Transport/ClassTransport';
import RootStore from '../../../js/Stores/RootStore';

class UpdateClass extends UpdateAction {
    static get type() {
        return new ClassContainer();
    }

    static get parentIdentifierName() {
        return 'class';
    }

    static get identifierName() {
        return 'uuid';
    }

    get parent() {
        return this.classContainer;
    }

    // processCardUsing(card) {
    //     card.isGhost =
    //         !card.isNew && card.entry.parent !== this.parentIdentifier;
    //
    //     return card;
    // }

    get calendarTitle() {
        return 'Plan class dates';
    }

    get isTargetVisible() {
        return !this.isUpdatingCard;
    }

    async prepare() {
        this.classContainer = await RootStore.stores.classStore.fetch(
            this.parentIdentifier
        );
    }

    async save(events, teamNotifications, _, unassignedToCoverList) {
        try {
            const { data } = await ClassTransport.addDates(
                this.classContainer.uuid,
                events,
                teamNotifications,
                unassignedToCoverList
            );

            // const newDates = data.entries.map((entry) => entry.scheduleId);
            //
            // // Remove new, and update existing cards
            this.calendar.removeItems((item) => !item.isNew);
            // this.calendar.populateCards(data.entries);

            Notification.success('Class updated');

            return new ResultUpdated();
        } catch (error) {
            return new ResultUpdatedError(error?.response?.data?.errors);
        }
    }
}

export default UpdateClass;

export class ResultUpdated extends DefaultResultUpdated {
    constructor(classContainer) {
        super();

        this.classContainer = classContainer;
    }
}

export class ResultUpdatedError extends DefaultResultUpdatedError {}
