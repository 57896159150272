import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DatePickerInput from '../../../Components/DatePickerInput';
import { DateTime } from 'luxon';
import { inUserZone } from '../../../Services/Date';

function getWeekDays(weekStart) {
    const days = [weekStart.toJSDate()];

    for (let i = 1; i < 7; i++) {
        days.push(weekStart.plus({ days: i }).toJSDate());
    }
    // console.log(days);
    return days;
}

const ScheduleDatePicker = (props) => {
    const inputRef = useRef(null);
    const [selectedDays, setSelectedDays] = useState([]);
    const isWeekView = props.view.type === 'week';

    const dateInLocalZone = props.view.startDate
        .startOf('week')
        .setZone('utc', { keepLocalTime: true });

    useEffect(() => {
        setSelectedDaysForView();
    }, [props.view]);

    const setSelectedDaysForView = () => {
        const days = isWeekView
            ? getWeekDays(dateInLocalZone)
            : [dateInLocalZone.toJSDate()];
        setSelectedDays(days);
    };

    const getTodayButton = () => {
        if (
            !isWeekView &&
            !props.view.startDate.toISODate() === DateTime.local().toISODate()
        ) {
            return 'Today';
        }

        return null;
    };

    const closeOverlay = () => {
        if (!!inputRef.current) {
            inputRef.current.hideDayPicker();
        }
    };

    const navigateToDate = (date) => {
        props.onDateSelected(
            inUserZone().set({
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
            })
        );
        closeOverlay();
    };

    // Event handlers
    const handleDayClick = (date) => navigateToDate(date);
    const handleWeekClick = (weekNumber, days, e) => navigateToDate(days[0]);
    const handleTodayClick = (day, modifiers) => navigateToDate(day);

    return (
        <DatePickerInput
            inputRef={inputRef}
            closeOverlay={closeOverlay}
            hasSelectableWeeks={isWeekView}
            pickerProps={{
                selectedDays,
                initialMonth: dateInLocalZone.toJSDate(),
                showWeekNumbers: true,
                todayButton: getTodayButton(),
                onDayClick: handleDayClick,
                onWeekClick: handleWeekClick,
                onTodayButtonClick: handleTodayClick,
            }}
            inputProps={{
                value: props.view.periodTitle,
                keepFocus: false,
                className: '--button-height cursor-pointer',
            }}
            variant="grey"
            maxWidth="none"
        />
    );
};

ScheduleDatePicker.propTypes = {
    view: PropTypes.object,
    onDateSelected: PropTypes.func,
};

export default ScheduleDatePicker;
