import React, { FunctionComponent, useState } from 'react';
import { uiColors } from '../../Theme';
import { observer } from 'mobx-react';
import Icon from '@/Components/Icon/Icon';
import { Button } from '@/Components/Button';
import classNames from 'classnames';
import { Session } from '@/Models/Session';
import RootStore from '@/Stores/RootStore';
import Notification from '@/Services/Notification';

interface Props {
    session: Session;
    client?: string;
    className?: string;
    onChange?(sess: Session, attendance: string | null, client?: string): void;
}

const SessionAttendanceButtons: FunctionComponent<Props> = ({
    session,
    client,
    onChange,
    className,
    ...props
}) => {
    const attendance = client
        ? session.attendanceForClient(client)
        : session.attendance;
    const [isBusy, setIsBusy] = useState<'completed' | 'forfeited' | null>(
        null
    );

    async function toggleState(
        toggledStatus: 'completed' | 'forfeited' | null
    ) {
        setIsBusy(toggledStatus);

        try {
            let newAttendance =
                attendance === toggledStatus ? null : toggledStatus;

            await RootStore.stores.entryStore.setAttendance(
                session,
                newAttendance,
                client
            );

            if (typeof onChange === 'function') {
                onChange(session, newAttendance, client);
            }

            Notification.success('Attendance updated');
        } catch (e) {
            console.log(e);
            Notification.error();
        }

        setIsBusy(null);
    }

    return (
        <div
            className={classNames(
                'grid auto-cols-min grid-flow-col gap-px',
                className
            )}
        >
            <Button
                isLoading={isBusy === 'completed'}
                onClick={() => toggleState('completed')}
                className={classNames('!rounded-br-none !rounded-tr-none', {
                    '!bg-legacy-positive-300': attendance === 'completed',
                })}
            >
                <Icon
                    name="ThumbUp"
                    fill={
                        attendance === 'completed'
                            ? uiColors.positive.default
                            : uiColors.button.icon
                    }
                    width={18}
                    height={18}
                />
            </Button>
            <Button
                isLoading={isBusy === 'forfeited'}
                onClick={() => toggleState('forfeited')}
                className={classNames('!rounded-bl-none !rounded-tl-none', {
                    '!bg-negative-300': attendance === 'forfeited',
                })}
            >
                <Icon
                    name="ThumbDown"
                    fill={
                        attendance === 'forfeited'
                            ? uiColors.negative.default
                            : uiColors.button.icon
                    }
                    width={18}
                    height={18}
                />
            </Button>
        </div>
    );
};

export default observer(SessionAttendanceButtons);
