import React, { forwardRef, FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import {
    durationStringFromDates,
    formatDate,
    formatTime,
} from '@/Services/Date';
import { SchedulePlannedEvent } from '@/Features/Schedule';

interface Props {
    event: SchedulePlannedEvent;
    view: any;
    className?: string;
    onViewEvent?(eventId: string): void;
}

const Component: FunctionComponent<Props> = forwardRef(
    ({ event, onViewEvent, className }, ref) => {
        return (
            <div
                className={classNames(
                    'flex min-h-[48px] items-center rounded-lg bg-newEntry px-4 transition-bg-opacity',
                    {
                        'cursor-pointer hover:bg-opacity-75':
                            typeof onViewEvent === 'function',
                    },
                    className
                )}
                onClick={
                    typeof onViewEvent === 'function'
                        ? () => onViewEvent(event.id)
                        : undefined
                }
                ref={ref}
            >
                <p className="text-base font-bold text-white">
                    {formatDate(event.start, true, true)} -{' '}
                    {formatTime(event.start)} (
                    {durationStringFromDates(event.start, event.end)})
                </p>
            </div>
        );
    }
);

export const ScheduleEventPlaceholderCard = observer(Component);
