import { observer } from 'mobx-react';
import { ClearButton } from '@/Components/Button';
import React, { FunctionComponent } from 'react';
import { Session } from '@/Models/Session';
import useAuth from '@/Hooks/useAuth';

interface Props {
    session: Session;
}

const ManagePackButton: FunctionComponent<Props> = ({ session }) => {
    const { hasRole } = useAuth();

    if (!hasRole('Owner')) {
        return null;
    }

    return (
        <ClearButton
            icon="Eye"
            href={`/session-packs/${session!.references.sessionPack}/summary`}
        >
            Manage pack
        </ClearButton>
    );
};

export default observer(ManagePackButton);
