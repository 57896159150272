import React, { FunctionComponent, useState } from 'react';
import { Session } from '@/Models/Session';
import { ClassDate } from '@/Models/ClassDate';
import SessionTransport from '@/Services/Transport/SessionTransport';
import ClassDateTransport from '@/Services/Transport/ClassDateTransport';
import Alert from '@/Components/Alert';
import { observer } from 'mobx-react';
import ConfirmationModal from '@/Components/ConfirmationModal';
import { StackableModalProps } from '@/Components/RootModal';
import Notification from '@/Services/Notification';
import RootStore from '@/Stores/RootStore';
import queryClient from '@/Modules/QueryClient';
import { queryKeys } from '@/Services/QueryKeys';
import { classDateQueryKeys } from '@/Features/ClassDates';

interface Props extends StackableModalProps {
    entry: Session | ClassDate;

    onUpdate?(entry: Session | ClassDate): void;
}

const Component: FunctionComponent<Props> = ({ entry, onUpdate, ...props }) => {
    const [error, setError] = useState<string | null>(null);
    const { entryStore, uiStateStore } = RootStore.stores;

    async function handleConfirmed() {
        try {
            if (entry.type === 'session') {
                const { data: session } = await SessionTransport.claim(
                    entry.eid
                );

                if (!session?.data?.eid) {
                    setError('Sorry, you are not available at that time.');

                    return;
                }

                entryStore.createOrUpdate(session.data);

                if (typeof onUpdate === 'function') {
                    onUpdate(session.data);
                }
            } else {
                const { data: classDate } = await ClassDateTransport.claim(
                    entry.uuid
                );

                if (!classDate?.data?.uuid) {
                    setError('Sorry, you are not available at that time.');

                    return;
                }

                entryStore.createOrUpdate(classDate.data);

                if (typeof onUpdate === 'function') {
                    onUpdate(classDate.data);
                }
            }

            Notification.success(
                `${entry.type === 'session' ? 'Session' : 'Class'} claimed.`
            );

            await queryClient.invalidateQueries(queryKeys.availableEvents);
            await queryClient.invalidateQueries(classDateQueryKeys.all);

            uiStateStore.popModal();
        } catch (e) {
            console.log(e);
            Notification.error();
        }
    }

    return (
        <ConfirmationModal
            isPositive
            shouldAutoClose={false}
            confirmText="Assign to me"
            onConfirmed={handleConfirmed}
            content={() => (
                <>
                    <p>
                        Are you sure you want to deliver this{' '}
                        {entry.type === 'session' ? 'session' : 'class'}?
                    </p>

                    {error && (
                        <Alert title={error} type="error" className="mt-4" />
                    )}
                </>
            )}
            {...props}
        />
    );
};

export const AvailableEntryClaimConfirmationModal = observer(Component);
