import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import ItemListTextCell from '@/Components/ItemList/TextCell';
import Icon from '@/Components/Icon/Icon';
import { uiColors } from '@/Theme';
import { simplePercentage } from '@/Services/Number';

interface Props {
    client?: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    function getIcon() {
        if (client.isArchived) {
            return (
                <Icon
                    name="UICross"
                    width={14}
                    height={14}
                    fill={uiColors.grey.mid}
                />
            );
        }
        if (client.isActive) {
            return (
                <Icon
                    name="UICheck"
                    width={14}
                    height={14}
                    fill={uiColors.positive.default}
                />
            );
        }
        return (
            <Icon
                name="WarningTriangle"
                width={14}
                height={14}
                fill={uiColors.warning.default}
            />
        );
    }
    function getContent() {
        if (client.isArchived) {
            return 'Archived';
        }

        return client.isActive ? 'Active' : 'Inactive';
    }
    function getSecondaryContent() {
        if (!client.attendanceStatus?.total) {
            return 'No attendance data';
        }

        return `${simplePercentage(
            client.attendanceStatus.attended,
            client.attendanceStatus?.total ?? 0
        )}% attendance`;
    }

    return (
        <ItemListTextCell
            icon={getIcon()}
            content={getContent()}
            secondaryContent={getSecondaryContent()}
        />
    );
};

export const ClientListItemStatusCell = observer(Component);
