import React, { FunctionComponent } from 'react';
import { uiColors } from '../../Theme';
import { ButtonProps, WhiteButton } from '../Button';

interface Props extends ButtonProps {}

const ZeroContentButton: FunctionComponent<Props> = (props) => (
    <WhiteButton
        icon="Plus"
        fill={uiColors.core.primary}
        className="!h-16 w-full"
        {...props}
    >
        {props.children}
    </WhiteButton>
);

export default ZeroContentButton;
