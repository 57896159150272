import BaseTransport from './BaseTransport';
import {
    InviteMemberFormValues,
    UpdateMemberFormValues,
} from '@/Features/Team/Types';
import { AxiosResponse } from 'axios';
import { UserInvite } from '@/Models/UserInvite';

class OrganisationTransport extends BaseTransport {
    async listWorkingPeriods() {
        return await this.get(`/v3/user/current-organisation/working-periods`);
    }

    async updateWorkingPeriods(workingPeriods = {}) {
        return await this.put(`/v3/user/current-organisation/working-periods`, {
            workingPeriods,
        });
    }

    async dismissGuide() {
        return await this.post('/v3/user/current-organisation/dismiss-guide');
    }

    async updateConfiguration(data = {}) {
        return await this.put(
            `/v3/user/current-organisation/configuration`,
            data
        );
    }

    async updateStriivePayments(state = false) {
        return await this.put(
            '/v3/user/current-organisation/striive-payments',
            { state }
        );
    }

    async updateClientPortal(data = {}) {
        return await this.put(
            `/v3/user/current-organisation/client-portal`,
            data
        );
    }

    async updateSmsSubscription(data = {}) {
        return await this.put(
            '/v3/user/current-organisation/subscription/sms',
            data
        );
    }

    async getDefaultPaymentMethod() {
        return this.get(`/v3/user/current-organisation/payment-method`);
    }

    async updatePaymentMethod(data = {}) {
        return await this.put(
            '/v3/user/current-organisation/payment-method',
            data
        );
    }

    async listPastInvoices(params = {}) {
        return await this.get('/v3/user/current-organisation/invoices', params);
    }

    async getSubscriptionStatus() {
        return await this.get('/v3/user/current-organisation/subscription');
    }

    async listAvailablePlans() {
        return this.get(`/v3/user/current-organisation/available-plans`);
    }

    async getPlanDetails(priceId: string, coupon: string | null = null) {
        return this.get(
            `/v3/user/current-organisation/available-plans/${priceId}`,
            { coupon }
        );
    }

    async listCards() {
        return await this.get('/v3/user/current-organisation/cards');
    }

    /**
     * Set up a user's intent to pay for a subscription
     * @returns {Promise<*>}
     */
    async requestSetupIntent() {
        return await this.get('/v3/user/current-organisation/setup-intent');
    }

    async getActiveClientCount() {
        return await this.get(
            '/v3/user/current-organisation/active-client-count'
        );
    }

    async createSubscription(data = {}) {
        return await this.post(
            '/v3/user/current-organisation/subscription',
            data
        );
    }

    async updateSubscription(data = {}) {
        return await this.put(
            '/v3/user/current-organisation/subscription',
            data
        );
    }

    async cancelSubscription(data = {}) {
        return await this.post(
            '/v3/user/current-organisation/subscription/cancel',
            data
        );
    }

    async resetCalendarUrl() {
        return this.post('/v3/user/current-organisation/reset-ical-code');
    }

    async extendTrial() {
        return await this.post('/v3/user/current-organisation/extend-trial');
    }

    async getPackageOrder() {
        return await this.get('/v3/user/current-organisation/package-order');
    }

    async listUsers(params = {}) {
        return await this.get('/v3/organisation/users', params);
    }

    async fetchUser(id: string) {
        return await this.get(`/v3/organisation/users/${id}`);
    }

    async inviteUser(
        data: InviteMemberFormValues
    ): Promise<AxiosResponse<UserInvite>> {
        return await this.post('/v3/organisation/invite-user', data);
    }

    async updateUser(
        uuid: string,
        data: UpdateMemberFormValues
    ): Promise<AxiosResponse<UserInvite>> {
        return await this.put(`/v3/organisation/users/${uuid}`, data);
    }

    async archiveUser(uuid: string): Promise<AxiosResponse<UserInvite>> {
        return await this.post(`/v3/organisation/users/${uuid}/archive`);
    }

    async restoreUser(uuid: string): Promise<AxiosResponse<UserInvite>> {
        return await this.post(`/v3/organisation/users/${uuid}/restore`);
    }

    async resendInvite(id: string): Promise<AxiosResponse<UserInvite>> {
        return await this.post(`/v3/organisation/user-invite/${id}/resend`);
    }

    async deleteInvite(id: string): Promise<AxiosResponse<UserInvite>> {
        return await this.delete(`/v3/organisation/user-invite/${id}`);
    }

    async createOrganisation(data = {}) {
        return await this.post('/v3/organisations', data);
    }

    async getAvailableUsers(params: { start: string; end: string }) {
        return await this.get('/v3/organisation/available-users', params);
    }
}

export default new OrganisationTransport();
