import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import withConfirmationModalContext from '@/HOCs/WithConfirmationModalContext';
import ScheduleAddEventButton from '@/Domain/Schedule/ScheduleAddEventButton';
import {
    SaveAdditionalEntriesButton,
    SaveEntryRescheduleButton,
    ScheduleCancelActionButton,
    ScheduleFinishedPlacingClassDatesButton,
    ScheduleFinishedPlacingDiaryEntriesButton,
    ScheduleFinishedPlacingSessionsButton,
} from '@/Features/Schedule';
import { periodsToTimeSummary } from '@/Services/Date';
import UpdateSessionPack from '../../../../assets/js/actions/UpdateSessionPack';

interface Props {
    view: any;
    action: any;
    calendar: any;
    className: string;
    dateCount: number;
    newDateCount: number;
    totalPeriods: number;
    hasUpdatedCard: boolean;
    onActionCancel: () => void;
}

const Component: FunctionComponent<Props> = ({
    action,
    calendar,
    view,
    dateCount,
    newDateCount,
    totalPeriods,
    hasUpdatedCard,
    onActionCancel,
}) => {
    const timeSummary = periodsToTimeSummary(totalPeriods, false, '0h');
    const showTimeSummary = view.action instanceof UpdateSessionPack;

    if (!action.isAvailable) {
        return (
            <ScheduleAddEventButton
                responsive
                view={view}
                calendar={calendar}
                on-success={view.resetAction}
            />
        );
    }

    return (
        <div className="flex w-full gap-2 rounded-md border border-gray-200 p-3">
            <div className="flex flex-1 items-center gap-2 text-base font-bold">
                {action.isCreating || action.isUpdating ? (
                    <div className="flex items-start gap-2">
                        <span className="min-w-[26px] rounded bg-newEntry px-1.5 py-0.5 pt-px text-center text-white">
                            {action.isRescheduling && !hasUpdatedCard
                                ? '0'
                                : dateCount}
                        </span>
                        <div className="flex flex-col">
                            <span className="relative top-px">
                                {action.isRescheduling ? 'moved' : 'added'}
                            </span>
                            {showTimeSummary && (
                                <span className="text-xs text-gray-500">
                                    ({timeSummary} left in pack)
                                </span>
                            )}
                        </div>
                    </div>
                ) : undefined}
            </div>
            <div className="flex gap-2">
                <ScheduleCancelActionButton
                    view={view}
                    calendar={calendar}
                    onSuccess={onActionCancel}
                />
                {action.isCreating && action.type.nameSingular == 'session' && (
                    <ScheduleFinishedPlacingSessionsButton
                        action={action}
                        view={view}
                        calendar={calendar}
                        disabled={!dateCount}
                    />
                )}
                {action.isCreating &&
                    action.type.nameSingular == 'class-date' && (
                        <ScheduleFinishedPlacingClassDatesButton
                            action={action}
                            view={view}
                            calendar={calendar}
                            disabled={!dateCount}
                        />
                    )}
                {action.isCreating &&
                    action.type.nameSingular == 'diary-entry' && (
                        <ScheduleFinishedPlacingDiaryEntriesButton
                            action={action}
                            view={view}
                            calendar={calendar}
                            disabled={!dateCount}
                        />
                    )}
                {action.isRescheduling && (
                    <SaveEntryRescheduleButton view={view} card={action.card} />
                )}
                {action.isUpdating && !action.isRescheduling && (
                    <SaveAdditionalEntriesButton
                        view={view}
                        disabled={!newDateCount}
                    />
                )}
            </div>
        </div>
    );
};

export const ScheduleActionPanel = withConfirmationModalContext(
    observer(Component)
);
