import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import {
    CreateUpdateMessageModal,
    MessageFormValues,
} from '@/Features/Messages';
import { StackableModalProps } from '@/Components/RootModal';
import MessageTransport from '@/Services/Transport/MessageTransport';
import CustomerSupport from '@/Services/CustomerSupport';
import { ClassDate } from '@/Models/ClassDate';
import { formatDate, formatTime } from '@/Services/Date';
import FormCheckboxField from '@/Components/FormCheckboxField';
import FormField from '@/Components/FormField';
import Tag from '@/Components/Tag';

interface Props extends StackableModalProps {
    classDate: ClassDate;
}

const Component: FunctionComponent<Props> = ({ classDate, ...props }) => {
    const mapPropsToValues = (): MessageFormValues & {
        includeForfeited: boolean;
    } => {
        return {
            recipients: [],
            subject: '',
            content: '',
            includeForfeited: false,
        };
    };

    const handleSave = async (values: MessageFormValues) => {
        await MessageTransport.storeClassDateMessage(classDate.uuid, values);

        CustomerSupport.trackEvent('Message Sent');
    };

    return (
        <CreateUpdateMessageModal
            isCreating
            stackProps={props.stackProps}
            mapPropsToValues={mapPropsToValues}
            onSave={handleSave}
            hasRecipientField={false}
            recipientContent={
                <>
                    <FormField>
                        <FormField.Label required>Recipients</FormField.Label>
                        <Tag
                            size="large"
                            className="!h-auto !whitespace-normal py-2 font-bold !leading-normal"
                        >
                            Class attendees: {classDate.class?.name} (
                            {formatDate(classDate.start)} -{' '}
                            {formatTime(classDate.start)})
                        </Tag>
                    </FormField>

                    <FormField>
                        <FormCheckboxField
                            id="includeForfeited"
                            name="includeForfeited"
                            label="Include forfeited clients"
                        />
                    </FormField>
                </>
            }
        />
    );
};

export const CreateClassDateMessageModal = observer(Component);
