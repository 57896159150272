import React from 'react';

const CustomSchedule = (props) => (
    <svg viewBox="0 0 20 20" {...props}>
        <path
            fill={props.fill}
            d="M15 0a2 2 0 012 2h1a2 2 0 012 2v14a2 2 0 01-2 2H2a2 2 0 01-2-2V4a2 2 0 012-2h1a2 2 0 014 0h6a2 2 0 012-2zm2 13h-5a1 1 0 00-1 1v2a1 1 0 001 1h5a1 1 0 001-1v-2a1 1 0 00-1-1zm-9 0H3a1 1 0 00-1 1v2a1 1 0 001 1h5a1 1 0 001-1v-2a1 1 0 00-1-1zm9-6h-5a1 1 0 00-1 1v2a1 1 0 001 1h5a1 1 0 001-1V8a1 1 0 00-1-1zM8 7H3a1 1 0 00-1 1v2a1 1 0 001 1h5a1 1 0 001-1V8a1 1 0 00-1-1z"
            fillRule="evenodd"
        />
    </svg>
);

export default CustomSchedule;
