import BaseTransport from './BaseTransport';

class PhysicalLocationTransport extends BaseTransport {
    async list(params = {}) {
        return await this.get('/v3/physical-locations', params);
    }

    async store(data) {
        return await this.post('/v3/physical-locations', data);
    }

    async update(uuid, data) {
        return await this.put(`/v3/physical-locations/${uuid}`, data);
    }

    async destroy(uuid) {
        return await this.delete(`/v3/physical-locations/${uuid}`);
    }
}

export default new PhysicalLocationTransport();
