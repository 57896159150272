import CreateAction from './CreateAction.js';
import Diary from './Diary.js';

export default class CreateDiary extends CreateAction {
    static get type() {
        return new Diary();
    }

    get isReady() {
        return this.calendar.newCards.length > 0;
    }

    get cards() {
        return this.calendar.newCards;
    }

    get calendarTitle() {
        return 'Plan appointments';
    }
}
