import { applySnapshot, destroy, flow, getRoot, types } from 'mobx-state-tree';
import TransactionTransport from '../Services/Transport/TransactionTransport';
import { Transaction } from '../Models/Transaction';

export const TransactionStore = types
    .model('TransactionStore', {
        transactions: types.map(Transaction),
    })

    .volatile((self) => ({
        inFlight: new Set(),
    }))

    .views((self) => {
        const fetchTransaction = async (uuid, params = {}) => {
            if (self.inFlight.has(uuid) || self.transactions.has(uuid)) {
                return;
            }

            self.inFlight.add(uuid);

            try {
                await self.fetch(uuid, params);
            } catch (error) {
                console.log({ error });
            }

            self.inFlight.delete(uuid);
        };

        return {
            find(uuid) {
                return self.transactions.get(uuid);
            },

            findOrFetch(uuid) {
                fetchTransaction(uuid);
                return self.transactions.get(uuid);
            },

            get available() {
                return Array.from(self.transactions.values()).filter(
                    (trans) => !trans.isDeleted
                );
            },

            getWithinInterval(interval) {
                return self.available.filter((trans) =>
                    interval.contains(trans.madeAt)
                );
            },
        };
    })

    .actions((self) => ({
        fetch: flow(function* fetch(uuid, params = {}) {
            const { data } = yield TransactionTransport.fetch(uuid, params);
            return self.createOrUpdate(data.data);
        }),

        list: flow(function* list(requestData, withMeta = false) {
            const { data } = yield TransactionTransport.list(requestData);
            const items = data.data.map((transaction) =>
                self.createOrUpdate(transaction)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        store: flow(function* store(type, transactionData) {
            const { data } = yield TransactionTransport.store(
                type,
                transactionData
            );
            return self.createOrUpdate(data.data);
        }),

        update: flow(function* update(uuid, type, transactionData) {
            const { data } = yield TransactionTransport.update(
                uuid,
                type,
                transactionData
            );
            return self.createOrUpdate(data.data);
        }),

        destroy: flow(function* destroy(uuid, type) {
            const { data } = yield TransactionTransport.destroy(uuid, type);
            return self.createOrUpdate(data.data);
        }),

        createOrUpdate(transactionData) {
            const transaction = self.processNestedResources(transactionData);

            if (self.transactions.has(transaction.uuid)) {
                const existing = self.transactions.get(transaction.uuid);
                applySnapshot(existing, transaction);
            } else {
                self.transactions.set(transaction.uuid, transaction);
            }

            return self.transactions.get(transaction.uuid);
        },

        /**
         * @param transactionData
         * @returns {*}
         */
        processNestedResources(transactionData) {
            if (typeof transactionData.client !== 'undefined') {
                transactionData.client = getRoot(
                    self
                ).stores.clientStore.processAsNested(transactionData.client);
            }

            transactionData.classPass = getRoot(
                self
            ).stores.classPassStore.processAsNested(transactionData.classPass);
            transactionData.enrolment = getRoot(
                self
            ).stores.enrolmentStore.processAsNested(transactionData.enrolment);
            transactionData.sessionPack = getRoot(
                self
            ).stores.sessionPackStore.processAsNested(
                transactionData.sessionPack
            );
            transactionData.membership = getRoot(
                self
            ).stores.membershipSubscriptionStore.processAsNested(
                transactionData.membership
            );

            return transactionData;
        },

        deleteForSessionPack(sessionPackEid) {
            Array.from(self.transactions.values())
                .filter(
                    (transaction) =>
                        transaction.isForSessionPack &&
                        transaction.sessionPackEid === sessionPackEid
                )
                .forEach((transaction) => destroy(transaction));
        },
    }));
