import React, { FunctionComponent } from 'react';
import { WhiteButton } from '@/Components/Button';
import { UseInfiniteQueryResult } from 'react-query';

interface Props {
    queryResult: UseInfiniteQueryResult;
}

export const InfiniteItemListFooter: FunctionComponent<Props> = ({
    queryResult,
}) => {
    if (!queryResult.hasNextPage) {
        return null;
    }

    return (
        <footer className="mt-4 flex justify-center">
            <WhiteButton
                onClick={() => queryResult.fetchNextPage()}
                isLoading={queryResult.isFetchingNextPage}
            >
                Load more
            </WhiteButton>
        </footer>
    );
};
