import './jqueryGlobal';
import angular from 'angular';
import ngResource from 'angular-resource';
// @ts-ignore
import ngMessages from 'angular-messages';
import { lazyInjector } from '../assets/js/lazy-injector';

// Services
import '../assets/js/services/auth';
import '../assets/js/services/calendar';
import '../assets/js/services/dialog';
import '../assets/js/services/resource-fetcher';
import '../assets/js/services/resource-scroller';
import '../assets/js/services/transformer';
import '../assets/js/services/validation';

// Resources
import '../assets/js/resources/session';
import '../assets/js/resources/client-session-pack';
import '../assets/js/resources/client';
import '../assets/js/resources/diary-entry';
import '../assets/js/resources/consultation';
import '../assets/js/resources/class-date';
import '../assets/js/resources/referral';

// Components
import '../assets/js/components/link/link';
import '../assets/js/components/dialog-wrapper/dialog-wrapper';
import '../assets/js/components/wizard/wizard';
import '../assets/js/components/client-parq-data-review-dialog/client-parq-data-review-dialog';

import '../assets/js/react/react-consultation-entry-card';
import '../assets/js/react/react-session-entry-card';
import '../assets/js/react/react-diary-entry-card';
import '../assets/js/react/react-client-card';
import '../assets/js/react/react-transaction-row-summary';

// Sections
import '../assets/js/sections/schedule/week/week';

// React bridging components
import '../assets/js/react/react-icon';
import '../assets/js/react/react-loader';

// Utilities
import '../assets/js/utilities/general';
import '../assets/js/utilities/buttons';

export function bootAngular() {
    return new Promise((resolve) => {
        angular
            .module('striive', [
                ngResource,
                ngMessages,

                // Services
                'auth',
                'service.calendar',
                'service.dialog',
                'service.resource-fetcher',
                'service.resource-scroller',
                'service.transformer',
                'service.validation',

                // Resources
                'resource.client-session-pack',
                'resource.client',
                'resource.diary-entry',
                'resource.session',
                'resource.consultation',
                'resource.class-date',
                'resource.referral',

                // Sections
                'section.schedule.week',

                // Components
                'component.nav-link',
                'component.dialog-wrapper',
                'component.wizard',
                'component.client-parq-data-review-dialog',

                // React bridging components
                'component.react.icon',
                'component.react.loader',
                'component.react.client-card',
                'component.react.transaction-row-summary',

                // Utilities
                'utilities.general',
                'utilities.buttons',
            ])
            .config([
                '$interpolateProvider',
                '$httpProvider',
                function (
                    // @ts-ignore
                    $interpolateProvider,
                    // @ts-ignore
                    $httpProvider
                ) {
                    // Set the template symbols
                    $interpolateProvider.startSymbol('[[');
                    $interpolateProvider.endSymbol(']]');

                    // Set the ajax header
                    $httpProvider.defaults.headers.common['X-Requested-With'] =
                        'XMLHttpRequest';
                    $httpProvider.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';
                    $httpProvider.defaults.xsrfCookieName = 'XSRF-TOKEN';
                    $httpProvider.defaults.withCredentials = true;

                    $httpProvider.interceptors.push('apiInterceptor');
                    $httpProvider.interceptors.push('authInterceptor');
                    $httpProvider.interceptors.push('XSRFInterceptor');
                },
            ])
            .run([
                '$injector',
                function (_$injector: any) {
                    lazyInjector.$injector = _$injector;

                    resolve('booted');
                },
            ]);
    });
}
