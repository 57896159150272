import classNames from 'classnames';
import React, { FunctionComponent, HTMLProps } from 'react';

export type InputType = FunctionComponent<HTMLProps<HTMLDivElement>>;

const Input: InputType = ({ className, ...props }) => (
    <div className={classNames('form-field__field', className)} {...props} />
);

export default Input;
