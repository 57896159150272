import { getRoot, types } from 'mobx-state-tree';

const LocationBase = types
    .model()

    .views((self) => ({
        get isPhysical() {
            return self.locationType === 'physical';
        },

        get isDefault() {
            return (
                getRoot(self).stores.currentUserStore.organisation
                    ?.defaultLocation?.uuid === self.uuid
            );
        },

        // Legacy properties
        get default() {
            return self.isDefault;
        },
    }));

export default LocationBase;
