import { Instance, types } from 'mobx-state-tree';
import { Currency } from '@/Models/Currency';

export interface LocaleInstance extends Instance<typeof Locale> {}

export const Locale = types
    .model('Locale', {
        countryCode: types.identifier,
        countryName: types.string,
        currency: types.maybeNull(Currency),
        timezone: types.string,
        timeFormat: types.maybeNull(types.enumeration(['12h', '24h'])),
        eu: types.boolean,
        smsPlan: types.model({
            name: types.string,
            cost: types.integer,
            threshold: types.integer,
            quantity: types.integer,
        }),
        supportsStripe: types.boolean,
    })

    .views((self) => ({}));
