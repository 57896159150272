import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { useInfiniteQuery } from 'react-query';
import merge from 'lodash-es/merge';
import InfiniteItemList from '@/Components/InfiniteItemList';
import { ClassPass } from '@/Models/ClassPass';
import ItemListStickyHeader from '@/Components/ItemList/StickyHeader';
import SmallCaps from '@/Components/SmallCaps';
import ZeroContent from '@/Components/ZeroContent';
import RootStore from '@/Stores/RootStore';
import { ClientListItem, CreateClientOptionsButton } from '@/Features/Clients';
import { HelpButton } from '@/Components/HelpButton';
import { getNextPageParam } from '@/Services/Api';

interface Props {
    filters?: any;
}

const Component: FunctionComponent<Props> = ({ filters }) => {
    const { clientStore } = RootStore.stores;
    const queryResult = useInfiniteQuery(
        ['clients', 'roster', filters],
        async ({ pageParam = 1 }) => {
            return await clientStore.list(
                merge(
                    {
                        page: pageParam,
                        include: ['lastActive', 'nextActive'],
                        append: ['hasPortalPasswordInvite'],
                    },
                    filters
                ),
                true
            );
        },
        {
            getNextPageParam,
            refetchOnMount: 'always',
        }
    );

    return (
        <InfiniteItemList<ClassPass>
            queryResult={queryResult}
            renderHeader={
                <ItemListStickyHeader className="xl:grid-cols-clients-xl">
                    <SmallCaps className="col-span-2">Name</SmallCaps>
                    <SmallCaps>Last seen</SmallCaps>
                    <SmallCaps>Upcoming</SmallCaps>
                    <SmallCaps className="col-span-2">
                        Client area / App
                    </SmallCaps>
                </ItemListStickyHeader>
            }
            renderItem={(client, classes) => (
                <ClientListItem client={client} className={classes} />
            )}
            zeroContent={
                <ZeroContent
                    inline
                    icon="CustomClients"
                    title="No clients yet"
                    content={
                        <>
                            <p className="mb-2">
                                Sign up clients by sending an invite or sharing
                                your screen.
                            </p>
                            <HelpButton
                                href="https://help.striive.co/article/5-clients-overview"
                                withText="Learn more about clients"
                            />
                            <CreateClientOptionsButton />
                        </>
                    }
                />
            }
        />
    );
};

export const ClientList = observer(Component);
