export default class Diary {
    get nameSingular() {
        return 'diary-entry';
    }

    get namePlural() {
        return 'diary-entries';
    }

    get titleSingular() {
        return 'Appointment';
    }

    get titlePlural() {
        return 'Appointments';
    }
}
