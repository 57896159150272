import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { useInfiniteQuery } from 'react-query';
import merge from 'lodash-es/merge';
import InfiniteItemList from '@/Components/InfiniteItemList';
import ItemListStickyHeader from '@/Components/ItemList/StickyHeader';
import SmallCaps from '@/Components/SmallCaps';
import ZeroContent from '@/Components/ZeroContent';
import { CreateMessageButton, MessageListItem } from '@/Features/Messages';
import RootStore from '@/Stores/RootStore';
import { getNextPageParam } from '@/Services/Api';

interface Props {
    filters?: any;
}

const Component: FunctionComponent<Props> = ({ filters }) => {
    const { messageStore } = RootStore.stores;
    const queryResult = useInfiniteQuery(
        ['messages', 'list', filters],
        async ({ pageParam = 1 }) =>
            await messageStore.list(
                merge(
                    {
                        page: pageParam,
                        withRelations: ['client'],
                    },
                    filters
                ),
                true
            ),
        {
            getNextPageParam,
            refetchOnMount: 'always',
        }
    );

    return (
        <InfiniteItemList
            queryResult={queryResult}
            renderHeader={
                <ItemListStickyHeader className="xl:grid-cols-messages-xl">
                    <SmallCaps className="col-span-2">Client</SmallCaps>
                    <SmallCaps>Details</SmallCaps>
                </ItemListStickyHeader>
            }
            renderItem={(message, classes) => (
                <MessageListItem message={message} className={classes} />
            )}
            zeroContent={
                <ZeroContent
                    inline
                    icon="CustomMessages"
                    title="No emails sent yet"
                    content={
                        <>
                            <p>
                                Quickly send emails to multiple clients at once
                                with replies delivered to your regular inbox.
                            </p>
                            <CreateMessageButton variant="primary" />
                        </>
                    }
                />
            }
        />
    );
};

export const MessageList = observer(Component);
