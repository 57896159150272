import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import Avatar, { AvatarSize } from '@/Components/Avatar';
import { UserInstance } from '@/Models/User';
import MissingUserAvatar from '@/Components/MissingUserAvatar';
import { OrganisationUser } from '@/Models/OrganisationUser';
import { ConditionalWrapper } from '@/Components/ConditionalWrapper';
import { Tooltip } from '@/Components/Tooltip';

interface Props {
    user: UserInstance | OrganisationUser;
    size?: AvatarSize;
    url?: string;
    className?: string;
    missingIcon?: React.ReactNode;
    withTooltip?: boolean;
}

const Component: FunctionComponent<Props> = ({
    user,
    size = 'small',
    url,
    className,
    missingIcon = <MissingUserAvatar />,
    withTooltip = false,
}) => {
    const src = user?.image?.urls?.[size];

    return (
        <ConditionalWrapper
            condition={withTooltip}
            wrapper={(children) => (
                <Tooltip label={user.fullName}>
                    <div>{children}</div>
                </Tooltip>
            )}
        >
            <Avatar
                src={src}
                alt={user.fullName}
                url={url}
                className={className}
                missingIcon={missingIcon}
            />
        </ConditionalWrapper>
    );
};

export const UserAvatar = observer(Component);
