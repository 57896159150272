import BaseTransport from './BaseTransport';

class EnrolmentTransport extends BaseTransport {
    async list(params = {}) {
        return await this.get('/v3/enrolments', params);
    }

    async fetch(uuid, params = {}) {
        return await this.get(`/v3/enrolments/${uuid}`, params);
    }

    async update(uuid, params = {}) {
        return await this.put(`/v3/enrolments/${uuid}`, params);
    }

    async destroy(uuid, params = {}) {
        return await this.put(`/v3/enrolments/${uuid}/cancel`, params);
    }
}

export default new EnrolmentTransport();
