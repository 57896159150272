import { flow, getRoot, types } from 'mobx-state-tree';
import RootStore from '../Stores/RootStore';
import { DateTimeType } from '../Types/DateTime';
import { durationString } from '../Services/Date';
import { ClientReference } from './Client';
import { Session, SessionReference } from './Session';
import { DecimalType } from '../Types/Decimal';
import SessionPackTransport from '../Services/Transport/SessionPackTransport';
import { currencyDisplay } from '../Services/Currency';
import WithLocation from './WithLocation';
import WithPermissions from './WithPermissions';
import { DateTime } from 'luxon';
import { SessionPackParticipation } from './SessionPackParticipation';
import { clientGroupString } from '../Services/Client';
import { deliveryFromFormValues } from '../Services/Delivery';
import { OrganisationUser } from './OrganisationUser';
import { Currency } from './Currency';
import { TransactionReference } from './Transaction';

const SessionPackModel = types
    .model('SessionPack', {
        eid: types.string,
        type: types.literal('session-pack'),
        identifier: types.identifier,
        handle: types.maybe(types.string),
        name: types.string,

        // Dates
        purchasedAt: DateTimeType,
        start: types.maybeNull(DateTimeType),
        end: types.maybeNull(DateTimeType),

        unscheduled: types.maybe(types.integer),
        reference: types.string,
        remindersEnabled: types.boolean,

        // Costs
        currency: Currency,
        totalPaid: types.integer,
        totalCost: types.integer,

        /**
         * Related models
         */

        participation: types.maybe(types.array(SessionPackParticipation)),

        // Clients
        // clients: types.maybe(types.array(ClientReference)),
        clients: types.maybe(types.array(types.late(() => ClientReference))),
        clientCosts: types.map(DecimalType),

        assignedUsers: types.array(
            types.late(() => types.reference(OrganisationUser))
        ),

        sessions: types.array(types.late(() => SessionReference)),
        transactions: types.array(types.late(() => TransactionReference)),

        sessionCount: types.integer,
        totalSessionLength: types.integer,

        isActive: types.boolean,
        isDeleted: false,

        permissions: types.maybe(
            types.model({
                update: types.model({
                    purchasedAt: types.boolean,
                }),
            })
        ),

        /**
         * Used for adding / editing
         */
        isNew: false,
        length: types.optional(types.integer, 0),
        saveAttempted: false,

        /** Conditional attributes */
        paymentsCount: types.maybe(types.integer),
        updatePlanned: types.maybe(types.array(types.string)),
    })

    .preProcessSnapshot((snapshot) => {
        let newSnapshot = {
            ...snapshot,
            clients: [],
            sessions: [],
        };

        if (!!snapshot.clients) {
            snapshot.clients.forEach((client) => {
                if (typeof client === 'string') {
                    newSnapshot.clients.push(client);
                } else {
                    RootStore.stores.clientStore.createOrUpdate(client);
                    newSnapshot.clients.push(client.eid);
                }
            });
        }

        if (!!snapshot.sessions) {
            if (typeof snapshot.length === 'undefined') {
                newSnapshot.length = snapshot.unscheduled;
            }

            snapshot.sessions.forEach((session) => {
                if (typeof snapshot.length === 'undefined') {
                    newSnapshot.length += session.length;
                }

                if (typeof session === 'string') {
                    newSnapshot.sessions.push(session);
                } else {
                    RootStore.stores.entryStore.createOrUpdate(session);
                    newSnapshot.sessions.push(session.identifier);
                }
            });
        }

        return newSnapshot;
    })

    .views((self) => ({
        get humanType() {
            return 'session pack';
        },

        get url() {
            return `/session-packs/${self.eid}`;
        },

        get plannedLength() {
            return self.sessions.reduce(
                (acc, session) => acc + +session.length,
                0
            );
        },

        get plannedSessions() {
            const now = DateTime.local();
            return self.sessions.filter((session) => session.start >= now);
        },

        get plannedCount() {
            return self.plannedSessions.length;
        },

        get hasPlanned() {
            return self.plannedCount > 0;
        },

        get deliveredSessions() {
            const now = DateTime.local();
            return self.sessions.filter((session) => session.start < now);
        },

        get deliveredLength() {
            return self.deliveredSessions.reduce(
                (acc, session) => acc + +session.length,
                0
            );
        },

        get undeliveredLength() {
            return self.length - self.deliveredLength;
        },

        get undeliveredString() {
            return durationString(self.undeliveredLength * 15);
        },

        get deliveredCount() {
            return self.deliveredSessions.length;
        },

        get hasDelivered() {
            return self.deliveredCount > 0;
        },

        get hasUndelivered() {
            return self.undeliveredLength > 0;
        },

        get lengthInHours() {
            return self.totalLength / 4;
        },

        get hasUnplanned() {
            return self.unscheduled > 0;
        },

        get hourlyRate() {
            if (!self.lengthInHours) {
                return null;
            }

            return self.totalCost / self.lengthInHours;
        },

        get hourlyRateFormatted() {
            if (!self.hourlyRate) {
                return '-';
            }

            return currencyDisplay(self.hourlyRate, self.currency);
        },

        get unplannedString() {
            return durationString(self.unscheduled * 15);
        },

        get totalLength() {
            return self.totalSessionLength + self.unscheduled;
        },

        get totalLengthString() {
            return durationString(self.totalLength * 15);
        },

        get extendedTotalLengthString() {
            return durationString(self.length * 15, true);
        },

        get plannedLengthString() {
            return durationString(self.plannedLength * 15);
        },

        get deliveredLengthString() {
            return durationString(self.deliveredLength * 15);
        },

        get clientCount() {
            return self.clients.length;
        },

        hasClient(eid) {
            return self.clients.find((client) => client.eid === eid);
        },

        get hasClients() {
            return self.clients && self.clientCount > 0;
        },

        get clientIdentifiers() {
            return self.clients.map((client) => client.eid);
        },

        get clientLabel() {
            return clientGroupString(self.clients, 'fullName');
        },

        get hasSessions() {
            return (
                self.sessions &&
                (self.sessions.length > 0 || self.sessionCount > 0)
            );
        },

        get formattedCost() {
            return currencyDisplay(self.totalCost, self.currency);
        },

        get hasImage() {
            return self.hasClients && self.clients[0].hasImage;
        },

        get imageSrc() {
            return self.clients[0].imageSrc;
        },

        get outstanding() {
            return self.totalCost - self.totalPaid;
        },

        get overpaid() {
            return self.outstanding;
        },

        get hasOutstanding() {
            return self.outstanding > 0;
        },

        get hasOverpaid() {
            return self.outstanding < 0;
        },

        get outstandingFormatted() {
            return currencyDisplay(self.outstanding, self.currency);
        },

        get totalPaidFormatted() {
            return currencyDisplay(self.totalPaid, self.currency);
        },

        get totalCostFormatted() {
            return currencyDisplay(self.totalCost, self.currency);
        },

        get isComplete() {
            return !self.hasUndelivered;
        },

        // API compatible object
        get saveData() {
            return {
                name: self.name,
                length: self.length,
                unscheduled: self.unscheduled,
                delivery: self.delivery,
                remindersEnabled: self.remindersEnabled,
                assignedUsers: self.assignedUsers.map((u) => ({ ...u })),
            };
        },

        get mapDataToFormValues() {
            const costs = {};
            self.clients.forEach((c) => {
                const participation = self.participation.find(
                    (p) => p.client.eid === c.eid
                );
                costs[c.eid] = participation?.amount ?? 0;
            });

            return {
                ...self.saveData,
                ...self.deliveryFormValues,
                clients: self.clients,
                costs,
            };
        },
    }))

    .actions((self) => ({
        /**
         * Update the sessionPack on the server
         */
        update: flow(function* update(values) {
            const {
                name,
                length,
                costs,
                remindersEnabled,
                purchasedAt,
                updatePlanned,
            } = values;
            const { data } = yield SessionPackTransport.update(self.eid, {
                name,
                costs,
                length,
                purchasedAt,
                remindersEnabled,
                updatePlanned,
                delivery: deliveryFromFormValues(values),
                assignedUsers: values.assignedUsers,
            });
            const sessionPack = getRoot(
                self
            ).stores.sessionPackStore.createOrUpdate(data.data);

            if (values.applyToFuture) {
                self.updateSessionDeliveries(sessionPack.delivery);
            }
        }),

        endEarly: flow(function* endEarly(clientCosts) {
            const { data } = yield SessionPackTransport.end(
                self.eid,
                clientCosts
            );

            return getRoot(self).stores.sessionPackStore.createOrUpdate(
                data.data
            );
        }),

        /**
         * Update the sessionPack on the server
         */
        remove: flow(function* remove(notify) {
            yield SessionPackTransport.remove(self.eid, { notify });

            self.isDeleted = true;

            RootStore.stores.sessionPackStore.delete(
                self.identifier,
                true,
                true
            );
        }),

        /**
         * Update the total length of the sessionPack, which can only manually be done by changing the amount of unplanned time
         * @param newLength integer (this is in periods)
         */
        updateLength(newLength) {
            self.length = newLength;

            // Also ensure unplanned time is in sync
            self.updateUnplanned();
        },

        /**
         * Update the total amount of unplanned time
         */
        updateUnplanned() {
            const unscheduled = self.length - self.plannedLength;
            self.unscheduled = unscheduled > 0 ? unscheduled : 0;
        },

        updateSessionDeliveries(delivery) {
            self.sessions.forEach((session) =>
                session.updateDelivery(delivery)
            );
        },

        validateLength() {
            if (self.length < self.plannedLength) {
                self.updateLength(self.plannedLength);
            }
        },

        addClient(client) {
            self.clients.push(client);
            self.setSaveAttempted(false);

            self.manualCosts = false;
        },

        removeClient(client) {
            self.clients.remove(client);
            self.clientCosts.delete(client.eid);

            if (!self.clients.length) {
                self.manualCosts = false;
            }
        },

        sortSessions() {
            self.sessions = self.sessions
                .slice()
                .sort((a, b) => (a.start < b.start ? -1 : 1));
        },

        resetNewSessions(cards) {
            const existing = self.sessions.map((session) => session.identifier);

            self.sessions = [];
            existing.forEach((identifier) =>
                RootStore.stores.entryStore.delete(identifier)
            );

            self.addNewSessions(cards);
        },

        addNewSessions(cards) {
            cards.forEach((card, index) => {
                const session = Session.create({
                    eid: card.internalId,
                    identifier: card.internalId,
                    type: 'session',
                    label: '',
                    location: self.location,
                    isNew: true,
                    index: index + 1,
                    start: card.startDate,
                    end: card.endDate,
                    length: card.length,
                });
                RootStore.stores.entryStore.createOrUpdate(session);

                self.sessions.push(card.internalId);
            });

            self.sortSessions();
            self.validateLength();
        },

        removeSession(session) {
            const identifier = session.identifier;
            self.sessions.remove(session);
            RootStore.stores.entryStore.delete(identifier);
        },

        // updateClientCost(eid, cost) {
        //     if (!self.manualCosts) {
        //         let defaultCost = Math.round(self.calculatedTotalCost / self.clients.length);
        //         self.clients.forEach(({ eid }) => self.clientCosts.set(eid, defaultCost));
        //
        //         self.manualCosts = true;
        //     }
        //
        //     self.clientCosts.set(eid, cost);
        //     // self.calculateTotalCost();
        // },

        setSaveAttempted(saveAttempted) {
            self.saveAttempted = saveAttempted;
        },

        getPlannedSessionsAfter(datetime) {
            return self.plannedSessions
                .slice()
                .filter((session) => session.start > datetime);
        },
    }));

export const SessionPack = types.compose(
    SessionPackModel,
    WithPermissions,
    WithLocation
);

export const SessionPackReference = types.maybeNull(
    types.reference(SessionPack, {
        get(identifier, parent) {
            return getRoot(parent).stores.sessionPackStore.findOrFetch(
                identifier
            );
        },
        set({ identifier }) {
            return identifier;
        },
    })
);
