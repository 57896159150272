import React, { ChangeEvent, Fragment, FunctionComponent } from 'react';

interface Props {
    id?: string;
    name?: string;
    value?: string;
    checked: boolean;
    label: string | JSX.Element;
    isDisabled?: boolean;
    labelClassName?: string;
    className?: string;
    onChange(event: ChangeEvent<HTMLInputElement>): void;
}

const Radio: FunctionComponent<Props> = ({
    isDisabled,
    label,
    labelClassName,
    ...props
}) => {
    let id = props.id || props.name;

    if (!id) {
        id = Math.random().toString(36).substr(2, 5);
    }

    return (
        <Fragment>
            <input
                type="radio"
                className="radio"
                disabled={isDisabled}
                {...props}
                id={id}
            />
            {label && (
                <label htmlFor={id} className={labelClassName}>
                    {label}
                </label>
            )}
        </Fragment>
    );
};

export default Radio;
