import CreateAction from './CreateAction';
import ClassContainer from './ClassContainer';

export default class CreateClass extends CreateAction {
    static get type() {
        return new ClassContainer();
    }

    constructor(calendar, parameters = {}) {
        super(calendar, parameters);
    }

    get isReady() {
        return true;
    }

    get calendarTitle() {
        return 'Plan class dates';
    }
}
