import React, { FunctionComponent } from 'react';
import { NewPopper } from '@/Components/NewPopper';
import ClassDateUpdateButton from '@/Components/ClassDate/UpdateButton';
import EntryRescheduleButton, {
    CalendarSetRescheduleAction,
} from '@/Components/EntryRescheduleButton';
import ClassDateCancelButton from '@/Components/ClassDate/CancelButton';
import ScheduleAddEntryDatesButton from '@/Components/Schedule/AddEntryDatesButton';
import { ClearButton } from '@/Components/Button';
import { ClassDate } from '@/Models/ClassDate';
import {
    AvailableEntryClaimButton,
    ScheduleEventTeachersButton,
} from '@/Features/Schedule';
import useAuth from '@/Hooks/useAuth';
import EntryRelinquishButton from '@/Components/EntryRelinquishButton';

interface Props {
    classDate: ClassDate;
    handleUpdated?: (classDate: ClassDate, otherDates: ClassDate[]) => void;
    handleCancelled?: () => void;
    rescheduleAction?: CalendarSetRescheduleAction;
    onAssignedUserChange?(assignedUser: string | undefined): void;
}

const OptionsButton: FunctionComponent<Props> = ({
    classDate,
    handleUpdated,
    handleCancelled,
    rescheduleAction,
    onAssignedUserChange,
}) => {
    const { hasRole } = useAuth();

    return (
        <div className="ui-button-group">
            <NewPopper placement="bottom-end">
                {() => (
                    <div className="ui-button-list">
                        {hasRole('Owner') && (
                            <>
                                <ClassDateUpdateButton
                                    classDate={classDate}
                                    onSuccess={handleUpdated}
                                />
                                <ScheduleEventTeachersButton
                                    entry={classDate}
                                    variant="full"
                                    onAssignedUserChange={onAssignedUserChange}
                                />
                                <EntryRescheduleButton
                                    entry={classDate}
                                    variant="full"
                                    action={rescheduleAction}
                                />
                            </>
                        )}

                        <EntryRelinquishButton
                            entry={classDate}
                            variant="menu"
                        />
                        <AvailableEntryClaimButton entry={classDate} />

                        {hasRole('Owner') && (
                            <>
                                <ClassDateCancelButton
                                    classDate={classDate}
                                    onSuccess={handleCancelled}
                                />
                                <ScheduleAddEntryDatesButton
                                    variant="menu"
                                    type="class-date"
                                    parentIdentifier={
                                        classDate.references.class
                                    }
                                />
                                <ClearButton
                                    icon="Eye"
                                    href={`/classes/${classDate.references.class}/summary`}
                                >
                                    Manage class
                                </ClearButton>
                            </>
                        )}
                    </div>
                )}
            </NewPopper>
        </div>
    );
};

export default OptionsButton;
