import React, { FunctionComponent } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import classNames from 'classnames';
import MenuBar from './MenuBar';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import HardBreak from '@tiptap/extension-hard-break';
import Underline from '@tiptap/extension-underline';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';

interface Props {
    content: string;
    onChange(newContent: string | null): void;
    withLists?: boolean;
    testId?: string;
}

const TipTap: FunctionComponent<Props> = ({
    content,
    withLists = true,
    onChange,
    ...props
}) => {
    const editor = useEditor({
        extensions: [
            Document,
            Paragraph,
            Text,
            HardBreak,
            Bold,
            Italic,
            Underline,
            ...(withLists ? [ListItem, BulletList, OrderedList] : []),
        ],
        content,
        editorProps: {
            attributes: {
                class: 'flex-1 prose prose-sm max-w-full outline-none p-3',
            },
        },
        onUpdate: ({ editor }) => {
            onChange(editor.isEmpty ? null : editor.getHTML());
        },
    });

    return (
        <div
            className={classNames(
                'flex min-h-[240px] flex-col rounded border bg-white transition-colors focus-within:border-focus focus-within:shadow-focus',
                {
                    'border-gray-200': !editor?.isFocused,
                    'border-focus shadow-focus': editor?.isFocused,
                }
            )}
        >
            <MenuBar editor={editor} withLists={withLists} />
            <EditorContent
                editor={editor}
                className="flex flex-1 items-stretch"
                data-testid={props.testId}
            />
        </div>
    );
};

export default TipTap;
