import { useMemo } from 'react';
import { InfiniteData } from 'react-query/types/core/types';

function useInfiniteQueryResult(data?: InfiniteData<any>) {
    return useMemo(() => {
        const pages = (data?.pages ?? []).map((page) => page.data);
        return pages.reduce((acc, page) => acc.concat(page), []);
    }, [data]);
}

export default useInfiniteQueryResult;
