import React from 'react';
import RootStore from '../../Stores/RootStore';
import { TextButton } from '../Button';

const CloseButton = ({
    cancelText = 'Cancel',
    cancelType = 'top',
    ...props
}) => {
    const handleClose = (ev) => {
        if (cancelType === 'top') {
            RootStore.stores.uiStateStore.popModal();
        } else {
            RootStore.stores.uiStateStore.closeStack();
        }
    };
    return (
        <TextButton type="button" onClick={handleClose} {...props}>
            {cancelText}
        </TextButton>
    );
};

export default CloseButton;
