import React, { FunctionComponent } from 'react';
import { PrimaryButton } from '@/Components/Button';

const NoOrganisationScreen: FunctionComponent = () => {
    return (
        <div className="max-w-[600px] p-10">
            <h1 className="mb-4 text-xl font-bold">No current organisation</h1>
            <p className="mb-8">
                Sorry, you don't appear to have an organisation selected. Please
                select one from the dropdown in the sidebar, or create a new one
            </p>

            <PrimaryButton href="/organisation/new">
                Create an organisation
            </PrimaryButton>
        </div>
    );
};

export default NoOrganisationScreen;
