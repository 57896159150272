import { types } from 'mobx-state-tree';
import LocationBase from '@/Models/LocationBase';

const PhysicalLocationModel = types
    .model({
        uuid: types.identifier,
        type: 'location',
        resourceVersion: types.number,
        name: types.string,
        locationType: types.literal('physical'),
        isDeleted: types.boolean,
        isRequestable: types.boolean,
        preposition: types.string,
        usesUrl: false,
        usesPassword: false,
    })

    .views((self) => ({
        get deleted() {
            return self.isDeleted;
        },
    }))

    .actions((self) => ({
        updateDeleted(deleted) {
            self.isDeleted = deleted;
        },
    }));

export const PhysicalLocation = types.compose(
    'PhysicalLocation',
    PhysicalLocationModel,
    LocationBase
);
