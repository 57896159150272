import angular from 'angular';

angular
    .module('resource.referral', [])

    .service('Referral', [
        '$resource',
        'TransformerService',
        function ($resource, TransformerService) {
            return $resource(
                '/v1/referrals/:eid',
                { eid: '@eid' },
                {
                    query: {
                        isArray: true,
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                    get: {
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                }
            );
        },
    ]);
