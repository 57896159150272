import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import Icon from '@/Components/Icon/Icon';
import { uiColors } from '@/Theme';

interface Props {
    client: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    return (
        <NavLink
            to={`/clients/roster/${client.eid}/agreement`}
            className="flex min-h-[110px] flex-col items-center justify-center border-b border-gray-200 font-bold transition-colors hover:bg-blue-50"
        >
            <Icon
                name={
                    !client.hasAgreement || client.currentAgreement.isArchived
                        ? 'WarningTriangle'
                        : 'CheckCircle'
                }
                fill={
                    !client.hasAgreement || client.currentAgreement.isArchived
                        ? uiColors.warning.default
                        : uiColors.positive.default
                }
                width={18}
                height={18}
                className="mb-1"
            />
            {!client.hasAgreement && <p>Agreement missing</p>}
            {client.hasAgreement && client.currentAgreement.isArchived && (
                <p>Agreement out of date</p>
            )}
            {client.hasAgreement && !client.currentAgreement.isArchived && (
                <p>Agreement up to date</p>
            )}
        </NavLink>
    );
};

export const ClientAgreementStatus = observer(Component);
