const debug = false;

export class Cache {
    constructor() {
        this.data = {};
        this.exists = [];
    }

    add(name, callback) {
        if (!this.has(name)) {
            if (debug) {
                console.log('-addCache-', name);
            }

            this.exists.push(name);
            this.data[name] = callback();
        }

        return this.data[name];
    }

    remove(name) {
        if (debug) {
            console.log('-removeCache-', name);
        }

        this.exists = this.exists.filter((current) => current != name);
    }

    has(name) {
        return this.exists.includes(name);
    }

    clear() {
        if (debug) {
            for (let name of this.exists) {
                console.log('-removeCache-', name);
            }
        }

        this.data = {};
        this.exists = [];
    }
}
