import React, { FunctionComponent } from 'react';
import { Button } from '@/Components/Button';
import { uiColors } from '@/Theme';
import { Icon } from '@mdi/react';
import { mdiAccountEdit, mdiCalendarEdit } from '@mdi/js';
import { Tooltip } from '@/Components/Tooltip';
import { ScheduleEntryPopperActionType } from '@/Features/Schedule';

interface Props {
    shouldDisplayRepeat: boolean;
    shouldDisplayUserSelect: boolean;
    shouldDisplayRemove: boolean;
    onOptionClick(action: ScheduleEntryPopperActionType): void;
    onRemoveClick(): void;
}

export const ScheduleEntryPopperOptions: FunctionComponent<Props> = ({
    shouldDisplayRepeat,
    shouldDisplayUserSelect,
    shouldDisplayRemove,

    onOptionClick,
    onRemoveClick,
}) => {
    return (
        <div className="flex h-14 items-center gap-2 p-2">
            <Tooltip label="Move to date">
                <Button
                    icon={
                        <Icon
                            path={mdiCalendarEdit}
                            size={0.8}
                            className="fill-current text-icon"
                        />
                    }
                    onClick={() => onOptionClick('move-date')}
                />
            </Tooltip>
            {shouldDisplayUserSelect && (
                <Tooltip label="Change teacher">
                    <Button
                        icon={
                            <Icon
                                path={mdiAccountEdit}
                                size={0.8}
                                className="fill-current text-icon"
                            />
                        }
                        onClick={() => onOptionClick('change-teacher')}
                    />
                </Tooltip>
            )}
            {shouldDisplayRepeat && (
                <Tooltip label="Repeat for">
                    <Button
                        icon="Repeat"
                        onClick={() => onOptionClick('repeat')}
                    />
                </Tooltip>
            )}
            {shouldDisplayRemove && (
                <Tooltip label="Remove">
                    <Button
                        icon="Delete"
                        onClick={onRemoveClick}
                        fill={uiColors.negative.default}
                    />
                </Tooltip>
            )}
        </div>
    );
};
