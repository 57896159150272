import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface Props {
    prepend?: string | JSX.Element;
    append?: string | JSX.Element;
    appendClasses?: string;
    onClick?(): void;
    isDisabled?: boolean;
}

const InputGroup: FunctionComponent<Props> = ({
    prepend,
    append,
    appendClasses,
    onClick,
    isDisabled,
    children,
}) => {
    const renderPrepend = () => {
        if (!prepend) {
            return;
        }

        return (
            <div
                className={classNames('input-group__prepend', {
                    '--is-disabled': isDisabled,
                })}
                onClick={onClick}
            >
                {prepend}
            </div>
        );
    };

    const renderAppend = () => {
        if (!append) {
            return;
        }

        return (
            <div
                className={classNames('input-group__append', appendClasses)}
                onClick={onClick}
            >
                {append}
            </div>
        );
    };

    const classes = classNames('input-group', {
        '--prepend': !!prepend,
        '--append': !!append,
        '--is-disabled': isDisabled,
    });

    return (
        <div className={classes}>
            {renderPrepend()}
            {children}
            {renderAppend()}
        </div>
    );
};

export default InputGroup;
