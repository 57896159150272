import * as Yup from 'yup';

export const messages = {
    required: 'This field is required',
    cardCountryMismatch: "Your billing country must match the card's country",
    invalidVat: 'Please enter a valid VAT number',
    hexColor: 'Please enter a valid hex colour',
    domainRequired: 'Please enter a valid domain',
    alphaDash: 'Please enter a valid domain',
    url: 'Please enter a valid link, starting http:// or https://',
    positiveNumber: 'Please enter a valid number',
    validDate: 'Please select a valid date',
    validAmount: 'Please enter a valid amount',
};

export const portalDomainSchema = Yup.string()
    .required(messages.domainRequired)
    .min(4)
    .max(32)
    .matches(/^[a-zA-Z0-9\-_]+$/, messages.alphaDash);
