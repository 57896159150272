import classNames from 'classnames';
import React, { FunctionComponent, HTMLProps } from 'react';

export type RowType = FunctionComponent<HTMLProps<HTMLDivElement>>;

const Row: RowType = ({ className, ...props }) => (
    <div className={classNames('form-field-group', className)} {...props} />
);

export default Row;
