import Echo from 'laravel-echo';
import cookie from 'cookie';
import Config from '../Config';
import Pusher from 'pusher-js';

window.Pusher = Pusher;
// window.Pusher.logToConsole = true;

export default new (class SocketStore {
    connection = null;
    isConnected = false;
    channel = null;

    constructor() {
        this.handleConnected = this.handleConnected.bind(this);
        this.handleDisconnected = this.handleDisconnected.bind(this);

        this.connect();
    }

    async connect() {
        if (typeof window.Pusher === 'undefined') {
            this.disconnect();
            return;
        }

        const cookies = cookie.parse(document.cookie);
        let csrfToken;

        if (!!cookies['XSRF-TOKEN']) {
            csrfToken = cookies['XSRF-TOKEN'];
        }

        this.connection = new Echo({
            broadcaster: 'pusher',
            key: Config.pusherAppKey,
            cluster: 'eu',
            forceTLS: true,
            auth: {
                headers: {
                    'X-XSRF-TOKEN': csrfToken,
                },
            },
        });

        this.registerConnectionListeners();
    }

    disconnect() {
        if (this.isConnected && this.connection) {
            this.connection.connector.socket.off(
                'connect',
                this.handleConnected
            );
            this.connection.connector.socket.off(
                'disconnect',
                this.handleDisconnected
            );
        }

        this.handleDisconnected();
    }

    handleDisconnected() {
        this.connection = null;
        this.isConnected = false;
    }

    handleConnected() {
        this.isConnected = true;
    }

    registerConnectionListeners() {
        this.connection.connector.pusher.bind('connect', this.handleConnected);
        this.connection.connector.pusher.bind(
            'disconnect',
            this.handleDisconnected
        );
    }

    getChannel(organisationUuid) {
        if (!this.channel) {
            if (!organisationUuid) {
                return null;
            }

            this.channel = this.connection?.private(
                `organisation.${organisationUuid}`
            );
        }

        return this.channel;
    }
})();
