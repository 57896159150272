import * as Views from '../../Calendar/Views';

import angular from 'angular';

angular
    .module('component.schedule-week-holiday', [])

    .component('scheduleWeekHoliday', {
        bindings: {
            calendar: '=',
            holiday: '=',
            offsetTop: '=',
        },
        require: {
            view: '^scheduleWeek',
        },
        template: `
            <div class="schedule__week-holiday --days-[[ $ctrl.calendar.view.days.length ]] --left-[[ $ctrl.startDay ]] --width-[[ $ctrl.endDay ]]">
                <div class="schedule__week-holiday__start --width-[[ $ctrl.endDay ]]"
                     style="top: [[ $ctrl.startTop ]]px; bottom: [[ $ctrl.startBottom ]]px"
                     ng-show="$ctrl.startVisible">
                    <div class="schedule__week-holiday__label" style="top: [[ $ctrl.offsetTop ]]px">[[ $ctrl.holiday.label ]]</div>
                </div>
                <div class="schedule__week-holiday__main --width-[[ $ctrl.endDay ]] --left-[[ $ctrl.endDay ]]"
                     style="width: [[ $ctrl.mainWidth ]]%; left: [[ $ctrl.mainLeft ]]%"
                     ng-show="$ctrl.mainVisible">
                    <div class="schedule__week-holiday__label" style="top: [[ $ctrl.offsetTop ]]px" ng-if="!$ctrl.startVisible" ng-cloak>[[ $ctrl.holiday.label ]]</div>
                </div>
                <div class="schedule__week-holiday__end --width-[[ $ctrl.endDay ]]"
                     style="top: [[ $ctrl.endTop ]]px; bottom: [[ $ctrl.endBottom ]]px"
                     ng-show="$ctrl.endVisible">
                </div>
            </div>
        `,
        controller: [
            '$scope',
            function ($scope) {
                let $ctrl = this;

                function redraw() {
                    let mark = $ctrl.holiday;

                    if ($ctrl.calendar.view instanceof Views.Day) {
                        let isFirstDay = $ctrl.calendar.view == mark.firstDay;
                        let isLastDay = $ctrl.calendar.view == mark.lastDay;

                        $ctrl.startDay = 0;
                        $ctrl.endDay = 1;
                        // $ctrl.endPeriod = 0;
                        $ctrl.startTop = 0;
                        $ctrl.startBottom = mark.endPeriod * 25;
                        $ctrl.startVisible = true;

                        if (isFirstDay) {
                            $ctrl.startTop = mark.startPeriod * 25;
                        }
                        if (isLastDay) {
                            $ctrl.startBottom = mark.endPeriod * 25;
                            // $ctrl.endPeriod = mark.endPeriod;
                        }
                    } else if ($ctrl.calendar.view instanceof Views.Week) {
                        let isFirstWeek = $ctrl.calendar.view == mark.firstWeek;
                        let isLastWeek = $ctrl.calendar.view == mark.lastWeek;

                        $ctrl.startDay = 0;
                        $ctrl.endDay = 7;
                        $ctrl.endPeriod = 0;

                        $ctrl.startVisible = false;
                        $ctrl.mainVisible = false;
                        $ctrl.endVisible = false;

                        $ctrl.startTop = 0;
                        $ctrl.startBottom = 0;
                        $ctrl.mainWidth = 0;
                        $ctrl.mainLeft = 0;
                        $ctrl.endTop = 0;
                        $ctrl.endBottom = mark.endPeriod * 25;

                        if (isFirstWeek) {
                            $ctrl.startVisible = true;
                            $ctrl.startDay = mark.firstDay.index;
                            $ctrl.startTop = mark.startPeriod * 25;
                        }

                        if (isFirstWeek && isLastWeek) {
                            $ctrl.endDay = mark.days.length;

                            let span = mark.lastDay.index - mark.firstDay.index;

                            if (span === 0) {
                                $ctrl.startBottom = $ctrl.endBottom;
                            }
                            if (span > 0) {
                                $ctrl.endVisible = true;
                            }
                            if (span > 1) {
                                $ctrl.mainVisible = span > 1;
                                $ctrl.mainWidth =
                                    ((span - 1) / (span + 1)) * 100;
                                $ctrl.mainLeft = 100 / (span + 1);
                            }
                        } else if (isFirstWeek) {
                            $ctrl.endDay =
                                $ctrl.calendar.view.days.length -
                                mark.firstDay.index;

                            let span = $ctrl.endDay;
                            $ctrl.mainVisible = span > 0;
                            $ctrl.mainWidth = ((span - 1) / span) * 100;
                            $ctrl.mainLeft = 100 / span;
                        } else if (isLastWeek) {
                            $ctrl.endVisible = true;
                            $ctrl.endDay = mark.lastDay.index + 1;

                            let span = $ctrl.endDay;
                            $ctrl.mainVisible = span > 0;
                            $ctrl.mainWidth = ((span - 1) / span) * 100;
                        } else {
                            $ctrl.mainVisible = true;
                            $ctrl.mainWidth = 100;
                        }
                    }
                }

                $ctrl.$onInit = () => {
                    redraw();
                };

                $scope.$on('calendarViewChanged', () => {
                    redraw();
                });
            },
        ],
    });
