import React, { FunctionComponent } from 'react';
import { useInfiniteQuery } from 'react-query';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import InfiniteItemList from '@/Components/InfiniteItemList';
import { ClassPass } from '@/Models/ClassPass';
import ItemListStickyHeader from '@/Components/ItemList/StickyHeader';
import SmallCaps from '@/Components/SmallCaps';
import ZeroContent from '@/Components/ZeroContent';
import {
    ClientInviteListItem,
    SendClientSignUpInviteButton,
} from '@/Features/Clients';
import { queryKeys } from '@/Services/QueryKeys';
import { getNextPageParam } from '@/Services/Api';

interface Props {}

const Component: FunctionComponent<Props> = () => {
    const { portalInviteStore } = RootStore.stores;
    const queryResult = useInfiniteQuery(
        queryKeys.clientInvites.list,
        async ({ pageParam = 1 }) =>
            await portalInviteStore.list({ page: pageParam }, true),
        {
            getNextPageParam,
        }
    );

    return (
        <InfiniteItemList<ClassPass>
            queryResult={queryResult}
            renderHeader={
                <ItemListStickyHeader className="xl:grid-cols-invites-xl">
                    <SmallCaps>Email</SmallCaps>
                    <SmallCaps>Sent</SmallCaps>
                    <SmallCaps className="col-span-2">Expires</SmallCaps>
                </ItemListStickyHeader>
            }
            renderItem={(invite, classes) => (
                <ClientInviteListItem invite={invite} className={classes} />
            )}
            zeroContent={
                <ZeroContent
                    inline
                    icon="CustomClients"
                    title="No active invites"
                    content={
                        <>
                            <p>
                                See the status of invites you have sent to your
                                clients.
                            </p>
                            <SendClientSignUpInviteButton />
                        </>
                    }
                />
            }
        />
    );
};

export const ClientInviteList = observer(Component);
