import React, { FunctionComponent } from 'react';
import RootStore from '@/Stores/RootStore';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import { ClientPortalInviteButton } from '@/Features/Clients';

interface Props {
    client: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    const { portalLoginEnabled } = RootStore.stores.currentUserStore.user;

    function getTitle() {
        if (client.hasPortalPasswordInvite) {
            return 'Password not set yet.';
        }
        if (!client.portalRegistered) {
            return 'No client area or app access';
        }
        if (client.portalEnabled) {
            return 'Client area and app access enabled';
        }

        return 'Client area and app access disabled';
    }

    function getAction() {
        return client.hasPortalPasswordInvite || !client.portalRegistered ? (
            <ClientPortalInviteButton client={client} />
        ) : client.portalEnabled && portalLoginEnabled && !!client.lastSeen ? (
            `Last seen ${client.lastSeenAge}`
        ) : null;
    }

    return (
        <div className="col-span-2 flex flex-col items-center justify-center border-b border-gray-200 p-6">
            <p className="mb-2 font-bold">{getTitle()}</p>
            {getAction()}
        </div>
    );
};

export const ClientPortalStatus = observer(Component);
