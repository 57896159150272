import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
// @ts-ignore
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { uiColors } from '@/Theme';
import { simplePercentage } from '@/Services/Number';

interface Props {
    client: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    if (client.attendanceStatus?.total === 0) {
        return (
            <div className="flex items-center justify-center border-l border-gray-200 p-4">
                <p className="text-center font-bold text-gray-400">
                    No attendance data available for {client.firstName} yet
                </p>
            </div>
        );
    }

    const data = [
        {
            name: 'Attended',
            color: uiColors.positive.default,
            value: client.attendanceStatus.attended,
        },
        {
            name: 'Forfeited',
            color: uiColors.negative.default,
            value: client.attendanceStatus.forfeited,
        },
    ];

    return (
        <div className="col-start-2 border-l border-gray-200 p-4">
            <div className="relative mx-auto flex h-[150px] w-[150px] items-center justify-center">
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            data={data}
                            nameKey="name"
                            dataKey="value"
                            innerRadius="88%"
                            outerRadius="100%"
                            startAngle={90}
                            endAngle={-270}
                            fill="#8884d8"
                        >
                            {data.map((entry) => (
                                <Cell key={entry.name} fill={entry.color} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                <p className="absolute w-full text-center">
                    <span className="text-4xl font-bold">
                        {simplePercentage(
                            client.attendanceStatus.attended,
                            client.attendanceStatus?.total ?? 0
                        )}
                    </span>
                    <span className="">%</span>
                </p>
            </div>
            <p className="mt-2 text-center font-bold text-gray-400">
                {client.firstName} has attended{' '}
                {client.attendanceStatus.attended} of{' '}
                {client.attendanceStatus?.total} planned events.
            </p>
        </div>
    );
};

export const ClientAttendanceStatus = observer(Component);
