import BaseTransport from './BaseTransport';

class MessageTransport extends BaseTransport {
    async list(data = {}) {
        return await this.get('/v3/messages', data);
    }

    async store(data = {}) {
        return await this.post('/v3/messages', data);
    }

    async storeClassDateMessage(classDateId: string, data = {}) {
        return await this.post(`/v3/messages/class-date/${classDateId}`, data);
    }

    async storeSessionMessage(sessionEid: string, data = {}) {
        return await this.post(`/v3/messages/session/${sessionEid}`, data);
    }
}

export default new MessageTransport();
