import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import history from '@/Modules/History';
import { lazyInjector } from '../../../../assets/js/lazy-injector';
import RootStore from '@/Stores/RootStore';
import { ClientAvatar } from '@/Features/Clients';
import useAuth from '../../../Hooks/useAuth';
import classNames from 'classnames';

const ClientCard = ({
    link,
    nameDisplay = 'initialed',
    client,
    suffix,
    ...props
}) => {
    const { uiStateStore } = RootStore.stores;
    const handleClick = (ev) => {
        if (!link) {
            return;
        }

        let url = `/clients/roster/${client.eid}`;

        if (!!props.linkTab) {
            url += `/${props.linkTab}`;
        }

        lazyInjector.$injector.get('$timeout')(function () {
            uiStateStore.closeStack();
            history.push(url);
        });
    };

    const { hasRole } = useAuth();

    return (
        <div
            className={classNames('flex items-center', {
                'hover:cursor-pointer': link,
            })}
            onClick={handleClick}
            {...props}
        >
            <ClientAvatar
                withLink={link && hasRole('Owner')}
                client={client}
                imageClassName="w-9 h-9"
            />
            <div className="ml-2 min-w-0">
                <p
                    className={classNames(
                        'm-0 truncate text-sm font-bold transition-colors lg:text-base',
                        { 'text-link': link }
                    )}
                >
                    {nameDisplay === 'initialed'
                        ? client.initialed
                        : client.fullName}
                </p>
                {suffix && (
                    <div className="text-sm text-gray-400">{suffix}</div>
                )}
            </div>
        </div>
    );
};

ClientCard.propTypes = {
    size: PropTypes.oneOf(['small', 'medium']),
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    link: PropTypes.bool,
    linkTab: PropTypes.string,
};

ClientCard.defaultProps = {
    size: 'small',
    link: false,
};

export default observer(ClientCard);
