import React, { FunctionComponent, HTMLProps } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

interface Props {
    required?: boolean;
}

export type LabelType = FunctionComponent<Props & HTMLProps<HTMLLabelElement>>;

const Label: LabelType = ({ className, ...props }) => (
    <label
        className={classNames(
            'mb-[6px] block text-sm font-bold text-gray-600',
            className
        )}
        {...props}
    >
        {props.children}
        {props.required && (
            <span className="ml-1 inline-block text-negative">*</span>
        )}
    </label>
);

Label.propTypes = {
    required: PropTypes.bool,
};

export default Label;
