import CreateAction from './CreateAction.js';
import Consultation from './Consultation';
import RootStore from '../../../js/Stores/RootStore';

export default class CreateConsultation extends CreateAction {
    static get type() {
        return new Consultation();
    }

    constructor(calendar, parameters = {}) {
        super(calendar, parameters);

        this.existingConsultation = null;

        if (!!parameters.eid) {
            this.getCancelledConsultation(parameters.eid);
        }
    }

    get isReady() {
        return this.calendar.newCards.length == 1;
    }

    get isTargetVisible() {
        return this.calendar.newCards.length == 0;
    }

    get entry() {
        if (!this.calendar.newCards.length) return null;

        let entry = this.calendar.newCards[0].entry;

        if (this.existingConsultation) {
            entry.firstName = this.existingConsultation.firstName;
            entry.lastName = this.existingConsultation.lastName;
            entry.email = this.existingConsultation.email;
            entry.phone = this.existingConsultation.phone;
            entry.notes = this.existingConsultation.notes;
            entry.existing = this.existingConsultation.eid;
            entry.location = this.existingConsultation.delivery.location.uuid;
            entry.locationUrl = this.existingConsultation.delivery.url;
            entry.locationPassword =
                this.existingConsultation.delivery.password;
        }

        return entry;
    }

    get cards() {
        return this.calendar.newCards;
    }

    createCard() {
        let card = super.createCard();

        card.isRepeatable = false;

        return card;
    }

    async getCancelledConsultation(eid) {
        this.parameters.eid = eid;
        this.isLoading = true;

        try {
            let consultation =
                await RootStore.stores.entryStore.loadConsultation(eid);
            this.existingConsultation = consultation;
            this.isLoading = false;
        } catch (e) {
            delete this.parameters.eid;
            this.isLoading = false;
        }
    }
}
