import React, { FunctionComponent, HTMLProps } from 'react';
import classNames from 'classnames';

interface Props extends HTMLProps<HTMLDivElement> {
    isHidden?: boolean;
    maxHeight?: number;
}

export type ChildrenType = FunctionComponent<Props & HTMLProps<HTMLDivElement>>;

const Children: FunctionComponent<Props> = ({
    children,
    isHidden = false,
    maxHeight = 80,
}) => (
    <div className={classNames('relative', { 'overflow-hidden': isHidden })}>
        <div
            className={classNames('form-field__children', {
                '--hidden': isHidden,
            })}
            style={{ maxHeight: isHidden ? maxHeight : 'none' }}
        >
            {children}
        </div>
        {isHidden && <div className="form-field__children__shroud" />}
    </div>
);

export default Children;
