import React, { FunctionComponent, ReactNode } from 'react';
import { FieldInputProps, FieldProps } from 'formik';
import TextInputField from '../../../Components/TextInputField';
import FormFieldElements, {
    FormFieldElementProps,
} from '../../../Components/FormFieldElements';

interface Props extends FormFieldElementProps {
    isDisabled?: boolean;
    placeholder?: string;
    input?(fieldProps: FieldInputProps<any>): ReactNode;
    inputProps?: any;
    id?: string;
    fieldAccessory?: ReactNode;
    fieldContainerClassName?: string;
}

const FormTextField: FunctionComponent<Props> = ({
    input,
    inputProps = {},
    isDisabled,
    placeholder,
    id,
    fieldAccessory,
    fieldContainerClassName,
    ...props
}) => {
    return (
        <FormFieldElements {...props}>
            {({ field }: FieldProps) => (
                <div className={fieldContainerClassName}>
                    {!!input && input(field)}
                    {!input && (
                        <TextInputField
                            className="w-full"
                            isDisabled={isDisabled}
                            placeholder={placeholder}
                            id={id || field.name}
                            {...field}
                            {...inputProps}
                        />
                    )}
                    {fieldAccessory}
                </div>
            )}
        </FormFieldElements>
    );
};

export default FormTextField;
