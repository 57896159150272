import { types } from 'mobx-state-tree';
import LocationBase from '@/Models/LocationBase';

const VirtualLocationModel = types.model('VirtualLocation', {
    uuid: types.identifier,
    type: 'location',
    resourceVersion: types.number,
    name: types.string,
    locationType: types.literal('virtual'),
    group: types.number,
    preposition: types.string,
    usesUrl: types.boolean,
    usesPassword: types.boolean,
    urlTerm: types.maybeNull(types.string),
    icon: types.maybeNull(types.string),
    isRequestable: types.boolean,
});

export const VirtualLocation = types.compose(
    'VirtualLocation',
    VirtualLocationModel,
    LocationBase
);
