import { useState } from 'react';
import { when } from 'mobx';
import { isValidReference } from 'mobx-state-tree';
import Notification from '../Services/Notification';

// export type DirtyMap = { [key: string]: boolean };
//
// interface DirtyValues {
//     dirty: DirtyMap,
//     keys: string[]
// }

interface Config {
    timeout: number;
}

export default function useAsyncReference(timeout: number = 5000) {
    const [isResolving, setIsResolving] = useState(false);

    async function resolve(node: any, property: string) {
        setIsResolving(true);

        try {
            await when(
                () => {
                    return (
                        node[property] === null ||
                        typeof node[property] === 'string' ||
                        isValidReference(() => node[property])
                    );
                },
                { timeout }
            );
        } catch (e) {
            console.log(e);
            Notification.error();
            throw 'Resolution failed';
        } finally {
            setIsResolving(false);
        }
    }

    return {
        resolve,
        isResolving,
    };
}
