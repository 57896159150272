import RescheduleAction from './RescheduleAction.js';
import Session from './Session.js';

export default class RescheduleSession extends RescheduleAction {
    static get type() {
        return new Session();
    }

    get calendarTitle() {
        return 'Reschedule session';
    }
}
