import BaseTransport from './BaseTransport';

class UserTransport extends BaseTransport {
    /**
     * Get information about the signed in user
     * @returns {Promise<*>}
     */
    async userData() {
        return await this.get(`/v3/user`);
    }

    /**
     * Sign the user out
     * @returns {Promise<*>}
     */
    async signOut() {
        return await this.post(
            '/sign-out',
            {},
            {
                baseURL: '/', // This is not on the api domain
            }
        );
    }

    /**
     * Store the user's onboarding data
     * @returns {Promise<*>}
     */
    async storeOnboardingData(formValues) {
        return await this.post('/v3/user/onboard', formValues);
    }

    /**
     * Validate the format of a vat number
     * @param vatNumber
     * @returns {Promise<*>}
     */
    async verifyVatNumber(vatNumber) {
        return await this.once({
            method: 'post',
            url: '/v1/users/validate-vat-number',
            data: { vatNumber },
        });
    }

    async getCurrentOrganisation(uuid) {
        return await this.get('/v3/user/current-organisation');
    }

    async switchOrganisation(uuid) {
        return await this.post('/v3/user/current-organisation/switch', {
            organisation: uuid,
        });
    }

    async updateAccountProfile(data) {
        return await this.put('/v3/user/profile', data);
    }

    async updateAccountSecurity(data) {
        return await this.put('/v3/user/security', data);
    }

    async updateAccountPreferences(data) {
        return await this.put('/v3/user/preferences', data);
    }

    /**
     * Update the user's brand data
     * @param data
     * @returns {Promise<*>}
     */
    async updateBrand(data) {
        return await this.put('/v1/user/brand', data);
    }

    async fetchActiveCounts() {
        return await this.get('/v3/user/active-counts');
    }

    async fetchSuggestedDomain(suggestion) {
        return await this.get('/v3/user/suggested-domain', { suggestion });
    }

    async validatePortalDomain(domain) {
        return await this.post('/v3/user/validate-domain', {
            portalDomain: domain,
        });
    }

    async validateSmsNumber(sms) {
        return await this.post('/v3/user/validate-sms', { sms });
    }

    async dismissTip(identifier) {
        return await this.put('/v1/users/dismiss-tip', { identifier });
    }

    async listReferrals() {
        return this.get('/v3/user/referrals');
    }

    async resetCalendarUrl() {
        return this.post('/v3/user/reset-ical-code');
    }

    async submitFeedback(params = {}) {
        return this.post('/v3/user/feedback', params);
    }

    async listPartners() {
        return this.get('/v3/user/partners');
    }

    async storePartners(enabledPartners) {
        return this.post('/v3/user/partners', { enabledPartners });
    }

    async listOldestDebts(params = {}) {
        return this.get('/v3/finances/oldest-debt', params);
    }

    async listActivity(params = {}) {
        return this.get('/v3/user/activity', params);
    }

    async getNextRef() {
        return await this.get('/v3/user/next-reference');
    }

    async resendVerificationEmail() {
        return await this.post('/v1/auth/resend-verification');
    }

    async cancelNewEmail() {
        return await this.post('/v3/user/cancel-verification');
    }
}

export default new UserTransport();
