import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface Props {
    className?: string;
}

const ModalContent: FunctionComponent<Props> = ({ children, className }) => {
    return (
        <section className={classNames('dialog__content__inner', className)}>
            {children}
        </section>
    );
};

export default ModalContent;
