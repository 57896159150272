import Action from './Action.js';
import CreateSessionPack from './CreateSessionPack.js';
import CreateConsultation from './CreateConsultation.js';
import CreateDiary from './CreateDiary.js';
import RescheduleConsultation from './RescheduleConsultation.js';
import RescheduleDiary from './RescheduleDiary.js';
import RescheduleSession from './RescheduleSession.js';
import UpdateSessionPack from './UpdateSessionPack.js';
import CreateClass from './CreateClass';
import RescheduleClass from './RescheduleClass';
import UpdateClass from './UpdateClass';

const Actions = [
    CreateSessionPack,
    CreateConsultation,
    CreateDiary,
    CreateClass,
    UpdateSessionPack,
    UpdateClass,
    RescheduleConsultation,
    RescheduleClass,
    RescheduleDiary,
    RescheduleSession,
];

export default function createAction(calendar, parameters = {}) {
    for (let action of Actions) {
        // console.log(action.name, action.canCreateFromParameters(parameters));
        if (action.canCreateFromParameters(parameters)) {
            return new action(calendar, parameters);
        }
    }

    return new Action(calendar);
}
