import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import SentryFullStory from '@sentry/fullstory';
import Config from './index';

if (import.meta.env.VITE_FS_ID) {
    FullStory.init({ orgId: import.meta.env.VITE_FS_ID });
}

if (import.meta.env.VITE_SENTRY_DSN) {
    let sentryConfig = {
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: Config.environment,
        integrations: [new BrowserTracing()],
    };

    if (import.meta.env.VITE_FS_ID) {
        sentryConfig.integrations.push(
            new SentryFullStory(import.meta.env.VITE_SENTRY_ORG)
        );
    }
    if (window.App.sentryRelease) {
        sentryConfig.release = window.App.sentryRelease;
    }

    Sentry.init(sentryConfig);
}
