import React from 'react';

const CustomExpenses = (props) => (
    <svg viewBox="0 0 14 14" {...props}>
        <path
            fill={props.fill}
            d="M12.6 0A1.4 1.4 0 0114 1.4V14l-2.333-2-2.334 2L7 12l-2.333 2-2.334-2L0 14V1.4A1.4 1.4 0 011.4 0h11.2zM8 7H2v2h6V7zm4 0h-2v2h2V7zM8 3H2v2h6V3zm4 0h-2v2h2V3z"
            fillRule="evenodd"
        />
    </svg>
);

export default CustomExpenses;
