import React from 'react';

const CustomLocations = (props) => (
    <svg viewBox="0 0 16 16" {...props}>
        <path
            fill={props.fill}
            d="M2.5 5.75c0 3.9 5.172 9.389 5.172 9.389.181.2.475.2.664-.01 0 0 5.164-5.479 5.164-9.379C13.5 2.825 11.064.5 8 .5S2.5 2.825 2.5 5.75zM8 7.625c-1.1 0-1.964-.825-1.964-1.875S6.9 3.875 8 3.875 9.964 4.7 9.964 5.75 9.1 7.625 8 7.625z"
            fillRule="evenodd"
        />
    </svg>
);

export default CustomLocations;
