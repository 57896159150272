import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, ClearButton } from '../../Button';
import { uiColors } from '../../../Theme';
import { ClassDate } from '../../../Models/ClassDate';
import RootStore from '../../../Stores/RootStore';
import Notification from '../../../Services/Notification';
import ClassDateTransport from '@/Services/Transport/ClassDateTransport';

interface Props {
    classDate: ClassDate;
    onSuccess?(): void;
    variant?: 'default' | 'icon';
}

const ClassDateCancelButton: FunctionComponent<Props> = ({
    classDate,
    variant = 'default',
    onSuccess,
}) => {
    const { uiStateStore, entryStore } = RootStore.stores;
    const [isBusy, setIsBusy] = useState(false);

    async function handleClick() {
        setIsBusy(true);

        try {
            await entryStore.fetchClassDate(classDate.uuid);
            const { data: notificationCounts } =
                await ClassDateTransport.getClientCommunicationMethodCounts(
                    classDate.uuid
                );

            uiStateStore.pushModal('CLASS_DATE_CANCEL', {
                classDate,
                notificationCounts,
                onSuccess,
            });
        } catch (e) {
            console.log(e);
            Notification.error();
        }

        setIsBusy(false);
    }

    const buttonProps = {
        icon: 'CloseCircle',
        onClick: handleClick,
        fill: uiColors.negative.default,
        isLoading: isBusy,
    };

    if (variant === 'icon') {
        return <Button {...buttonProps} />;
    }

    return <ClearButton {...buttonProps}>Cancel</ClearButton>;
};

export default observer(ClassDateCancelButton);
