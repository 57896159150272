import uiColors from '../../Theme/uiColors';

const commonStyles = {
    positive: {
        name: 'CheckCircle',
        width: 20,
        height: 20,
        fill: uiColors.positive.default,
    },
    warning: {
        name: 'WarningTriangle',
        width: 18,
        height: 18,
        fill: uiColors.warning.default,
    },
    negative: {
        name: 'WarningTriangle',
        width: 18,
        height: 18,
        fill: uiColors.negative.default,
    },
    info: {
        name: 'Information',
        width: 18,
        height: 18,
        fill: uiColors.info.default,
    },
};

export const getCommonStyle = (type) => {
    return typeof commonStyles[type] !== 'undefined' ? commonStyles[type] : {};
};
