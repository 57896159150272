import angular from 'angular';
import Action from './Action.js';
import * as Items from '../Calendar/Items';
import RootStore from '../../../js/Stores/RootStore';
import { lazyInjector } from '../lazy-injector';
import { DateTime } from 'luxon';

export default class CreateAction extends Action {
    static get action() {
        return 'add';
    }

    static canCreateFromParameters(parameters) {
        return (
            angular.isDefined(parameters.action) &&
            angular.isDefined(parameters.type) &&
            parameters.action === this.action &&
            parameters.type === this.type.nameSingular
        );
    }

    constructor(calendar, parameters = {}) {
        super(calendar);

        this.newEntries = 0;
        this.parameters = {
            action: this.constructor.action,
            type: this.constructor.type.nameSingular,
        };
    }

    get displaysSummaryPanel() {
        return true;
    }

    get cards() {
        return this.calendar.focusedCards;
    }

    get newCards() {
        return this.cards.filter((card) => card.isNew);
    }

    get canPlaceCards() {
        return true;
    }

    get canSelectCards() {
        return true;
    }

    get canPopperCards() {
        return false;
    }

    get isAvailable() {
        return true;
    }

    get isCreating() {
        return true;
    }

    get isTargetVisible() {
        return !this.isUpdatingCard;
    }

    get hasUnsavedChanges() {
        return !!this.cards.length;
    }

    get periods() {
        let entries = this.calendar.newCards;

        return entries.reduce((sum, card) => {
            return sum + card.length;
        }, 0);
    }

    createCard() {
        const length =
            RootStore.stores.currentUserStore.organisation.defaultEventLength;
        const type = this.constructor.type.nameSingular;

        const card = new Items.Card(this.calendar, {
            type,
            scheduleId: `new-${type}-${this.calendar.getNextNewId()}`,
            label: 'New',
            start_at: DateTime.local(),
            end_at: DateTime.local().plus({ minutes: length }),
            new: true,
            assignedUser:
                this.calendar.currentCalendar === 'unassigned'
                    ? null
                    : this.calendar.currentCalendar,
        });

        card.isFocused = true;
        card.isMovable = true;
        card.isResizable = true;
        card.isRemovable = true;
        card.isRepeatable = true;
        card.isSelectable = true;

        return card;
    }

    cancel() {
        return new Promise((resolve) => {
            this.calendar.removeItems((item) => !item.isNew);
            resolve();
        });
    }

    finalise(result) {
        return new Promise((resolve) => {
            this.calendar.removeItems((item) => !item.isNew);

            if (result instanceof ResultCreated) {
                for (let entry of result.entries) {
                    this.calendar.populateCard(entry);
                }
            }

            resolve();
        });
    }
}

export class ResultCreated {
    constructor(response) {
        this.response = response;
    }

    get entries() {
        let entries = [];
        let types = {
            consultation: lazyInjector.$injector.get('Consultation'),
            'diary-entry': lazyInjector.$injector.get('DiaryEntry'),
            session: lazyInjector.$injector.get('Session'),
            holiday: lazyInjector.$injector.get('Holiday'),
        };

        for (let entry of this.response.data.entries.data) {
            entries.push(new types[entry.type](entry));
        }

        return entries;
    }
}
