import BaseTransport from './BaseTransport';

class MembershipSubscriptionTransport extends BaseTransport {
    async list(params = {}) {
        return await this.get('/v3/membership-subscriptions', params);
    }

    async fetch(uuid, params = {}) {
        return await this.get(`/v3/membership-subscriptions/${uuid}`, params);
    }

    async cancel(uuid) {
        return await this.post(`/v3/membership-subscriptions/${uuid}/cancel`);
    }

    async getCancellationData(uuid) {
        return await this.get(`/v3/membership-subscriptions/${uuid}/cancel`);
    }
}

export default new MembershipSubscriptionTransport();
