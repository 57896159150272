import React from 'react';

const UIAccountBox = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            fill={props.fill}
            d="M1.8 0h8.4A1.8 1.8 0 0112 1.8v8.4a1.8 1.8 0 01-1.8 1.8H1.8A1.8 1.8 0 010 10.2V1.8A1.8 1.8 0 011.8 0zM8 4c0 1.133-.867 2-2 2s-2-.867-2-2 .867-2 2-2 2 .867 2 2zM2 9.333C2 8 4.667 7.267 6 7.267c1.333 0 4 .733 4 2.066V10H2v-.667z"
            fillRule="evenodd"
        />
    </svg>
);

export default UIAccountBox;
