import { types } from 'mobx-state-tree';
import { currencyDisplay } from '../Services/Currency';
import { Currency } from './Currency';

const ClassEmployedBase = types
    .model({
        currency: types.maybe(Currency),
    })
    .views((self) => ({
        /**
         * Get the simplest representation of the fee, without symbol
         * @returns {string}
         */
        get simplifiedFee() {
            return currencyDisplay(self.fee, self.currency, false);
        },

        /**
         * Get the simplest representation of the fee, with symbol
         * @returns {string}
         */
        get simplifiedFeeDisplay() {
            return currencyDisplay(self.fee, self.currency, false);
        },
    }));

export default ClassEmployedBase;
