import angular from 'angular';

angular
    .module('component.wizard.form-pane', [])

    .component('wizardFormPane', {
        template: `
            <div class="dialog__overlay" ng-if="$ctrl.selected && $ctrl.ready">
                <div class="dialog__wrapper" ng-class="{'--wide': $ctrl.isWide, '--confirmation': $ctrl.confirmation}">
                    <form name="$ctrl.form" class="wizard-form-pane" ng-class="{'--wide': $ctrl.isWide, '--new-ui': $ctrl.newUi, '--confirmation': $ctrl.confirmation}" ng-submit="$ctrl.submit()" ng-transclude novalidate></form>
                </div>
            </div>
        `,
        transclude: true,
        bindings: {
            canNavigateTo: '<',
            canNavigateBackwardsToCustom: '<canNavigateBackwardsTo',
            canNavigateForwardsToCustom: '<canNavigateForwardsTo',
            onBackwards: '=',
            onCancel: '=',
            onSubmit: '=',
            onForwards: '=',
            onNavigateTo: '=',
            form: '=?',
        },
        require: {
            wizard: '^wizard',
        },
        controller: [
            '$q',
            '$scope',
            '$attrs',
            function ($q, $scope, $attrs) {
                var $ctrl = this;

                function canNavigateToPane() {
                    if (angular.isDefined($ctrl.canNavigateTo)) {
                        return angular.isFunction($ctrl.canNavigateTo)
                            ? $ctrl.canNavigateTo()
                            : $ctrl.canNavigateTo;
                    }

                    return true;
                }

                $ctrl.canNavigateBackwardsTo = function () {
                    if (angular.isDefined($ctrl.canNavigateBackwardsToCustom)) {
                        return angular.isFunction(
                            $ctrl.canNavigateBackwardsToCustom
                        )
                            ? $ctrl.canNavigateBackwardsToCustom()
                            : $ctrl.canNavigateBackwardsToCustom;
                    }

                    return canNavigateToPane();
                };

                $ctrl.canNavigateForwardsTo = function () {
                    if (angular.isDefined($ctrl.canNavigateForwardsToCustom)) {
                        return angular.isFunction(
                            $ctrl.canNavigateForwardsToCustom
                        )
                            ? $ctrl.canNavigateForwardsToCustom()
                            : $ctrl.canNavigateForwardsToCustom;
                    }

                    return canNavigateToPane();
                };

                $ctrl.$onInit = function () {
                    $ctrl.wizard.addPane($ctrl);
                };

                $ctrl.submit = function () {
                    let valid = $ctrl.form.$valid;

                    if (!!$ctrl.onSubmit) {
                        $ctrl.wizard.navigatingForwards = true;

                        $q.when($ctrl.onSubmit())
                            .then(() => {
                                $ctrl.submitAction(valid);
                            })
                            .finally(() => {
                                $ctrl.wizard.navigatingForwards = false;
                            });
                    } else {
                        $ctrl.submitAction(valid);
                    }
                };

                $ctrl.submitAction = function (forwards) {
                    $ctrl.wizard.navigatingForwards = false;

                    if (forwards) {
                        $ctrl.wizard.forwards();
                    }
                };

                $ctrl.isWide = angular.isDefined($attrs.wide);
                $ctrl.newUi = angular.isDefined($attrs.newUi);
                $ctrl.confirmation = angular.isDefined($attrs.confirmation);
            },
        ],
    })

    .component('wizardFormFooter', {
        template: `
            <div class="wizard-footer action-bar">
                <div class="action-bar__group">
                    <wizard-backwards-button>[[ $ctrl.backwardsText || "Back" ]]</wizard-backwards-button>
                    <wizard-cancel-button ng-if="!$ctrl.hasTranscludedCancelButton">[[ $ctrl.cancelText || "Cancel" ]]</wizard-cancel-button>
                    <ng-transclude ng-transclude-slot="cancelButton"></ng-transclude>
                </div>
                <div class="action-bar__group --align-right">
                    <button type="submit" class="button --positive" ng-class="{'--continue': $ctrl.shouldDisplayContinueIndicator()}" load-button="$ctrl.wizard.navigatingForwards" ng-disabled="!$ctrl.forwardsIsEnabled()">
                        <span class="text">[[ $ctrl.forwardsText || "Continue" ]]</span>
                    </button>
                </div>
                <div class="action-bar__group --align-right" ng-transclude="actionBarGroupRight"></div>
            </div>
        `,
        bindings: {
            backwardsText: '@',
            cancelText: '@',
            forwardsText: '@',
            forwardsIsEnabledCustom: '<forwardsIsEnabled',
            displayContinueIndicator: '<',
        },
        require: {
            wizard: '^wizard',
        },
        transclude: {
            cancelButton: '?cancelButton',
            actionBarGroupRight: '?actionBarGroupRight',
        },
        controller: [
            '$transclude',
            function ($transclude) {
                let $ctrl = this;

                $ctrl.hasTranscludedCancelButton =
                    $transclude.isSlotFilled('cancelButton');

                /**
                 * Determine if the forwards button should be enabled
                 * @returns {*}
                 */
                $ctrl.forwardsIsEnabled = () => {
                    if (angular.isFunction($ctrl.forwardsIsEnabledCustom)) {
                        return $ctrl.forwardsIsEnabledCustom();
                    } else if (
                        angular.isDefined($ctrl.forwardsIsEnabledCustom)
                    ) {
                        return $ctrl.forwardsIsEnabledCustom;
                    }

                    return true;
                };

                $ctrl.shouldDisplayContinueIndicator = () => {
                    if (angular.isDefined($ctrl.displayContinueIndicator)) {
                        return $ctrl.displayContinueIndicator;
                    }

                    return !$ctrl.wizard.isLastPane();
                };
            },
        ],
    })

    .component('wizardFormNewUiHeader', {
        template: `
            <header class="dialog__header">
                <div class="dialog__header__left-action">
                    <wizard-backwards-button>[[ $ctrl.backwardsText || "Back" ]]</wizard-backwards-button>
                    <wizard-cancel-button ng-if="!$ctrl.hasTranscludedCancelButton && $ctrl.shouldDisplayCancelButton">[[ $ctrl.cancelText || "Cancel" ]]</wizard-cancel-button>
                    <ng-transclude ng-transclude-slot="cancelButton"></ng-transclude>
                </div>
            
                <div class="dialog__header__title">
                    <h2>[[ $ctrl.title ]]</h2>
                </div>
            
                <div class="dialog__header__right-action">
                    <button type="submit" class="button --positive" ng-class="{'--continue': $ctrl.shouldDisplayContinueIndicator()}" load-button="$ctrl.wizard.navigatingForwards" ng-disabled="!$ctrl.forwardsIsEnabled()">
                        <span class="text">[[ $ctrl.forwardsText || "Continue" ]]</span>
                    </button>
                </div>
                <div class="dialog__header__right-action" ng-transclude="actionBarGroupRight"></div>
            </header>
        `,
        bindings: {
            title: '@',
            backwardsText: '@',
            cancelText: '@',
            forwardsText: '@',
            forwardsIsEnabledCustom: '<forwardsIsEnabled',
            displayContinueIndicator: '<',
            displayCancelButton: '<',
        },
        require: {
            wizard: '^wizard',
        },
        transclude: {
            cancelButton: '?cancelButton',
            actionBarGroupRight: '?actionBarGroupRight',
        },
        controller: [
            '$transclude',
            function ($transclude) {
                let $ctrl = this;

                $ctrl.hasTranscludedCancelButton =
                    $transclude.isSlotFilled('cancelButton');
                $ctrl.shouldDisplayCancelButton =
                    $ctrl.hasTranscludedCancelButton ||
                    $ctrl.displayCancelButton !== false;

                /**
                 * Determine if the forwards button should be enabled
                 * @returns {*}
                 */
                $ctrl.forwardsIsEnabled = () => {
                    if (angular.isFunction($ctrl.forwardsIsEnabledCustom)) {
                        return $ctrl.forwardsIsEnabledCustom();
                    } else if (
                        angular.isDefined($ctrl.forwardsIsEnabledCustom)
                    ) {
                        return $ctrl.forwardsIsEnabledCustom;
                    }

                    return true;
                };

                $ctrl.shouldDisplayContinueIndicator = () => {
                    if (angular.isDefined($ctrl.displayContinueIndicator)) {
                        return $ctrl.displayContinueIndicator;
                    }

                    return !$ctrl.wizard.isLastPane();
                };
            },
        ],
    });
