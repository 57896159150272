import React, { FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';
import { ClearButton, PrimaryButton } from '../../Button';
import { getFormattedUrl } from '@/Services/Schedule';
import { uiColors } from '@/Theme';
import classNames from 'classnames';

interface Props {
    variant: 'primary' | 'menu';
    type: 'session' | 'class-date';
    parentIdentifier: string;
    className?: string;
}

interface ScheduleUrlParams {
    action: 'place';
    type: 'session' | 'class-date';
    'session-pack'?: string;
    class?: string;
}

const ScheduleAddEntryDatesButton: FunctionComponent<Props> = ({
    variant,
    type,
    parentIdentifier,
    className,
}) => {
    const buttonText = type === 'session' ? 'Session(s)' : 'Date(s)';
    const urlParams: ScheduleUrlParams = {
        action: 'place',
        type,
    };

    const buttonProps = useMemo(() => {
        if (type === 'session') {
            urlParams['session-pack'] = parentIdentifier;
        } else {
            urlParams['class'] = parentIdentifier;
        }

        return {
            href: getFormattedUrl(null, 'responsive', {
                ...urlParams,
            }),
            children: buttonText,
            icon: 'Plus',
            className,
        };
    }, []);

    if (variant === 'menu') {
        return (
            <ClearButton
                {...buttonProps}
                icon="PlusBox"
                fill={uiColors.core.primary}
                iconWidth={20}
                iconHeight={20}
                className={classNames(
                    'w-full justify-start',
                    buttonProps.className
                )}
                iconClassName="mr-[5px] ml-[-3px]"
            />
        );
    }

    return <PrimaryButton {...buttonProps} />;
};

export default observer(ScheduleAddEntryDatesButton);
