import BaseTransport from './BaseTransport';

class ClassPassTransport extends BaseTransport {
    async list(params = {}) {
        return await this.get('/v3/class-passes', params);
    }

    async fetch(uuid, params = {}) {
        return await this.get(`/v3/class-passes/${uuid}`, params);
    }

    async store(data) {
        return await this.post('/v3/class-passes', data);
    }

    async update(uuid, data) {
        return await this.put(`/v3/class-passes/${uuid}`, data);
    }

    async destroy(uuid) {
        return await this.delete(`/v3/class-passes/${uuid}`);
    }

    async expire(uuid) {
        return await this.post(`/v3/class-passes/${uuid}/expire`);
    }
}

export default new ClassPassTransport();
