import { DateTime } from 'luxon';
import { types } from 'mobx-state-tree';
import { inUserZone } from '../Services/Date';

export const DateTimeType = types.custom({
    name: 'DateTime',
    fromSnapshot(value) {
        return inUserZone(value);
    },
    toSnapshot(value) {
        return value?.toISO({
            suppressMilliseconds: true,
        });
    },
    isTargetType(value) {
        return value === null || value instanceof DateTime;
    },
    getValidationMessage(value) {
        return inUserZone(value).isValid
            ? ''
            : `${value} doesn't look like a date`;
    },
});
