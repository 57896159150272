import React, { FunctionComponent, useContext } from 'react';
import { Button } from '@/Components/Button';
import RootStore from '@/Stores/RootStore';
import { observer } from 'mobx-react';
import CreateSessionPack from '../../../../assets/js/actions/CreateSessionPack';
import CreateClass from '../../../../assets/js/actions/CreateClass';
import CreateDiary from '../../../../assets/js/actions/CreateDiary';
import EventConfirmationModalContext from '@/Contexts/EventConfirmationModalContext';
import withConfirmationModalContext from '@/HOCs/WithConfirmationModalContext';
import { countInflect } from '@/Services/String';
import classNames from 'classnames';

interface Props {
    view: any;
    calendar: any;
    className: string;
}

const Component: FunctionComponent<Props> = ({ view, calendar, className }) => {
    const { uiStateStore } = RootStore.stores;
    const confirmationContext = useContext(EventConfirmationModalContext);

    function handleClick() {
        if (view.action.hasUnsavedChanges) {
            confirmationContext.requireConfirmation({
                content: () => (
                    <p className="mb-2">
                        Are you sure you want to discard{' '}
                        {countInflect(
                            view.action.cards.length,
                            `this new ${view.action.type.titleSingular.toLowerCase()}`,
                            `these new ${view.action.type.titlePlural.toLowerCase()}`,
                            false
                        )}
                        ?
                    </p>
                ),
                onConfirmed: confirm,
            });
        } else {
            confirm();
        }
    }

    async function confirm() {
        view.action.cancel().then(() => {
            let callback;

            if (view.action instanceof CreateSessionPack) {
                callback = () => {
                    uiStateStore.pushModal('SESSION_PACK_CREATE', {
                        view,
                        calendar,
                    });
                };
            } else if (view.action instanceof CreateClass) {
                callback = () => {
                    uiStateStore.pushModal('CLASSES_CREATE', {
                        view,
                        calendar,
                    });
                };
            } else if (view.action instanceof CreateDiary) {
                callback = () => {
                    uiStateStore.pushModal('DIARY_ENTRY_CREATE', {
                        view,
                        calendar,
                    });
                };
            }

            view.resetAction(callback);

            uiStateStore.closeStack();
        });
    }

    return (
        <Button
            className={classNames('!text-negative', className)}
            onClick={handleClick}
        >
            Cancel
        </Button>
    );
};

export const ScheduleCancelActionButton = withConfirmationModalContext(
    observer(Component)
);
