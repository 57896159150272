import React, { FunctionComponent } from 'react';
import { Field } from 'formik';

interface Props {
    name: string;
    value?: string;
    id?: string;
    label: JSX.Element | string;
    isDisabled?: boolean;
    className?: string;
    labelProps?: React.LabelHTMLAttributes<HTMLElement>;
}

const FormCheckboxField: FunctionComponent<Props> = ({
    name,
    value,
    id,
    label,
    isDisabled = false,
    className,
    labelProps,
    ...props
}) => {
    id = id ?? `${name}-${value}`;

    return (
        <div className={className} {...props}>
            <Field
                type="checkbox"
                name={name}
                value={value}
                className="checkbox"
                id={id}
                disabled={isDisabled}
            />
            <label htmlFor={id} {...labelProps}>
                {label}
            </label>
        </div>
    );
};

export default FormCheckboxField;
