type BreakpointName = keyof typeof breakpoints;
interface BreakpointMap {
    [key: string]: number;
}

const breakpoints: BreakpointMap = {
    mobileSmall: 0,
    mobileMedium: 400,
    mobileLarge: 480,
    tabletSmall: 540,
    tabletMedium: 600,
    tabletLarge: 720,
    desktopSmall: 1024,
};

export const getBreakpoint = (breakpoint: BreakpointName) => {
    if (typeof breakpoints[breakpoint] === 'undefined') {
        throw `Breakpoint '${breakpoint}' doesn't exist!`;
    }
    return breakpoints[breakpoint];
};

export const minWidth = (breakpoint: BreakpointName) => {
    return `min-width: ${getBreakpoint(breakpoint)}px`;
};

export const maxWidth = (breakpoint: BreakpointName) => {
    const pixels = getBreakpoint(breakpoint);
    return `max-width: ${pixels! - 1}px`;
};

export default breakpoints;
