import BaseTransport from './BaseTransport';

class ClassTransport extends BaseTransport {
    async list(params = {}) {
        return await this.get('/v3/classes', params);
    }

    async fetch(uuid: string, params = {}) {
        return await this.get(`/v3/classes/${uuid}`, params);
    }

    async store(data = {}) {
        return await this.post('/v3/classes', data);
    }

    async addDates(
        uuid: string,
        dates: any[],
        sendTeamNotifications = false,
        makeUnassignedAvailable = false
    ) {
        return await this.post(`/v3/classes/${uuid}/dates`, {
            dates,
            sendTeamNotifications,
            makeUnassignedAvailable,
        });
    }

    async update(uuid: string, data = {}) {
        return await this.put(`/v3/classes/${uuid}`, data);
    }

    async archive(uuid: string) {
        return await this.post(`/v3/classes/${uuid}/archive`);
    }

    async restore(uuid: string) {
        return await this.post(`/v3/classes/${uuid}/restore`);
    }

    async cancelFutureClassDates(uuid: string) {
        return await this.post(`/v3/classes/${uuid}/cancel-future-dates`);
    }

    async cancelSelectedClassDates(uuid: string, classDates: string[]) {
        return await this.post(`/v3/classes/${uuid}/cancel-selected-dates`, {
            classDates,
        });
    }

    async updateDate(classUuid: string, dateUuid: string, data = {}) {
        return await this.put(
            `/v3/classes/${classUuid}/dates/${dateUuid}`,
            data
        );
    }

    async sendEmployerSchedule(uuid: string) {
        return await this.post(`/v3/classes/${uuid}/send-employer-schedule`);
    }

    async countAfter(uuid: string, date: string) {
        return await this.get(`/v3/classes/${uuid}/planned-after-count`, {
            date,
        });
    }
}

export default new ClassTransport();
