import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import ItemListTitleCell from '@/Components/ItemList/TitleCell';
import { ClientAvatar } from '@/Features/Clients';

interface Props {
    client: Client | Client[];
    subtitle?: string | JSX.Element;
    avatarClassName?: string;
}

const Component: FunctionComponent<Props> = ({
    client,
    subtitle,
    avatarClassName,
}) => {
    const singleClient = Array.isArray(client) ? client[0] : client;

    return (
        <>
            <ClientAvatar
                client={singleClient}
                withLink={singleClient?.url}
                className={avatarClassName}
            />
            <ItemListTitleCell
                url={singleClient?.url}
                title={singleClient?.initialed ?? 'Unknown client'}
                subtitle={subtitle}
            />
        </>
    );
};

export const ClientAvatarCell = observer(Component);
