export default class Session {
    get nameSingular() {
        return 'session';
    }

    get namePlural() {
        return 'sessions';
    }

    get titleSingular() {
        return 'Session';
    }

    get titlePlural() {
        return 'Sessions';
    }
}
