import React, { FunctionComponent, ReactNode } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

export type AvatarSize = 'profile' | 'small' | 'original';

interface Props {
    src?: string;
    alt: string;
    url?: string;
    className?: string;
    missingIcon?: ReactNode;
}

const Component: FunctionComponent<Props> = ({
    src,
    alt,
    url,
    missingIcon,
    className,
}) => {
    const WrapperComponent = url ? NavLink : 'div';

    return (
        <WrapperComponent
            // @ts-ignore
            to={url}
            className={classNames(
                'relative flex overflow-hidden rounded-full bg-gray-100',
                className
            )}
        >
            {src && (
                <img
                    src={src}
                    alt={alt}
                    className="h-full w-full object-cover"
                />
            )}
            {!src && missingIcon}
        </WrapperComponent>
    );
};

export default observer(Component);
