import { flow, getRoot, types } from 'mobx-state-tree';
import RootStore from '../Stores/RootStore';
import { DateTimeType } from '@/Types/DateTime';
import { DecimalType } from '@/Types/Decimal';
import { ExpenseCategoryReference } from '@/Models/ExpenseCategory';
import { formatDate } from '@/Services/Date';
import ExpenseTransport from '@/Services/Transport/ExpenseTransport';
import { Currency } from './Currency';

export const Expense = types
    .model('Expense', {
        uuid: types.identifier,
        date: DateTimeType,
        supplier: types.string,
        description: types.maybeNull(types.string),
        amount: DecimalType,
        method: types.string,
        isDeleted: types.boolean,
        category: ExpenseCategoryReference,
        currency: Currency,
    })

    .views((self) => ({
        get dateFormatted() {
            return formatDate(self.date);
        },
    }))

    .actions((self) => ({
        update: flow(function* update(input = {}) {
            const { data } = yield ExpenseTransport.update(self.uuid, input);
            getRoot(self).stores.expenseStore.createOrUpdate(data.data);
        }),
    }));

export const ExpenseReference = types.reference(Expense, {
    get(identifier) {
        return RootStore.stores.expenseStore.find(identifier) || null;
    },
    set(expense) {
        return expense.uuid;
    },
});
