import RootStore from '@/Stores/RootStore';

function useAuth() {
    const { user } = RootStore.stores.currentUserStore;

    return {
        hasRole: (role: string) => user?.auth.roles.includes(role),
        hasPermissionTo: (permission: string) =>
            user?.auth.permissions.includes(permission),
        user,
    };
}

export default useAuth;
