import { types } from 'mobx-state-tree';
import { DateTimeType } from '@/Types/DateTime';
import { formatDate } from '@/Services/Date';
import { ClientReference } from '@/Models/Client';
import striptags from 'striptags';

export const Message = types
    .model('Message', {
        eid: types.string,
        type: types.literal('message'),
        identifier: types.identifier,
        subject: types.string,
        content: types.maybeNull(types.string),
        createdAt: DateTimeType,
        client: types.maybe(types.late(() => ClientReference)),
    })

    .views((self) => ({
        get createdAtFormatted() {
            return formatDate(self.createdAt, true, true);
        },

        get hasContent() {
            return !!self.content;
        },

        get strippedContent() {
            return striptags(self.content);
        },
    }))

    .actions((self) => ({}));
