import { Day, View, Week } from './Views';
import { Card, Mark } from './Items';
import { DateTime } from 'luxon';
import ScheduleTransport from '../../../js/Services/Transport/ScheduleTransport';
import RootStore from '../../../js/Stores/RootStore';
import { toISO } from '../../../js/Services/Date';

export default class Calendar {
    constructor(weeks = {}) {
        this.days = {};
        this.weeks = {};
        this.items = [];
        this.itemsExcluded = {};
        this.itemsLoaded = [];
        this._view = new View(this, DateTime.local());
        this.endDate = null;
        this.startDate = null;
        this.currentCalendar =
            RootStore.stores.currentUserStore.organisation?.organisationUser?.uuid;
    }

    get cards() {
        return this.items.filter((data) => data instanceof Card);
    }

    get date() {
        return this.view.date;
    }

    get focusedCards() {
        return this.cards.filter((card) => card.isFocused);
    }

    isFocused(scheduleId) {
        return this.focusedCards.some((card) => card.internalId === scheduleId);
    }

    get marks() {
        return this.items.filter((data) => data instanceof Mark);
    }

    get newCards() {
        return this.cards.filter((card) => card.isNew);
    }

    get selectedCards() {
        return this.cards.filter((card) => card.isSelected);
    }

    get view() {
        return this._view;
    }

    set view(value) {
        if (value instanceof View) {
            this._view = value;
            this.view.clearCache();
        } else {
            throw 'Expected an instance of View.';
        }
    }

    setCurrentCalendar(user) {
        this.currentCalendar = user;
        this.removeAllItems();
    }

    findCard(internalId) {
        return this.cards.find((card) => card.internalId === internalId);
    }

    getNextNewId() {
        const max =
            this.newCards.length > 0
                ? Math.max(
                      ...this.newCards.map((card) =>
                          parseInt(card.scheduleId.match(/\d+/)?.[0] ?? 0)
                      )
                  )
                : 0;

        return max + 1;
    }

    addItem(item) {
        this.items.push(item);
        this.itemsLoaded.push(item.internalId);
        const identifier = item.internalId;

        if (typeof identifier !== 'undefined') {
            if (!this.itemsExcluded[item.type]) {
                this.itemsExcluded[item.type] = [identifier];
            } else {
                this.itemsExcluded[item.type].push(identifier);
            }
        }

        this.sortItems();
        this.clearCacheInRange(item.startDate, item.endDate);
    }

    blurCards(callback) {
        if (!(callback instanceof Function)) {
            callback = () => false;
        }

        for (let card of this.cards) {
            card.isFocused = callback(card);
        }
    }

    clearCache() {
        Object.keys(this.days).forEach((key) => {
            const day = this.days[key];
            this.setPopulated(day.startDate, day.endDate, false);
            day.clearCache();
            // this.clearCacheByView(day);
        });

        Object.keys(this.weeks).forEach((key) => {
            const week = this.weeks[key];
            this.setPopulated(week.startDate, week.endDate, false);
            week.clearCache();
            // this.clearCacheByView(week);
        });

        // this.weeks = {};
        // this.days = {};
    }

    clearCacheByView(view, force = false) {
        let startDate = view.startDate;
        let endDate = view.endDate;

        // console.log('clearing cache by view', {
        //     isClearingCache: this.isClearingCache,
        // });

        if (this.isClearingCache && !force) {
            return;
        }

        this.isClearingCache = true;

        for (let item of this.getItemsInRange(startDate, endDate)) {
            item.clearCache();
        }

        for (let current of this.getDaysInRange(startDate, endDate)) {
            if (current != view) {
                current.clearCache();
            }
        }

        for (let current of this.getWeeksInRange(startDate, endDate)) {
            if (current != view) {
                current.clearCache();
            }
        }

        this.isClearingCache = false;
    }

    clearCacheInRange(startDate, endDate) {
        if (this.isClearingCache) {
            return;
        }

        this.isClearingCache = true;

        for (let item of this.getItemsInRange(startDate, endDate)) {
            item.clearCache();
        }

        for (let view of this.getDaysInRange(startDate, endDate)) {
            view.clearCache();
        }

        for (let view of this.getWeeksInRange(startDate, endDate)) {
            view.clearCache();
        }

        this.isClearingCache = false;
    }

    deselectAllCards() {
        for (let card of this.cards) {
            card.deselect();
        }
    }

    focusCards(callback) {
        let index = 1;

        if (!(callback instanceof Function)) {
            callback = () => true;
        }

        for (let card of this.cards) {
            card.isFocused = callback(card);
        }
    }

    hasDay(date) {
        return this.days[date.toISODate()] instanceof Day;
    }

    hasItem(item) {
        return this.itemsLoaded.includes(item.internalId);
    }

    hasWeek(date) {
        date = date.startOf('week');

        return this.weeks[date.toFormat('kkkk-WW')] instanceof Week;
    }

    isPopulated(startDate, endDate) {
        let isPopulated = false;

        for (let day of this.getDaysInRange(startDate, endDate)) {
            if (!day.isPopulated) {
                isPopulated = false;
                break;
            } else {
                isPopulated = true;
            }
        }

        return isPopulated;
    }

    getDay(date) {
        if (!this.hasDay(date)) {
            this.days[date.toISODate()] = new Day(this, date);
        }

        return this.days[date.toISODate()];
    }

    getDaysInRange(start, end) {
        let date = start.startOf('day');
        let views = [];

        while (date < end) {
            views.push(this.getDay(date));

            date = date.plus({ days: 1 });
        }

        return views;
    }

    getItemsInRange(start, end) {
        if (start > end) {
            [start, end] = [end, start];
        }

        return this.items.filter((data) => {
            return (
                data.startDate <= end &&
                data.endDate.minus({ minutes: 1 }) >= start &&
                ((this.currentCalendar !== 'unassigned' &&
                    (data.type === 'holiday' ||
                        data.type === 'diary-entry' ||
                        data.type === 'unavailable')) ||
                    data.entry.assignedUser === this.currentCalendar ||
                    (!data.entry.assignedUser &&
                        this.currentCalendar === 'unassigned'))
            );
        });
    }

    getWeek(date) {
        date = date.startOf('week');

        if (!this.hasWeek(date)) {
            this.weeks[date.toFormat('kkkk-WW')] = new Week(this, date);
        }

        return this.weeks[date.toFormat('kkkk-WW')];
    }

    getWeeksInRange(start, end) {
        let date = start.startOf('week');
        let views = [];

        while (date < end) {
            views.push(this.getWeek(date));

            date = date.plus({ weeks: 1 });
        }

        return views;
    }

    async populate(
        action,
        startDate,
        endDate,
        force = false,
        populateAdjacent = true
    ) {
        const shouldForce =
            force || this.currentCalendarAction !== action.constructor.name;

        this.currentCalendarAction = action.constructor.name;

        if (populateAdjacent) {
            if (!this.view.next.isPopulated || shouldForce) {
                this.view.next.populate(action, shouldForce, false);
            }

            if (!this.view.previous.isPopulated || shouldForce) {
                this.view.previous.populate(action, shouldForce, false);
            }
        }

        if (this.isPopulated(startDate, endDate) && !shouldForce) {
            console.log('populate: skipping');
            return Promise.resolve();
        }

        console.log('populate: populating');

        const calendarEntries =
            (
                await ScheduleTransport.listEntriesBetween({
                    start: toISO(startDate),
                    end: toISO(endDate),
                    exclude: this.itemsExcluded,
                    ...(this.currentCalendar === 'unassigned'
                        ? { type: 'unassigned' }
                        : { user: this.currentCalendar }),
                })
            )?.data?.data ?? [];

        this.isClearingCache = true;
        this.setDateRange(startDate, endDate);
        this.setPopulated(startDate, endDate);
        this.populateCards(
            calendarEntries,
            action.processCardUsing.bind(action)
        );
        this.isClearingCache = false;
        this.clearCacheInRange(startDate, endDate);

        return Promise.resolve();
    }

    populateCard(entry, callback) {
        if (this.isFocused(entry.scheduleId)) {
            return;
        }

        let card = new Card(this, entry);

        if (typeof callback === 'function') {
            card = callback(card);
        }

        this.removeItem(card);
        this.addItem(card);
    }

    populateCards(entries, callback) {
        entries.forEach((entry) => {
            if (entry.type === 'holiday') {
                this.populateMark(entry);
            } else {
                this.populateCard(entry, callback);
            }
        });
    }

    populateMark(entry) {
        let mark = new Mark(this, entry);

        if (!this.hasItem(mark)) {
            this.addItem(mark);
        }
    }

    removeItem(item) {
        this.items = this.items.filter(
            (current) => item.internalId != current.internalId
        );
        this.itemsLoaded = this.itemsLoaded.filter(
            (current) => item.internalId != current
        );

        if (!!this.itemsExcluded[item.type]) {
            this.itemsExcluded[item.type] = this.itemsExcluded[
                item.type
            ].filter((identifier) => {
                const itemIdentifier = !!item.eid ? 'eid' : 'uuid';
                return item[itemIdentifier] != identifier;
            });
        }

        this.isClearingCache = false;
        this.clearCacheInRange(item.startDate, item.endDate);
    }

    removeItems(except) {
        for (let card of this.cards) {
            if (typeof except === 'undefined' || !except(card)) {
                this.removeItem(card);
            }
        }
    }

    removeMarks() {
        for (let mark of this.marks) {
            this.removeItem(mark);
        }
    }

    reset() {
        this.days = {};
        this.weeks = {};
        this.items = [];
        this.itemsExcluded = {};
        this.itemsLoaded = [];
        this._view = new View(this, DateTime.local());
        this.endDate = null;
        this.startDate = null;
    }

    removeAllItems() {
        this.removeMarks();
        this.removeItems((item) => item.isNew || item.isFocused);

        this.itemsLoaded = [];
        this.itemsExcluded = {};
    }

    setDateRange(startDate, endDate) {
        startDate = startDate || this.startDate;
        endDate = endDate || this.endDate;

        // Set or expand start date:
        if (!this.startDate || startDate < this.startDate) {
            this.startDate = startDate;
        }

        // Set or expand end date:
        if (!this.endDate || endDate > this.endDate) {
            this.endDate = endDate;
        }
    }

    setPopulated(startDate, endDate, isPopulated = true) {
        for (let day of this.getDaysInRange(startDate, endDate)) {
            day.isPopulated = isPopulated;
        }
    }

    sortItems() {
        this.items.sort((a, b) => {
            if (a.entry.start_at < b.entry.start_at) {
                return -1;
            } else if (a.entry.start_at > b.entry.start_at) {
                return 1;
            }

            return 0;
        });
    }
}
