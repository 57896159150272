import angular from 'angular';
import * as Views from '../../Calendar/Views';
import { DateTime } from 'luxon';

angular
    .module('component.schedule-navigation', [
        'component.react.schedule-date-picker',
    ])

    .component('scheduleNavigation', {
        template: `
            <div class="ui-schedule-week__navigation --[[ $ctrl.calendar.view.type ]]">
                
                <div class="flex">
                    <div class="flex flex-grow xl:flex-grow-0 gap-2">
                        <a class="button --react w-10 min-w-10" ng-click="$ctrl.showView($ctrl.calendar.view.previous)">
                            <react-icon name="'ChevronLeft'"/>
                        </a>
                        <react-schedule-date-picker
                            view="$ctrl.calendar.view"
                            on-date-selected="$ctrl.showView"
                            class='w-full xl:max-w-[220px]'
                        ></react-schedule-date-picker>
                        <a class="button --react w-10 min-w-10" ng-click="$ctrl.showView($ctrl.calendar.view.next)">
                            <react-icon name="'ChevronRight'"/>
                        </a>
                    </div>
                    <div class="ui-action-bar__group +mleft-16" ng-if="$ctrl.calendar.view.is('week') && !$ctrl.calendar.view.isActive" ng-cloak>
                        <a class="button --text" ng-click="$ctrl.showActiveView()">Today</a>
                    </div>
                </div>
            </div>
        `,
        bindings: {
            action: '=',
            calendar: '=',
        },
        require: {
            root: '^scheduleWeek',
        },
        controller: [
            function () {
                let $ctrl = this;

                $ctrl.isOpen = false;

                $ctrl.views = [
                    // Views.Day,
                    Views.Week,
                ];

                $ctrl.changeView = (type) => {
                    if ($ctrl.calendar.view instanceof type) return;
                    $ctrl.showView(type.createFromView($ctrl.calendar.view));
                };

                $ctrl.showView = (date) => {
                    if (date instanceof DateTime) {
                        if ($ctrl.calendar.view instanceof Views.Day) {
                            date = $ctrl.calendar.getDay(date);
                        } else {
                            date = $ctrl.calendar.getWeek(date);
                        }
                    }

                    // console.log(
                    //     `/schedule/${date.date.isoWeekYear()}/W${date.date.isoWeek()}`
                    // );
                    // history.push(
                    //     `/schedule/${date.date.isoWeekYear()}/W${date.date.isoWeek()}`
                    // );
                    if (date instanceof Views.View) {
                        $ctrl.root.show(date);
                        $ctrl.isOpen = false;
                    }
                };

                $ctrl.showActiveView = () => {
                    $ctrl.showView($ctrl.calendar.view.active);
                };

                $ctrl.getNearbyViews = () => {
                    let current = $ctrl.calendar.view;
                    let from = -10;
                    let to = 10;
                    let views = [];

                    while (from++ < 0) {
                        current = current.previous;
                        views.push(current);
                    }

                    current = $ctrl.calendar.view;
                    views = views.reverse();
                    views.push(current);

                    while (from++ <= to) {
                        current = current.next;
                        views.push(current);
                    }

                    return views;
                };
            },
        ],
    });
