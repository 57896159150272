import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import { getCommonStyle } from '../Icon/common';

const Notice = ({
    children,
    type,
    text,
    icon,
    iconProps,
    rightAction,
    ...props
}) => {
    const getText = () => (typeof text === 'function' ? text() : text);
    const getClasses = () => {
        const classes = [props.className, 'ui-notice'];

        if (typeof type !== 'undefined') {
            classes.push(`--${type}`);
        }

        return classNames(classes);
    };

    iconProps = {
        name: icon,
        ...getCommonStyle(type),
        ...iconProps,
    };
    const hasIcon = typeof iconProps.name !== 'undefined';

    return (
        <div className={getClasses()}>
            {hasIcon && <Icon className="ui-notice__icon" {...iconProps} />}
            <span className="ui-notice__content">{getText() || children}</span>
            {rightAction && (
                <div className="ui-notice__action">{rightAction()}</div>
            )}
        </div>
    );
};

Notice.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    type: PropTypes.oneOf(['info', 'warning', 'negative', 'positive']),
    icon: PropTypes.string,
    iconProps: PropTypes.object,
    rightAction: PropTypes.func,
};

Notice.defaultProps = {
    iconProps: {},
};

export default Notice;
