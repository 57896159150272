import React, { ComponentPropsWithoutRef, FunctionComponent } from 'react';

interface Props extends ComponentPropsWithoutRef<'div'> {}

const OptionsMenu: FunctionComponent<Props> = ({ children, ...props }) => {
    return (
        <div className="ui-button-list" {...props}>
            {children}
        </div>
    );
};

export default OptionsMenu;
