import {
    applySnapshot,
    destroy,
    flow,
    getRoot,
    getSnapshot,
    types,
} from 'mobx-state-tree';
import SessionTransport from '../Services/Transport/SessionTransport';
import ClassDateTransport from '../Services/Transport/ClassDateTransport';
import ConsultationTransport from '../Services/Transport/ConsultationTransport';
import DiaryEntryTransport from '../Services/Transport/DiaryEntryTransport';
import { Entry } from '../Models/Entry';
import ClientTransport from '../Services/Transport/ClientTransport';

export const EntryStore = types
    .model('EntryStore', {
        busy: false,
        entries: types.map(Entry),
    })

    .volatile((self) => ({
        inFlight: new Set(),
    }))

    .views((self) => {
        return {
            find(identifier) {
                return self.entries.get(identifier);
            },

            findOrFetch(identifier, type, params = {}, force = false) {
                // identifier = formatIdentifier(identifier);
                self.fetchEntry(identifier, type, params, force);
                return self.entries.get(identifier);
            },

            get available() {
                return Array.from(self.entries.values())
                    .filter(
                        (entry) => entry.isAppointment || !entry.isCancelled
                    )
                    .sort((a, b) => a.start - b.start);
            },

            getWithinInterval(interval, dateCol = 'start', filter = null) {
                let entries = self.available
                    .slice()
                    .filter((exp) => interval.contains(exp[dateCol]));

                if (typeof filter === 'function') {
                    return entries.filter(filter);
                }

                return entries;
            },
        };
    })

    .actions((self) => ({
        listForClient: flow(function* listClientEntries(
            clientEid,
            params = {},
            withMeta = false
        ) {
            const { data, meta } = yield ClientTransport.listEntries(
                clientEid,
                params
            );
            const items = data.data.map((entry) => self.createOrUpdate(entry));
            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        fetchEntry: flow(function* fetchEntry(
            identifier,
            type,
            params = {},
            force = false
        ) {
            if (
                (self.inFlight.has(identifier) ||
                    self.entries.has(identifier)) &&
                !force
            ) {
                return;
            }

            self.inFlight.add(identifier);
            let entry;

            switch (type) {
                case 'session':
                    entry = yield self.fetchSession(
                        identifier.replace('session-', ''),
                        params
                    );
                    break;
                case 'class-date':
                    entry = yield self.fetchClassDate(
                        identifier.replace('class-date-', ''),
                        params
                    );
                    break;
            }

            self.inFlight.delete(identifier);

            return entry;
        }),

        fetchClassDate: flow(function* fetchClassDate(identifier, params = {}) {
            const { data } = yield ClassDateTransport.fetch(identifier, params);
            return self.createOrUpdate(data.data);
        }),

        fetchSession: flow(function* fetchSession(identifier, params = {}) {
            const { data } = yield SessionTransport.fetch(identifier, params);
            return self.createOrUpdate(data.data);
        }),

        fetchDiaryEntry: flow(function* fetchDiaryEntry(
            identifier,
            params = {}
        ) {
            const { data } = yield DiaryEntryTransport.fetch(
                identifier,
                params
            );
            return self.createOrUpdate(data.data);
        }),

        // loadUpcoming: flow(function* loadUpcoming() {
        //     try {
        //         self.busy = true;
        //         const { data } = yield EntryTransport.upcoming();
        //
        //         data.forEach((entry) => self.createOrUpdate(entry));
        //
        //         self.busy = false;
        //     } catch (e) {
        //         console.error('error: ', e);
        //     }
        // }),

        listSessions: flow(function* listSessions(params, withMeta = false) {
            const { data } = yield SessionTransport.list(params);
            const items = data.data.map((session) =>
                self.createOrUpdate(session)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        listClassDates: flow(function* listClassDates(
            params,
            withMeta = false
        ) {
            const { data } = yield ClassDateTransport.list(params);
            const items = data.data.map((date) => self.createOrUpdate(date));

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        loadConsultation: flow(function* loadConsultation(eid) {
            try {
                const { data: consultation } =
                    yield ConsultationTransport.getConsultation(eid);
                return self.createOrUpdate(consultation.data);
            } catch (e) {
                console.error('error: ', e);
            }
        }),

        listConsultations: flow(function* listConsultations(
            params,
            withMeta = false
        ) {
            const { data } = yield ConsultationTransport.list(params);
            const items = data.data.map((consultation) =>
                self.createOrUpdate(consultation)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        getSessionCancellationData: flow(function* getSessionCancellationData(
            eid
        ) {
            const { data } = yield SessionTransport.getCancellationData(eid);

            return {
                session: self.createOrUpdate(data.session),
                clients: data.clients.map((c) =>
                    getRoot(self).stores.clientStore.createOrUpdate(c)
                ),
                willCloseSessionPack: data.willCloseSessionPack,
            };
        }),

        setAttendance: flow(function* setAttendance(
            session,
            attendance,
            client = null
        ) {
            try {
                yield SessionTransport.setAttendance(
                    session.eid,
                    attendance,
                    client
                );

                const existing = self.find(session.identifier);

                if (!!existing) {
                    existing.setAttendance(attendance, client);
                }
            } catch (e) {
                console.error('error: ', e);
            }
        }),

        deleteConsultation: flow(function* deleteConsultation(eid) {
            try {
                yield ConsultationTransport.deleteConsultation(eid);

                const existing = self.find(`consultation-${eid}`);

                if (!!existing) {
                    destroy(existing);
                }
            } catch (e) {
                console.error('error: ', e);
            }
        }),

        dismissConsultation: flow(function* dismissConsultation(eid) {
            try {
                yield ConsultationTransport.dismiss(eid);

                const existing = self.find(`consultation-${eid}`);

                if (!!existing) {
                    destroy(existing);
                }
            } catch (e) {
                console.error('error: ', e);
            }
        }),

        createOrUpdate(entryData) {
            const entry = self.processNestedResources(entryData);

            if (self.entries.has(entry.identifier)) {
                const existing = self.entries.get(entry.identifier);
                applySnapshot(existing, {
                    ...getSnapshot(existing),
                    ...entry,
                });
            } else {
                self.entries.set(entry.identifier, entry);
            }

            return self.entries.get(entry.identifier);
        },

        /**
         * @param entryData¡
         * @returns {*}
         */
        processNestedResources(entryData) {
            if (
                typeof entryData.assignedUsers !== 'undefined' &&
                Array.isArray(entryData.assignedUsers)
            ) {
                entryData.assignedUsers = entryData.assignedUsers.map(
                    getRoot(self).stores.usersStore.processAsNested
                );
            }

            if (
                typeof entryData.enrolments !== 'undefined' &&
                Array.isArray(entryData.enrolments)
            ) {
                entryData.enrolments = entryData.enrolments.map(
                    getRoot(self).stores.enrolmentStore.processAsNested
                );
            }

            return entryData;
        },

        /**
         * Add the entry to the store, and return the identifier
         * @returns {*}
         * @param entry
         */
        processAsNested(entry) {
            if (!!entry?.identifier) {
                self.createOrUpdate(entry);
                return entry.identifier;
            }

            return entry;
        },

        delete(identifier) {
            if (Array.isArray(identifier)) {
                identifier.forEach((id) => self.delete(id));
                return;
            }

            let entry = self.find(identifier);

            if (!!entry) {
                destroy(entry);
            }
        },
    }));
