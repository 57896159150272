import moment, { Moment } from 'moment';
import { DateObject, DateTime, Interval } from 'luxon';
import RootStore from '../Stores/RootStore';

export type DateTimeType =
    | DateTime
    | Date
    | Moment
    | DateObject
    | string
    | null
    | undefined;

export default class DateService {
    /**
     * Convert a date to a moment object
     * @param date
     * @returns {moment.Moment}
     */
    static toMoment(date: Moment | DateTime | string) {
        if (date instanceof DateTime) {
            date = date.toString();
        }

        return moment.isMoment(date) ? date : moment.parseZone(date);
    }

    /**
     * Get a duration string using luxon objects
     * @param start
     * @param end
     * @param extended
     * @param zeroState
     * @returns {*}
     */
    static luxonDuration(
        start: DateTime,
        end: DateTime,
        extended = false,
        zeroState = '0h'
    ) {
        let interval = Interval.fromDateTimes(start, end);
        const minutes = interval.length('minutes');

        return durationString(minutes, extended, zeroState);
    }

    /**
     * Display the duration between 2 dates
     * @param start
     * @param end
     * @param extended
     * @param zeroState
     */
    static duration(
        start: Moment | DateTime | string,
        end: Moment | DateTime | string,
        extended = false,
        zeroState = '0h'
    ) {
        if (start instanceof DateTime && end instanceof DateTime) {
            return this.luxonDuration(start, end, extended, zeroState);
        }

        const duration = moment.duration(
            this.toMoment(end).diff(this.toMoment(start))
        );
        const minutes = duration.as('minutes');

        return durationString(minutes, extended, zeroState);
    }
}

/**
 * Convert the given date to a Luxon one
 * @param date
 * @param forceDate
 */
export const toLuxon = (
    date: DateTimeType = null,
    forceDate = true
): DateTime | null => {
    if (date === null || typeof date === 'undefined') {
        return forceDate ? DateTime.local() : null;
    }

    if (date instanceof DateTime) {
        return date;
    }

    if (date instanceof Date) {
        return DateTime.fromJSDate(date);
    }

    if (moment.isMoment(date)) {
        date = date.toISOString(true);
    }

    if (typeof date === 'object') {
        return DateTime.fromObject(date);
    }

    return DateTime.fromISO(date);
};

/**
 * Return a Luxon DateTime object set with the user's timezone setting
 * @returns {DateTime}
 * @param date
 * @param keepLocalTime
 */
export const inUserZone = (date: DateTimeType = null, keepLocalTime = true) => {
    let { user } = RootStore.stores.currentUserStore;

    if (!user) {
        throw 'No user timezone available';
    }

    return toLuxon(date)?.setZone(user.timezone, { keepLocalTime });
};

export const toISO = (date: DateTimeType) => {
    return toLuxon(date)
        ?.set({ millisecond: 0 })
        .toISO({ suppressMilliseconds: true });
};

export const toISODate = (date: DateTimeType, force = false) => {
    date = toLuxon(date, force);
    return !!date ? (date as DateTime | null)?.toISODate() : null;
};

/**
 * Get the ordinal indicator for the given number
 * @param number
 * @returns {string}
 */
export const getOrdinal = (number: number) => {
    const mod10 = number % 10;
    const mod100 = number % 100;

    if (mod10 == 1 && mod100 != 11) return 'st';
    if (mod10 == 2 && mod100 != 12) return 'nd';
    if (mod10 == 3 && mod100 != 13) return 'rd';

    return 'th';
};

/**
 * Format a date
 * @param date
 * @param includeYear
 * @param includeDay
 * @param forceYear
 * @returns {string}
 */
export const formatDate = (
    date: DateTimeType,
    includeYear = true,
    includeDay = false,
    forceYear = false
) => {
    const luxonDate = toLuxon(date);

    let formatted = luxonDate?.day + getOrdinal(luxonDate?.day!);
    formatted += ' ' + luxonDate?.toFormat('MMM');

    if (includeDay) {
        formatted = `${luxonDate?.toFormat('ccc')} ${formatted}`;
    }

    function shouldDisplayYear() {
        const today = inUserZone();
        return (
            forceYear ||
            (includeYear && /*date < today ||*/ luxonDate?.year !== today?.year)
        );
    }

    if (shouldDisplayYear()) {
        formatted += ' ' + luxonDate?.year;
    }

    return formatted;
};

export const userTimeFormat = () => {
    return RootStore.stores.currentUserStore.user?.locale?.timeFormat === '12h'
        ? 'h:mm a'
        : 'HH:mm';
};

/**
 * Display the given date in a common time format
 * @param time
 * @returns {string}
 */
export const formatTime = (time: Moment | DateTime | string) => {
    return inUserZone(time)!.toFormat(userTimeFormat());
};

/**
 * Return a human string representing the age of of the given date
 * @param date
 * @param shorten
 * @param dateOverDays
 * @returns {string}
 */
export const age = (
    date: DateTimeType,
    shorten = false,
    dateOverDays: number | undefined = undefined
) => {
    const luxonDate = toLuxon(date);
    const today = DateTime.local();
    const yesterday = DateTime.local().minus({ days: 1 });
    const interval = Interval.fromDateTimes(luxonDate!, today);

    if (shorten && luxonDate?.hasSame(today, 'day')) {
        return 'Today';
    }
    if (shorten && luxonDate?.hasSame(yesterday, 'day')) {
        return 'Yesterday';
    }

    const duration = interval.toDuration('days');
    const days = Math.round(duration.toObject().days!);

    if (dateOverDays && days > dateOverDays) {
        return formatDate(date);
    }

    const suffix = days === 1 ? '' : 's';

    return `${days} day${suffix} ago`;
};

/**
 * Return the number of years between now, and the given date
 * @param date
 * @returns {number}
 */
export const ageInYears = (date: DateTimeType) => {
    const luxonDate = inUserZone(toLuxon(date));
    return Math.floor(inUserZone(null, false)!.diff(luxonDate!, 'years').years);
};

/**
 * Determine if the given date is in the past
 * @param date
 * @returns {boolean}
 */
export const inPast = (date: DateTimeType) => {
    return inUserZone(date)! < inUserZone()!;
};

/**
 * Determine if date1 happened before date 2
 * @param date1
 * @param date2
 * @returns {boolean}
 */
export const happenedBefore = (date1: DateTimeType, date2: DateTimeType) => {
    return toLuxon(date1)! < toLuxon(date2)!;
};

/**
 *
 * @param date
 * @param format
 * @returns {string}
 */
export const getSimplifiedDate = (
    date: DateTimeType,
    format = 'cccc, LLLL d'
) => {
    const today = inUserZone(null, false);
    const luxonDate = toLuxon(date);

    if (today!.toISODate() === luxonDate?.toISODate()) {
        return 'Today';
    }
    if (today!.minus({ day: 1 }).toISODate() === luxonDate?.toISODate()) {
        return 'Yesterday';
    }
    if (today!.plus({ day: 1 }).toISODate() === luxonDate?.toISODate()) {
        return 'Tomorrow';
    }

    return luxonDate?.toFormat(format);
};

/**
 * Output a date range using 2 dates
 * @param start
 * @param end
 * @returns {string}
 */
export const dateRange = (start: DateTimeType, end: DateTimeType) => {
    const today = DateTime.local();
    const luxonStart = toLuxon(start);
    const luxonEnd = toLuxon(end);

    let displayStartYear = true;
    let displayEndYear = true;

    if (luxonStart?.isValid && luxonEnd?.isValid) {
        displayStartYear =
            !luxonStart?.hasSame(today, 'year') ||
            !luxonStart?.hasSame(luxonEnd, 'year');
        displayEndYear = !luxonStart?.hasSame(today, 'year');

        if (luxonStart?.diff(luxonEnd, 'days').toObject().days === 0) {
            return formatDate(luxonEnd, displayEndYear);
        }
    }

    let string = luxonStart?.isValid
        ? formatDate(luxonStart, displayStartYear)
        : '-';
    string += ' - ';
    string += luxonEnd?.isValid ? formatDate(luxonEnd, displayEndYear) : '-';

    return string;
};

export const timeRange = (start: DateTimeType, end: DateTimeType) => {
    const luxonStart = toLuxon(start);
    const luxonEnd = toLuxon(end);
    const timeFormat = userTimeFormat();
    return (
        luxonStart?.toFormat(timeFormat) +
        ' - ' +
        luxonEnd?.toFormat(timeFormat)
    );
};

/**
 * Get a representation of the duration like '6 hours 15 minutes' or '6h 15m'
 * @param minutes
 * @param extended
 * @param zeroState
 * @returns {string}
 */
export const durationString = (
    minutes: number,
    extended = false,
    zeroState = '0h'
) => {
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    let bits = [];

    if (hours) {
        let hoursTerm = !extended ? 'h' : hours === 1 ? ' hour' : ' hours';
        bits.push(`${hours}${hoursTerm}`);
    }

    if (minutes) {
        let minutesTerm = !extended ? 'm' : minutes === 1 ? ' min' : ' mins';
        bits.push(`${minutes}${minutesTerm}`);
    }

    if (!bits.length) {
        return zeroState;
    }

    return bits.join(' ');
};

export function durationStringFromDates(
    start: DateTimeType,
    end: DateTimeType
) {
    return durationString(
        Math.round(
            toLuxon(end)!.diff(toLuxon(start)!, 'minutes').toObject().minutes!
        )
    );
}

export function durationInPeriods(start: DateTimeType, end: DateTimeType) {
    return Math.round(
        toLuxon(end)!.diff(toLuxon(start)!, 'minutes').toObject().minutes! / 15
    );
}

/**
 * Take the given entries and return a total length string for hem all
 * @param entries
 * @param extended
 * @param zeroState
 * @returns {string}
 */
export const entryDurationString = (
    entries: any[],
    extended = false,
    zeroState = '0h'
) => {
    return durationString(
        entries.reduce((acc, entry) => acc + entry.length * 15, 0),
        extended,
        zeroState
    );
};

export const periodsToTimeSummary = (
    periods: number,
    extended = false,
    zeroState = 'none'
) => {
    let hours = Math.floor(periods / 4);
    let minutes = (periods % 4) * 15;

    let bits = [];

    if (hours) {
        let hoursTerm = !extended ? 'h' : hours === 1 ? ' hour' : ' hours';
        bits.push(`${hours}${hoursTerm}`);
    }

    if (minutes) {
        let minutesTerm = !extended
            ? 'm'
            : minutes === 1
            ? ' minute'
            : ' minutes';
        bits.push(`${minutes}${minutesTerm}`);
    }

    if (!bits.length) {
        return zeroState;
    }

    return bits.join(' ');
};
