import React, { FunctionComponent } from 'react';
import { ClearButton } from '../../Button';
import RootStore from '../../../Stores/RootStore';
import { ClassDate } from '../../../Models/ClassDate';

interface Props {
    classDate: ClassDate;
    onSuccess?(classDate: ClassDate, otherDates: ClassDate[]): void;
}

const ClassDateUpdateButton: FunctionComponent<Props> = ({
    classDate,
    onSuccess,
}) => {
    function handleClick() {
        RootStore.stores.uiStateStore.pushModal('CLASS_DATE_UPDATE', {
            classDate,
            onUpdate: onSuccess,
        });
    }

    return (
        <ClearButton
            icon="Pencil"
            onClick={handleClick}
            disabled={classDate.endInPast()}
        >
            Edit
        </ClearButton>
    );
};

export default ClassDateUpdateButton;
