import styled from 'styled-components';
import { uiColors } from '../../Theme';

import {
    StyledAvatarContainer as DefaultAvatarContainer,
    StyledAvatarIcon as DefaultAvatarIcon,
} from '../EntryCard/Style';

export const StyledAvatarContainer = styled(DefaultAvatarContainer)`
    background: ${uiColors.scheduleEntities.classDark};
`;

export const StyledAvatarIcon = styled(DefaultAvatarIcon).attrs({
    name: 'CustomClasses',
    fill: uiColors.scheduleEntities.class,
    width: 24,
    height: 24,
})``;
