import React, { FunctionComponent, useMemo } from 'react';
import { useQuery } from 'react-query';
import DataTransport from '@/Services/Transport/DataTransport';
import { Country } from '@/Models/Country';
import { FormFieldElementProps } from '@/Components/FormFieldElements';
import FormSelectField from '@/Components/FormSelectField';
import { SelectFieldProps } from '@/Components/SelectField';

interface Props extends FormFieldElementProps {
    fieldProps?: SelectFieldProps;
    initialData?: Country[];
    id?: string;
}

const FormCountrySelectField: FunctionComponent<Props> = ({
    name = 'country',
    fieldProps = {},
    initialData,
    id = 'country',
    ...props
}) => {
    const { data, isLoading } = useQuery(
        ['data', 'countries'],
        async () => {
            const { data } = await DataTransport.listCountries();
            return data.data;
        },
        { initialData, enabled: !initialData }
    );

    const options = useMemo(() => {
        return (data ?? []).map((item: Country) => ({
            value: item.iso,
            label: item.name,
        }));
    }, [data]);

    return (
        <FormSelectField
            options={options}
            name={name}
            id={id}
            fieldProps={{
                isLoading,
                isSearchable: true,
                ...fieldProps,
            }}
            {...props}
        />
    );
};

export default FormCountrySelectField;
