import React, { Component, ComponentType } from 'react';
import { QueryClientProvider } from 'react-query';
import queryClient from '@/Modules/QueryClient';

const withQueryClient = (Component: ComponentType<any>) => {
    return function WrapperComponent(props: any) {
        return (
            <QueryClientProvider client={queryClient}>
                <Component {...props} />
            </QueryClientProvider>
        );
    };
};

export default withQueryClient;
