import { types } from 'mobx-state-tree';

export const MetricLine = types.model('MetricLine', {
    identifier: types.identifier,
    type: types.literal('metric-line'),
    line: types.array(
        types.model({
            delimiter: types.identifier,
            value: types.number,
        })
    ),
});
