import { PaginatedResponse } from '@/Hooks/useLoadMore';

/**
 * Used with `useInfiniteQuery` hook to get the next page number from a Laravel pagination response
 * @param lastPage
 */
export const getNextPageParam = (lastPage: PaginatedResponse) => {
    return lastPage.meta.current_page < lastPage.meta.last_page
        ? lastPage.meta.current_page + 1
        : undefined;
};
