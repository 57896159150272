import React, { FunctionComponent } from 'react';
import Icon from '@/Components/Icon/Icon';
import { ClassDate } from '@/Models/ClassDate';
import { observer } from 'mobx-react';
import { Tooltip } from '@/Components/Tooltip';
import { countInflect } from '@/Services/String';

interface Props {
    classDate: ClassDate;
}

const Component: FunctionComponent<Props> = ({ classDate }) => {
    return (
        <Tooltip
            label={`${countInflect(
                classDate.totalAttending,
                'client',
                'clients'
            )} attending`}
        >
            <div className="flex items-center rounded-md border border-white border-opacity-50 px-1.5 py-1">
                <Icon
                    name="AccountMultipleCheck"
                    width={20}
                    height={20}
                    fill="#fff"
                />
                <span className="ml-2 font-bold text-white">
                    {classDate.totalAttending}
                </span>
            </div>
        </Tooltip>
    );
};

export const ClassDateEnrolmentCountPill = observer(Component);
