import React from 'react';
import PropTypes from 'prop-types';
import { StyledContainer, StyledContentContainer } from './Style';
import { observer } from 'mobx-react';
import { openEntryViewModal } from '../../Services/Entry';
import Avatar from '../Avatar';
import useTeam from '../../Hooks/useTeam';
import Date, { formatDate, formatTime } from '../../Services/Date';
import Icon from '../Icon/Icon';
import { uiColors } from '../../Theme';
import classNames from 'classnames';

const EntryCard = ({
    type,
    entry,
    withLocation,
    withDuration,
    withLink,
    withAvatar,
    avatar,
    title,
    subtitle,
    subtitleType = 'time',
    accessory,
    ...props
}) => {
    const { hasTeamMembers } = useTeam();

    const renderAvatar = () => {
        if (!avatar) return null;

        if (typeof avatar === 'function') {
            return avatar();
        }

        return (
            <Avatar
                src={avatar}
                alt={title}
                className="aspect-square rounded-l rounded-r-none"
            />
        );
    };

    /**
     * Get the subtitle for the card
     * IMPORTANT: User length is passed in to ensure re-rendering when the user is added/removed
     * @param userLength
     * @returns {*|JSX.Element}
     */
    function getSubtitle(userLength) {
        if (!!props.subtitle) return props.subtitle;

        let subtitle = [];

        if (props.subtitleTypes?.includes('date')) {
            subtitle.push(formatDate(entry.start_at));
        }
        if (props.subtitleTypes?.includes('time')) {
            subtitle.push(
                `${formatTime(entry.start_at)} (${Date.duration(
                    entry.start_at,
                    entry.end_at
                )})`
            );
        }
        if (props.subtitleTypes?.includes('assignedUsers') && hasTeamMembers) {
            const user = entry.assignedUsers?.[0]?.fullName;

            subtitle.push(
                user ?? (
                    <>
                        <Icon
                            name="WarningTriangle"
                            width={12}
                            height={12}
                            fill={uiColors.warning.default}
                            className="relative top-px mr-1"
                        />
                        Unassigned
                    </>
                )
            );
        }
        if (props.subtitleTypes?.includes('location') && entry.hasLocation) {
            subtitle.push(entry.locationName);
        }

        return (
            <>
                {subtitle.map((part, index) => (
                    <span key={part}>
                        {part}
                        {index < subtitle.length - 1 ? ' - ' : ''}
                    </span>
                ))}
            </>
        );
    }

    function handleViewEntry() {
        openEntryViewModal(entry.type, {
            eid: entry.eid,
            uuid: entry.uuid,
            onUpdate: props.handleUpdate,
        });
    }

    return (
        <StyledContainer type={type} {...props}>
            {entry.attendance && (
                <span
                    className={classNames(
                        'absolute bottom-0 left-0 top-0 w-[10px] rounded-bl-[6px] rounded-tl-[6px] border-r-2',
                        {
                            'bg-legacy-positive-500':
                                entry.attendance === 'completed',
                            'bg-negative': entry.attendance === 'forfeited',
                        }
                    )}
                />
            )}
            {withAvatar && renderAvatar()}
            <StyledContentContainer withAttendance={!!entry.attendance}>
                <p className="!m-0 -mt-px text-base font-bold text-white">
                    {title}
                </p>
                <p className="m-0 truncate text-[13px] text-white/70">
                    {getSubtitle(entry.assignedUsers?.length)}
                </p>
            </StyledContentContainer>
            {withLink && (
                <a
                    onClick={handleViewEntry}
                    className="absolute inset-0 bg-none hover:bg-white/20"
                />
            )}
            {accessory && (
                <div className="absolute bottom-3 right-3 top-3 flex items-center">
                    {accessory()}
                </div>
            )}
        </StyledContainer>
    );
};

EntryCard.propTypes = {
    type: PropTypes.oneOf(['session', 'consultation', 'diary', 'class-date'])
        .isRequired,
    entry: PropTypes.object,
    withLink: PropTypes.bool,
    withLocation: PropTypes.bool,
    withAvatar: PropTypes.bool,
    withDuration: PropTypes.bool,
    avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    subtitle: PropTypes.string,
    subtitleType: PropTypes.oneOf(['date', 'time']),
    accessory: PropTypes.func,
    handleUpdate: PropTypes.func,
    handleViewClosed: PropTypes.func,
};

export default observer(EntryCard);
