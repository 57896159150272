import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../Stores/RootStore';
import { uiColors } from '../../../Theme';
import { Button } from '../../Button';
import Notification from '../../../Services/Notification';
import { inPast } from '../../../Services/Date';
import { currencyDisplay } from '../../../Services/Currency';
import { ClassDate } from '../../../Models/ClassDate';
import classNames from 'classnames';
import useAsyncReference from '../../../Hooks/useAsyncReference';

interface Props {
    classDate: ClassDate;
    variant?: 'default' | 'full';
    className?: string;
}

const ClassesDateConfirmButton: FunctionComponent<Props> = ({
    classDate,
    className,
}) => {
    const { uiStateStore } = RootStore.stores;
    const fillColor = classDate.isConfirmed
        ? uiColors.positive.default
        : uiColors.button.icon;
    const { resolve, isResolving } = useAsyncReference();

    const handleClick = async () => {
        if (!classDate.isConfirmed) {
            try {
                await resolve(classDate, 'class');
                uiStateStore.pushModal('CLASS_DATE_CONFIRM', { classDate });
            } catch (e) {}
            return;
        }

        await classDate.update({
            confirmedAt: null,
            employer: null,
        });

        Notification.success(`Class unconfirmed`);
    };

    const canBeConfirmed = inPast(classDate.start);

    if (!canBeConfirmed) {
        return null;
    }

    return (
        <Button
            isLoading={classDate.busy || isResolving}
            onClick={handleClick}
            icon="ThumbUp"
            iconWidth={16}
            iconHeight={16}
            fill={fillColor}
            className={classNames(
                { 'bg-legacy-positive-300': classDate.isConfirmed },
                className
            )}
        >
            {currencyDisplay(classDate.fee, classDate.currency)}
            {!classDate.isConfirmed && '?'}
        </Button>
    );
};

export default observer(ClassesDateConfirmButton);
