import React, {
    ChangeEvent,
    Fragment,
    FunctionComponent,
    SyntheticEvent,
} from 'react';

interface Props {
    id?: string;
    name?: string;
    value?: string;
    checked: boolean;
    label?: JSX.Element | string;
    isDisabled?: boolean;
    className?: string;
    onChange(event: ChangeEvent<HTMLInputElement>): void;
    onBlur?(event: SyntheticEvent): void;
}

const Checkbox: FunctionComponent<Props> = ({
    isDisabled,
    label,
    className,
    ...props
}) => {
    let id = props.id || props.name;

    if (!id) {
        id = Math.random().toString(36).substr(2, 5);
    }

    return (
        <Fragment>
            <input
                type="checkbox"
                className="checkbox"
                disabled={isDisabled}
                {...props}
                id={id}
            />
            <label htmlFor={id} className={className}>
                {label}
            </label>
        </Fragment>
    );
};

export default Checkbox;
