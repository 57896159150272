import { types } from 'mobx-state-tree';
import { countInflect } from '../Services/String';
import { formatDate, inUserZone } from '../Services/Date';

export const ClassPassBase = types.model('ClassPassBase').views((self) => ({
    get enrolmentLimitEnabled() {
        return !!self.enrolmentLimit;
    },

    get classRestrictionEnabled() {
        return (
            self.packageType === 'class' &&
            Array.isArray(self.classRestriction) &&
            self.classRestriction.length > 0
        );
    },

    get classRestrictionIds() {
        console.log(self.classRestriction);
        return self.classRestriction
            .filter((classModel) => !!classModel)
            .map((classModel) =>
                typeof classModel === 'string' ? classModel : classModel.uuid
            );
    },

    get expiryEnabled() {
        return self.packageType === 'class' && !!self.expiry;
    },

    get expiryFormatted() {
        return formatDate(self.expiry, true, false);
    },

    get hasExpired() {
        return self.expiryEnabled && self.expiry < inUserZone();
    },

    get isExpiringSoon() {
        const now = inUserZone();
        const soonDate = now.plus({ days: 14 });
        return (
            self.expiryEnabled && self.expiry > now && self.expiry < soonDate
        );
    },

    get attendanceWindowEnabled() {
        return self.packageType === 'class' && !!self.attendanceWindow;
    },

    get attendanceWindowCount() {
        if (!self.attendanceWindowEnabled) {
            return null;
        }

        return self.attendanceWindow.substring(
            1,
            self.attendanceWindow.length - 1
        );
    },

    get attendanceWindowPeriod() {
        if (!self.attendanceWindowEnabled) {
            return null;
        }

        const period = self.attendanceWindow.charAt(
            self.attendanceWindow.length - 1
        );

        switch (period) {
            case 'D':
                return 'day';
            case 'W':
                return 'week';
            case 'M':
                return 'month';
        }
    },

    get attendanceWindowString() {
        if (!self.attendanceWindowEnabled) {
            return null;
        }

        return `${countInflect(
            self.attendanceWindowCount,
            self.attendanceWindowPeriod,
            `${self.attendanceWindowPeriod}s`
        )} after ${
            self.attendanceWindowAnchor === 'purchase'
                ? 'purchase'
                : 'first use'
        }`;
    },

    get classRestrictionString() {
        return self.classRestrictionEnabled
            ? countInflect(self.classRestriction.length, 'class', 'classes')
            : 'All classes';
    },

    get enrolmentLimitString() {
        return self.enrolmentLimitEnabled
            ? countInflect(self.enrolmentLimit, 'credit')
            : 'No limit';
    },
}));
