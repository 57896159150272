import angular from 'angular';
import createAction from '../../actions';
import RootStore from '../../../../js/Stores/RootStore';
import { openEntryViewModal } from '../../../../js/Services/Entry';

import reactScheduleEntryPopper from '../../react/react-schedule-entry-popper';

angular
    .module('component.schedule-entry', [reactScheduleEntryPopper.name])

    .component('scheduleEntry', {
        bindings: {
            calendar: '=',
            action: '<',
            card: '=scheduleCard',
            delivery: '<', // Purely to force update when location changes
            isViewable: '<',
            isSelected: '<',
        },
        require: {
            view: '^scheduleWeek',
        },
        template: `
            <div
                class='schedule-entry entry --[[ ::$ctrl.card.type ]] --positioned --days-[[ $ctrl.calendar.view.days.length ]] --left-[[ $ctrl.card.day.index ]] --top-[[ $ctrl.card.start ]] --height-[[ $ctrl.card.end - $ctrl.card.start ]]'
                ng-class="{
                'opacity-50':      $ctrl.card.isGhost,
                '--is-focused':      $ctrl.card.isFocused,
                '--is-movable':      $ctrl.card.isMovable,
                '--is-movable-x':    $ctrl.card.isMovable && $ctrl.calendar.view.nameSingular !== 'Day',
                '--is-selected':     $ctrl.card.isSelected,
                '--is-interactable': $ctrl.isInteractable()
            }">
                <div class='entry__place-shield z-[1] absolute top-0 left-0 w-full h-full' ng-if='$ctrl.card.isFocused'></div>
                <div class='entry__label' ng-class="{'!pl-[13px]': $ctrl.card.entry.flash }">
                    <span ng-if='!$ctrl.timeLengthLabel' class='+text-ellipsis'>[[ $ctrl.card.label ]]</span>
                    <span ng-if='$ctrl.timeLengthLabel'>[[ $ctrl.timeLengthLabel ]]</span>
                    <span class='entry__location h-4' ng-if='$ctrl.card.hasLocation()' ng-cloak>[[ $ctrl.card.getLocation() ]]</span>
                    <span class="absolute top-0.5 bottom-0.5 left-0.5 w-[7px] rounded-tl-[4px] rounded-bl-[4px] border-r border-white" ng-show='$ctrl.card.entry.flash' ng-class="{'bg-legacy-positive-500': $ctrl.card.entry.flash === 'completed', 'bg-negative': $ctrl.card.entry.flash === 'forfeited'}"></span>
                </div>
                <span class='schedule-entry__handle' ng-if='$ctrl.card.isResizable && $ctrl.card.isSelected'></span>
                <div class='entry__view-trigger' ng-if='!$ctrl.preventClick && $ctrl.view.action.canPopperCards'
                     ng-click='$ctrl.viewEntry()'></div>
            
                <react-schedule-entry-popper
                    is-selected='$ctrl.isSelected'
                    is-moving='$ctrl.isMoving'
                    card='$ctrl.card'
                    view='$ctrl.view'
                    calendar='$ctrl.calendar'
                    action='$ctrl.action'
                    on-remove='$ctrl.remove'
                    on-existing-remove='$ctrl.existingEntryRemoved'
                ></react-schedule-entry-popper>
            </div>
        `,
        controller: [
            '$document',
            '$element',
            '$scope',
            '$timeout',
            function ($document, $element, $scope, $timeout) {
                let $ctrl = this;
                let isSelected;

                $ctrl.$onChanges = (changes) => {
                    if (!!changes.action) {
                        $ctrl.displayTimeAndLength();
                    }
                    // if (typeof changes.isSelected !== 'undefined') {
                    //     handleSelectionChange();
                    // }
                };
                //
                // function handleSelectionChange() {
                //     if ($ctrl.isSelected === isSelected) {
                //         return;
                //     }
                //
                //     isSelected = $ctrl.isSelected;
                //
                //     if (isSelected) {
                //         console.log(
                //             'openPopper',
                //             $ctrl.action instanceof CreateAction
                //                 ? 'place'
                //                 : 'reschedule'
                //         );
                //
                //     } else if (openPopper) {
                //         closePlaceOptionsPopper();
                //     }
                // }

                // function closePlaceOptionsPopper() {
                // RootStore.stores.uiStateStore.setPopperOpen(false)

                // if (openPopper) {
                //     return new Promise((resolve, reject) => {
                //         PopperService.close(openPopper).then(function () {
                //             openPopper = undefined;
                //             resolve();
                //         });
                //     });
                // }
                //
                // return Promise.resolve();
                // }

                $ctrl.displayTimeAndLength = () => {
                    if ($ctrl.card.isMovable || $ctrl.card.isResizable) {
                        $ctrl.timeLengthLabel = $ctrl.view.periodsToTimeSummary(
                            $ctrl.card.start,
                            $ctrl.card.end
                        );
                    } else {
                        $ctrl.timeLengthLabel = null;
                    }
                };

                $ctrl.moveEventToRange = (event) => {
                    let [posX, posY] = [event.pageX, event.pageY];
                    let day =
                        $ctrl.calendar.view.days[$ctrl.view.pixelsToDays(posX)];
                    let start = $ctrl.view.pixelsToPeriods(
                        posY - $ctrl.dragTop
                    );
                    let end = start + ($ctrl.card.end - $ctrl.card.start);

                    return { day, start, end };
                };

                $ctrl.resizeEventToRange = (event) => {
                    let day = $ctrl.card.day;
                    let start = $ctrl.card.start;
                    let end = Math.max(
                        $ctrl.card.start + 1,
                        $ctrl.view.pixelsToPeriods(event.pageY + $ctrl.dragTop)
                    );

                    if (
                        end - $ctrl.card.start >
                        $ctrl.view.action.periodsAvailable
                    ) {
                        end =
                            $ctrl.card.start +
                            $ctrl.view.action.periodsAvailable;
                    } else if ($ctrl.resizeLimit !== null) {
                        let maxEnd =
                            $ctrl.card.start +
                            $ctrl.originalLength +
                            $ctrl.resizeLimit;

                        if (end > maxEnd) {
                            end = maxEnd;
                        }
                    }

                    return { day, start, end };
                };

                function onMove(event) {
                    $ctrl.preventClick = true;

                    let range = $ctrl.moveEventToRange(
                        angular.isDefined(event.originalEvent.touches) &&
                            angular.isDefined(event.originalEvent.touches[0])
                            ? event.originalEvent.touches[0]
                            : event.originalEvent
                    );

                    if ($ctrl.card.canMoveToRange(range)) {
                        $ctrl.card.moveToRange(range);
                        $ctrl.view.action.updateCard($ctrl.card);
                        $ctrl.displayTimeAndLength();
                        $scope.$apply();
                    }
                }

                function onResize(event) {
                    $ctrl.preventClick = true;

                    let range = $ctrl.resizeEventToRange(
                        $ctrl.isTouch
                            ? event.originalEvent.changedTouches[0]
                            : event.originalEvent
                    );

                    if ($ctrl.card.canMoveToRange(range)) {
                        $ctrl.card.moveToRange(range);
                        $ctrl.view.action.updateCard($ctrl.card);
                        $ctrl.displayTimeAndLength();
                        $scope.$apply();
                    }
                }

                $ctrl.isInteractable = () => {
                    return (
                        !$ctrl.preventClick &&
                        (($ctrl.view.action.canSelectCards &&
                            $ctrl.card.isFocused) ||
                            $ctrl.view.action.canPopperCards)
                    );
                };

                $ctrl.isSelectable = () => {
                    return (
                        !$ctrl.preventClick && $ctrl.view.action.canSelectCards
                    );
                };

                $ctrl.$onInit = () => {
                    $ctrl.displayTimeAndLength();

                    $ctrl.resizeLimit = $ctrl.card.resizeLimit;
                    $ctrl.originalLength = $ctrl.card.length;

                    // View an entry
                    $element.click(() => {
                        if ($ctrl.preventClick) {
                            return;
                        }

                        if ($ctrl.view.action.canSelectCards) {
                            if ($ctrl.card.isSelectable) {
                                $ctrl.view.selectCard($ctrl.card);
                                $scope.$apply();
                            }
                        }
                    });

                    $element.on('mousedown touchstart', startEvent);

                    $ctrl.view.dayWrapperElement.on(
                        'mouseup touchend',
                        (event) => {
                            if (!$ctrl.isMoving && !$ctrl.isResizing) {
                                return;
                            }

                            detachHandlers();

                            $timeout(() => {
                                $ctrl.preventClick = false;
                                $ctrl.isMoving = false;
                                $ctrl.isResizing = false;
                            });

                            $ctrl.view.action.endUpdatingCard($ctrl.card);
                            $ctrl.view.selectCard($ctrl.card);
                        }
                    );
                };

                function startEvent(event) {
                    if (!$ctrl.card.isMovable) {
                        return;
                    }

                    event = event.originalEvent;

                    isSelected = false;
                    // closePlaceOptionsPopper();

                    $ctrl.view.action.beginUpdatingCard($ctrl.card);

                    let eventName = 'mousemove';
                    let eventHandler = onMove;

                    $ctrl.isResizing = event.target.classList.contains(
                        'schedule-entry__handle'
                    );
                    $ctrl.isMoving = !$ctrl.isResizing;
                    $ctrl.preventClick = false;
                    $ctrl.isTouch = !!event.changedTouches;

                    if ($ctrl.isTouch) {
                        eventName = 'touchmove';
                        $ctrl.dragTop =
                            event.changedTouches[0].clientY -
                            event.target.getBoundingClientRect().top;
                    } else {
                        $ctrl.dragTop =
                            event.clientY -
                            event.target.getBoundingClientRect().top;
                    }

                    if ($ctrl.isResizing) {
                        eventHandler = onResize;
                    }

                    $ctrl.view.element.on(eventName, eventHandler);

                    return false;
                }

                function detachHandlers() {
                    $ctrl.view.element.off('mousemove', onMove);
                    $ctrl.view.element.off('mousemove', onResize);
                    $ctrl.view.element.off('touchmove', onMove);
                    $ctrl.view.element.off('touchmove', onResize);
                }

                $ctrl.reschedule = (params) => {
                    RootStore.stores.uiStateStore.popModal();
                    $ctrl.view.setAction(createAction($ctrl.calendar, params));
                };

                $ctrl.remove = () => {
                    $ctrl.view.removeCard($ctrl.card);
                    $scope.$apply();
                };

                $ctrl.existingEntryRemoved = (response) => {
                    $ctrl.view.removeCard($ctrl.card);
                    $ctrl.view.resetAction();
                };

                $ctrl.entryUpdated = (entry, entries = []) => {
                    $timeout(() => {
                        $ctrl.card.setEntryData(entry.scheduleResourceData);

                        if (entries.length > 0) {
                            entries.forEach((entry) => {
                                $ctrl.calendar
                                    .findCard(entry.scheduleId)
                                    ?.setEntryData(entry.scheduleResourceData);
                            });
                        }

                        $scope.$apply();
                    });
                };

                $ctrl.updateAttendance = (attendance) => {
                    $timeout(() => {
                        $ctrl.card.setFlash(attendance);
                    });
                };

                $ctrl.updateAssignedUser = (assignedUser) => {
                    $timeout(() => {
                        $ctrl.view.setCurrentCalendar(
                            !!assignedUser ? assignedUser : 'unassigned'
                        );
                    });
                };

                $ctrl.viewEntry = () => {
                    const { eid, uuid } = $ctrl.card;

                    openEntryViewModal($ctrl.card.type, {
                        eid,
                        uuid,
                        view: $ctrl.view,
                        rescheduleAction: $ctrl.reschedule,
                        onUpdate: $ctrl.entryUpdated,
                        onCancel: $ctrl.existingEntryRemoved,
                        onAttendanceUpdated: $ctrl.updateAttendance,
                        onAssignedUserChange: $ctrl.updateAssignedUser,
                    });
                };

                $scope.$on('$destroy', function () {
                    detachHandlers();
                });
            },
        ],
    });
