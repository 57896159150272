import { Item } from '.';

export class Mark extends Item {
    constructor(calendar, entry) {
        super(calendar, entry);
    }

    get isNew() {
        return false;
    }

    get isEndAfterView() {
        return this.cache.add('Items.Mark.isEndAfterView', () => {
            return this.endDate.isAfter(this.calendar.view.endDate);
        });
    }

    get isStartBeforeView() {
        return this.cache.add('Items.Mark.isStartBeforeView', () => {
            return this.startDate.isBefore(this.calendar.view.startDate);
        });
    }

    get daysInView() {
        return this.cache.add('Items.Mark.daysInView', () => {
            if (this.isStartBeforeView && this.isEndAfterView) {
                return this.calendar.view.days;
            } else if (this.isStartBeforeView) {
                return this.calendar.getDaysInRange(
                    this.calendar.view.startDate,
                    this.endDate
                );
            } else if (this.isEndAfterView) {
                return this.calendar.getDaysInRange(
                    this.startDate,
                    this.calendar.view.endDate
                );
            } else {
                return this.days;
            }
        });
    }

    get firstDay() {
        return this.cache.add('Items.Mark.firstDay', () => {
            return this.days[0];
        });
    }

    get lastDay() {
        return this.cache.add('Items.Mark.lastDay', () => {
            return this.days[this.days.length - 1];
        });
    }

    get firstWeek() {
        return this.cache.add('Items.Mark.firstWeek', () => {
            return this.weeks[0];
        });
    }

    get lastWeek() {
        return this.cache.add('Items.Mark.lastWeek', () => {
            return this.weeks[this.weeks.length - 1];
        });
    }

    get endPeriod() {
        return this.cache.add('Items.Mark.endPeriod', () => {
            let midnight = this.endDate.endOf('day');

            const end =
                this.endDate.second === 59
                    ? this.endDate.plus({ seconds: 1 })
                    : this.endDate;

            return Math.ceil(midnight.diff(end, ['minutes']).minutes / 15);
        });
    }

    get startPeriod() {
        return this.cache.add('Items.Mark.startPeriod', () => {
            let midnight = this.startDate.startOf('day');

            return this.startDate.diff(midnight, ['minutes']).minutes / 15;
        });
    }

    remove() {
        this.calendar.removeItem(this);
    }
}
