import { applySnapshot, flow, getRoot, types } from 'mobx-state-tree';
import { Expense } from '@/Models/Expense';
import ExpenseTransport from '../Services/Transport/ExpenseTransport';

export const ExpenseStore = types
    .model('ExpenseStore', {
        expenses: types.map(Expense),
    })

    .views((self) => ({
        get available() {
            return Array.from(self.expenses.values()).filter(
                (exp) => !exp.isDeleted
            );
        },

        getWithinInterval(interval) {
            return self.available.filter((exp) => interval.contains(exp.date));
        },
    }))

    .actions((self) => ({
        list: flow(function* list(requestData, withMeta = false) {
            const { data } = yield ExpenseTransport.list(requestData);
            const items = data.data.map((expense) =>
                self.createOrUpdate(expense)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        store: flow(function* store(expenseData) {
            const { data } = yield ExpenseTransport.store(expenseData);
            return self.createOrUpdate(data.data);
        }),

        destroy: flow(function* destroy(identifier) {
            const { data } = yield ExpenseTransport.destroy(identifier);
            return self.createOrUpdate(data.data);
        }),

        createOrUpdate(expenseData) {
            const expense = self.processNestedResources(expenseData);

            if (self.expenses.has(expense.uuid)) {
                const existing = self.expenses.get(expense.uuid);
                applySnapshot(existing, expense);
            } else {
                self.expenses.set(expense.uuid, expense);
            }

            return self.expenses.get(expense.uuid);
        },

        /**
         * @param expenseData
         * @returns {*}
         */
        processNestedResources(expenseData) {
            expenseData.category = getRoot(
                self
            ).stores.expenseCategoryStore.processAsNested(expenseData.category);

            return expenseData;
        },
    }));
