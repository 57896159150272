import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Client } from '@/Models/Client';
import ItemListTitleCell from '@/Components/ItemList/TitleCell';
import ItemListActionCell from '@/Components/ItemList/ActionCell';
import {
    ClientAvatar,
    ClientListItemLastActivityCell,
    ClientListItemNextActivityCell,
    ClientListItemPortalStatusCell,
    ClientOptionsMenu,
} from '@/Features/Clients';

interface Props {
    client: Client;
    className?: string;
}

const Component: FunctionComponent<Props> = ({ client, className }) => {
    return (
        <div
            className={classNames(
                className,
                'grid-cols-[36px_auto_auto] xl:grid-cols-clients-xl'
            )}
        >
            <ClientAvatar
                client={client}
                className="row-span-4 xl:row-auto"
                withLink={!client.isArchived}
            />
            <ItemListTitleCell
                url={client.isArchived ? undefined : client.url}
                title={client.fullName}
                subtitle={
                    <span
                        className={classNames({
                            'text-gray-400': !client.email,
                        })}
                    >
                        {client.email ?? 'No email'}
                    </span>
                }
            />
            <ClientListItemLastActivityCell client={client} />
            <ClientListItemNextActivityCell client={client} />
            <ClientListItemPortalStatusCell client={client} />
            <ItemListActionCell className="row-span-4">
                <ClientOptionsMenu client={client} />
            </ItemListActionCell>
        </div>
    );
};

export const ClientListItem = observer(Component);
