import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface Props {
    className?: string;
}

const Item: FunctionComponent<Props> = ({ className, children }) => {
    return (
        <div className={classNames('mr-2 flex flex-col', className)}>
            {children}
        </div>
    );
};

export default Item;
