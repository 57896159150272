import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import RootStore from '../../Stores/RootStore';
import { StyledNotice, StyledOverlay } from './Style';
import Navigation from './Navigation';
import ModalFooter from './Footer';
import ModalHeader from './Header';
import ModalContent from './Content';

ReactModal.setAppElement('#react-root');

export const WIDTHS = {
    narrow: 400,
    small: 500,
    medium: 580,
    default: 800,
};

class Modal extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleClose = this.handleClose.bind(this);
    }

    render() {
        const {
            width,
            children,
            leftAction,
            title,
            rightAction,
            closable,
            customContent,
            isConfirmation,
            'data-testid': testId,
        } = this.props;
        const { modalHidden, eventConfirm } = RootStore.stores.uiStateStore;

        // Adjust some props based on whether this is a confirmation
        const modalWidth = isConfirmation ? WIDTHS.narrow : width;
        const isCustom = customContent || isConfirmation || false;
        const contentClassNames = classNames(
            'dialog__content',
            '--new-ui',
            this.props.contentClassName,
            { '--confirmation': isConfirmation }
        );

        // Tweak component if there's a submit handler
        let WrapperEl;
        const wrapperProps = {};
        const pushed = typeof this.props.stackProps !== 'undefined';

        if (typeof this.props.onSubmit === 'function') {
            WrapperEl = 'form';
            wrapperProps.onSubmit = this.props.onSubmit;
        } else {
            WrapperEl = Fragment;
        }
        const displayOverlay = this.props.stackProps.isTop;
        const stackPosition = Math.max(
            this.props.stackProps.visiblePosition,
            -2
        );

        return (
            <div onClick={(ev) => ev.stopPropagation()}>
                <StyledOverlay
                    isOpen
                    overlayClassName={classNames(
                        'dialog__overlay',
                        this.props.overlayClassNames
                    )}
                    isHidden={modalHidden}
                    onRequestClose={this.handleClose}
                    onAfterOpen={this.props.onAfterOpen}
                    displayOverlay={displayOverlay}
                    stackPosition={stackPosition}
                >
                    <section
                        className="dialog__wrapper"
                        ref={this.setRef}
                        onClick={(ev) => ev.stopPropagation()}
                        style={{ width }}
                        data-testid={testId}
                    >
                        <div className={contentClassNames}>
                            <WrapperEl {...wrapperProps}>
                                {isCustom && children}
                                {!isCustom && (
                                    <Fragment>
                                        <ModalHeader
                                            pushed={pushed}
                                            title={title}
                                            closable={closable}
                                            cancelText={this.props.cancelText}
                                            cancelType={this.props.cancelType}
                                            leftAction={leftAction}
                                            rightAction={rightAction}
                                            content={this.props.headerContent}
                                        />
                                        <ModalContent
                                            className={classNames({
                                                'bg-white': this.props.whiteBg,
                                            })}
                                            obscure={eventConfirm}
                                        >
                                            {children}
                                        </ModalContent>
                                        <ModalFooter />
                                    </Fragment>
                                )}
                            </WrapperEl>
                        </div>
                    </section>
                </StyledOverlay>
            </div>
        );
    }

    handleClose(ev) {
        if (typeof this.props.onClose === 'function') {
            this.props.onClose();
        }
    }
}

Modal.propTypes = {
    width: PropTypes.any,
    title: PropTypes.any,
    cancelText: PropTypes.string,
    leftAction: PropTypes.func,
    rightAction: PropTypes.func,
    headerContent: PropTypes.object,
    customContent: PropTypes.bool,
    whiteBg: PropTypes.bool,
    closable: PropTypes.any,
    children: PropTypes.any,
    onClose: PropTypes.func,
    onAfterOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    isConfirmation: PropTypes.bool,
};

Modal.defaultProps = {
    width: WIDTHS.default,
};

export default observer(
    Object.assign(Modal, {
        Header: ModalHeader,
        Notice: StyledNotice,
        Footer: ModalFooter,
        Navigation: Navigation,
        Content: ModalContent,
    })
);
