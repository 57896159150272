import angular from 'angular';
import { react2angular } from 'react2angular';
import TransactionRowSummary from '../../../js/Components/Wrappers/Finances/TransactionRowSummary';

angular
    .module('component.react.transaction-row-summary', [])
    .component(
        'reactTransactionRowSummary',
        react2angular(TransactionRowSummary, [
            'amount',
            'absolute',
            'type',
            'symbol',
            'suffix',
        ])
    );
