import React, { FunctionComponent, useRef } from 'react';
import { FieldProps, useField, useFormikContext } from 'formik';
import InputGroup from '../InputGroup';
import Icon from '../Icon/Icon';
import FormFieldElements, { FormFieldElementProps } from '../FormFieldElements';
import DatePickerInput from '../DatePickerInput';
import { DayModifiers, DayPickerProps } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/types/DayPickerInput';
import { formatDate } from '../../Services/Date';
import { uiColors } from '../../Theme';
import TextInputField from '../TextInputField';
import { DateTime } from 'luxon';

interface Props extends FormFieldElementProps {
    isDisabled?: boolean;
    className?: string;
    maxDate?: Date;
}

const FormDateField: FunctionComponent<Props> = ({
    className,
    maxDate,
    isDisabled = false,
    ...props
}) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const inputRef = useRef<DayPickerInput>(null);
    const [field] = useField(props);

    /**
     * Handle when the user presses a day
     * @param day
     * @param modifiers
     */
    const handleDayClick = (day: Date, modifiers: DayModifiers) => {
        if (modifiers.disabled) return;

        setFieldValue(field.name, day);
    };

    const formattedValue = field.value
        ? formatDate(field.value, true, false)
        : field.value;
    const selectedDay = field.value
        ? DateTime.fromJSDate(field.value)
              ?.setZone('utc', { keepLocalTime: true })
              .toJSDate() ?? undefined
        : undefined;
    const pickerProps: DayPickerProps = {
        onDayClick: handleDayClick,
        selectedDays: selectedDay,
        month: selectedDay,
    };

    if (maxDate) {
        pickerProps.disabledDays = { after: maxDate };
    }

    const handleTouched = () => {
        setFieldTouched(field.name, true);
    };

    const closeOverlay = () => {
        if (!!inputRef.current) {
            inputRef.current.hideDayPicker();
        }
    };

    const openOverlay = () => {
        if (!!inputRef.current) {
            inputRef.current.showDayPicker();
        }
    };

    return (
        <FormFieldElements {...props}>
            {({ field, meta }: FieldProps) => (
                <InputGroup
                    prepend={
                        <Icon name="Calendar" fill={uiColors.text.xLight} />
                    }
                    onClick={openOverlay}
                    isDisabled={isDisabled}
                >
                    {isDisabled && (
                        <TextInputField value={formattedValue} isDisabled />
                    )}
                    {!isDisabled && (
                        <DatePickerInput
                            inputRef={inputRef}
                            inputProps={{
                                value: formattedValue,
                                placeholder: 'Select...',
                                onDayPickerHide: handleTouched,
                            }}
                            pickerProps={pickerProps}
                            closeOverlay={closeOverlay}
                            maxWidth="none"
                        />
                    )}
                </InputGroup>
            )}
        </FormFieldElements>
    );
};

export default FormDateField;
