import { flow, getRoot, types } from 'mobx-state-tree';
import { ClientReference } from './Client';
import { ClassPassBase } from './ClassPassBase';
import { DateTimeType } from '../Types/DateTime';
import { ClassReference } from '../Stores/ClassStore';
import { formatDate, inUserZone } from '../Services/Date';
import { TransactionReference } from './Transaction';
import { Enrolment } from './Enrolment';
import { ClassDateReference } from './ClassDate';
import WithPermissions from '@/Models/WithPermissions';
import ClassPassTransport from '@/Services/Transport/ClassPassTransport';

const ClassPassModel = types
    .model('ClassPass', {
        uuid: types.identifier,
        name: types.string,
        description: types.maybeNull(types.string),
        terms: types.maybeNull(types.string),
        reference: types.string,
        cost: types.number,
        enrolmentLimit: types.maybeNull(types.integer),
        classRestriction: types.array(types.late(() => ClassReference)),
        expiry: types.maybeNull(DateTimeType),
        attendanceWindow: types.maybeNull(types.string),
        attendanceWindowAnchor: types.maybeNull(types.string),

        isCredit: types.boolean,
        creditedClassDate: types.maybe(types.late(() => ClassDateReference)),

        client: types.maybe(types.late(() => ClientReference)),
        transactions: types.array(types.late(() => TransactionReference)),
        enrolments: types.array(
            types.late(() => types.safeReference(Enrolment))
        ),

        // Requestable attributes
        enrolmentCount: types.maybe(types.integer),
        remainingCredits: types.maybe(types.maybeNull(types.integer)),
        purchasedAt: DateTimeType,

        createdAt: DateTimeType,
        isActive: types.boolean,
        isDeleted: types.boolean,

        permissions: types.maybe(
            types.model({
                update: types.model({
                    purchasedAt: types.boolean,
                }),
            })
        ),
    })

    .views((self) => ({
        get humanType() {
            return 'class pass';
        },

        get packageType() {
            return 'class';
        },

        get isClassPass() {
            return true;
        },

        get url() {
            return `/class-passes/purchases/${self.uuid}`;
        },

        get hasExpiry() {
            return !!self.expiry;
        },

        get hasExpired() {
            return self.hasExpiry && self.expiry.endOf('day') < inUserZone();
        },

        get hasEnrolments() {
            return (
                typeof self.enrolmentCount !== 'undefined' &&
                self.enrolmentCount > 0
            );
        },

        get createdAtFormatted() {
            return formatDate(self.createdAt, true, true);
        },

        get purchasedAtFormatted() {
            if (!self.purchasedAt) {
                return null;
            }

            return formatDate(self.purchasedAt, true, true);
        },
    }))

    .actions((self) => ({
        destroy: flow(function* destroy() {
            const { data } = yield ClassPassTransport.destroy(self.uuid);
            return getRoot(self).stores.classPassStore.createOrUpdate(
                data.data
            );
        }),

        expire: flow(function* expire() {
            const { data } = yield ClassPassTransport.expire(self.uuid);
            getRoot(self).stores.classPassStore.createOrUpdate(data.data);
        }),

        setEnrolmentReferences(refs = []) {
            self.enrolments = refs;
        },
    }));

export const ClassPass = types.compose(
    ClassPassModel,
    ClassPassBase,
    WithPermissions
);

export const ClassPassReference = types.maybeNull(
    types.reference(ClassPass, {
        get(uuid, parent) {
            return getRoot(parent).stores.classPassStore.findOrFetch(uuid);
        },
        set({ uuid }) {
            return uuid;
        },
    })
);
