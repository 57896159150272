import React from 'react';

const CustomFinances = (props) => (
    <svg viewBox="0 0 20 20" {...props}>
        <path
            fill={props.fill}
            d="M18 0a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h16zm-8 3a1.5 1.5 0 00-1.5 1.5v11a1.5 1.5 0 003 0v-11A1.5 1.5 0 0010 3zm-4.5 7A1.5 1.5 0 004 11.5v4a1.5 1.5 0 003 0v-4A1.5 1.5 0 005.5 10zm9-3A1.5 1.5 0 0013 8.5v7a1.5 1.5 0 003 0v-7A1.5 1.5 0 0014.5 7z"
            fillRule="evenodd"
        />
    </svg>
);

export default CustomFinances;
