import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import Notification from '@/Services/Notification';
import ArchiveRestoreButton from '@/Components/ArchiveRestoreButton';
import queryClient from '@/Modules/QueryClient';
import RootStore from '@/Stores/RootStore';
import history from '@/Modules/History';
import { queryKeys } from '@/Services/QueryKeys';

interface Props {
    client: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    const { uiStateStore, currentUserStore } = RootStore.stores;

    async function handleArchive() {
        await client.archive();
        await queryClient.invalidateQueries(queryKeys.clients.all);

        Notification.success('Client archived');

        history.push(`/clients/archive`);
    }

    async function handleRestore() {
        await currentUserStore.refreshActiveClientCount();

        if (!currentUserStore.canIncreaseClientCount) {
            uiStateStore.pushModal('ACCOUNT_UPGRADE');
            return;
        }

        await client.restore();
        await queryClient.invalidateQueries(queryKeys.clients.all);

        Notification.success('Client restored');

        history.push(`/clients/roster/${client.eid}`);
    }

    return (
        <ArchiveRestoreButton
            variant="menu"
            isDisabled={client.isActive}
            isArchived={client.isArchived}
            onArchive={handleArchive}
            onRestore={handleRestore}
            content={<p>Are you sure you want to archive this client?</p>}
        />
    );
};

export const ArchiveClientButton = observer(Component);
