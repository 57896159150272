import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import EntryCard from '../EntryCard/EntryCard';
import { StyledAvatarContainer, StyledAvatarIcon } from './Style';
import EntryRemindButton from '../Entry/RemindButton';
import { CreateClientButton } from '@/Features/Clients';
import { DismissConsultationButton } from '@/Features/Consultations';

const ConsultationEntryCard = ({
    withAvatar,
    withLink,
    withName,
    consultation,
    accessory,
    ...props
}) => {
    const getTitle = () => {
        if (withName) {
            return consultation.label;
        }
    };

    const getAccessory = () => {
        if (typeof accessory === 'function') {
            return accessory();
        } else if (props.withReminderAccessory === true) {
            return () => (
                <EntryRemindButton
                    entry={consultation}
                    type="consultation"
                    isResponsive
                />
            );
        } else if (props.withPastAccessory === true) {
            return () => (
                <Fragment>
                    <div style={{ marginRight: 8 }}>
                        <CreateClientButton
                            consultation={consultation}
                            onSuccess={props.onDismissed}
                            hideIcon
                        >
                            Sign up
                        </CreateClientButton>
                    </div>
                    <DismissConsultationButton
                        consultation={consultation}
                        type="dismiss"
                        onDismiss={props.onDismissed}
                    />
                </Fragment>
            );
        }
    };

    const renderAvatar = () => {
        if (!withAvatar) {
            return null;
        }

        return () => (
            <StyledAvatarContainer>
                <StyledAvatarIcon />
            </StyledAvatarContainer>
        );
    };

    return (
        <EntryCard
            type="consultation"
            entry={consultation}
            withLocation
            withDuration
            title={getTitle()}
            withLink={withLink}
            avatar={renderAvatar()}
            accessory={getAccessory()}
        />
    );
};

ConsultationEntryCard.propTypes = {
    withAvatar: PropTypes.bool,
    withName: PropTypes.bool,
    withLink: PropTypes.bool,
    withReminderAccessory: PropTypes.bool,
    withPastAccessory: PropTypes.bool,
    consultation: PropTypes.object,
    accessory: PropTypes.func,

    // Angular helpers
    dismissalType: PropTypes.oneOf(['incomplete', 'cancelled']),
    onDismissed: PropTypes.func,
};

ConsultationEntryCard.defaultProps = {
    withLink: true,
};

export default observer(ConsultationEntryCard);
