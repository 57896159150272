import React, { FunctionComponent } from 'react';
import RootStore from '@/Stores/RootStore';
import { PrimaryButton, PrimaryResponsiveButton } from '@/Components/Button';

export interface CreateEntityButtonProps {
    isResponsive?: boolean;
    className?: string;
}

interface Props extends CreateEntityButtonProps {
    modal: string;
}

const CreateEntityButton: FunctionComponent<Props> = ({
    modal,
    isResponsive = false,
    className,
    children,
    ...modalProps
}) => {
    const { uiStateStore } = RootStore.stores;

    const handleClick = () => {
        uiStateStore.pushModal(modal, modalProps);
    };

    const buttonProps = {
        type: 'button' as 'button' | 'submit' | undefined,
        onClick: handleClick,
        icon: 'Plus',
        children,
        className,
    };

    return (
        <>
            {isResponsive && <PrimaryResponsiveButton {...buttonProps} />}
            {!isResponsive && <PrimaryButton {...buttonProps} />}
        </>
    );
};

export default CreateEntityButton;
