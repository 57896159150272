import { types } from 'mobx-state-tree';
import ClassBase from './ClassBase';
import WithLocation from './WithLocation';
import ClassPrivateBase from './ClassPrivateBase';
import { Currency } from './Currency';

const ClassPrivate = types
    .model({
        hasLimitedSpaces: types.boolean,
        maxEnrolments: types.maybeNull(types.integer),

        enrolmentCost: types.maybeNull(types.integer),
        payableAtLocation: types.boolean,
        cancellationPolicy: types.integer,
        singleEnrolmentEnabled: types.boolean,

        // Optional properties
        totalClientReach: types.maybe(types.integer),
        averageAttendance: types.maybe(types.maybeNull(types.number)),

        currency: types.maybeNull(Currency),

        // Available includes
        plannedDatesCount: types.maybeNull(types.integer),
        deliveredDatesCount: types.maybeNull(types.integer),
    })

    .views((self) => ({
        get humanEntityType() {
            return 'Private class';
        },

        /**
         * These are properties that a Class date can share with its parent class
         * @returns {{references: {class: *}, fee: *, name: string, class: *, estimatedFee: *}}
         */
        get inheritableProperties() {
            return {
                class: self.uuid,
                classType: 'private',
                name: self.name,
                maxEnrolments: self.maxEnrolments,
                enrolmentCost: self.enrolmentCost,
                payableAtLocation: self.payableAtLocation,
                cancellationPolicy: self.cancellationPolicy,
                references: { class: self.uuid },
            };
        },
    }));

const ComposedClassPrivate = types.compose(
    'ClassPrivate',
    ClassBase,
    ClassPrivateBase,
    ClassPrivate,
    WithLocation
);

export default ComposedClassPrivate;
