import styled from 'styled-components';
import { uiColors } from '../../../Theme';
import ListItemDetails from '../../ListItemDetails';

export const StyledContainer = styled(ListItemDetails)`
    height: 100%;
    margin-right: 8px;
    text-align: right;
`;

export const StyledAmount = styled(ListItemDetails.Title)`
    white-space: nowrap;

    ${(p) =>
        p.type === 'positive' &&
        `
        color: ${uiColors.positive.default};
    `}
    ${(p) =>
        p.type === 'negative' &&
        `
        color: ${uiColors.negative.default};
    `}
`;
