import React, { Fragment, FunctionComponent } from 'react';
import { Button, ClearButton } from '../Button';
import { uiColors } from '../../Theme';
import history from '@/Modules/History';
import { getFormattedUrl } from '@/Services/Schedule';
import useTeam from '@/Hooks/useTeam';
import RootStore from '@/Stores/RootStore';

export type CalendarSetRescheduleAction = (params: any) => void;

interface Props {
    entry: any;
    variant?: 'icon' | 'full';
    action?: CalendarSetRescheduleAction;
}

const EntryRescheduleButton: FunctionComponent<Props> = ({
    entry,
    action,
    variant = 'icon',
}) => {
    const { hasTeamMembers } = useTeam();
    const { currentUserStore } = RootStore.stores;

    const { type, start } = entry;
    const identifierType = type === 'class-date' ? 'uuid' : 'eid';

    const buttonParams = {
        icon: 'Move',
        fill: uiColors.scheduleEntities.new,
        onClick: handleClick,
    };

    function handleClick() {
        const assignedUser = entry.assignedUsers?.[0]?.uuid;
        const commonParams = {
            [identifierType]: entry[identifierType],
            type,
            action: 'move',
            date: entry.start.toISODate(),
            calendar:
                assignedUser ??
                (hasTeamMembers
                    ? 'unassigned'
                    : currentUserStore.organisation.organisationUser.uuid),
        };

        if (type === 'class-date') {
            commonParams.class = entry.references.class;
        } else if (type === 'session') {
            commonParams['session-pack'] = entry.references.sessionPack;
        }

        // if (assignedUser) {
        //     commonParams.calendar = assignedUser;
        // } else if (hasTeamMembers) {
        //     commonParams.calendar = 'blank';
        // }

        if (typeof action === 'function') {
            action({
                ...commonParams,
                year: entry.start.toFormat('kkkk'),
                week: `W${entry.start.toFormat('WW')}`,
            });
        } else {
            history.push(
                getFormattedUrl(start, 'week', {
                    ...commonParams,
                    date: entry.start.toISODate(),
                })
            );
        }
    }

    return (
        <>
            {variant === 'icon' && <Button {...buttonParams} />}
            {variant === 'full' && (
                <ClearButton {...buttonParams}>Reschedule</ClearButton>
            )}
        </>
    );
};

export default EntryRescheduleButton;
