import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import config from '../../Config';
import Auth from '../../Modules/Auth';
import { displayUpdateNotification } from '@/Services/App';
import qs from 'qs';

axios.defaults.withCredentials = true;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.response.use(
    (success) => {
        const { headers } = success;

        displayUpdateNotification(headers['x-client-version']);

        return success;
    },
    (error) => {
        const { response } = error;

        if (response.status === 401 || response.status === 419) {
            return Auth.goToSignIn();
        }

        return Promise.reject(error);
    }
);

interface CurrentRequestMap {
    [key: string]: CancelTokenSource;
}

export default class BaseTransport {
    currentRequests: CurrentRequestMap = {};
    baseUrl = config.apiUrl;
    //
    // constructor() {
    //     this.setInterceptors();
    // }
    //
    // setInterceptors() {
    //
    // }

    request(requestConfig: AxiosRequestConfig) {
        return axios.request({
            baseURL: this.baseUrl,
            ...requestConfig,
        });
    }

    once(config: AxiosRequestConfig = {}) {
        if (typeof this.currentRequests[config.url!] !== 'undefined') {
            this.currentRequests[config.url!]!.cancel(
                'Only one request allowed at a time.'
            );
            delete this.currentRequests[config.url!];
        }

        this.currentRequests[config.url!] = axios.CancelToken.source();

        config.cancelToken = this.currentRequests[config.url!]!.token;
        return this.request(config);
    }

    async get(url: string, params: any = {}, config = {}) {
        return await this.request({
            method: 'get',
            url,
            params,
            ...config,
        });
    }

    async post(url: string, data: any = {}, config = {}) {
        return await this.request({
            method: 'post',
            url,
            data,
            ...config,
        });
    }

    async put(url: string, data: any = {}) {
        return await this.request({
            method: 'put',
            url,
            data,
        });
    }

    async delete(url: string) {
        return await this.request({
            method: 'delete',
            url,
        });
    }
}

export function paramsSerializer(params: any) {
    if (Array.isArray(params?.include)) {
        params.include = params.include.join(',');
    }

    return qs.stringify(params, {
        arrayFormat: 'repeat',
        encodeValuesOnly: true,
    });
}
