import React, { FunctionComponent, useContext } from 'react';
import { ClearButton } from '@/Components/Button';
import { ClassDate } from '@/Models/ClassDate';
import { Session } from '@/Models/Session';
import { observer } from 'mobx-react';
import useAuth from '@/Hooks/useAuth';
import { mdiMinusCircle, mdiPlusCircle } from '@mdi/js';
import { Icon } from '@mdi/react';
import EventConfirmationModalContext from '@/Contexts/EventConfirmationModalContext';
import queryClient from '@/Modules/QueryClient';
import { queryKeys } from '@/Services/QueryKeys';
import Notification from '@/Services/Notification';
import ClassDateTransport from '@/Services/Transport/ClassDateTransport';
import SessionTransport from '@/Services/Transport/SessionTransport';
import useTeam from '@/Hooks/useTeam';

interface Props {
    entry: ClassDate | Session;
}

const Component: FunctionComponent<Props> = ({ entry }) => {
    const { hasTeamMembers } = useTeam();
    const { hasRole } = useAuth();
    const confirmationContext = useContext(EventConfirmationModalContext);

    async function toggleCoverList() {
        const data = {
            assignedUsers: [],
            isAvailableToAssign: !entry.isAvailableToAssign,
        };

        if (entry.type === 'class-date') {
            await ClassDateTransport.assignUsers(entry.uuid, data);
        } else {
            await SessionTransport.assignUsers(entry.eid, data);
        }

        await queryClient.invalidateQueries(queryKeys.availableEvents);

        Notification.success('Cover list updated');
    }

    function handleClick() {
        confirmationContext.requireConfirmation({
            content: () => (
                <p className="mb-2">
                    Are you sure you want to{' '}
                    {entry.isAvailableToAssign
                        ? 'remove this from'
                        : 'add this to'}{' '}
                    the cover list?
                </p>
            ),
            isPositive: true,
            onConfirmed: toggleCoverList,
        });

        // RootStore.stores.uiStateStore.pushModal('ASSIGN_TEACHER', {
        //     event: entry,
        // });
    }

    if (!hasRole('Owner') || !hasTeamMembers) {
        return null;
    }

    return (
        <ClearButton onClick={handleClick}>
            <Icon
                path={
                    entry.isAvailableToAssign ? mdiMinusCircle : mdiPlusCircle
                }
                className="mr-1.5 h-4 w-4 fill-current text-icon"
            />
            {entry.isAvailableToAssign ? 'Remove from' : 'Add to'} cover list
        </ClearButton>
    );
};

export const ToggleScheduleEventAvailableButton = observer(Component);
