import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface Props {
    className?: string;
}

const Heading: FunctionComponent<Props> = ({ className, children }) => {
    return (
        <h2
            className={classNames(
                'm-0 text-base font-bold text-gray-600 2xl:text-lg'
            )}
        >
            {children}
        </h2>
    );
};

export default Heading;
