import React, { FunctionComponent, HTMLProps } from 'react';
import classNames from 'classnames';

interface Props extends HTMLProps<HTMLTextAreaElement> {
    placeholder?: string;
    readOnly?: boolean;
    value?: string;
    className?: string;
    isDisabled?: boolean;
}

const TextAreaField: FunctionComponent<Props> = ({
    className,
    isDisabled,
    ...props
}) => {
    return (
        <textarea
            className={classNames('input', '--height-l', className)}
            disabled={isDisabled}
            {...props}
        />
    );
};

export default TextAreaField;
