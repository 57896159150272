import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../Theme';
import Menu from '../Menu';

const Navigation = styled(Menu)`
    padding: 20px;
    margin: -20px;
    background: #fff;
    border-radius: 0 0 4px 4px;

    ${(p) =>
        p.bordered &&
        css`
            border-top: 1px solid ${colors.grey.xLight};
        `}
    ${(p) =>
        p.type === 'grid' &&
        css`
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        `}
`;

Navigation.propTypes = {
    bordered: PropTypes.bool,
    type: PropTypes.oneOf(['grid']),
};

export default Navigation;
