import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { StyledAmount, StyledContainer } from './Style';
import ListItemDetails from '../../ListItemDetails';
import { currencyDisplay } from '../../../Services/Currency';
import { CurrencyInstance } from '@/Models/Currency';

interface Props {
    amount: number;
    absolute?: boolean;
    type?: 'positive' | 'negative';
    currency: CurrencyInstance;
    suffix?: string;
}

const TransactionRowSummary: FunctionComponent<Props> = React.memo(
    ({ amount, absolute = false, type, currency, suffix }) => {
        const transactionAmount = Number(amount);

        if (typeof type === 'undefined') {
            type = amount >= 0 ? 'positive' : 'negative';
        }

        const sign = absolute ? null : type === 'positive' ? '+ ' : '- ';

        return (
            <StyledContainer>
                <StyledAmount type={type}>
                    {sign}
                    {currencyDisplay(transactionAmount, currency)}
                </StyledAmount>
                {suffix && (
                    <ListItemDetails.Subtitle className="+text-nowrap">
                        {suffix}
                    </ListItemDetails.Subtitle>
                )}
            </StyledContainer>
        );
    }
);

TransactionRowSummary.propTypes = {
    absolute: PropTypes.bool,
};

TransactionRowSummary.defaultProps = {
    absolute: false,
};

export default TransactionRowSummary;
