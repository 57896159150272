import React from 'react';

const CustomHolidays = (props) => (
    <svg viewBox="0 0 16 16" {...props}>
        <path
            fill={props.fill}
            d="M13.186 14.73c.204-.204.315-.605.252-.88l-1.764-7.643 3.45-3.45c.501-.5.501-1.379 0-1.88-.502-.502-1.38-.502-1.882 0l-3.45 3.449L2.15 2.562a1.045 1.045 0 00-.88.252l-.514.515c-.204.204-.166.477.104.618L6.97 7.148l-3.449 3.45-1.926-.276a.611.611 0 00-.45.143l-.578.578c-.1.1-.088.234.04.305l2.6 1.445 1.445 2.6c.068.123.21.135.305.04l.578-.577c.1-.1.164-.3.143-.451l-.275-1.926 3.449-3.45 3.201 6.112c.137.262.412.31.618.104l.515-.515z"
            fillRule="evenodd"
        />
    </svg>
);

export default CustomHolidays;
