import React, { FunctionComponent, HTMLProps } from 'react';
import classNames from 'classnames';
import Label, { LabelType } from '@/Components/FormField/Label';
import Options, { OptionsType } from '@/Components/FormField/Options';
import Children, { ChildrenType } from '@/Components/FormField/Children';
import Row, { RowType } from '@/Components/FormField/Row';
import Info, { InfoType } from '@/Components/FormField/Info';
import Error, { ErrorType } from '@/Components/FormField/Error';
import Input, { InputType } from '@/Components/FormField/Input';

interface FormFieldFunctionComponent<P> extends FunctionComponent<P> {
    Label: LabelType;
    Options: OptionsType;
    Children: ChildrenType;
    Row: RowType;
    Info: InfoType;
    Error: ErrorType;
    Input: InputType;
}

const FormField: FormFieldFunctionComponent<HTMLProps<HTMLDivElement>> = ({
    className,
    ...props
}) => <div className={classNames('form-field', className)} {...props} />;

FormField.Label = Label;
FormField.Options = Options;
FormField.Children = Children;
FormField.Row = Row;
FormField.Info = Info;
FormField.Error = Error;
FormField.Input = Input;

export default FormField;
