import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import useMicroStore from '@/Stores/useMicroStore';
import useAuth from '../Hooks/useAuth';
import RootStore from '../Stores/RootStore';
import { observer } from 'mobx-react';
import NoOrganisationScreen from '../Screens/NoOrganisation';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const authenticated = useMicroStore((state) => state.authenticated);

    return (
        <Route
            {...rest}
            render={(props) =>
                authenticated ? (
                    <Component {...props} {...rest} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/auth/sign-in',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export const RouteRequiringActiveOrganisation = observer(
    ({ component: Component, requiresRole, ...rest }) => {
        const { organisation } = RootStore.stores.currentUserStore;
        const { hasRole } = useAuth();

        if (!organisation) return <NoOrganisationScreen />;

        const redirectUrl = hasRole('Owner')
            ? '/organisation/settings/subscription'
            : '/organisation/inactive';

        return (
            <Route
                {...rest}
                render={(props) =>
                    organisation.isActive ? (
                        requiresRole ? (
                            <RouteRequiringRole
                                role={requiresRole}
                                component={Component}
                                {...props}
                                {...rest}
                            />
                        ) : (
                            <Component {...props} {...rest} />
                        )
                    ) : (
                        <Redirect
                            to={{
                                pathname: redirectUrl,
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            />
        );
    }
);

export const RouteRequiringRole = ({ component: Component, role, ...rest }) => {
    const { hasRole } = useAuth();

    return (
        <Route
            {...rest}
            render={(props) =>
                hasRole(role) ? (
                    <Component {...props} {...rest} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

// export const PublicRoute = ({ component: Component, ...rest }) => (
//     <Route {...rest} render={props => (
//         Auth.isUserAuthenticated() ? (
//             <Redirect to={{
//                 pathname: '/',
//                 state: { from: props.location }
//             }}/>
//         ) : (
//             <Component {...props} {...rest} />
//         )
//     )}/>
// );
//
// export const PropsRoute = ({ component: Component, ...rest }) => (
//     <Route {...rest} render={props => (
//         <Component {...props} {...rest} />
//     )}/>
// );
