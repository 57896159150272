const config = {
    environment: import.meta.env.VITE_ENV,
    apiUrl: `${import.meta.env.VITE_API_URL}/pro`,
    appUrl: import.meta.env.VITE_APP_URL,
    webUrl: import.meta.env.VITE_WEB_URL,
    signupUrl: import.meta.env.VITE_SIGNUP_URL,
    pusherAppKey: import.meta.env.VITE_PUSHER_KEY,
};

export default config;
