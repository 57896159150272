import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, uiColors } from '../../Theme';
import * as Icons from './icons/';

export const IconRing = styled.div`
    display: inline-flex;
    width: 40px;
    height: 40px;
    border: 2px solid ${colors.core.brandLight};
    border-radius: 100%;
    justify-content: center;
    align-items: center;
`;

export const StyledIcon = styled.i`
    display: inline-flex;
    align-content: center;
    fill: ${uiColors.button.icon};
`;

const Icon = ({ setDimensions = true, ...props }) => {
    const IconSvg = Icons[props.name];

    return (
        <StyledIcon {...props}>
            <IconSvg
                width={setDimensions ? props.width : undefined}
                height={setDimensions ? props.height : undefined}
                fill={props.fill}
            />
        </StyledIcon>
    );
};

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    fill: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

Icon.defaultProps = {
    width: 20,
    height: 20,
};

export default Icon;
