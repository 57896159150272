import { types } from 'mobx-state-tree';
import { DateTimeType } from '@/Types/DateTime';

export const ClientDataReview = types
    .model('ClientDataReview', {
        uuid: types.identifier,
        action: types.string,
        reviewedAt: types.maybeNull(DateTimeType),
        createdAt: DateTimeType,
    })

    .views((self) => ({}));
