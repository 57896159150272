import React, { FunctionComponent } from 'react';
import { Button, ClearButton } from '../../Button';
import { Session } from '../../../Models/Session';
import RootStore from '../../../Stores/RootStore';
import { uiColors } from '../../../Theme';
import useAuth from '@/Hooks/useAuth';

interface Props {
    sessionId: string;
    onSuccess?(sessions: Session[]): void;
    variant?: 'default' | 'icon';
}

const SessionCancelButton: FunctionComponent<Props> = ({
    sessionId,
    variant = 'default',
    onSuccess,
}) => {
    const { uiStateStore } = RootStore.stores;
    const { hasRole } = useAuth();

    if (!hasRole('Owner')) {
        return null;
    }

    async function handleClick() {
        uiStateStore.pushModal('SESSION_CANCEL', { sessionId, onSuccess });
    }

    const buttonProps = {
        icon: 'CloseCircle',
        onClick: handleClick,
        fill: uiColors.negative.default,
    };

    if (variant === 'icon') {
        return <Button {...buttonProps} />;
    }

    return <ClearButton {...buttonProps}>Cancel</ClearButton>;
};

export default SessionCancelButton;
