import angular from 'angular';

angular
    .module('component.wizard.forwards-button', [])

    .component('wizardForwardsButton', {
        bindings: {
            buttonText: '@',
            buttonStyle: '@',
        },
        template: `<a class="button --positive [[ $ctrl.style ]]" ng-click="$ctrl.wizard.forwards()" load-button="$ctrl.wizard.navigatingForwards" ng-transclude>[[ $ctrl.text ]]</a>`,
        transclude: true,
        require: {
            wizard: '^wizard',
        },
        controller: [
            function () {
                let $ctrl = this;

                $ctrl.text = angular.isDefined($ctrl.buttonText)
                    ? $ctrl.buttonText
                    : 'Continue';
                $ctrl.style = angular.isDefined($ctrl.buttonStyle)
                    ? $ctrl.buttonStyle
                    : '--continue';
            },
        ],
    });
