import React, { FunctionComponent, ReactNode, useContext } from 'react';
import { observer } from 'mobx-react';
import {
    ScheduleEventAvailabilityListAlerts,
    ScheduleEventAvailabilityListItem,
    ScheduleEventAvailabilityListItemProps,
} from '@/Features/Schedule';
import useTeam from '@/Hooks/useTeam';
import FormField from '@/Components/FormField';
import PlanScheduleEventsContext from '@/Contexts/PlanScheduleEventsContext';
import Checkbox from '@/Components/Checkbox';
import ActionBar from '@/Components/ActionBar';
import Loader from '@/Components/Loader';

export interface ScheduleEventAvailabilityListProps
    extends Omit<
        ScheduleEventAvailabilityListItemProps,
        'event' | 'organisationUsers'
    > {
    title?: string;
    subtitle?: string;
    eventType: 'session' | 'date' | 'appointment';
    zcsButton?: ReactNode;
}

const Component: FunctionComponent<ScheduleEventAvailabilityListProps> = ({
    title,
    subtitle,
    eventType,
    zcsButton,
    ...props
}) => {
    const { hasTeamMembers } = useTeam();
    const {
        events,
        isCheckingAvailability,
        hasConflicts,
        makeUnassignedAvailable,
        setMakeUnassignedAvailable,
    } = useContext(PlanScheduleEventsContext);

    return (
        <>
            <ActionBar>
                <ActionBar.Item>
                    <h2 className="ui-action-bar__title --with-subtitle">
                        {title}
                    </h2>
                    {!!subtitle && <p>{subtitle}</p>}
                </ActionBar.Item>
                {isCheckingAvailability && (
                    <ActionBar.Group right className="flex items-center">
                        <Loader />
                        <span className="ml-2">Checking availability...</span>
                    </ActionBar.Group>
                )}
            </ActionBar>

            <div className="mt-3">
                {events.length > 0 && (
                    <>
                        <ScheduleEventAvailabilityListAlerts
                            eventType={eventType}
                        />

                        <div data-testid="sessions-grid" className="my-4">
                            {events.map((event) => (
                                <ScheduleEventAvailabilityListItem
                                    key={event.id}
                                    event={event}
                                    {...props}
                                />
                            ))}
                        </div>
                    </>
                )}

                {zcsButton}

                {props.allowAssignment && hasTeamMembers && hasConflicts && (
                    <FormField className="mt-6">
                        <Checkbox
                            label="Add unassigned dates to the cover list."
                            checked={makeUnassignedAvailable}
                            onChange={() =>
                                setMakeUnassignedAvailable(
                                    !makeUnassignedAvailable
                                )
                            }
                        />
                    </FormField>
                )}
            </div>
        </>
    );
};

export const ScheduleEventAvailabilityList = observer(Component);
