import { flow, getRoot, Instance, types } from 'mobx-state-tree';
import { WorkingPeriod } from '@/Models/WorkingPeriod';
import OrganisationTransport from '@/Services/Transport/OrganisationTransport';
import { LocationReference } from '@/Models/Location';
import { Locale } from '@/Models/Locale';
import { DateTime, Interval } from 'luxon';
import { DateTimeType } from '@/Types/DateTime';
import { inUserZone } from '@/Services/Date';
import { countInflect } from '@/Services/String';
import { Organisation } from '@/Models/Organisation';
import { OrganisationUser } from '@/Models/OrganisationUser';

export interface CurrentOrganisationInstance
    extends Instance<typeof CurrentOrganisation> {}

const CurrentOrganisationModel = types
    .model({
        businessIntro: types.maybeNull(types.string),
        phoneNumber: types.maybeNull(types.string),

        address1: types.maybeNull(types.string),
        address2: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        postcode: types.maybeNull(types.string),

        locale: types.maybeNull(Locale),

        calendarUrl: types.maybe(types.string),
        brandColor: types.maybeNull(types.string),

        socialFacebook: types.maybeNull(types.string),
        socialTwitter: types.maybeNull(types.string),
        socialInstagram: types.maybeNull(types.string),
        socialTikTok: types.maybeNull(types.string),

        monthlySummaryEmailEnabled: types.boolean,
        defaultEventLength: types.integer,
        defaultLocation: types.maybeNull(LocationReference),
        defaultSessionPackLength: types.integer,
        defaultCancellationPolicy: types.integer,
        signupsRequireParq: types.boolean,
        targetHourlyRate: types.maybeNull(types.integer),
        paymentTerms: types.maybeNull(types.string),

        referencePrefix: types.string,
        nextReferenceNumber: types.maybeNull(types.integer),

        hasStripeProvider: false,
        hasPartnersAvailable: false,
        hasStripeConnectAvailable: false,
        canReceivePayment: false,

        portalEnabled: false,
        portalName: types.string,
        portalDomain: types.maybeNull(types.string),
        portalRegistrationEnabled: false,
        portalLoginEnabled: false,
        portalLogoutUrl: types.maybeNull(types.string),
        portalDisplaySchedule: false,
        portalDisplayPackages: false,
        portalScheduleProjection: 'P2W',
        portalPackageTypeOrder: types.array(types.string),

        referralUrl: types.string,

        workingPeriods: types.array(WorkingPeriod),

        smsStatus: types.enumeration(['enabled', 'disabled']),
        smsCredits: types.integer,

        cardBrand: types.maybeNull(types.string),
        cardLastFour: types.maybeNull(types.string),

        plan: types.maybeNull(
            types.model({
                name: types.string,
                clientLimit: types.maybeNull(types.integer),
            })
        ),
        isSubscribed: false,
        hasEverSubscribed: false,
        onTrial: false,
        onGracePeriod: false,
        gracePeriodEndsAt: types.maybeNull(DateTimeType),
        hasExtendedTrial: false,
        trialEndsAt: types.maybeNull(DateTimeType),
        activeClientCount: 0,

        organisationUser: types.maybe(types.reference(OrganisationUser)),

        guides: types.maybeNull(types.array(types.string)),
    })

    .views((self) => ({
        get isCurrent() {
            return (
                self.uuid ===
                getRoot(self).stores.currentUserStore.organisation?.uuid
            );
        },

        get hasSmsCredits() {
            return self.smsCredits > 0;
        },

        get hasCardOnFile() {
            return !!self.cardLastFour;
        },

        get hasExpiredTrial() {
            return !self.onTrial && !self.isSubscribed;
        },

        get trialDaysRemaining() {
            if (!(self.trialEndsAt instanceof DateTime)) {
                return 0;
            }

            return Math.floor(
                Interval.fromDateTimes(
                    inUserZone(),
                    inUserZone(self.trialEndsAt)
                )
                    .toDuration()
                    .as('days')
            );
        },

        get trialEndingString() {
            const days = this.trialDaysRemaining;

            if (days === 0) {
                return 'today';
            }
            if (days === 1) {
                return 'tomorrow';
            }

            return `in ${countInflect(days, 'day', 'days')}`;
        },

        get trialIsExtendable() {
            if (
                self.hasExtendedTrial ||
                !(self.trialEndsAt instanceof DateTime)
            ) {
                return false;
            }

            const expiryDays = Math.floor(
                Interval.fromDateTimes(
                    // @ts-ignore
                    inUserZone(self.trialEndsAt),
                    inUserZone()
                )
                    .toDuration()
                    .as('days')
            );

            return (
                this.hasExpiredTrial && !isNaN(expiryDays) && expiryDays >= 7
            );
        },

        get shouldDisplayGuide() {
            return Array.isArray(this.guides) && this.guides.length > 0;
        },
    }))

    .actions((self) => {
        const updateData = (data: any) => {
            getRoot(self).stores.currentUserStore.updateOrganisationData(data);
        };

        const updateProperty = (property: string, value: any) => {
            // @ts-ignore
            self[property] = value;
        };

        return {
            updateData,
            updateProperty,
            updateWorkingPeriods: flow(function* updateWorkingPeriods(values) {
                const {
                    data: { data },
                } = yield OrganisationTransport.updateWorkingPeriods(
                    values.workingPeriods
                );

                if (typeof data !== 'undefined') {
                    self.workingPeriods = data;
                }
            }),

            updateConfiguration: flow(function* updateConfiguration(values) {
                const {
                    data: { data },
                } = yield OrganisationTransport.updateConfiguration(values);

                updateData(data);
            }),

            updateClientPortal: flow(function* updateClientPortal(values) {
                const {
                    data: { data },
                } = yield OrganisationTransport.updateClientPortal(values);

                updateData(data);
            }),

            updateStriivePayments: flow(function* updateStriivePayments(state) {
                const { data } =
                    yield OrganisationTransport.updateStriivePayments(state);

                updateProperty('canReceivePayment', data.canReceivePayment);
            }),

            updateSmsSubscription: flow(function* updateSmsSubscription(
                subData
            ) {
                const {
                    data: { data },
                } = yield OrganisationTransport.updateSmsSubscription(subData);

                updateData(data);
            }),

            updatePaymentMethod: flow(function* updatePaymentMethod(subData) {
                const {
                    data: { data },
                } = yield OrganisationTransport.updatePaymentMethod(subData);

                updateData(data);
            }),

            updateSubscription: flow(function* updateSubscription(subData) {
                let { data } = yield OrganisationTransport.updateSubscription(
                    subData
                );
                updateData(data.data);
            }),

            createSubscription: flow(function* createSubscription(subData) {
                let { data } = yield OrganisationTransport.createSubscription(
                    subData
                );
                updateData(data.data);
            }),

            cancelSubscription: flow(function* cancelSubscription(subData) {
                let { data } = yield OrganisationTransport.cancelSubscription(
                    subData
                );
                updateData(data.data);
            }),

            extendTrial: flow(function* extendTrial() {
                const { data } = yield OrganisationTransport.extendTrial();
                updateData(data.data);
            }),

            resetCalendarUrl: flow(function* resetCalendarUrl() {
                const { data } = yield OrganisationTransport.resetCalendarUrl();
                updateProperty('calendarUrl', data.url);
            }),
        };
    });

export const CurrentOrganisation = types.compose(
    'CurrentOrganisation',
    CurrentOrganisationModel,
    Organisation
);
