import { Cache } from '../Cache';
import { DateTime } from 'luxon';
import { inUserZone } from '../../../../js/Services/Date';

let instanceCount = 0;

export class Item {
    constructor(calendar, entry) {
        this.cache = new Cache();
        this.calendar = calendar;
        this._entry = entry;

        if (!(this.entry.start_at instanceof DateTime)) {
            this.entry.start_at = inUserZone(this.entry.start);
        }
        if (!(this.entry.end_at instanceof DateTime)) {
            this.entry.end_at = inUserZone(this.entry.end);
        }

        this.instanceCount = instanceCount++;
    }

    get internalId() {
        return (
            this.scheduleId ?? `new-${this.entry.type}-${this.instanceCount}`
        );
    }

    get scheduleId() {
        return this.entry.scheduleId;
    }

    get eid() {
        return this.entry.type !== 'class-date' ? this.entry.id : undefined;
    }

    get uuid() {
        return this.entry.type === 'class-date' ? this.entry.id : undefined;
    }

    get type() {
        return this.entry.type;
    }

    get label() {
        return this.entry.label;
    }

    set type(type) {
        this.entry.type = type;
    }

    get entry() {
        return this._entry;
    }

    set entry(value) {
        this._entry = value;
        this.clearCache();
    }

    get startDate() {
        return this.entry.start_at;
    }

    get endDate() {
        return this.entry.end_at;
    }

    get day() {
        return this.cache.add('Items.Item.day', () => {
            return this.calendar.getDay(this.startDate);
        });
    }

    get days() {
        return this.cache.add('Items.Item.days', () => {
            return this.calendar.getDaysInRange(this.startDate, this.endDate);
        });
    }

    get week() {
        return this.cache.add('Items.Item.week', () => {
            return this.calendar.getWeek(this.startDate);
        });
    }

    get weeks() {
        return this.cache.add('Items.Item.weeks', () => {
            return this.calendar.getWeeksInRange(this.startDate, this.endDate);
        });
    }

    is(type) {
        return Array.isArray(type)
            ? type.includes(this.type)
            : this.type === type;
    }

    clearCache() {
        this.cache.clear();
    }
}
