import { MultiValueGenericProps } from 'react-select/src/components/MultiValue';
import { components, OptionTypeBase } from 'react-select';
import React from 'react';

const MultiValueContainer = (props: MultiValueGenericProps<OptionTypeBase>) => {
    return (
        <div className="m-[2px] rounded border border-gray-200">
            <components.MultiValueContainer {...props} />
        </div>
    );
};

export default MultiValueContainer;
