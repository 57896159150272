import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { observer } from 'mobx-react';
import withQueryClient from '@/HOCs/WithQueryClient';
import { StackableModalProps } from '@/Components/RootModal';
import Modal from '@/Components/Modal';
import { PrimaryButton } from '@/Components/Button';
import { ScheduleEventAvailabilityList } from '@/Features/Schedule';
import Alert from '@/Components/Alert';
import Checkbox from '@/Components/Checkbox';
import {
    ResultUpdated,
    ResultUpdatedError,
} from '../../../../assets/js/actions/UpdateAction';
import { SessionPack } from '@/Models/SessionPack';
import { Class } from '@/Models/Class';
import { DateTime } from 'luxon';
import NotificationOptions from '@/Components/NotificationOptions';
import { EventConfirmationNotificationMap } from '@/Types/EventConfirmationNotificationMap';
import RootStore from '@/Stores/RootStore';
import PlanScheduleEventsContext from '@/Contexts/PlanScheduleEventsContext';
import useTeam from '@/Hooks/useTeam';
import ModalFormError from '@/Components/ModalFormError';
import FormField from '@/Components/FormField';

interface Props extends StackableModalProps {
    entryType: 'session' | 'class-date';
    parent: SessionPack | Class;
    newEvents: any;
    view: any;
    calendar: any;
    shouldDisplayAddEventsButton?: boolean;
}

const Component: FunctionComponent<Props> = ({
    entryType,
    parent,
    newEvents,
    ...props
}) => {
    const { events, setEvents, getFormattedEvents, makeUnassignedAvailable } =
        useContext(PlanScheduleEventsContext);
    const { hasTeamMembers } = useTeam();
    const [teamNotifications, setTeamNotifications] = useState(true);
    const [clientNotifications, setClientNotifications] =
        useState<EventConfirmationNotificationMap>();
    const [isBusy, setIsBusy] = useState(false);
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        setEvents(
            newEvents.map((card) => ({
                id: card.scheduleId,
                start: card.startDate,
                end: card.endDate,
                assignedUser: card.entry.assignedUser,
            }))
        );
    }, [newEvents]);

    const notificationsAvailable = useMemo(() => {
        return events.some(
            (ev) => ev.start > DateTime.local() && !!ev.assignedUser
        );
    }, [events]);

    useEffect(() => {
        if (!notificationsAvailable) {
            setTeamNotifications(false);
        }
    }, [notificationsAvailable]);

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        setIsBusy(true);

        const result = await props.view.action.save(
            getFormattedEvents(events),
            teamNotifications,
            clientNotifications,
            makeUnassignedAvailable
        );

        if (result instanceof ResultUpdated) {
            props.view.closeAction();

            props.view.calendar.setCurrentCalendar(
                props.view.calendar.currentCalendar
            );

            RootStore.stores.uiStateStore.closeStack();
        } else if (result instanceof ResultUpdatedError) {
            setErrors(result.errors);
            setIsBusy(false);
        } else {
            setIsBusy(false);
        }
    }

    function handleNotificationChange(
        notify: EventConfirmationNotificationMap
    ) {
        setClientNotifications(notify);
    }

    function getZCSButton() {
        return null;
        // parent.type === 'session-pack' ? (
        //     <SchedulePlaceSessionsButton
        //         view={props.view}
        //         calendar={props.calendar}
        //         variant="zero-content"
        //     />
        // ) : (
        //     <PlaceDatesButton
        //         view={props.view}
        //         calendar={props.calendar}
        //         variant="zero-content"
        //     />
        // );
    }

    return (
        <Modal
            stackProps={props.stackProps}
            title={`Add additional ${
                props.view.action?.type?.titlePlural?.toLowerCase() ?? 'entries'
            }`}
            rightAction={() => (
                <PrimaryButton type="submit" isLoading={isBusy}>
                    Save
                </PrimaryButton>
            )}
            onSubmit={handleSubmit}
            cancelText="Back"
            headerContent={
                <ModalFormError isValid={!Object.keys(errors ?? {}).length} />
            }
        >
            <section className="dialog__panel">
                <ScheduleEventAvailabilityList
                    allowRemove
                    eventType={entryType === 'session' ? 'session' : 'date'}
                    title="Availability"
                    zcsButton={getZCSButton()}
                    view={props.view}
                />
                {!!errors?.events?.[0] && (
                    <FormField.Error>
                        <p>{errors?.events?.[0]}</p>
                    </FormField.Error>
                )}
            </section>

            {hasTeamMembers && (
                <section className="dialog__panel">
                    <h2 className="ui-action-bar__title mb-2">
                        Team notifications
                    </h2>

                    {!notificationsAvailable && (
                        <Alert
                            type="warning"
                            title="You cannot send notifications if all new entries are in the past"
                            className="mt-3"
                        />
                    )}
                    {notificationsAvailable && (
                        <Checkbox
                            checked={teamNotifications}
                            onChange={() =>
                                setTeamNotifications(!teamNotifications)
                            }
                            label="Send email notifications to teachers"
                        />
                    )}
                </section>
            )}

            {parent.type === 'session-pack' && (
                <section className="dialog__panel">
                    <h2 className="ui-action-bar__title mb-2">
                        Client notifications
                    </h2>
                    {!notificationsAvailable && (
                        <Alert
                            type="warning"
                            title="You cannot send notifications if all new entries are in the past"
                            className="mt-3"
                        />
                    )}
                    {notificationsAvailable && (
                        <NotificationOptions
                            initialEmail
                            sendsAppNotification
                            withTitle={false}
                            recipients={parent.clients}
                            onChange={handleNotificationChange}
                        />
                    )}
                </section>
            )}
        </Modal>
    );
};

export const ScheduleAdditionalEventsModal = withQueryClient(
    observer(Component)
);
