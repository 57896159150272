import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import ItemListTitleCell from '@/Components/ItemList/TitleCell';
import { PortalInvite } from '@/Models/PortalInvite';
import ItemListTextCell from '@/Components/ItemList/TextCell';
import ItemListActionCell from '@/Components/ItemList/ActionCell';
import {
    DeleteClientPortalInviteButton,
    ResendClientPortalInviteButton,
} from '@/Features/Clients';
import { NewPopper } from '@/Components/NewPopper';

interface Props {
    invite: PortalInvite;
    className?: string;
}

const Component: FunctionComponent<Props> = ({ invite, className }) => {
    return (
        <div
            className={classNames(
                className,
                'grid-cols-invites-mobile xl:grid-cols-invites-xl'
            )}
        >
            <ItemListTitleCell
                title={invite.email}
                subtitle={invite.scopesString}
            />
            <ItemListTextCell
                content={
                    <>
                        <span className="xl:hidden">Created&nbsp;</span>
                        {invite.createdAtFormatted}
                    </>
                }
            />
            <ItemListTextCell
                content={
                    <>
                        <span className="xl:hidden">Expires&nbsp;</span>
                        {invite.expiresAtFormatted}
                    </>
                }
            />
            <ItemListActionCell colStart="col-start-2" className="row-span-3">
                <NewPopper placement="bottom-end">
                    {() => (
                        <div className="ui-button-list">
                            <ResendClientPortalInviteButton invite={invite} />
                            <DeleteClientPortalInviteButton invite={invite} />
                        </div>
                    )}
                </NewPopper>
            </ItemListActionCell>
        </div>
    );
};

export const ClientInviteListItem = observer(Component);
