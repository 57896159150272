import { FunctionComponent, ReactElement, ReactNode } from 'react';

interface Props {
    condition: boolean;
    wrapper(children: ReactNode): ReactElement | null;
    children: ReactElement;
}

export const ConditionalWrapper: FunctionComponent<Props> = ({
    condition,
    wrapper,
    children,
}) => {
    return condition ? wrapper(children) : children;
};
