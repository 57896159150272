import { createContext } from 'react';
import { SchedulePlannedEvent } from '@/Features/Schedule';

interface PlanScheduleEventsContextInterface {
    formValues?: any;
    initialFormValues?: any;
    setFormValues(values?: any): void;
    events: SchedulePlannedEvent[];
    setEvents(events: SchedulePlannedEvent[]): void;
    removeEvent(id: string): void;
    setAssignedUser(assignedUser: string, eventId?: string): void;
    isCheckingAvailability: boolean;
    reset(): void;
    hasConflicts: boolean;
    hasHolidays: boolean;
    makeUnassignedAvailable: boolean;
    setMakeUnassignedAvailable(value: boolean): void;
    getFormattedEvents(eventsToFormat: SchedulePlannedEvent[]): any[];
}

const PlanScheduleEventsContext =
    createContext<PlanScheduleEventsContextInterface>({
        formValues: undefined,
        initialFormValues: undefined,
        setFormValues: () => {},
        events: [],
        setEvents: () => {},
        removeEvent: () => {},
        setAssignedUser: () => {},
        isCheckingAvailability: false,
        reset: () => {},
        hasConflicts: false,
        hasHolidays: false,
        makeUnassignedAvailable: false,
        setMakeUnassignedAvailable: () => {},
        getFormattedEvents: () => [],
    });

export default PlanScheduleEventsContext;
