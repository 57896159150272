import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import FormField from '../FormField';

export interface FormFieldElementProps {
    name: string;
    label?: string | JSX.Element | ((htmlFor: string) => JSX.Element);
    info?: string | ReactNode;
    isRequired?: boolean;
    className?: string;
    style?: CSSProperties;
    displayErrors?: false | 'after-touch' | 'after-submit';
    onBlur?: (event: React.FocusEvent<any>) => void;
}

interface Props extends FormFieldElementProps {
    children(fieldProps: FieldProps): ReactNode;
}

const FormFieldElements: FunctionComponent<Props> = ({
    name,
    label,
    info,
    isRequired,
    children,
    displayErrors = 'after-touch',
    ...props
}) => {
    const { submitCount } = useFormikContext();

    return (
        <Field name={name}>
            {(fieldProps: FieldProps) => (
                <FormField {...props}>
                    {typeof label === 'string' && (
                        <FormField.Label required={isRequired}>
                            {label}
                        </FormField.Label>
                    )}
                    {typeof label === 'function' && label(name)}
                    {!!label &&
                        typeof label !== 'string' &&
                        typeof label !== 'function' && <label>{label}</label>}

                    {children(fieldProps)}

                    {info && (
                        <FormField.Info>
                            {typeof info === 'string' && <p>{info}</p>}
                            {typeof info !== 'string' && info}
                        </FormField.Info>
                    )}
                    {((displayErrors === 'after-touch' &&
                        fieldProps.meta.touched) ||
                        (displayErrors === 'after-submit' &&
                            submitCount > 0)) &&
                        fieldProps.meta.error && (
                            <FormField.Error>
                                <p>{fieldProps.meta.error}</p>
                            </FormField.Error>
                        )}
                </FormField>
            )}
        </Field>
    );
};

export default FormFieldElements;
