import { types } from 'mobx-state-tree';
import { ClientReference } from './Client';
import { SessionPackReference } from './SessionPack';
import { Currency } from './Currency';

export const SessionPackParticipation = types
    .model('SessionPackParticipation', {
        amount: types.number,
        paid: types.number,
        currency: Currency,
        outstanding: types.number,
        overpaymentDismissed: types.boolean,
        sessionPack: types.late(() => SessionPackReference),
        client: types.late(() => ClientReference),
    })

    .views((self) => ({
        get isOverpaid() {
            return self.outstanding < 0;
        },

        get sessionPackUrl() {
            return self.sessionPack.url;
        },

        get hasOutstanding() {
            return self.outstanding > 0;
        },
    }))

    .actions((self) => ({}));
