import React from 'react';

const Move = (props) => (
    <svg viewBox="0 0 16 16" {...props}>
        <path
            fill={props.fill}
            d="M6.545 5.818h2.91V3.636h2.181L8 0 4.364 3.636h2.181v2.182zm-.727.727H3.636V4.364L0 8l3.636 3.636V9.455h2.182v-2.91zM16 8l-3.636-3.636v2.181h-2.182v2.91h2.182v2.181L16 8zm-6.545 2.182h-2.91v2.182H4.364L8 16l3.636-3.636H9.455v-2.182z"
        />
    </svg>
);

export default Move;
