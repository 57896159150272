import angular from 'angular';

angular
    .module('resource.class-date', [])

    .service('ClassDate', [
        '$resource',
        'TransformerService',
        function ($resource, TransformerService) {
            let ClassDate = $resource(
                '/v3/classes/:classId/dates/:uuid',
                { uuid: '@uuid' },
                {
                    query: {
                        isArray: true,
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                    get: {
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                    update: {
                        method: 'PUT',
                    },
                }
            );

            return ClassDate;
        },
    ]);
