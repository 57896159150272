import {
    applySnapshot,
    flow,
    getRoot,
    getSnapshot,
    types,
} from 'mobx-state-tree';
import { ClassReference } from '../Stores/ClassStore';
import { currencyDisplay } from '../Services/Currency';
import { Currency } from './Currency';
import MembershipTransport from '../Services/Transport/MembershipTransport';
import { countInflect } from '../Services/String';

export const Membership = types
    .model('Membership', {
        uuid: types.identifier,
        enabled: types.boolean,
        portalDisplayEnabled: types.boolean,
        portalOrder: types.maybeNull(types.integer),
        name: types.string,
        description: types.maybeNull(types.string),
        terms: types.maybeNull(types.string),
        cost: types.integer,
        currency: Currency,
        renewalInterval: 'month',
        renewalAnchor: types.maybeNull(types.string),
        renewalAnchorDay: types.maybeNull(types.integer),
        renewalAnchorMonth: types.maybeNull(types.integer),
        classesEnabled: types.boolean,
        classRestriction: types.array(types.late(() => ClassReference)),

        enrolmentLimit: types.maybeNull(types.integer),

        isArchived: false,
        isDeleted: false,

        hasBeenPurchased: types.maybe(types.boolean),

        // Requestable attributes
        usableSubscriptionCount: types.maybe(types.integer),
        cancelledSubscriptionCount: types.maybe(types.integer),
        overdueSubscriptionCount: types.maybe(types.integer),
        gracePeriodSubscriptionCount: types.maybe(types.integer),
    })

    .views((self) => ({
        get url() {
            return `/memberships/packages/${self.uuid}`;
        },

        get costHumanString() {
            return `${self.costFormatted} per ${self.renewalInterval}`;
        },

        get renewalSyncEnabled() {
            return self.renewalAnchor !== null;
        },

        get classRestrictionEnabled() {
            return (
                self.classesEnabled &&
                Array.isArray(self.classRestriction) &&
                self.classRestriction.length > 0
            );
        },

        get enrolmentLimitEnabled() {
            return !!self.enrolmentLimit;
        },

        get costFormatted() {
            return currencyDisplay(self.cost, self.currency);
        },

        get assumedIncome() {
            return (self.usableSubscriptionCount ?? 0) * self.cost;
        },

        get assumedIncomeFormatted() {
            return currencyDisplay(
                self.assumedIncome,
                self.currency,
                true,
                true,
                true
            );
        },

        get classRestrictionString() {
            if (!self.classesEnabled) {
                return 'No classes';
            }

            return self.classRestrictionEnabled
                ? countInflect(self.classRestriction.length, 'class', 'classes')
                : 'All classes';
        },

        get enrolmentLimitString() {
            return self.enrolmentLimitEnabled
                ? countInflect(self.enrolmentLimit, 'credit')
                : 'No limit';
        },
    }))

    .actions((self) => ({
        archive: flow(function* archive() {
            const { data } = yield MembershipTransport.archive(self.uuid);
            self.updateData(data.data);
        }),

        restore: flow(function* restore() {
            const { data } = yield MembershipTransport.restore(self.uuid);
            self.updateData(data.data);
        }),

        updateData(data = {}) {
            applySnapshot(self, {
                ...getSnapshot(self),
                ...data,
            });
        },
    }));

export const MembershipReference = types.maybeNull(
    types.reference(Membership, {
        get(identifier, parent) {
            return getRoot(parent).stores.membershipStore.findOrFetch(
                identifier
            );
        },
        set({ identifier }) {
            return identifier;
        },
    })
);
