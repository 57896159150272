import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { fonts, uiColors } from '../../Theme';

const Title = styled.div`
    margin: 0;
    font-size: ${fonts.size(16)};
    font-weight: bold;

    ${(p) =>
        p.isLink &&
        css`
            color: ${uiColors.text.link};
            transition: color 150ms;

            &:hover {
                color: ${uiColors.text.linkDark};
            }
        `}
`;

Title.propTypes = {
    isLink: PropTypes.bool,
};

export default Title;
