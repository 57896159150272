import React from 'react';

const UITransactionOutSimple = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            fill={props.fill}
            d="M6 0c3.046 0 5.581 2.3 5.953 5.25H5.101l1.475-1.77.065-.09a.75.75 0 00-1.217-.87l-2.5 3-.011.013a1.041 1.041 0 00-.048.067l-.006.01a.825.825 0 00-.066.14l-.012.037a.747.747 0 00-.013.05l-.008.043-.003.018-.005.052a.755.755 0 00-.002.034v.048l.002.017a.716.716 0 00.012.093l.005.026a.788.788 0 00.046.139l.023.045c.004.01.01.018.014.027l.007.01.006.01.009.014a.595.595 0 00.04.055l.01.012 2.5 3 .077.08a.75.75 0 001.14-.95l-.065-.09-1.474-1.77 6.85.001C11.58 9.7 9.047 12 6 12c-3.3 0-6-2.7-6-6s2.7-6 6-6z"
            fillRule="evenodd"
        />
    </svg>
);

export default UITransactionOutSimple;
