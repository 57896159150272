import styled, { css } from 'styled-components';
import { uiColors } from '../../Theme';
import { maxWidth, minWidth } from '../../Theme/breakpoints';

export const StyledContainer = styled.div`
    width: 100%;

    ${(p) =>
        p.maxWidth === 'default' &&
        css`
            max-width: 260px;
        `}
    ${(p) =>
        p.maxWidth === 'xl' &&
        css`
            @media (${minWidth('tabletLarge')}) {
                max-width: 260px;
            }
        `}
  .DayPickerInput {
        width: 100%;
    }

    .DayPickerInput input {
        width: 100%;
        min-width: 190px;
        font-size: 16px;

        ${(p) =>
            p.variant === 'grey' &&
            css`
                background: ${uiColors.button.default};
                border: none;
                border-radius: 4px;
            `}
    }

    .DayPickerInput-Overlay {
        z-index: 119 !important;
        margin-top: 4px;
        border-radius: 4px;
        border: 1px solid ${uiColors.border.default};

        @media (${maxWidth('tabletSmall')}) {
            position: fixed;
            top: 64px;
            left: 12px;
            right: 12px;
        }
    }

    .DayPicker {
        outline: none;

        @media (${maxWidth('tabletSmall')}) {
            width: 100%;
        }
    }

    .DayPicker-wrapper {
        outline: none;
    }

    .DayPicker-NavBar {
        position: absolute;
        top: 12px;
        right: 12px;

        button {
            width: 32px;
            height: 32px;
        }
    }

    .DayPicker-Footer {
        display: flex;
        justify-content: center;
    }

    .DayPicker-Months {
        padding: 1em 1em 0;
    }

    .DayPicker-Month {
        margin: 0;

        @media (${maxWidth('tabletSmall')}) {
            width: 100%;
        }
    }

    .DayPicker-WeekNumber {
        width: 32px;
        min-width: 32px;
    }

    .DayPicker-Day {
        outline: none;
        border-radius: 0 !important;
    }

    .DayPicker-Day--selected {
        border-radius: 0;
        background-color: ${uiColors.positive.xLight} !important;
        color: #444 !important;
    }

    .DayPicker-Day--today {
        color: ${uiColors.positive.midDark} !important;
    }

    ${(p) =>
        p.hasSelectableWeeks &&
        css`
            .DayPicker-Week:hover {
                background-color: #f4f4f4 !important;
                cursor: pointer !important;

                .DayPicker-Day {
                    cursor: pointer !important;
                }
            }
        `}
`;
