import React from 'react';

const UICredits = (props) => (
    <svg viewBox="0 0 14 14" {...props}>
        <path
            fill={props.fill}
            d="M3 8a3 3 0 110 6 3 3 0 010-6zm8 0a3 3 0 110 6 3 3 0 010-6zM3 0a3 3 0 110 6 3 3 0 010-6zm8 0a3 3 0 110 6 3 3 0 010-6z"
        />
    </svg>
);

export default UICredits;
