import React, { FunctionComponent, useContext } from 'react';
import { observer } from 'mobx-react';
import useStores from '@/Hooks/useStores';
import { Agreement } from '@/Models/Agreement';
import { ClearButton } from '@/Components/Button';
import { uiColors } from '@/Theme';
import EventConfirmationModalContext from '@/Contexts/EventConfirmationModalContext';
import Notification from '@/Services/Notification';

interface Props {
    agreement: Agreement;
    onUpdate?(agreement: Agreement): void;
}

const Component: FunctionComponent<Props> = ({ agreement }) => {
    const { agreementStore } = useStores();
    const confirmationContext = useContext(EventConfirmationModalContext);

    async function setAsDefault() {
        try {
            await agreementStore.setDefault(agreement.eid);
            Notification.success('Agreement updated');
        } catch (e) {
            console.log(e);
            Notification.error();
        }
    }

    function handleClick() {
        confirmationContext.requireConfirmation({
            content: () => (
                <p className="mb-2">
                    Are you sure you want to set this agreement as your default?
                </p>
            ),
            isPositive: true,
            onConfirmed: setAsDefault,
        });
    }

    return (
        <ClearButton
            onClick={handleClick}
            icon="Star"
            fill={uiColors.core.upgrade}
            disabled={agreement.isDefault}
        >
            Set default
        </ClearButton>
    );
};

export const SetAgreementAsDefaultButton = observer(Component);
