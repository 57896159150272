import {
    applySnapshot,
    flow,
    getRoot,
    getSnapshot,
    types,
} from 'mobx-state-tree';
import { Message } from '@/Models/Message';
import MessageTransport from '@/Services/Transport/MessageTransport';

export const MessageStore = types
    .model('MessageStore', {
        messages: types.map(Message),
    })

    .views((self) => ({
        find(identifier) {
            return self.messages.find(
                (message) => message.identifier === identifier
            );
        },
    }))

    .actions((self) => ({
        list: flow(function* list(requestData, withMeta = false) {
            const { data } = yield MessageTransport.list(requestData);
            const items = data.data.map((classPass) =>
                self.createOrUpdate(classPass)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        createOrUpdate(messageData) {
            const message = self.processNestedResources(messageData);

            if (self.messages.has(message.identifier)) {
                const existing = self.messages.get(message.identifier);
                applySnapshot(existing, {
                    ...getSnapshot(existing),
                    ...message,
                });
            } else {
                self.messages.set(message.identifier, message);
            }

            return self.messages.get(message.identifier);
        },

        /**
         * @param messageData
         * @returns {*}
         */
        processNestedResources(messageData) {
            messageData.client = getRoot(
                self
            ).stores.clientStore.processAsNested(messageData.client);

            return messageData;
        },
    }));
