import React, { useEffect, useMemo, useState } from 'react';
import Loader from '@/Components/Loader';
import Modal, { WIDTHS } from '@/Components/Modal';
import { StackProps } from '@/Components/RootModal';
import Alert from '@/Components/Alert';

type AsyncLoader = () => Promise<any>;

interface Config {
    enabled?: boolean;
    isLoading?: boolean;
    isError?: boolean;
}

function useModalPreload(
    stackProps: StackProps,
    config: Config = {},
    ...loaders: AsyncLoader[]
) {
    const enabled = config.enabled || true;
    const [isLoading, setIsLoading] = useState<boolean>(
        enabled || config.isLoading
    );
    const [hasError, setHasError] = useState<boolean>(config.isError || false);

    useEffect(() => {
        if (!enabled || config.isLoading || config.isError) {
            return;
        }

        Promise.all(
            loaders.map(async (loader) => {
                try {
                    await loader();
                } catch (e) {
                    setHasError(true);
                }
            })
        ).finally(() => {
            setIsLoading(false);
        });
    }, []);

    const LoaderModal = useMemo(() => {
        if (isLoading || hasError) {
            return (
                <Modal width={WIDTHS.narrow} stackProps={stackProps}>
                    {isLoading && <Loader withPosition />}
                    {hasError && (
                        <Alert
                            type="error"
                            title="There was a problem, please try again."
                        />
                    )}
                </Modal>
            );
        }

        return null;
    }, [isLoading, hasError]);

    return {
        shouldDisplayPreload: isLoading || hasError,
        LoaderModal,
    };
}

export default useModalPreload;
