import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import classNames from 'classnames';

interface Props {
    title: string;
    subtitle?: string | JSX.Element;
    className?: string;
    url?: string;
}

const ItemListTitleCell: FunctionComponent<Props> = ({
    title,
    subtitle,
    url,
    className,
}) => {
    return (
        <div
            className={classNames(
                'flex min-h-[40px] flex-col justify-center pr-6',
                className
            )}
        >
            <h3 className="text-base font-bold leading-5">
                {!!url ? <NavLink to={url}>{title}</NavLink> : title}
            </h3>
            {!!subtitle && <p className="leading-5">{subtitle}</p>}
        </div>
    );
};

export default observer(ItemListTitleCell);
