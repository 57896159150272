import React, { FunctionComponent, useContext } from 'react';
import { observer } from 'mobx-react';
import { ClearButton } from '@/Components/Button';
import { PortalInvite } from '@/Models/PortalInvite';
import EventConfirmationModalContext from '@/Contexts/EventConfirmationModalContext';
import Notification from '@/Services/Notification';
import queryClient from '@/Modules/QueryClient';

interface Props {
    invite: PortalInvite;
}

const Component: FunctionComponent<Props> = ({ invite }) => {
    const confirmationContext = useContext(EventConfirmationModalContext);

    async function resendInvite() {
        try {
            await invite.resend();
            await queryClient.invalidateQueries(['clients', 'invites']);
            Notification.success('Invite resent');
        } catch (e) {
            console.log('error', e);
            Notification.error();
        }
    }

    function handleClick() {
        confirmationContext.requireConfirmation({
            content: () => (
                <p className="mb-2">
                    Are you sure you want to resend this invite?
                </p>
            ),
            isPositive: true,
            onConfirmed: resendInvite,
        });
    }

    return (
        <ClearButton onClick={handleClick} icon="EmailSync">
            Resend
        </ClearButton>
    );
};

export const ResendClientPortalInviteButton = observer(Component);
