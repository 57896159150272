import RootStore from '@/Stores/RootStore';
import { useQuery } from 'react-query';
import { queryKeys } from '@/Services/QueryKeys';

function useTeam() {
    const usersQuery = useQuery(
        queryKeys.teamMembers.list(),
        RootStore.stores.usersStore.list
    );

    const teamMembers = usersQuery.data ?? [];

    return {
        teamMembers,
        hasTeamMembers: teamMembers.length > 1,
    };
}

export default useTeam;
