import React from 'react';

const CustomClients = (props) => (
    <svg viewBox="0 0 20 20" {...props}>
        <path
            fill={props.fill}
            d="M15.5 7C17.483 7 19 5.483 19 3.5S17.483 0 15.5 0 12 1.517 12 3.5 13.517 7 15.5 7zM7 8a2 2 0 012 2v4a2 2 0 01-2 2v3a1 1 0 01-1 1H3a1 1 0 01-1-1v-3a2 2 0 01-2-2v-4a2 2 0 012-2h.478c.448.613 1.176 1 2.022 1 .846 0 1.574-.387 2.022-1H7zM18 8a2 2 0 012 2v4a2 2 0 01-2 2v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-3a2 2 0 01-2-2v-4a2 2 0 012-2h.478c.448.613 1.176 1 2.022 1 .846 0 1.574-.387 2.022-1H18zM4.5 7C6.483 7 8 5.483 8 3.5S6.483 0 4.5 0 1 1.517 1 3.5 2.517 7 4.5 7z"
            fillRule="evenodd"
        />
    </svg>
);

export default CustomClients;
