import React from 'react';
import useInfiniteQueryResult from '../../Hooks/useInfiniteQueryResult';
import { UseInfiniteQueryResult } from 'react-query';
import ItemList, { ItemListProps } from '../ItemList';
import { observer } from 'mobx-react';
import { InfiniteItemListFooter } from '@/Components/InfiniteItemListFooter';

interface Props<ItemType> extends Omit<ItemListProps<ItemType>, 'items'> {
    queryResult: UseInfiniteQueryResult;
}

const InfiniteItemList = <ItemType extends any>({
    queryResult,
    ...props
}: Props<ItemType>) => {
    const items = useInfiniteQueryResult(queryResult.data);

    return (
        <ItemList<ItemType>
            items={items}
            isLoading={queryResult.isLoading}
            isError={queryResult.isError}
            renderFooter={<InfiniteItemListFooter queryResult={queryResult} />}
            {...props}
        />
    );
};

export default observer(InfiniteItemList);
