import React from 'react';

const UIClassPassSimple = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            fill={props.fill}
            d="M10.401 0C11.284 0 12 .716 12 1.599v2.464a2 2 0 000 3.874v2.464c0 .883-.716 1.599-1.599 1.599H1.6A1.599 1.599 0 010 10.401V7.937a2 2 0 000-3.874V1.6C0 .716.716 0 1.599 0H10.4zM7.757 4.092a.545.545 0 00-.756.15L5.187 6.6l-.256-.257-.061-.052a.546.546 0 00-.71.824l.727.727.06.05c.245.185.603.13.78-.134l2.181-2.909.04-.07a.546.546 0 00-.19-.686z"
            fillRule="evenodd"
        />
    </svg>
);

export default UIClassPassSimple;
