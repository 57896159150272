import { isValidReference, types } from 'mobx-state-tree';
import ClassBase from './ClassBase';
import WithLocation from './WithLocation';
import { EmployerReference } from './Employer';
import ClassEmployedBase from './ClassEmployedBase';
import { Currency } from './Currency';

const ClassEmployed = types
    .model({
        fee: types.integer,
        employer: types.maybeNull(EmployerReference),
        currency: types.maybeNull(Currency),
    })

    .views((self) => ({
        get humanEntityType() {
            return 'Employed class';
        },

        /**
         * These are properties that a Class date can share with its parent class
         * @returns {{references: {class: *}, fee: *, name: string, class: *, estimatedFee: *}}
         */
        get inheritableProperties() {
            return {
                class: self.uuid,
                classType: 'employed',
                name: self.name,
                fee: self.fee,
                estimatedFee: self.fee,
                references: { class: self.uuid },
            };
        },

        /**
         * Determine if this class has an attached employer
         * @returns {boolean}
         */
        get hasEmployer() {
            return isValidReference(() => self.employer);
        },

        /**
         * Determine if this class has an employer that is not deleted
         * @returns {*|boolean}
         */
        get hasAvailableEmployer() {
            return self.hasEmployer && !self.employer.isDeleted;
        },

        /**
         * Get the dates that have been confirmed
         */
        get confirmedDates() {
            return self.deliveredDates.filter((date) => date.isConfirmed);
        },

        /**
         * Get the dates that have not been confirmed
         */
        get unconfirmedDates() {
            return self.deliveredDates.filter((date) => !date.isConfirmed);
        },

        /**
         * Count the number of dates that are unconfirmed
         * @returns {*}
         */
        get unconfirmedCount() {
            return self.unconfirmedDates.length;
        },

        /**
         * Determine if the class has any unconfirmed dates
         * @returns {boolean}
         */
        get hasUnconfirmed() {
            return self.unconfirmedCount > 0;
        },

        /**
         * Get the rolling average of the last 3 confirmed dates, averaging their hourly rates
         * @returns {number}
         */
        get activeHourlyEarnings() {
            const dates = self.confirmedDates.slice(0, 3);

            if (!dates.length) {
                return 0;
            }

            return (
                dates.reduce((acc, date) => acc + date.hourlyEarnings, 0) /
                dates.length
            );
        },

        /**
         * Get the total earnings from all confirmed dates
         * @returns {*}
         */
        get confirmedEarnings() {
            return self.confirmedDates.reduce((acc, date) => acc + date.fee, 0);
        },

        /**
         * Get the projected earnings for all future dates, based on the default fee.
         * @returns {number}
         */
        get plannedEarnings() {
            return self.plannedCount * self.fee;
        },
    }));

const ComposedClassEmployed = types.compose(
    'ClassEmployed',
    ClassBase,
    ClassEmployed,
    ClassEmployedBase,
    WithLocation
);

export default ComposedClassEmployed;
