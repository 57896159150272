import React from 'react';

const UIClassPass = (props) => (
    <svg viewBox="0 0 35 21" {...props}>
        <path
            fill={props.fill}
            d="M32.202 0A2.798 2.798 0 0135 2.798V7a3.5 3.5 0 000 7v4.202A2.798 2.798 0 0132.202 21H2.798A2.798 2.798 0 010 18.202V14a3.5 3.5 0 000-7V2.798A2.798 2.798 0 012.798 0h29.404zm-5.036 8.126a.75.75 0 00-1.04.208l-2.494 3.24-.352-.353-.084-.073a.75.75 0 00-.976 1.134l1 .999.081.07a.75.75 0 001.073-.185l3-4 .055-.097a.75.75 0 00-.263-.943zm-7.5 0a.75.75 0 00-1.04.208l-2.494 3.24-.352-.353-.084-.073a.75.75 0 00-.976 1.134l1 .999.081.07a.75.75 0 001.073-.185l3-4 .055-.097a.75.75 0 00-.263-.943zm-7.5 0a.75.75 0 00-1.04.208l-2.494 3.24-.352-.353-.084-.073a.75.75 0 00-.976 1.134l1 .999.081.07a.75.75 0 001.073-.185l3-4 .055-.097a.75.75 0 00-.263-.943z"
            fillRule="evenodd"
        />
    </svg>
);

export default UIClassPass;
