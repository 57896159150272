import React from 'react';

const CustomAgreements = (props) => (
    <svg viewBox="0 0 16 16" {...props}>
        <path
            fill={props.fill}
            d="M9.756.5L14 4.743v8.758a2 2 0 01-2 2H4a2 2 0 01-2-2v-11a2 2 0 012-2L9.756.5zM11 10.5H5a1 1 0 100 2h6a1 1 0 000-2zm0-3H5a1 1 0 100 2h6a1 1 0 000-2zm-4-3H5a1 1 0 100 2h2a1 1 0 100-2z"
            fillRule="evenodd"
        />
    </svg>
);

export default CustomAgreements;
