import React from 'react';

const WarningTriangle = (props) => (
    <svg viewBox="0 0 22 20" {...props}>
        <path
            fill={props.fill}
            d="M9.382 1.077c.82-1.434 2.146-1.438 2.967 0l9.033 15.807c.82 1.434.142 2.597-1.507 2.597H1.857c-1.652 0-2.329-1.16-1.508-2.597L9.382 1.077zm.484 7.394v4.019a1 1 0 0 0 1 .99c.556 0 1-.443 1-.99V8.47a1 1 0 0 0-1-.99c-.556 0-1 .443-1 .99zm0 7.01c0 .556.447 1 1 1 .556 0 1-.448 1-1 0-.557-.448-1-1-1-.556 0-1 .447-1 1z"
        />
    </svg>
);

export default WarningTriangle;
