import React, { ComponentType, FunctionComponent } from 'react';
import SelectField, { SelectFieldProps } from '../SelectField';
import { FieldProps, useField, useFormikContext } from 'formik';
import FormField from '../FormField';
import FormFieldElements, { FormFieldElementProps } from '../FormFieldElements';
import {
    GroupedOptionsType,
    OptionsType,
    OptionTypeBase,
} from 'react-select/src/types';
import { ValueType } from 'react-select';

export interface FormSelectFieldProps extends FormFieldElementProps {
    fieldProps?: SelectFieldProps;
    fieldAccessoryBefore?: JSX.Element;
    fieldAccessoryAfter?: JSX.Element;
}

interface Props extends FormSelectFieldProps {
    options?: GroupedOptionsType<OptionTypeBase> | OptionsType<OptionTypeBase>;
    id?: string;
    Component?: ComponentType;
}

const FormSelectField: FunctionComponent<Props> = ({
    options,
    fieldProps,
    fieldAccessoryBefore,
    fieldAccessoryAfter,
    id,
    Component,
    ...props
}) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [field] = useField(props);
    const SelectComponent = Component ?? SelectField;

    function handleChange(selected: ValueType<OptionTypeBase, boolean>) {
        // @ts-ignore
        if (fieldProps?.isMulti === true) {
            setFieldValue(
                field.name,
                (selected as ValueType<OptionTypeBase, true>).map(
                    (opt: OptionTypeBase) => opt.value
                )
            );
        } else {
            setFieldValue(
                field.name,
                (selected as OptionTypeBase)?.value ?? ''
            );
        }
    }

    const handleTouched = () => {
        setFieldTouched(field.name, true);
    };

    return (
        <FormFieldElements {...props}>
            {({ field }: FieldProps) => (
                <FormField.Input
                    id={id}
                    className="flex flex-wrap items-center"
                >
                    {fieldAccessoryBefore}
                    <div className="+flex">
                        <SelectComponent
                            value={field.value}
                            options={options}
                            onChange={handleChange}
                            onMenuClose={handleTouched}
                            closeMenuOnSelect={!fieldProps?.isMulti}
                            size="lg"
                            {...fieldProps}
                        />
                    </div>
                    {fieldAccessoryAfter}
                </FormField.Input>
            )}
        </FormFieldElements>
    );
};

export default FormSelectField;
