import Session from './Session.js';
import RootStore from '../../../js/Stores/RootStore';
import UpdateAction, {
    ResultUpdated as DefaultResultUpdated,
    ResultUpdatedError as DefaultResultUpdatedError,
} from './UpdateAction';
import Notification from '@/Services/Notification';
import SessionPackTransport from '@/Services/Transport/SessionPackTransport';

export default class UpdateSessionPack extends UpdateAction {
    static get type() {
        return new Session();
    }

    static get parentIdentifierName() {
        return 'session-pack';
    }

    get parent() {
        return this.sessionPack;
    }

    constructor(calendar, parameters = {}) {
        super(calendar, parameters);

        this.sessionPack = {};
    }

    async prepare() {
        this.sessionPack = await RootStore.stores.sessionPackStore.fetch(
            this.parentIdentifier,
            {
                include: ['sessions'],
            }
        );
        this.periodsUnscheduled = this.sessionPack.unscheduled;
        this.periodsAvailable = this.periodsUnscheduled;
    }

    endAddingCard(card) {
        super.endAddingCard(card);

        this.setAvailablePeriods();
    }

    endRemovingCard(card) {
        super.endRemovingCard(card);

        this.setAvailablePeriods();
    }

    beginUpdatingCard(card) {
        super.beginUpdatingCard(card);

        this.setAvailablePeriods(0 - card.length);
    }

    endUpdatingCard(card) {
        super.endUpdatingCard(card);

        this.setAvailablePeriods();
    }

    setAvailablePeriods(value = 0) {
        this.periodsAvailable = this.getAvailablePeriods(value);
    }

    getAvailablePeriods(value = 0) {
        let calc = (val, card) => val + card.length;
        let periods = this.calendar.newCards.reduce(calc, value);

        return this.periodsUnscheduled - periods;
    }

    get isTargetVisible() {
        return !this.isUpdatingCard && this.periodsAvailable > 0;
    }

    // async initialiseCards(eid) {
    //     this.parameters.eid = eid;
    //     this.isLoading = true;
    //
    //     const sessionPack = await RootStore.stores.sessionPackStore.fetch(eid, {
    //         include: ['sessions'],
    //     });
    //
    //     const sessions = this.loadSessionPack(sessionPack);
    //     this.focusSessionPackCards(sessions);
    //
    //     this.isLoading = false;
    // }
    //
    // loadSessionPack(sessionPack) {
    //     this.sessionPack = sessionPack;
    //     this.periodsUnscheduled = sessionPack.unscheduled;
    //     this.periodsAvailable = this.periodsUnscheduled;
    //
    //     const sessionScheduleIds = [];
    //
    //     sessionPack.sessions.forEach((session) => {
    //         this.calendar.populateCard({
    //             scheduleId: `session-${session.eid}`,
    //             type: 'session',
    //             label: session.label,
    //             start: session.start,
    //             end: session.end,
    //         });
    //
    //         sessionScheduleIds.push(`session-${session.eid}`);
    //     });
    //
    //     return sessionScheduleIds;
    // }

    focusSessionPackCards(sessions) {
        this.calendar.focusCards((card) => sessions.includes(card.scheduleId));
    }

    createCard() {
        let card = super.createCard();

        // card.group.id = `session-pack-${this.parameters.eid}`;
        card.isRepeatable = true;

        return card;
    }

    async save(events, teamNotifications, clientNotifications) {
        try {
            await SessionPackTransport.addSessions(
                this.sessionPack.eid,
                events,
                teamNotifications,
                clientNotifications
            );

            // Remove new, and update existing cards
            this.calendar.removeItems((item) => !item.isNew);

            Notification.success('Session pack updated');

            return new ResultUpdated();
        } catch (error) {
            return new ResultUpdatedError(error?.response?.data?.errors);
        }
    }
}

export class ResultUpdated extends DefaultResultUpdated {}

export class ResultUpdatedError extends DefaultResultUpdatedError {}
