import React from 'react';

const Repeat = (props) => (
    <svg viewBox="0 0 17 14" {...props}>
        <path
            fill={props.fill}
            d="M13.68 6.8h3.12l-3.9 3.75L9 6.8h3.12c0-2.475-2.106-4.5-4.68-4.5S2.76 4.325 2.76 6.8s2.106 4.5 4.68 4.5v1.5c-3.432 0-6.24-2.7-6.24-6s2.808-6 6.24-6c3.432 0 6.24 2.7 6.24 6h0z"
        />
    </svg>
);

export default Repeat;
