import React from 'react';

const CustomEmployers = (props) => (
    <svg viewBox="0 0 16 16" {...props}>
        <path
            fill={props.fill}
            d="M13.5.5a2 2 0 012 2v11a2 2 0 01-1.85 1.995l-.15.005h-11a2 2 0 01-2-2v-7a2 2 0 012-2h2v-2a2 2 0 012-2h7zm-9 10h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm4 0h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm4 0h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm-8-4h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm4 0h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm4 0h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm-4-4h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm4 0h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1z"
            fillRule="evenodd"
        />
    </svg>
);

export default CustomEmployers;
