import { applySnapshot, flow, getSnapshot, types } from 'mobx-state-tree';
import RootStore from '../Stores/RootStore';
import PackageTransport from '../Services/Transport/PackageTransport';
import { DateTimeType } from '../Types/DateTime';
import { ClassPassBase } from './ClassPassBase';
import { ClassReference } from '../Stores/ClassStore';
import { durationString, inPast } from '../Services/Date';
import WithLocation from './WithLocation';
import { deliveryFromFormValues } from '../Services/Delivery';
import { currencyDisplay } from '../Services/Currency';
import { Currency } from './Currency';
import { OrganisationUser } from './OrganisationUser';

const PackageModel = types
    .model('Package', {
        uuid: types.identifier,
        packageType: types.string,
        enabled: types.boolean,
        portalDisplayEnabled: types.boolean,
        name: types.maybeNull(types.string),
        description: types.maybeNull(types.string),
        terms: types.maybeNull(types.string),
        cost: types.integer,
        periods: types.maybeNull(types.integer),

        enrolmentLimit: types.maybeNull(types.integer),

        classRestriction: types.array(types.late(() => ClassReference)),
        expiry: types.maybeNull(DateTimeType),
        attendanceWindow: types.maybeNull(types.string),
        attendanceWindowAnchor: types.maybeNull(types.string),

        currency: Currency,

        assignedUser: types.maybeNull(
            types.late(() => types.safeReference(OrganisationUser))
        ),

        // Optional attributes
        totalActive: types.maybe(types.integer),
        totalSold: types.maybe(types.integer),
        totalIncome: types.maybe(types.integer),
        lastPurchased: types.maybe(types.maybeNull(DateTimeType)),

        isArchived: types.boolean,
    })

    .views((self) => ({
        get isClassPass() {
            return self.packageType === 'class';
        },

        get isSessionPack() {
            return self.packageType === 'session';
        },

        get hasExpired() {
            return self.expiry && inPast(self.expiry.endOf('day'));
        },

        get url() {
            const type = self.isClassPass ? 'class-passes' : 'session-packs';
            return `/${type}/packages/${self.uuid}`;
        },

        get summaryString() {
            let string = '';

            if (!self.isClassPass) {
                string += durationString(self.periods * 15) + ' for ';
            }

            return (string += currencyDisplay(self.cost, self.currency));
        },

        get costFormatted() {
            return currencyDisplay(self.cost, self.currency);
        },
    }))

    .actions((self) => ({
        archive: flow(function* archive() {
            const { data } = yield PackageTransport.archive(self.uuid);
            self.updateData(data.data);
        }),

        restore: flow(function* restore() {
            const { data } = yield PackageTransport.restore(self.uuid);
            self.updateData(data.data);
        }),

        setFormData({ uuid, ...values }) {
            self.updateData(values);

            const delivery = deliveryFromFormValues(values);
            self.updateDelivery(delivery);
        },

        updateData(data = {}) {
            applySnapshot(self, {
                ...getSnapshot(self),
                ...data,
            });
        },
    }));

export const Package = types.compose(PackageModel, ClassPassBase, WithLocation);

export const PackageReference = types.reference(Package, {
    get(uuid) {
        return RootStore.stores.packageStore.find(uuid) || null;
    },
    set(packageModel) {
        return packageModel.uuid;
    },
});
