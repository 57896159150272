import angular from 'angular';
import history from '../../../../js/Modules/History';
import RootStore from '../../../../js/Stores/RootStore';

const NavLink = {
    bindings: {
        href: '@',
        active: '=',
        reload: '=',
    },
    transclude: true,
    template: `
        <a ng-class="{active: $ctrl.active}">
            <ng-transclude />
        </a>        
    `,
    controller: [
        '$scope',
        '$element',
        function ($scope, $element) {
            let $ctrl = this;

            $element.click(function () {
                RootStore.stores.uiStateStore.setPopperOpen(false);

                if ($ctrl.reload) {
                    window.location.href = $ctrl.href;
                    return;
                }

                history.push($ctrl.href);
                $scope.$apply();
            });
        },
    ],
};

angular.module('component.nav-link', []).component('navLink', NavLink);

export default NavLink;
