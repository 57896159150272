import BaseTransport from './BaseTransport';

class DataTransport extends BaseTransport {
    async getAssumedLocale() {
        return await this.get('/v3/locale');
    }

    async listCountries(params = {}) {
        return await this.get('/v3/locale/countries', params);
    }

    async getCountry(countryCode) {
        return await this.get(`/v3/locale/countries/${countryCode}`);
    }

    async listTimezones(params = {}) {
        return await this.get('/v3/locale/timezones', params);
    }
}

export default new DataTransport();
