import angular from 'angular';

angular
    .module('service.validation', [])

    /**
     * Override the default validation for urls
     * Taken from: http://stackoverflow.com/questions/21138574/overwriting-the-angularjs-url-validator
     */
    .directive('input', function () {
        return {
            require: ['?ngModel'],
            link: function (scope, element, attrs, ngModel) {
                function allowSchemelessUrls() {
                    // Match Django's URL validator, which allows schemeless urls.
                    var URL_REGEXP =
                        /^((?:http|ftp)s?:\/\/)(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/i;

                    // Silently prefixes schemeless URLs with 'http://' when
                    // converting a view value to model value.
                    ngModel[0].$parsers.unshift(function (value) {
                        if (
                            !URL_REGEXP.test(value) &&
                            URL_REGEXP.test('http://' + value)
                        ) {
                            return 'http://' + value;
                        } else {
                            return value;
                        }
                    });

                    ngModel[0].$validators.url = function (value) {
                        return (
                            ngModel[0].$isEmpty(value) || URL_REGEXP.test(value)
                        );
                    };
                }

                if (ngModel && attrs.type === 'url') {
                    allowSchemelessUrls();
                }
            },
        };
    });
