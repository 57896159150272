import React, {
    ChangeEvent,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import classNames from 'classnames';
import {
    EventConfirmationNotificationMap,
    NotificationRecipient,
} from '../../Types/EventConfirmationNotificationMap';
import SelectableOptionList from '../SelectableOptionList';
import Checkbox from '../Checkbox';
import FormField from '../FormField';
import RootStore from '../../Stores/RootStore';
import { Link } from 'react-router-dom';

interface Props {
    withTitle?: boolean;
    withLabels?: boolean;
    horizontal?: boolean;
    recipients: NotificationRecipient[];

    onChange(
        notifications: EventConfirmationNotificationMap,
        anySelected: boolean
    ): void;

    emailAvailable?: boolean;
    smsAvailable?: boolean;
    initialEmail?: boolean;
    initialSms?: boolean;
    sendsAppNotification?: boolean;

    renderLabel?(recipient: NotificationRecipient): string | JSX.Element;
}

const NotificationOptions: FunctionComponent<Props> = ({
    recipients,
    onChange,
    horizontal = true,
    withTitle = true,
    withLabels = true,
    emailAvailable = true,
    smsAvailable = true,
    initialEmail = false,
    initialSms = false,
    sendsAppNotification = false,
    ...props
}) => {
    const { currentUserStore } = RootStore.stores;
    const [notify, setNotify] = useState<EventConfirmationNotificationMap>({});

    useEffect(() => {
        const initialState: EventConfirmationNotificationMap = {};

        recipients.forEach((recipient) => {
            initialState[recipient.notificationIdentifier] = {
                email: initialEmail && recipient.hasEmail,
                sms: initialSms && recipient.hasSmsPhone,
            };
        });

        setNotify(initialState);
    }, []);

    useEffect(() => {
        let anySelected = false;

        if (!anySelected) {
            Object.values(notify).forEach(({ email, sms }) => {
                if (email || sms) {
                    anySelected = true;
                }
            });
        }

        onChange(notify, anySelected);
    }, [notify]);

    function handleChange(
        notificationIdentifier: string,
        method: string,
        checked: boolean
    ) {
        const newState = {
            ...notify,
            [notificationIdentifier]: {
                ...notify[notificationIdentifier],
                [method]: checked,
            },
        };

        setNotify(newState);
    }

    function renderLabel(recipient: NotificationRecipient) {
        if (typeof props.renderLabel === 'function') {
            return props.renderLabel(recipient);
        }

        return recipient.fullName;
    }

    return (
        <section>
            {withTitle && (
                <h2 className="ui-action-bar__title +mbottom-12 text-left">
                    Send notifications
                </h2>
            )}

            {recipients.map((recipient) => (
                <FormField key={recipient.notificationIdentifier}>
                    {withLabels && (
                        <FormField.Label>
                            {renderLabel(recipient)}
                        </FormField.Label>
                    )}
                    <SelectableOptionList horizontal={horizontal}>
                        {emailAvailable && (
                            <div
                                className={classNames(
                                    'ui-notification-options__option',
                                    { '--enabled': recipient.hasEmail }
                                )}
                            >
                                <Checkbox
                                    checked={
                                        notify[recipient.notificationIdentifier]
                                            ?.email ?? false
                                    }
                                    onChange={(
                                        event: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        handleChange(
                                            recipient.notificationIdentifier,
                                            'email',
                                            event.target.checked
                                        )
                                    }
                                    label={
                                        <span className="m-0">
                                            <strong>
                                                Email
                                                {sendsAppNotification
                                                    ? ' / app'
                                                    : ''}
                                            </strong>
                                            <br />
                                            <span className="font-normal">
                                                {recipient.hasEmail
                                                    ? recipient.email
                                                    : 'No email'}
                                            </span>
                                        </span>
                                    }
                                    isDisabled={!recipient.hasEmail}
                                />
                            </div>
                        )}
                        {smsAvailable && (
                            <div
                                className={classNames(
                                    'ui-notification-options__option',
                                    { '--enabled': recipient.hasSmsPhone }
                                )}
                            >
                                <Checkbox
                                    checked={
                                        notify[recipient.notificationIdentifier]
                                            ?.sms ?? false
                                    }
                                    onChange={(
                                        event: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        handleChange(
                                            recipient.notificationIdentifier,
                                            'sms',
                                            event.target.checked
                                        )
                                    }
                                    label={
                                        <span className="m-0">
                                            <strong>SMS</strong>
                                            <br />
                                            <span className="font-normal">
                                                {recipient.hasSmsPhone
                                                    ? recipient.smsPhone
                                                    : 'No SMS number'}
                                            </span>
                                        </span>
                                    }
                                    isDisabled={
                                        !recipient.hasSmsPhone ||
                                        !currentUserStore.organisation
                                            .hasSmsCredits
                                    }
                                />
                            </div>
                        )}
                    </SelectableOptionList>

                    {smsAvailable &&
                        !currentUserStore.organisation.hasSmsCredits && (
                            <p className="mt-2 italic text-gray-400">
                                You do not have any SMS credits remaining. You
                                can purchase more in{' '}
                                <Link to="/organisation/settings/sms">
                                    settings
                                </Link>
                            </p>
                        )}
                </FormField>
            ))}
        </section>
    );
};

export default NotificationOptions;
