import { applySnapshot, flow, types } from 'mobx-state-tree';
import { Employer } from '../Models/Employer';
import EmployerTransport from '../Services/Transport/EmployerTransport';
import { sortAlphabeticallyByKey } from '../Services/String';

export const EmployerStore = types
    .model('EmployerStore', {
        employers: types.array(Employer),
    })

    .views((self) => ({
        find(uuid) {
            return self.employers.find((employer) => employer.uuid === uuid);
        },

        get available() {
            return sortAlphabeticallyByKey(
                self.employers.filter((employer) => !employer.isDeleted),
                'name'
            );
        },

        get hasAvailable() {
            return self.available.length > 0;
        },

        /**
         * Get all available employers, and optionally include the given employer
         * @param include
         * @returns {*}
         */
        availableIncluding(include = null) {
            const items = self.available.slice();

            if (
                include &&
                !items.find((employer) => employer.uuid === include)
            ) {
                const toInclude = self.find(include);

                if (toInclude) {
                    items.push(toInclude);
                }
            }

            return sortAlphabeticallyByKey(items, 'name');
        },
    }))

    .actions((self) => ({
        list: flow(function* list(params = {}) {
            const { data } = yield EmployerTransport.list(params);
            return data.data.map((employer) => self.createOrUpdate(employer));
        }),

        store: flow(function* store(employerData) {
            const { data } = yield EmployerTransport.store(employerData);
            return self.createOrUpdate(data.data);
        }),

        update: flow(function* update(uuid, employerData) {
            const { data } = yield EmployerTransport.update(uuid, employerData);
            return self.createOrUpdate(data.data);
        }),

        destroy: flow(function* destroy(uuid) {
            const { data } = yield EmployerTransport.destroy(uuid);
            return self.createOrUpdate(data.data);
        }),

        init(employers) {
            applySnapshot(self.employers, employers);
        },

        createOrUpdate(employerData) {
            let existing = self.find(employerData.uuid);

            if (existing) {
                applySnapshot(existing, employerData);
            } else {
                self.employers.push(employerData);
            }

            return self.find(employerData.uuid);
        },
    }));
