import { Client } from '@/Models/Client';
import { countInflect, possessive } from '@/Services/String';
import { getOrdinal } from '@/Services/Date';

export const clientBirthdayString = (client: Client, includeEmoji = true) => {
    let string = includeEmoji ? '🎂  ' : '';

    return (
        string +
        `${possessive(client.firstName)} ${client.age}${getOrdinal(
            client.age
        )} birthday!`
    );
};

export const clientAnniversaryString = (
    client: Client,
    includeEmoji = true
) => {
    let string = includeEmoji ? '🎉  ' : '';

    return (
        string +
        `Client for ${countInflect(client.yearsAsClient, 'year', 'years')}!`
    );
};
