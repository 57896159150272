import React from 'react';
import PropTypes from 'prop-types';
import CloseButton from './CloseButton';
import ActionBar from '../ActionBar';
import { useFormikContext } from 'formik';
import ModalFormError from '@/Components/ModalFormError';

const ModalHeader = ({
    title,
    leftAction,
    rightAction,
    closable = true,
    cancelText,
    pushed,
    ...props
}) => {
    if (!title && !leftAction && !rightAction && !closable) {
        return null;
    }
    const formikData = useFormikContext();
    const renderLeftGroup = () => {
        if (typeof leftAction === 'function') {
            return leftAction();
        } else if (closable) {
            return (
                <CloseButton
                    pushed={pushed}
                    cancelText={cancelText}
                    cancelType={props.cancelType}
                />
            );
        }
    };
    const renderCenterGroup = () => {
        if (!title) {
            return null;
        }
        return typeof title == 'string' ? (
            <ActionBar.Heading>{title}</ActionBar.Heading>
        ) : (
            title()
        );
    };
    const renderRightGroup = () => {
        if (typeof rightAction === 'function') {
            return rightAction();
        }
    };

    return (
        <header className="dialog__header">
            <ActionBar.Group left className="dialog__header__left-action">
                {renderLeftGroup()}
            </ActionBar.Group>
            <ActionBar.Group center className="dialog__header__title">
                {renderCenterGroup()}
            </ActionBar.Group>
            <ActionBar.Group right className="dialog__header__right-action">
                {renderRightGroup()}
            </ActionBar.Group>

            {!!props.content && (
                <section className="dialog__header__content">
                    {props.content}
                </section>
            )}
            <ModalFormError {...formikData} />
        </header>
    );
};

ModalHeader.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
    ]),
    leftAction: PropTypes.func,
    rightAction: PropTypes.func,
    content: PropTypes.object,
    closable: PropTypes.bool,
    cancelText: PropTypes.string,
};

ModalHeader.defaultProps = {
    closable: true,
};

export default ModalHeader;
