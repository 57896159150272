import BaseTransport from './BaseTransport';

class ExpenseTransport extends BaseTransport {
    async list(data = {}) {
        return await this.get('/v3/expenses', data);
    }

    async store(data) {
        return await this.post('/v3/expenses', data);
    }

    async update(uuid, input = {}) {
        return await this.put(`/v3/expenses/${uuid}`, input);
    }

    async destroy(uuid) {
        return await this.delete(`/v3/expenses/${uuid}`);
    }
}

export default new ExpenseTransport();
