import React from 'react';
import { createPortal } from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { uiColors } from '@/Theme';

const ToastContainer = () => {
    return createPortal(
        <Toaster
            toastOptions={{
                duration: 4000,
                style: {
                    zIndex: 10010,
                    paddingLeft: 16,
                    paddingRight: 6,
                },
                success: {
                    iconTheme: {
                        primary: uiColors.positive.default,
                        secondary: 'white',
                    },
                },
                error: {
                    iconTheme: {
                        primary: uiColors.negative.default,
                        secondary: 'white',
                    },
                },
            }}
            containerStyle={{
                top: 32,
                zIndex: 10010,
            }}
        />,
        document.body
    );
};

export default ToastContainer;
