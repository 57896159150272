import React, { FunctionComponent, SyntheticEvent, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { WhiteButton } from '@/Components/Button';

// const StyledDropzone = styled(Dropzone)`
//     &.accepted > ${DropContainer} {
//         box-shadow: inset 0px 0px 40px 10px rgba(98, 181, 75, 0.1);
//     }
//
//     &.rejected > ${DropContainer} {
//         box-shadow: inset 0px 0px 40px 10px rgba(255, 93, 75, 0.1);
//     }
//
//     ${(p) =>
//         p.format === 'client' &&
//         css`
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//         `}
// `;

interface Props {
    accept: Accept;
    multiple?: boolean;
    uploadMessage?: string;
    onFilesRead: (files: any[], failed: any[]) => void;
}

const UploadZone: FunctionComponent<Props> = ({
    onFilesRead,
    uploadMessage = 'Click, tap, or drop here to upload',
    ...props
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragReject,
        open,
    } = useDropzone({
        accept: props.accept,
        maxSize: 8388608,
        multiple: props.multiple ?? false,
        onDrop: (acceptedFiles: File[]) => handleDrop(acceptedFiles),
    });

    const handleDrop = (acceptedFiles: File[]) => {
        setIsLoading(true);

        const failed = [];
        const rejectFile = (file, reject) => {
            failed.push(file);
            reject();
        };

        Promise.all(
            (function* () {
                for (let file of acceptedFiles) {
                    yield new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = () =>
                            resolve({
                                file: Object.assign(file, {
                                    preview: URL.createObjectURL(file),
                                }),
                                binary: reader.result,
                            });
                        reader.onerror = () => rejectFile(file, reject);
                        reader.onabort = () => rejectFile(file, reject);
                        reader.readAsBinaryString(file);
                    });
                }
            })()
        )
            .then(async (read) => {
                await onFilesRead(read, failed);
            })
            .catch(() => {})
            .finally(() => setIsLoading(false));
    };

    const handleOpen = (ev: SyntheticEvent) => {
        if (isLoading) {
            return;
        }

        ev.preventDefault();
        open();
    };

    return (
        <div
            className={classNames('w-full', { '--with-loader': isLoading })}
            {...getRootProps()}
            onClick={(ev) => ev.stopPropagation()}
        >
            <input {...getInputProps()} />
            <WhiteButton
                onClick={handleOpen}
                className={classNames('!h-[90px] w-full !rounded-lg', {
                    'shadow-[inset_0px_0px_40px_10px_rgba(98,181,75,0.1)]':
                        isDragAccept,
                    'shadow-[inset_0px_0px_40px_10px_rgba(255,93,75,0.1)]':
                        isDragReject,
                })}
                isLoading={isLoading}
            >
                <div className="text-base">
                    {props.children}
                    {!isDragAccept && !isDragReject && (
                        <p className="open">{uploadMessage}</p>
                    )}
                    {isDragAccept && <p>Drop file to upload</p>}
                    {isDragReject && <p>File not accepted</p>}
                </div>
            </WhiteButton>
        </div>
    );
};

export default UploadZone;
