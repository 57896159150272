import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

interface Props {
    variant?:
        | 'default'
        | 'white'
        | 'primary'
        | 'destructive'
        | 'warning'
        | 'highlight'
        | 'clear'
        | 'active'
        | 'transparent';
    size?: 'small' | 'medium' | 'large';
    icon?: string;
    iconProps?: any;
    className?: string;
    onClick?(): void;
}

const Tag: FunctionComponent<Props> = ({
    size = 'medium',
    variant = 'default',
    iconProps = {},
    className,
    ...props
}) => {
    iconProps = {
        width: 14,
        height: 14,
        ...iconProps,
        className: classNames('ui-tag__icon', iconProps?.className),
    };

    function getClassNames() {
        const classes = [];

        switch (variant) {
            case 'default':
                classes.push(['bg-gray-100', 'text-slate-600']);
                break;
            case 'active':
                classes.push(['bg-[#19ae8e]', 'text-white']);
                break;
            case 'destructive':
                classes.push(['bg-red-400', 'text-white']);
                break;
            case 'white':
                classes.push([
                    'bg-white',
                    '+border-color-light',
                    '+color-default',
                ]);
                break;
            case 'highlight':
                classes.push(['+bg-highlight', '+color-default']);
                break;
        }

        return classNames(
            'ui-tag',
            {
                [`--size-${size}`]: !!size,
            },
            classes,
            className
        );
    }

    return (
        <span className={getClassNames()} {...props}>
            {props.icon && <Icon name={props.icon} {...iconProps} />}
            {props.children}
        </span>
    );
};

export default Tag;
