import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Message } from '@/Models/Message';
import { StackableModalProps } from '@/Components/RootModal';
import Modal from '@/Components/Modal';
import FormField from '@/Components/FormField';
import DomPurify from 'dompurify';
import RootStore from '@/Stores/RootStore';
import { TextButton } from '@/Components/Button';

interface Props extends StackableModalProps {
    message: Message;
}

const Component: FunctionComponent<Props> = ({ message, ...props }) => {
    const { uiStateStore } = RootStore.stores;

    return (
        <Modal
            title="Message"
            leftAction={() => (
                <TextButton onClick={() => uiStateStore.popModal()}>
                    Close
                </TextButton>
            )}
            {...props}
        >
            <FormField.Row>
                <FormField>
                    <FormField.Label>Recipient</FormField.Label>
                    <span className="font-bold">
                        {message.client?.fullName}
                    </span>
                </FormField>
                <FormField>
                    <FormField.Label>Sent</FormField.Label>
                    <span className="font-bold">
                        {message.createdAtFormatted}
                    </span>
                </FormField>
            </FormField.Row>
            <FormField.Row>
                <FormField>
                    <FormField.Label>Subject</FormField.Label>
                    <span className="font-bold">{message.subject}</span>
                </FormField>
                <FormField />
            </FormField.Row>
            <FormField>
                <FormField.Label>Message</FormField.Label>
                <div
                    className="prose prose-sm max-w-full rounded-lg border border-gray-200 p-4"
                    dangerouslySetInnerHTML={{
                        __html: DomPurify.sanitize(message.content),
                    }}
                />
            </FormField>
        </Modal>
    );
};

export const ViewMessageModal = observer(Component);
