import React from 'react';

const UIClassEnrolmentSimple = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            fill={props.fill}
            d="M10.4 0A1.6 1.6 0 0112 1.6v8.8a1.6 1.6 0 01-1.6 1.6H1.6A1.6 1.6 0 010 10.4V1.6A1.6 1.6 0 011.6 0h8.8zM6 3a3 3 0 100 6 3 3 0 000-6z"
            fillRule="evenodd"
        />
    </svg>
);

export default UIClassEnrolmentSimple;
