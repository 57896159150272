export default class Consultation {
    get nameSingular() {
        return 'consultation';
    }

    get namePlural() {
        return 'consultations';
    }

    get titleSingular() {
        return 'Consultation';
    }

    get titlePlural() {
        return 'Consultations';
    }
}
