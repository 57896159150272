import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

interface Props {
    content: string | JSX.Element;
    contentClassName?: string;
    secondaryContent?: string | JSX.Element;
    secondaryContentClassName?: string;
    icon?: JSX.Element;
    url?: string;
    className?: string;
}

const ItemListTextCell: FunctionComponent<Props> = ({
    content,
    secondaryContent,
    secondaryContentClassName,
    icon,
    url,
    contentClassName,
    className,
}) => {
    return (
        <div
            className={classNames(
                'relative flex pr-6 xl:flex-col xl:justify-center',
                className
            )}
        >
            {!!icon && (
                <div className="absolute right-full top-[3px] mr-1.5 flex h-[14px] w-[14px] items-center justify-center">
                    {icon}
                </div>
            )}
            <p
                className={classNames(
                    'mr-2 text-sm font-bold leading-[18px] xl:mr-0',
                    contentClassName
                )}
            >
                {!!url ? <NavLink to={url}>{content}</NavLink> : content}
            </p>
            {!!secondaryContent && (
                <p
                    className={classNames(
                        'text-sm leading-5 text-gray-400',
                        secondaryContentClassName
                    )}
                >
                    {secondaryContent}
                </p>
            )}
        </div>
    );
};

export default ItemListTextCell;
