import * as Yup from 'yup';
import RootStore from '../Stores/RootStore';
import { messages } from '../Config/validation';

/**
 * Get the validation rules for a delivery
 * @returns {{locationUrl: *, locationPassword: *, location: *}}
 */
export const deliveryValidationRules = () => ({
    location: Yup.string(),
    locationUrl: Yup.string().url(messages.url),
    locationPassword: Yup.string(),
});

/**
 * Convert form values to a valid delivery block
 * @param values
 * @returns {null|{password: string, location: *, url: string}}
 */
export const deliveryFromFormValues = (values: any) => {
    if (!values.location) {
        return null;
    }

    const location = RootStore.stores.locationStore.find(values.location);

    if (!location) {
        return null;
    }

    return {
        location: values.location,
        url: location.usesUrl ? values.locationUrl : null,
        password: location.usesPassword ? values.locationPassword : null,
    };
};
