import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../Stores/RootStore';
import { ClearButton, ResponsiveButton } from '../Button';
import Notification from '../../Services/Notification';

interface Props {
    isDisabled?: boolean;
    isArchived: boolean;
    onArchive(): void;
    onRestore(): void;
    content: JSX.Element;
    variant?: 'default' | 'menu';
}

const ArchiveRestoreButton: FunctionComponent<Props> = ({
    isArchived,
    isDisabled = false,
    onArchive,
    onRestore,
    content,
    variant = 'default',
}) => {
    const { uiStateStore } = RootStore.stores;
    const [isBusy, setIsBusy] = useState(false);
    const icon = isArchived ? 'Restore' : 'Archive';

    async function handleRestore() {
        setIsBusy(true);

        try {
            await onRestore();
        } catch (e) {
            Notification.error();
        }
        setIsBusy(false);
    }

    async function handleClick() {
        if (isArchived) {
            await handleRestore();
        } else {
            uiStateStore.pushModal('ARCHIVE', {
                onConfirmed: onArchive,
                content,
            });
        }
    }

    const buttonProps = {
        icon,
        children: icon,
        onClick: handleClick,
        isLoading: isBusy,
        disabled: isDisabled,
    };

    return (
        <>
            {variant === 'default' && <ResponsiveButton {...buttonProps} />}
            {variant === 'menu' && <ClearButton {...buttonProps} />}
        </>
    );
};

export default observer(ArchiveRestoreButton);
