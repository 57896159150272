import { flow, getRoot, getSnapshot, types } from 'mobx-state-tree';
import UserTransport from '../Services/Transport/UserTransport';
import OrganisationTransport from '../Services/Transport/OrganisationTransport';
import { User } from '../Models/User';
import SocketStore from './SocketStore';
import CustomerSupport from '@/Services/CustomerSupport';
import queryClient from '../Modules/QueryClient';
import { CurrentOrganisation } from '../Models/CurrentOrganisation';
import { Settings } from 'luxon';

export const CurrentUserStore = types
    .model('CurrentUserStore', {
        user: types.maybe(User),
        organisation: types.maybe(CurrentOrganisation),
    })

    .views((self) => ({
        get shouldDisplayConfirmationWarning() {
            return !self.user.activated && self.user.signInCount >= 3;
        },

        get referralUrl() {
            return self.organisation.referralUrl;
        },

        // get currentPlan() {
        //     return self.user.billing.plan;
        // },

        // get hasFreePeriod() {
        //     return !!self.user.billing.freePeriodEnd;
        // },

        // get wasReferred() {
        //     return self.user.billing.referrer !== null;
        // },

        // get hasReferralBonus() {
        //     return self.user.billing.referralBonusEndsAt !== null;
        // },

        // get firstPaymentDate() {
        //     if (self.hasReferralBonus) {
        //         return self.user.billing.referralBonusEndsAt;
        //     }
        //
        //     return self.user.billing.trialEndsAt;
        // },

        // get eligibleForPartnerPack() {
        //     return self.user.country === 'GB';
        // },

        get canIncreaseClientCount() {
            return (
                !self.organisation?.plan?.clientLimit ||
                self.organisation.activeClientCount <
                    self.organisation?.plan?.clientLimit
            );
        },
    }))

    .actions((self) => ({
        init(user, organisation) {
            self.updateUserData(user);
            self.updateOrganisationData(organisation);

            CustomerSupport.pushUserData();

            self.listenOnSocket();
        },

        updateUserData(data) {
            self.user = {
                ...self.user,
                ...data,
            };

            // Set timezone for Luxon
            if (data?.locale?.timezone) {
                Settings.defaultZoneName = data?.locale?.timezone;
            }

            return self.user;
        },

        updateProperty(key, value) {
            self.user[key] = value;
        },

        updateOrganisationData(data) {
            if (!data) {
                return;
            }

            if (data.defaultLocation?.uuid) {
                data.defaultLocation = getRoot(
                    self
                ).stores.locationStore.processAsNested(data.defaultLocation);
            }

            if (data.organisationUser?.uuid) {
                data.organisationUser = getRoot(
                    self
                ).stores.usersStore.processAsNested(data.organisationUser);
            }

            self.organisation = {
                ...(self.organisation ? getSnapshot(self.organisation) : {}),
                ...data,
            };

            return self.organisation;
        },

        applyOrganisationSwitch(data) {
            self.updateUserData(data.user);
            self.updateOrganisationData(data.organisation);

            try {
                getRoot(self).clearAfterSwitch();
            } catch (e) {
                console.log(e);
            }

            queryClient.clear();
        },

        switchOrganisation: flow(function* switchOrganisation(uuid) {
            const { data } = yield UserTransport.switchOrganisation(uuid);

            self.applyOrganisationSwitch(data);
        }),

        createOrganisation: flow(function* createOrganisation(values) {
            const { data } = yield OrganisationTransport.createOrganisation(
                values
            );

            self.applyOrganisationSwitch(data);
        }),

        listenOnSocket() {
            if (!self.organisation) {
                return;
            }

            const channel = SocketStore.getChannel(self.organisation.uuid);

            if (!channel) {
                return;
            }

            channel
                .listen('UserSmsCreditsUpdated', (data) => {
                    if (!!data.count) {
                        self.updateUserBilling({ smsCredits: data.count });
                    }
                })
                .listen('StripePaymentProviderEnabled', (data) => {
                    self.updateOrganisationData({
                        canReceivePayment: data.onboarded && data.enabled,
                    });
                })
                .listen('ClientPasswordUpdated', (data) => {
                    const client = getRoot(self).stores.clientStore.find(
                        data?.client
                    );

                    if (client) {
                        client.updateData(data.data);
                    }
                });
        },

        hasFeature(feature) {
            return (
                Array.isArray(self.user.features) &&
                self.user.features.includes(feature)
            );
        },

        addDismissedTip(identifier) {
            if (!Array.isArray(self.user.hasDismissed)) {
                self.user.hasDismissed = [identifier];
            } else if (!self.user.hasDismissed.includes(identifier)) {
                self.user.hasDismissed.push(identifier);
            }
        },

        updateUserBilling(billingData) {
            self.user.billing = {
                ...self.user.billing,
                ...billingData,
            };
        },

        refreshUser: flow(function* refreshUser() {
            const { data } = yield UserTransport.userData();
            self.updateUserData(data.data);
        }),

        refreshCurrentOrganisation: flow(
            function* refreshCurrentOrganisation() {
                const { data } = yield UserTransport.getCurrentOrganisation();

                self.updateOrganisationData(data.data);
            }
        ),

        refreshActiveClientCount: flow(function* refreshActiveClientCount() {
            try {
                let { data } =
                    yield OrganisationTransport.getActiveClientCount();

                self.updateOrganisationData({
                    activeClientCount: data.activeClientCount,
                });

                return data.activeClientCount;
            } catch (e) {
                console.log(e);
            }
        }),

        updateBrand: flow(function* updateBrand(brandData) {
            let { data } = yield UserTransport.updateBrand(brandData);
            self.updateUserData(data.data);
        }),

        fetchPaymentHistory: flow(function* fetchPaymentHistory(params = {}) {
            return yield UserTransport.fetchPaymentHistory(params);
        }),

        dismissTip: flow(function* dismissTip(identifier) {
            try {
                yield UserTransport.dismissTip(identifier);
                self.addDismissedTip(identifier);
            } catch (e) {}
        }),

        updateAccountProfile: flow(function* updateAccountProfile(values) {
            const { data } = yield UserTransport.updateAccountProfile(values);

            self.updateUserData(data.data);
        }),

        updateAccountSecurity: flow(function* updateAccountSecurity(values) {
            const { data } = yield UserTransport.updateAccountSecurity(values);

            self.updateUserData(data.data);
        }),

        updateAccountPreferences: flow(function* updateAccountPreferences(
            values
        ) {
            const { data } = yield UserTransport.updateAccountPreferences(
                values
            );

            self.updateUserData(data.data);
        }),

        cancelNewEmail: flow(function* cancelNewEmail() {
            const { data } = yield UserTransport.cancelNewEmail();
            self.updateUserData(data);
        }),

        resendVerificationEmail: flow(function* resendVerificationEmail() {
            yield UserTransport.resendVerificationEmail();
        }),
    }));
