import messaging from '../Config/messaging';
import { toast, ToastOptions } from 'react-hot-toast';

export default class Notification {
    static dismiss() {
        toast.dismiss();
    }

    static success(message: string | JSX.Element, options?: ToastOptions) {
        toast.success(message, options ?? {});
    }

    static error(message: string | null = null, options?: ToastOptions) {
        if (!message) {
            message = messaging.error.generic;
        }

        toast.error(message, options ?? {});
    }
}
