import { types } from 'mobx-state-tree';
import { PhysicalLocation } from './PhysicalLocation';
import { VirtualLocation } from './VirtualLocation';
import RootStore from '../Stores/RootStore';

export const Location = types.union(
    {
        dispatcher: (snapshot) => {
            switch (snapshot.locationType) {
                case 'physical':
                    return PhysicalLocation;
                case 'virtual':
                    return VirtualLocation;
                default:
                    throw new Error();
            }
        },
    },
    PhysicalLocation,
    VirtualLocation
);

export const LocationReference = types.reference(Location, {
    get(uuid) {
        return RootStore.stores.locationStore.find(uuid) || null;
    },
    set(location) {
        return location.uuid;
    },
});
