import { types } from 'mobx-state-tree';
import { observable } from 'mobx';

export const UiStateStore = types
    .model('UiStateStore', {
        currentSection: '',
        currentSectionLoading: false,
        popperOpen: false,
        modalHidden: false,
    })

    .volatile((self) => ({
        openModalsVolatile: observable.array([]),
    }))

    .views((self) => ({
        get openModals() {
            return self.openModalsVolatile;
        },

        get modalAtTop() {
            if (self.openModals.length) {
                return self.openModals[self.openModals.length - 1];
            }
        },
    }))

    .actions((self) => ({
        setCurrentSection(section) {
            self.currentSection = section;

            const title = !!section ? `${section} - Striive` : 'Striive';
            if (document.title !== title) {
                document.title = title;
            }
        },

        setCurrentSectionLoading(loading) {
            self.currentSectionLoading = loading;
        },

        setPopperOpen(state) {
            if (state !== self.popperOpen) {
                self.popperOpen = state;
            }
        },

        /**
         * Hide the modal, but do not close it.
         * This is a bridging mechanism to aid in displaying an AngularJS modal after a React one.
         */
        temporarilyHideModal() {
            self.modalHidden = true;
        },

        /**
         * Re-enable the modal
         */
        unhideModal() {
            self.modalHidden = false;
        },

        /**
         * Add a new modal to the stack
         * @param identifier
         * @param props
         * @param removeBeneath
         */
        pushModal(identifier, props = {}, removeBeneath = false) {
            self.setPopperOpen(false);

            /**
             * Do not allow multiple instances of the same modal
             */
            if (
                self.openModals.find((modal) => modal.identifier === identifier)
            ) {
                return;
            }

            if (removeBeneath) {
                self.openModalsVolatile.clear();
            }

            self.openModalsVolatile.push({
                identifier,
                props,
            });
        },

        /**
         * Pop the last modal off of the stack
         */
        popModal(identifier = null) {
            if (self.openModals.length) {
                const lastItem = self.openModals[self.openModals.length - 1];

                if (!identifier || identifier === lastItem.identifier) {
                    self.openModals.remove(lastItem);
                }
            }
        },

        closeStack() {
            self.openModalsVolatile.clear();
        },
    }));
