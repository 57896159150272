import { types } from 'mobx-state-tree';
import Date, { timeRange } from '../Services/Date';
import { getFormattedUrl } from '@/Services/Schedule';

export default types
    .model({})
    .views((self) => ({
        get isSession() {
            return self.type === 'session';
        },

        get isConsultation() {
            return self.type === 'consultation';
        },

        get isClassDate() {
            return self.type === 'class-date';
        },

        get isAppointment() {
            return self.type === 'diary-entry';
        },

        get timeRangeDurationString() {
            let duration = Date.duration(self.start, self.end);
            return `${timeRange(self.start, self.end)} (${duration})`;
        },

        getViewScheduleUrl() {
            return getFormattedUrl(self.start, 'responsive', {
                calendar: self.assignedUsers?.[0]?.uuid ?? 'unassigned',
            });
        },
    }))

    .actions((self) => ({
        setCancelled(cancelled) {
            self.isCancelled = cancelled;
        },
    }));
