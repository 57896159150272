import BaseTransport from './BaseTransport';

class LocationTransport extends BaseTransport {
    async listCombined(params = {}) {
        return await this.get('/v3/locations', params);
    }

    async updateVirtual(uuid, data = {}) {
        return await this.put(`/v3/virtual-locations/${uuid}`, data);
    }
}

export default new LocationTransport();
