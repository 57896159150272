export const emergencyContactRelationships = [
    {
        order: 0,
        value: 'spouse',
        label: 'Spouse/Partner',
    },
    {
        order: 1,
        value: 'relative',
        label: 'Relative',
    },
    {
        order: 2,
        value: 'friend',
        label: 'Friend',
    },
];

export const clientGroupString = (clients, displayType = 'initialed') => {
    if (!clients.length || !clients[0]?.[displayType]) return '';

    let label = clients[0][displayType];
    let remaining = clients.slice(1).length;

    return remaining ? `${label} + ${remaining}` : label;
};

/**
 * Get the label for the user's selected relationship
 * @param value
 * @returns {null}
 */
export const getEmergencyContactRelationshipLabel = (value) => {
    const selected = emergencyContactRelationships.find(
        (relation) => relation.value === value
    );
    return selected ? selected.label : null;
};
