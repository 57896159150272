import BaseTransport from '@/Services/Transport/BaseTransport';
import { RoleFormValues } from '@/Features/Roles/Types';

class RolesTransport extends BaseTransport {
    async list(params: any = {}) {
        return await this.get('/v3/roles', params);
    }

    async listPermissions() {
        return await this.get('v3/permissions');
    }

    async saveRole(data: RoleFormValues, id?: number) {
        if (id) {
            return await this.put(`v3/roles/${id}`, data);
        }
        return await this.post('v3/roles', data);
    }

    async deleteRole(id: number) {
        return await this.delete(`v3/roles/${id}`);
    }
}

export default new RolesTransport();
