import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    StyledButton,
    StyledButtonIcon,
    StyledClearButton,
    StyledDestructiveButton,
    StyledDestructiveClearButton,
    StyledDestructiveTextButton,
    StyledMenuButton,
    StyledPrimaryButton,
    StyledPrimaryResponsiveButton,
    StyledResponsiveButton,
    StyledTextButton,
    StyledToggleableButton,
    StyledWhiteButton,
} from './Style';
import history from '@/Modules/History';

const handleLinkWrapping = (Component, props, ref) => {
    const { href, to, target, children, disabled, isLoading, ...rest } = props;
    const isSquare = props.variant === 'square' || (!children && rest.icon);
    const renderChildren =
        typeof children === 'string' ? <span>{children}</span> : children;
    const isExternalLink =
        href?.startsWith('http') || href?.startsWith('mailto');

    function handleClick(ev) {
        ev.preventDefault();
        history.push(href);
    }

    const hrefProps = useMemo(() => {
        if (typeof href === 'undefined') {
            return {};
        }

        return isExternalLink
            ? {
                  as: 'a',
                  href,
              }
            : {
                  onClick: handleClick,
              };
    }, [href, isExternalLink]);

    return (
        <Component
            square={isSquare}
            isLoading={isLoading}
            disabled={disabled || isLoading ? 'disabled' : undefined}
            ref={ref}
            target={target}
            rel={isExternalLink ? 'noopener noreferrer' : undefined}
            {...hrefProps}
            {...rest}
        >
            {props.icon && typeof props.icon === 'string' && (
                <StyledButtonIcon
                    name={props.icon}
                    fill={props.fill}
                    width={props.iconWidth}
                    height={props.iconHeight}
                    className={props.iconClassName}
                    position="before"
                />
            )}
            {props.icon && typeof props.icon !== 'string' && props.icon}
            {renderChildren}
        </Component>
    );
};

export const Button = React.forwardRef((props, ref) =>
    handleLinkWrapping(StyledButton, props, ref)
);

export const PrimaryButton = React.forwardRef((props, ref) =>
    handleLinkWrapping(StyledPrimaryButton, props, ref)
);
export const DestructiveButton = React.forwardRef((props, ref) =>
    handleLinkWrapping(StyledDestructiveButton, props, ref)
);
export const ClearButton = React.forwardRef((props, ref) =>
    handleLinkWrapping(StyledClearButton, props, ref)
);
export const WhiteButton = React.forwardRef((props, ref) =>
    handleLinkWrapping(StyledWhiteButton, props, ref)
);
export const MenuButton = React.forwardRef((props, ref) =>
    handleLinkWrapping(StyledMenuButton, { type: 'button', ...props }, ref)
);
export const DestructiveClearButton = React.forwardRef((props, ref) =>
    handleLinkWrapping(StyledDestructiveClearButton, props, ref)
);

export const TextButton = React.forwardRef((props, ref) =>
    handleLinkWrapping(StyledTextButton, props, ref)
);
export const DestructiveTextButton = React.forwardRef((props, ref) =>
    handleLinkWrapping(StyledDestructiveTextButton, props, ref)
);

export const ResponsiveButton = React.forwardRef((props, ref) =>
    handleLinkWrapping(StyledResponsiveButton, props, ref)
);
export const PrimaryResponsiveButton = React.forwardRef((props, ref) =>
    handleLinkWrapping(StyledPrimaryResponsiveButton, props, ref)
);

export const ToggleableButton = React.forwardRef((props, ref) =>
    handleLinkWrapping(StyledToggleableButton, props, ref)
);

Button.Icon = StyledButtonIcon;

Button.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    submit: PropTypes.bool,
    isLoading: PropTypes.bool,
    icon: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.node,
    ]),
    iconWidth: PropTypes.number,
    iconHeight: PropTypes.number,
    variant: PropTypes.oneOf(['square', 'delete', 'transparent']),
    size: PropTypes.oneOf(['small', 'large', 'full']),
};
