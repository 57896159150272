import {
    applySnapshot,
    flow,
    getRoot,
    getSnapshot,
    types,
} from 'mobx-state-tree';
import { DateTimeType } from '../Types/DateTime';
import { ClientReference } from './Client';
import { DateTime } from 'luxon';
import { formatDate, inUserZone } from '../Services/Date';
import MembershipSubscriptionTransport from '../Services/Transport/MembershipSubscriptionTransport';
import { MembershipReference } from './Membership';
import { Transaction } from './Transaction';

export const MembershipSubscription = types
    .model('MembershipSubscription', {
        uuid: types.identifier,
        status: types.string,
        reference: types.string,
        client: types.late(() => ClientReference),
        terms: types.maybeNull(types.string),
        rebillsAt: types.maybeNull(DateTimeType),
        paymentFailedAt: types.maybeNull(DateTimeType),
        endsAt: types.maybeNull(DateTimeType),
        membership: types.late(() => MembershipReference),
        purchasedAt: DateTimeType,
        createdAt: DateTimeType,
        remainingCredits: types.maybeNull(types.number),
    })

    .views((self) => ({
        get url() {
            return self.membership?.url;
        },

        get humanType() {
            return 'subscription';
        },

        get isOverdue() {
            return self.status === 'past_due';
        },

        get isCancelled() {
            return self.status === 'canceled';
        },

        get hasEndDate() {
            return self.endsAt instanceof DateTime;
        },

        get onGracePeriod() {
            return (
                self.status === 'active' &&
                self.hasEndDate &&
                self.endsAt > inUserZone()
            );
        },

        get createdAtFormatted() {
            return formatDate(self.createdAt);
        },
    }))

    .actions((self) => ({
        cancel: flow(function* cancel() {
            const { data } = yield MembershipSubscriptionTransport.cancel(
                self.uuid
            );

            applySnapshot(self, {
                ...getSnapshot(self),
                ...data.data,
            });
        }),
    }));

export const MembershipSubscriptionReference = types.maybeNull(
    types.reference(MembershipSubscription, {
        get(uuid, parent) {
            const params = Transaction.is(parent)
                ? { include: ['membership'] }
                : {};
            return getRoot(
                parent
            ).stores.membershipSubscriptionStore.findOrFetch(uuid, params);
        },
        set({ uuid }) {
            return uuid;
        },
    })
);
