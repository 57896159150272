import {
    applySnapshot,
    flow,
    getRoot,
    getSnapshot,
    isValidReference,
    types,
} from 'mobx-state-tree';
import { Image } from './Image';
import RootStore from '../Stores/RootStore';
import { DateTimeType } from '@/Types/DateTime';
import { ClassPass } from './ClassPass';
import { ParqAnswer } from '@/Models/ParqAnswer';
import { age, ageInYears, formatDate, inUserZone } from '@/Services/Date';
import { ClientAgreement } from '@/Models/ClientAgreement';
import ClientTransport from '@/Services/Transport/ClientTransport';
import { ClientDataReview } from '@/Models/ClientDataReview';
import PortalInviteTransport from '@/Services/Transport/PortalInviteTransport';

const SessionOrClassDate = types.model({
    identifier: types.identifier,
    type: types.enumeration(['session', 'class-date']),
    date: DateTimeType,
    label: types.string,
});

export const Client = types
    .model('Client', {
        eid: types.string,
        type: types.literal('client'),
        identifier: types.identifier,
        resourceVersion: 2,
        image: types.maybeNull(Image),
        firstName: types.string,
        lastName: types.string,
        gender: types.maybeNull(types.string),
        dob: types.maybeNull(DateTimeType),
        email: types.maybeNull(types.string),
        homePhone: types.maybeNull(types.string),
        mobilePhone: types.maybeNull(types.string),
        address1: types.maybeNull(types.string),
        address2: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        postCode: types.maybeNull(types.string),
        country: types.string,
        countryName: types.maybeNull(types.string),
        timezone: types.string,
        trainingNotes: types.maybeNull(types.string),
        signedUpAt: types.maybeNull(DateTimeType),
        isArchived: false,

        isActive: true,
        lastActive: types.maybeNull(SessionOrClassDate),
        nextActive: types.maybeNull(SessionOrClassDate),

        placeholder: false,

        automaticReminders: types.boolean,

        // Portal
        portalEnabled: types.boolean,
        portalRegistered: types.boolean,
        lastSeen: types.maybeNull(DateTimeType),

        // Emergency contact
        emergencyFirstName: types.maybeNull(types.string),
        emergencyLastName: types.maybeNull(types.string),
        emergencyFullName: types.maybeNull(types.string),
        emergencyRelationship: types.maybeNull(types.string),
        emergencyDaytimePhone: types.maybeNull(types.string),
        emergencyEveningPhone: types.maybeNull(types.string),

        totalDebt: types.maybe(types.number),

        classPasses: types.array(
            types.late(() => types.safeReference(ClassPass))
        ),

        // Optional attributes
        hasPortalPasswordInvite: types.maybe(types.boolean),
        currentAgreement: types.maybe(types.maybeNull(ClientAgreement)),
        currentParq: types.maybe(types.array(ParqAnswer)),
        attendanceStatus: types.maybe(
            types.model({
                attended: types.integer,
                forfeited: types.integer,
                total: types.integer,
            })
        ),
        paymentStatus: types.maybe(
            types.model({
                owes: types.integer,
                paid: types.integer,
                total: types.integer,
            })
        ),
        dataReviews: types.maybe(types.array(ClientDataReview)),
    })

    .preProcessSnapshot((snapshot) => {
        const newSnapshot = { ...snapshot };

        if (snapshot.resourceVersion === 1) {
            newSnapshot.isArchived = newSnapshot.archived;
            newSnapshot.isActive = newSnapshot.active;

            if (typeof snapshot.image?.data !== 'undefined') {
                newSnapshot.image = snapshot.image.data;
            }
        }

        return newSnapshot;
    })

    .views((self) => ({
        get url() {
            return `/clients/roster/${self.eid}`;
        },

        get fullName() {
            return `${self.firstName} ${self.lastName}`;
        },

        get initialed() {
            let lastNameInitial = self.lastName.charAt(0).toUpperCase();
            return `${self.firstName} ${lastNameInitial}`;
        },

        get hasImage() {
            return !!self.image;
        },

        get age() {
            if (!self.dob) {
                return null;
            }

            return ageInYears(self.dob);
        },
        get isBirthday() {
            if (!self.dob) {
                return false;
            }

            return (
                self.dob.toFormat('MM-dd') ===
                inUserZone(null, false).toFormat('MM-dd')
            );
        },

        get isAnniversary() {
            if (!self.signedUpAt) {
                return false;
            }

            const isToday =
                self.signedUpAt.toFormat('MM-dd') ===
                inUserZone(null, false).toFormat('MM-dd');

            return isToday && self.yearsAsClient >= 1;
        },

        get yearsAsClient() {
            if (!self.signedUpAt) {
                return null;
            }

            return ageInYears(self.signedUpAt);
        },

        get isOccasion() {
            return self.isBirthday || self.isAnniversary;
        },

        get imageSrc() {
            return self.image.urls.small;
        },

        get notificationIdentifier() {
            return self.eid;
        },

        get hasEmail() {
            return !!self.email;
        },

        get hasSmsPhone() {
            return !!self.mobilePhone;
        },

        get smsPhone() {
            return self.mobilePhone;
        },

        get hasPreviousActivity() {
            return !!self.lastActive;
        },

        // get hasRecentActivity() {
        //     if (!self.lastActive) {
        //         return false;
        //     }
        //
        //     const now = DateTime.local();
        //     const diff = now.diff(self.lastActive, 'days').toObject();
        //
        //     return diff.days <= 14;
        // },

        get hasUpcomingActivity() {
            return !!self.nextActive;
        },

        get nextActiveString() {
            if (!self.hasUpcomingActivity) {
                return 'None';
            }

            return formatDate(self.nextActive.date);
        },

        get hasAgreement() {
            return isValidReference(() => self.currentAgreement);
        },

        get hasParq() {
            return (
                Array.isArray(self.currentParq) && self.currentParq.length > 0
            );
        },

        get failedParqs() {
            return self.currentParq.filter((q) => q.answer);
        },

        get failedParqCount() {
            if (!Array.isArray(self.currentParq)) {
                return 0;
            }

            return self.failedParqs.length;
        },

        get hasPassedParq() {
            return self.failedParqCount === 0;
        },

        get parqUpdatedDate() {
            if (!self.hasParq) {
                return null;
            }

            return self.currentParq[0].updatedAt;
        },

        get parQOutOfDate() {
            return (
                self.hasParq &&
                self.parqUpdatedDate < inUserZone().minus({ years: 1 })
            );
        },

        get hasEmergencyContact() {
            return !!self.emergencyFirstName;
        },

        get emergencyContactFullName() {
            return [self.emergencyFirstName, self.emergencyLastName].join(' ');
        },

        get firstDataReview() {
            return Array.isArray(self.dataReviews) &&
                self.dataReviews.length > 0
                ? self.dataReviews[0]
                : null;
        },

        get lastSeenAge() {
            return !!self.lastSeen ? age(self.lastSeen, true) : null;
        },
    }))

    .actions((self) => ({
        afterCreate: flow(function* afterCreate() {
            if (self.placeholder) {
                yield RootStore.stores.clientStore.fetch(self.eid);
            }

            self.placeholder = false;
        }),

        update: flow(function* update(input = {}) {
            const { data } = yield ClientTransport.update(self.eid, input);
            getRoot(self).stores.clientStore.createOrUpdate(data.data);
        }),

        updateNotes: flow(function* updateNotes(notes) {
            const { data } = yield ClientTransport.updateNotes(self.eid, notes);
            getRoot(self).stores.clientStore.createOrUpdate(data.data);
        }),

        updateParqAnswer: flow(function* updateParqAnswer(questionId, notes) {
            const { data } = yield ClientTransport.updateParqAnswer(
                self.eid,
                questionId,
                notes
            );
            self.currentParq = data.data;
        }),

        updateEmergencyContact: flow(function* updateEmergencyContact(input) {
            const { data } = yield ClientTransport.updateEmergencyContact(
                self.eid,
                input
            );
            getRoot(self).stores.clientStore.createOrUpdate(data.data);
        }),

        archive: flow(function* archive() {
            const { data } = yield ClientTransport.archive(self.eid);
            self.updateData(data.data);
        }),

        restore: flow(function* restore() {
            const { data } = yield ClientTransport.restore(self.eid);
            self.updateData(data.data);
        }),

        inviteToPortal: flow(function* inviteToPortal() {
            const { data } = yield PortalInviteTransport.password(self.eid);
            self.updateData(data.data);
        }),

        updateData(data = {}) {
            applySnapshot(self, {
                ...getSnapshot(self),
                ...data,
            });
        },

        /**
         * Class passes
         */

        pushClassPassReferences(refs = []) {
            refs.forEach((ref) => {
                if (!self.classPasses.find((r) => r.uuid === ref)) {
                    self.classPasses.push(ref);
                }
            });
        },
    }));

export const ClientReference = types.maybeNull(
    types.safeReference(Client, {
        get(identifier, parent) {
            return getRoot(parent).stores.clientStore.findOrFetch(identifier);
        },
        set({ identifier }) {
            return identifier;
        },
    })
);
