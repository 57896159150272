import * as store from 'store';
import Notification from '@/Services/Notification';
import React from 'react';
import { TextButton } from '@/Components/Button';

export const setAppVersion = (appVersion?: string): void => {
    if (typeof appVersion === 'string') {
        store.set('striive.version', appVersion);
    } else {
        store.remove('striive.version');
    }
};

export const displayUpdateNotification = (newAppVersion?: string): void => {
    if (!newAppVersion) {
        return;
    }

    const currentVersion = store.get('striive.version');

    setAppVersion(newAppVersion);

    if (currentVersion && newAppVersion !== currentVersion) {
        Notification.dismiss();
        Notification.success(
            <div className="flex items-center space-x-6">
                <span>There's a new version of Striive!</span>
                <TextButton
                    onClick={() => window.location.reload()}
                    size="small"
                >
                    Update
                </TextButton>
            </div>,
            {
                duration: Infinity,
            }
        );
    }
};
