export const withDecimals = (number: number, decimals: number = 2) => {
    let divisor = Math.pow(10, decimals);
    return parseFloat(Math.round(number * divisor) / divisor).toFixed(decimals);
};

export const roundToNearest = (number: number, nearest: number = 1) => {
    return Math.floor(number / nearest) * nearest;
};

export type PercentageValue = number | string | null;

export const simplePercentage = (
    number: number,
    total: number,
    withErrorSymbol = true
): PercentageValue => {
    if (!total) {
        return withErrorSymbol ? '∅' : null;
    }

    return Math.round((number / total) * 100);
};

export const simplifyInteger = (number: number, decimals = 0) => {
    return number % 1 === 0
        ? parseInt(String(number))
        : decimals
        ? withDecimals(number, decimals)
        : number;
};
