import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface Props {
    withShroud?: boolean;
    withPosition?: boolean;
    className?: string;
    variant?: 'default' | 'white';
}

const Loader: FunctionComponent<Props> = ({
    withShroud = false,
    withPosition = false,
    variant = 'default',
    className,
}) => {
    function getContainerClasses() {
        const classes = withShroud ? 'absolute inset-0' : 'relative';
        return classNames(classes, className);
    }

    return (
        <div className={getContainerClasses()}>
            {withShroud && (
                <div className="absolute inset-0 z-50 bg-white opacity-75" />
            )}
            <div
                className={classNames(
                    'z-[110] h-4 w-4 animate-spin rounded-full border-2',
                    {
                        'absolute left-1/2 top-1/2 -ml-2 -mt-2': withPosition,
                        'border-[#D1D5DB] border-t-[#9ca3af]':
                            variant === 'default',
                        'border-[rgba(255,255,255,0.3)] border-t-[rgba(255,255,255,0.8)]':
                            variant === 'white',
                    }
                )}
            />
        </div>
    );
};

export default Loader;
