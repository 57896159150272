import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import {
    CreateUpdateMessageModal,
    MessageFormValues,
} from '@/Features/Messages';
import { StackableModalProps } from '@/Components/RootModal';
import MessageTransport from '@/Services/Transport/MessageTransport';
import CustomerSupport from '@/Services/CustomerSupport';
import { Session } from '@/Models/Session';
import { formatDate, formatTime } from '@/Services/Date';
import FormField from '@/Components/FormField';
import Tag from '@/Components/Tag';

interface Props extends StackableModalProps {
    session: Session;
}

const Component: FunctionComponent<Props> = ({ session, ...props }) => {
    const mapPropsToValues = (): MessageFormValues => ({
        recipients: [],
        subject: '',
        content: '',
    });

    const handleSave = async (values: MessageFormValues) => {
        await MessageTransport.storeSessionMessage(session.eid, values);

        CustomerSupport.trackEvent('Message Sent');
    };

    return (
        <CreateUpdateMessageModal
            isCreating
            stackProps={props.stackProps}
            mapPropsToValues={mapPropsToValues}
            onSave={handleSave}
            hasRecipientField={false}
            recipientContent={
                <FormField>
                    <FormField.Label required>Recipients</FormField.Label>
                    <Tag
                        size="large"
                        className="!h-auto !whitespace-normal py-2 font-bold !leading-normal"
                    >
                        Session attendees: {session.sessionPack?.name} (
                        {formatDate(session.start)} -{' '}
                        {formatTime(session.start)})
                    </Tag>
                </FormField>
            }
        />
    );
};

export const CreateSessionMessageModal = observer(Component);
