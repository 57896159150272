import React, { FunctionComponent } from 'react';
import ModalHeaderAlert from '@/Components/ModalHeaderAlert';

interface Props {
    isValid?: boolean;
    status?: {
        status: 'error';
        message: string;
    };
    submitCount?: number;
}

const ModalFormError: FunctionComponent<Props> = ({
    isValid,
    status,
    submitCount,
}) => {
    if (
        isValid === undefined &&
        status === undefined &&
        submitCount === undefined
    ) {
        return null;
    }

    if ((isValid || submitCount === 0) && status?.status !== 'error') {
        return null;
    }

    return <ModalHeaderAlert message={status?.message} />;
};

export default ModalFormError;
