import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import {
    ArchiveClientButton,
    ClientSettingsButton,
    UpdateClientButton,
} from '@/Features/Clients';
import { Client } from '@/Models/Client';
import { CreateMessageButton } from '@/Features/Messages';
import { NewPopper } from '@/Components/NewPopper';

interface Props {
    client: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    return (
        <NewPopper placement="bottom-end">
            {() => (
                <div className="ui-button-list">
                    {!client.isArchived && (
                        <>
                            <UpdateClientButton client={client} />
                            <CreateMessageButton
                                selected={[client.eid]}
                                variant="menu"
                                icon="MessageText"
                            >
                                Send message
                            </CreateMessageButton>
                            <ClientSettingsButton client={client} />
                        </>
                    )}
                    <ArchiveClientButton client={client} />
                </div>
            )}
        </NewPopper>
    );
};

export const ClientOptionsMenu = observer(Component);
