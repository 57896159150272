import angular from 'angular';
import { react2angular } from 'react2angular';
import ClientCard from '../../../js/Components/Wrappers/ClientCard';

angular
    .module('component.react.client-card', [])
    .component(
        'reactClientCard',
        react2angular(ClientCard, [
            'size',
            'client',
            'link',
            'linkTab',
            'suffix',
        ])
    );
