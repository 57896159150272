import { applySnapshot, flow, getRoot, types } from 'mobx-state-tree';
import { DateTimeType } from '@/Types/DateTime';
import { formatDate } from '@/Services/Date';
import { capitaliseWord } from '@/Services/String';
import PortalInviteTransport from '@/Services/Transport/PortalInviteTransport';

export const PortalInvite = types
    .model('PortalInvite', {
        uuid: types.identifier,
        email: types.maybeNull(types.string),
        scope: types.array(types.string),
        createdAt: DateTimeType,
        expiresAt: DateTimeType,
    })

    .views((self) => ({
        get createdAtFormatted() {
            return formatDate(self.createdAt, true, true);
        },

        get expiresAtFormatted() {
            return formatDate(self.expiresAt, true, true);
        },

        get scopesString() {
            return self.scope
                .map((scope) => {
                    return scope === 'parq'
                        ? 'PAR-Q'
                        : capitaliseWord(scope.replace('-', ' '));
                })
                .join(', ');
        },
    }))

    .actions((self) => ({
        remove: flow(function* remove() {
            yield PortalInviteTransport.destroy(self.uuid);
            getRoot(self).stores.portalInviteStore.delete(self.uuid);
        }),

        resend: flow(function* resend() {
            const { data } = yield PortalInviteTransport.resend(self.uuid);
            applySnapshot(self, data.data);
        }),
    }));
