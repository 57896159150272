import styled, { css } from 'styled-components';
import { animations, fonts, uiColors } from '../../Theme';
import { maxWidth } from '../../Theme/breakpoints';
import Icon from '../Icon/Icon';

export const StyledButtonIcon = styled(Icon).attrs(
    ({ width = 18, height = 18 }) => {
        return { width, height };
    }
)`
    ${(p) =>
        p.position === 'before' &&
        css`
            margin-left: -2px;
            margin-right: 6px;
        `}
    ${(p) =>
        p.position === 'after' &&
        css`
            margin-left: 6px;
            margin-right: -2px;
        `}
`;

const lightLoader = css`
    &:after {
        border-color: #fff;
        border-right-color: transparent;
        border-top-color: transparent;
    }
`;

const baseStyles = css`
    display: inline-flex;
    -webkit-appearance: none;
    font-size: ${fonts.size(13)};
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0;
    color: ${uiColors.text.default};
    text-align: center;
    white-space: nowrap;
    outline: none;
    opacity: ${(props) => (props.disabled ? '0.6' : '1')};

    &:disabled {
        cursor: default;
    }
`;

const primaryStyles = css`
    background-color: ${uiColors.core.primary};
    color: #fff;

    ${StyledButtonIcon} svg {
        fill: #fff;
    }

    &:hover:enabled {
        background-color: ${uiColors.core.primaryDark};
    }

    ${(p) => p.isLoading && lightLoader}
`;

const destructiveStyles = css`
    background-color: ${uiColors.negative.default};
    color: #fff;

    ${StyledButtonIcon} svg {
        fill: #fff;
    }

    &:hover:enabled {
        background: ${uiColors.negative.dark};
    }

    ${(p) => p.isLoading && lightLoader}
`;

export const A = styled.a`
    display: flex;
    align-items: center;
    flex: none;
`;

export const StyledButton = styled.button`
    ${baseStyles};
    transition: background 0.1s, color 0.1s;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    vertical-align: top;
    height: 40px;
    padding: 0 12px;
    background-color: ${uiColors.button.default};
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;

    &:hover:enabled {
        background: ${uiColors.button.hover};
    }

    // Sizes
    ${(props) =>
        props.size === 'small' &&
        css`
            height: 30px;
            padding: 0 12px;
        `}
    ${(props) =>
        props.size === 'large' &&
        css`
            height: 50px;
            padding: 0 20px;
        `}
    ${(props) =>
        props.size === 'full' &&
        css`
            width: 100%;
        `}

    ${(p) =>
        p.square &&
        css`
            width: 40px;
            padding: 0;

            ${StyledButtonIcon} {
                margin: 0;
            }
        `} // Variants
    ${(p) =>
        p.variant === 'delete' &&
        css`
            ${StyledButtonIcon} svg {
                fill: ${uiColors.negative.default};
            }
        `}
    ${(p) =>
        p.variant === 'transparent' &&
        css`
            background: none;
        `} // Flags
    ${(p) =>
        p.isLoading &&
        css`
            color: transparent !important;
            pointer-events: none;

            i {
                visibility: hidden;
            }

            &:after {
                animation: ${animations.rotate} 0.6s linear infinite;
                content: '';
                display: block;
                position: absolute;
                top: calc(50% - (1em / 2));
                left: calc(50% - (1em / 2));
                height: 1em;
                width: 1em;
                border: 2px solid #a1a1a1;
                border-radius: 100%;
                border-right-color: transparent;
                border-top-color: transparent;
            }
        `}

    ${(p) =>
        p.disabled &&
        css`
            opacity: 0.6;
        `}
`;

export const StyledTextButton = styled(StyledButton)`
    ${baseStyles};
    padding: 0;
    background: none;
    border: none;
    font-size: ${fonts.size(13)};
    color: ${uiColors.text.button};
    cursor: pointer;

    &:hover:enabled {
        background: none;
        color: ${uiColors.text.linkDark};
    }
`;

export const StyledDestructiveTextButton = styled(StyledTextButton)`
    color: ${uiColors.negative.default};

    &:hover:enabled {
        background: none;
        color: ${uiColors.negative.dark};
    }
`;

export const StyledPrimaryButton = styled(StyledButton)`
    ${primaryStyles};
`;

export const StyledDestructiveButton = styled(StyledButton)`
    ${destructiveStyles};
`;

export const StyledWhiteButton = styled(StyledButton)`
    background-color: #fff;
    border: 1px solid ${uiColors.grey.midLight};
    transition: border 150ms, box-shadow 150ms;

    &:hover:enabled {
        background-color: #fff;
        border: 1px solid #8bcdf1;
        box-shadow: 0 0 0 3px #d2e8f4;
    }
`;

export const StyledMenuButton = styled(StyledButton)`
    background-color: #fff;
    border: transparent;
    transition: background-color 150ms;

    &:hover:enabled {
        background-color: ${uiColors.button.default};
        color: ${uiColors.text.linkDark};
    }
`;

export const StyledToggleableButton = styled(StyledButton)`
    ${(p) =>
        p.isToggled &&
        css`
            background: #e8f0fe;

            &:hover {
                background: #d8e5fd !important;
            }
        `};
`;

export const StyledClearButton = styled(StyledButton)`
    background: none;

    &:hover:enabled {
        background: none;
        color: ${uiColors.text.linkDark};
    }
`;

export const StyledDestructiveClearButton = styled(StyledClearButton)`
    color: ${uiColors.negative.default};

    &:hover:enabled {
        border: 1px solid ${uiColors.negative.default};
    }
`;

export const StyledResponsiveButton = styled(StyledButton)`
    @media (${maxWidth('tabletSmall')}) {
        width: 40px;
        padding: 0;

        ${StyledButtonIcon} {
            margin: 0;
        }

        > span {
            display: none;
        }
    }
`;

export const StyledPrimaryResponsiveButton = styled(StyledResponsiveButton)`
    ${primaryStyles};
`;
