import React from 'react';

const ReorderHorizontal = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fill={props.fill}
            d="M3,15H21V13H3V15M3,19H21V17H3V19M3,11H21V9H3V11M3,5V7H21V5H3Z"
        />
    </svg>
);

export default ReorderHorizontal;
