import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface Props {
    horizontal?: boolean;
    className?: string;
    thirds?: boolean;
}

const SelectableOptionList: FunctionComponent<Props> = ({
    horizontal,
    thirds,
    children,
    className,
}) => {
    return (
        <div
            className={classNames(
                'ui-selectable-option-list',
                { '--horizontal': horizontal, '--thirds': thirds },
                className
            )}
        >
            {children}
        </div>
    );
};

export default SelectableOptionList;
