import React, { FunctionComponent } from 'react';
import { assetUrl } from '@/Services/String';
import { Button } from '@/Components/Button';

interface Props {}

export const AppError: FunctionComponent<Props> = (props) => {
    const logoUrl = assetUrl('/images/logo-trans.svg');

    function handleClick() {
        window.location.reload();
    }

    return (
        <div className="flex h-full flex-col items-center justify-center">
            <img
                src={logoUrl}
                alt="Striive logo"
                className="mb-4 h-6 w-[100px]"
            />

            <h1 className="mb-4">Uh oh! Something went wrong.</h1>
            <p className="mb-4">
                Try reloading the app. If the problem persists, please get in
                touch.
            </p>

            <footer className="flex space-x-2">
                <a href={import.meta.env.VITE_APP_URL}>Reload the page</a>
                <Button href="mailto:support@striive.co?subject=Something%20went%20wrong">
                    Send us an email
                </Button>
            </footer>
        </div>
    );
};
