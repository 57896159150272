import React, { FunctionComponent } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import Radio from '../Radio';

interface Props {
    name: string;
    value: string | boolean;
    id?: string;
    label: string | JSX.Element;
    isDisabled?: boolean;
    className?: string;
}

const FormRadioField: FunctionComponent<Props> = ({
    name,
    value,
    id,
    label,
    isDisabled = false,
    className,
}) => {
    const { setFieldValue } = useFormikContext();

    id = id ?? `${name}-${value}`;

    function handleChange() {
        setFieldValue(name, value, true);
    }

    return (
        <div className={className}>
            <Field type="radio" name={name} value={value} disabled={isDisabled}>
                {({ field, form }: FieldProps) => (
                    <Radio
                        checked={field.checked ?? false}
                        label={label}
                        id={id}
                        {...field}
                        onChange={handleChange}
                        isDisabled={isDisabled}
                    />
                )}
            </Field>
        </div>
    );
};

export default FormRadioField;
