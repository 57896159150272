import { types } from 'mobx-state-tree';
import { DateTimeType } from '@/Types/DateTime';

export const ParqAnswer = types
    .model('ParqAnswer', {
        question: types.string,
        questionId: types.integer,
        answer: types.boolean,
        clientNotes: types.maybeNull(types.string),
        trainerNotes: types.maybeNull(types.string),
        updatedAt: DateTimeType,
    })

    .views((self) => ({}))

    .actions((self) => ({}));
