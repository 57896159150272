import { types } from 'mobx-state-tree';
import { currencyDisplay } from '../Services/Currency';

const ClassPrivateBase = types.model().views((self) => ({
    /**
     * Get the simplest representation of the cost, without symbol
     * @returns {string}
     */
    get simplifiedEnrolmentCost() {
        return currencyDisplay(self.enrolmentCost, self.currency, true);
    },

    get hasAvailableSpaces() {
        return (
            !self.hasLimitedSpaces ||
            self.maxEnrolments - (self.totalAttending ?? 0) > 0
        );
    },
}));

export default ClassPrivateBase;
