import React from 'react';
import styled from 'styled-components';
import Title from './Title';
import Subtitle from './Subtitle';

const ListItemDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

ListItemDetails.Title = Title;
ListItemDetails.Subtitle = Subtitle;

ListItemDetails.propTypes = {};

export default ListItemDetails;
