import { applySnapshot, flow, getSnapshot, types } from 'mobx-state-tree';
import { DateTime } from 'luxon';
import { ClassDateReference } from './ClassDate';
import RootStore from '../Stores/RootStore';
import { formatDate, inUserZone } from '../Services/Date';
import { deliveryFromFormValues } from '../Services/Delivery';
import ClassTransport from '../Services/Transport/ClassTransport';
import { OrganisationUser } from './OrganisationUser';
import { classCostString } from '../Services/Classes';

const ClassBase = types
    .model({
        uuid: types.identifier,
        type: types.literal('class'),
        classType: types.string,
        resourceVersion: 2,
        name: types.string,
        description: types.maybeNull(types.string),
        enrolmentLink: types.maybeNull(types.string),
        dates: types.array(types.late(() => ClassDateReference)),
        isArchived: false,
        isDeleted: false,
        assignedUsers: types.array(types.reference(OrganisationUser)),
    })

    .views((self) => ({
        get humanEntityType() {
            return '';
        },

        get mapDataToFormValues() {
            return {
                ...getSnapshot(self),
                ...self.deliveryFormValues,
                employer: self.employer?.uuid ?? '',
                enrolmentLink: self.enrolmentLink ?? '',
                description: self.description ?? '',
                maxEnrolments: self.maxEnrolments ?? '',
                assignedUsers: self.assignedUsers,
            };
        },

        /**
         * Determine if the class is an employed one
         * @returns {boolean}
         */
        get isEmployed() {
            return self.classType === 'employed';
        },

        /**
         * Determine if the class is a private one
         * @returns {boolean}
         */
        get isPrivate() {
            return self.classType === 'private';
        },

        /**
         * Determine if the class is actually available. Not archived, and not deleted
         * @returns {boolean|boolean}
         */
        get isAvailable() {
            return !self.isArchived && !self.isDeleted;
        },

        /**
         * Determine if the class has any dates
         * @returns {boolean}
         */
        get hasDates() {
            return self.dates.length > 0;
        },

        /**
         * Sort the dates by date order
         * @returns {*}
         */
        get sortedDates() {
            return self.dates
                .slice()
                .sort((a, b) => (a.start < b.start ? -1 : 1));
        },

        /**
         * Get the latest date planned
         * @returns {*}
         */
        get lastDate() {
            return self.sortedDates.slice().pop();
        },

        /**
         * String representation of the last planned date
         * @returns {string}
         */
        get lastDateString() {
            return formatDate(self.lastDate.start, true);
        },

        /**
         * String representation of the last planned date without year
         * @returns {string}
         */
        get lastDateStringShort() {
            return formatDate(self.lastDate.start, false);
        },

        /**
         * Get the dates that are in the past
         */
        get deliveredDates() {
            return self.sortedDates
                .filter((date) => date.end <= inUserZone())
                .sort((a, b) => (a.start > b.start ? -1 : 1));
        },

        /**
         * Determine if the class has any planned dates
         * @returns {boolean}
         */
        get hasDelivered() {
            return self.deliveredDatesCount > 0;
        },

        /**
         * Get the dates that are in the past
         */
        get plannedDates() {
            const now = DateTime.local();
            return self.sortedDates.filter((date) => date.start >= now);
        },

        /**
         * Count the number of dates that are planned for the future
         */
        get plannedCount() {
            return self.plannedDates.length;
        },

        /**
         * Get a string representing how many dates are planned
         * @returns {string}
         */
        get plannedCountString() {
            return `${self.plannedDatesCount} ${
                self.plannedDatesCount === 1 ? 'date' : 'dates'
            } planned`;
        },

        /**
         * Determine if the class has any planned dates
         * @returns {boolean}
         */
        get hasPlanned() {
            return self.plannedDatesCount > 0 || self.plannedCount > 0;
        },

        /**
         * Determine if this class has a date in the future
         */
        get isActive() {
            return self.plannedCount > 0;
        },

        get costString() {
            return classCostString(self);
        },

        /**
         * Get a string summarising the class "2 dates planned at ZZZ Gym until 20 Jun 2030"
         * @returns {string}
         */
        get summaryString() {
            let string = '';

            if (self.hasPlanned) {
                string += `${self.plannedCountString} `;
            } else {
                return 'No future dates planned';
            }

            if (self.isActive) {
                string += `until ${self.lastDateStringShort}`;
            }

            return string;
        },

        get url() {
            return `/classes/${self.uuid}/summary`;
        },
    }))

    .actions((self) => ({
        removeDate(date) {
            self.dates = self.dates.filter((dt) => dt.uuid !== date.uuid);
            RootStore.stores.entryStore.delete(date.uuid);
        },

        update: flow(function* update(values) {
            self.busy = true;
            const { data } = yield ClassTransport.update(self.uuid, values);

            self.updateData({
                ...data.data,
                busy: false,
            });

            if (typeof data.dates !== 'undefined') {
                data.dates.forEach((date) =>
                    RootStore.stores.entryStore.createOrUpdate(date)
                );
            }

            return self;
        }),

        archive: flow(function* archive() {
            const { data } = yield ClassTransport.archive(self.uuid);
            self.updateData(data.data);
        }),

        restore: flow(function* restore() {
            const { data } = yield ClassTransport.restore(self.uuid);
            self.updateData(data.data);
        }),

        updateData(data = {}) {
            data.assignedUsers =
                data.assignedUsers?.map((user) => user.identifier || user) ||
                [];
            applySnapshot(self, {
                ...getSnapshot(self),
                ...data,
            });
        },

        // updateDateData() {
        //     self.dates.forEach(date => {
        //         date.updateData({ fee: self.fee, name: self.name });
        //     })
        // },

        setFormData({ uuid, ...values }) {
            self.updateData(values);

            const delivery = deliveryFromFormValues(values);
            self.updateDelivery(delivery);

            if (values.applyToFuture) {
                self.plannedDates.forEach((date) =>
                    date.updateDelivery(delivery)
                );
            }
        },

        // resetNewDates(cards) {
        //     const existing = self.dates.map(date => date.uuid);
        //
        //     self.dates = [];
        //     existing.forEach(uuid => RootStore.stores.entryStore.delete(uuid));
        //
        //     self.addNewDates(cards);
        // },
        //

        // removeNewDates() {
        //     self.dates.filter(date => date.isNew)
        //         .forEach(date => {
        //             self.removeDate(date)
        //         });
        // },
    }));

export default ClassBase;
