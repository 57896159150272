import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { ClassDateListFilter, classDateQueryKeys } from '@/Features/ClassDates';
import RootStore from '@/Stores/RootStore';
import { EntityListInfiniteParams } from '@/Types/Query';
import { getNextPageParam } from '@/Services/Api';

export function useClassDateListInfiniteQuery(
    params: EntityListInfiniteParams<ClassDateListFilter>,
    options?: UseInfiniteQueryOptions
) {
    return useInfiniteQuery(
        classDateQueryKeys.listInfinite(params),
        ({ pageParam = 1 }) =>
            RootStore.stores.entryStore.listClassDates(
                { ...params, page: pageParam },
                true
            ),
        {
            ...options,
            getNextPageParam,
        }
    );
}
