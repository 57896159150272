import React from 'react';

const CustomClassPasses = (props) => (
    <svg viewBox="0 0 20 20" {...props}>
        <path
            fill={props.fill}
            d="M18 0a2 2 0 012 2v1a1.5 1.5 0 000 3v3H0V6a1.5 1.5 0 000-3V2a2 2 0 012-2h16zM7.416 2.376a.75.75 0 00-1.04.208L4.882 4.823l-.352-.352-.084-.073a.75.75 0 00-.976 1.134l1 .999.081.07a.75.75 0 001.073-.185l2-3 .055-.097a.75.75 0 00-.263-.943zm4.5 0a.75.75 0 00-1.04.208L9.382 4.823l-.352-.352-.084-.073a.75.75 0 00-.976 1.134l1 .999.081.07a.75.75 0 001.073-.185l2-3 .055-.097a.75.75 0 00-.263-.943zm4.5 0a.75.75 0 00-1.04.208l-1.494 2.239-.352-.352-.084-.073a.75.75 0 00-.976 1.134l1 .999.081.07a.75.75 0 001.073-.185l2-3 .055-.097a.75.75 0 00-.263-.943zM20 11v3a1.5 1.5 0 000 3v1a2 2 0 01-2 2H2a2 2 0 01-2-2v-1a1.5 1.5 0 000-3v-3h20zM7.416 13.376a.75.75 0 00-1.04.208l-1.494 2.239-.352-.352-.084-.073a.75.75 0 00-.976 1.134l1 .999.081.07a.75.75 0 001.073-.185l2-3 .055-.097a.75.75 0 00-.263-.943zm4.5 0a.75.75 0 00-1.04.208l-1.494 2.239-.352-.352-.084-.073a.75.75 0 00-.976 1.134l1 .999.081.07a.75.75 0 001.073-.185l2-3 .055-.097a.75.75 0 00-.263-.943z"
            fillRule="evenodd"
        />
    </svg>
);

export default CustomClassPasses;
