// a function to retry loading a chunk to avoid chunk load error for out of date code
import { ComponentType } from 'react';
import store from 'store';

export default function <T extends ComponentType<any>>(
    componentImport: () => Promise<{ default: T }>,
    name: string
): Promise<{ default: T }> {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const key = `retry-${name}-refreshed`;
        const hasRefreshed = store.get(key) ?? false;

        // try to import the component
        componentImport()
            .then((component) => {
                store.remove(key);
                resolve(component);
            })
            .catch((error: Error) => {
                if (!hasRefreshed) {
                    store.set(key, true);
                    return window.location.reload();
                }

                reject(error); // Default error behaviour as already tried refresh
            });
    });
}
