import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import {
    CreateUpdateMessageModal,
    MessageFormValues,
    RecipientGroup,
} from '@/Features/Messages';
import { StackableModalProps } from '@/Components/RootModal';
import MessageTransport from '@/Services/Transport/MessageTransport';
import CustomerSupport from '@/Services/CustomerSupport';

interface Props extends StackableModalProps {
    onCreate?(): void;
    selected?: string[];
    subject?: string;
    recipientGroup?: RecipientGroup;
}

const Component: FunctionComponent<Props> = (props) => {
    const mapPropsToValues = (): MessageFormValues => {
        return {
            recipients: props.selected ?? [],
            subject: props.subject ?? '',
            content: '',
        };
    };

    const handleSave = async (
        values: MessageFormValues,
        recipientGroup?: RecipientGroup
    ) => {
        if (recipientGroup?.type === 'class-date') {
            await MessageTransport.storeClassDateMessage(
                recipientGroup.identifier,
                values
            );
        } else {
            await MessageTransport.store(values);
        }

        if (typeof props.onCreate === 'function') {
            props.onCreate();
        }

        CustomerSupport.trackEvent('Message Sent');
    };

    return (
        <CreateUpdateMessageModal
            isCreating
            stackProps={props.stackProps}
            mapPropsToValues={mapPropsToValues}
            onSave={handleSave}
            recipientGroup={props.recipientGroup}
        />
    );
};

export const CreateMessageModal = observer(Component);
