import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import ItemListTextCell from '@/Components/ItemList/TextCell';
import Icon from '@/Components/Icon/Icon';
import { uiColors } from '@/Theme';
import { age } from '@/Services/Date';

interface Props {
    client?: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    return (
        <ItemListTextCell
            icon={
                <Icon
                    name="ClockFilled"
                    width={16}
                    height={16}
                    fill={
                        client.hasPreviousActivity
                            ? uiColors.positive.default
                            : uiColors.warning.default
                    }
                />
            }
            className="min-w-0 flex-wrap lg:flex-nowrap"
            content={
                <>
                    <span className="xl:hidden">Last activity&nbsp;</span>
                    {client.hasPreviousActivity
                        ? age(client.lastActive.date, true, 7)
                        : 'Never'}
                </>
            }
            secondaryContent={
                client.hasPreviousActivity ? client.lastActive.label : 'N/A'
            }
            secondaryContentClassName="truncate"
        />
    );
};

export const ClientListItemLastActivityCell = observer(Component);
