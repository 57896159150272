import { applySnapshot, flow, getRoot, types } from 'mobx-state-tree';
import { MembershipSubscription } from '../Models/MembershipSubscription';
import MembershipSubscriptionTransport from '../Services/Transport/MembershipSubscriptionTransport';

export const MembershipSubscriptionStore = types
    .model('MembershipSubscriptionStore', {
        subscriptions: types.map(MembershipSubscription),
    })
    .volatile((self) => ({
        inFlight: new Set(),
    }))

    .views((self) => {
        const fetchSubscription = async (uuid, params = {}) => {
            if (self.inFlight.has(uuid) || self.subscriptions.has(uuid)) return;

            self.inFlight.add(uuid);

            try {
                await self.fetch(uuid, params);
            } catch (error) {
                console.log({ error });
            }

            self.inFlight.delete(uuid);
        };

        return {
            find(uuid) {
                return self.subscriptions.get(uuid);
            },

            findOrFetch(uuid, params = {}) {
                fetchSubscription(uuid, params);
                return self.subscriptions.get(uuid) ?? uuid;
            },
        };
    })

    .actions((self) => ({
        fetch: flow(function* fetch(uuid, params = {}) {
            const { data } = yield MembershipSubscriptionTransport.fetch(
                uuid,
                params
            );
            return self.createOrUpdate(data.data);
        }),

        list: flow(function* list(requestData, withMeta = false) {
            const { data } = yield MembershipSubscriptionTransport.list(
                requestData
            );
            const items = data.data.map((subData) =>
                self.createOrUpdate(subData)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        /**
         *
         * @param subscriptionData
         * @returns {*}
         */
        createOrUpdate(subscriptionData) {
            const subscription = self.processNestedResources(subscriptionData);

            if (self.subscriptions.has(subscription.uuid)) {
                const existing = self.subscriptions.get(subscription.uuid);
                applySnapshot(existing, subscription);
            } else {
                self.subscriptions.set(subscription.uuid, subscription);
            }

            return self.subscriptions.get(subscription.uuid);
        },

        /**
         * Add the subscription to the store, and return the identifier
         * @param subscription
         * @returns {*}
         */
        processAsNested(subscription) {
            if (!!subscription?.uuid) {
                self.createOrUpdate(subscription);
                return subscription.uuid;
            }

            return subscription;
        },

        /**
         * @param subscriptionData
         * @returns {*}
         */
        processNestedResources(subscriptionData) {
            subscriptionData.client = getRoot(
                self
            ).stores.clientStore.processAsNested(subscriptionData.client);
            subscriptionData.membership = getRoot(
                self
            ).stores.membershipStore.processAsNested(
                subscriptionData.membership
            );

            return subscriptionData;
        },
    }));
