import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import { ParqAnswer } from '@/Models/ParqAnswer';
import FormField from '@/Components/FormField';
import NotesField from '@/Components/Notes/Field';

interface Props {
    client: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    function handleNotesSaved(parq: ParqAnswer, notes: string | null) {
        client.updateParqAnswer(parq.questionId, notes);
    }

    if (!client.failedParqs) {
        return null;
    }

    return (
        <>
            {client.failedParqs.map((parq: ParqAnswer, index: number) => (
                <div
                    key={index}
                    className="mb-2 border-b border-t border-gray-200 bg-white xl:rounded-lg xl:border"
                >
                    <header className="border-b border-gray-200 p-4">
                        <div className="rounded border border-warning bg-warning-300 p-4">
                            <p>{parq.question}</p>
                        </div>
                    </header>
                    <section className="p-4">
                        <FormField>
                            <FormField.Label>Answer</FormField.Label>
                            <p>"{parq.clientNotes}"</p>
                        </FormField>

                        <FormField className="m-0">
                            <FormField.Label>Your notes</FormField.Label>
                            <NotesField
                                content={parq.trainerNotes}
                                onSave={async (notes) =>
                                    await handleNotesSaved(parq, notes)
                                }
                            />
                        </FormField>
                    </section>
                </div>
            ))}
        </>
    );
};

export const ClientParqResults = observer(Component);
