import classNames from 'classnames';
import React, { FunctionComponent, HTMLProps } from 'react';

export type ErrorType = FunctionComponent<HTMLProps<HTMLDivElement>>;

const Error: ErrorType = ({ className, ...props }) => (
    <div className={classNames('form-field__error', className)} {...props} />
);

export default Error;
