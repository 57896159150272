export default {
    /**
     * Convert a pixel size to rems
     * @param pixels
     * @param context
     * @returns {string}
     */
    size(pixels: number, context = 16) {
        return `${pixels / context}rem`;
    },
};
