import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import ItemListTextCell from '@/Components/ItemList/TextCell';
import Icon from '@/Components/Icon/Icon';
import { uiColors } from '@/Theme';

interface Props {
    client?: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    function getIcon() {
        if (client.isArchived) {
            return (
                <Icon
                    name="UICross"
                    width={14}
                    height={14}
                    fill={uiColors.grey.mid}
                />
            );
        }
        if (client.portalEnabled && client.portalRegistered) {
            return (
                <Icon
                    name="UICheck"
                    width={14}
                    height={14}
                    fill={uiColors.positive.default}
                />
            );
        }
        return (
            <Icon
                name="WarningTriangle"
                width={14}
                height={14}
                fill={uiColors.warning.default}
            />
        );
    }

    function getContent() {
        if (client.isArchived) {
            return (
                <>
                    <span className="xl:hidden">Client area</span>{' '}
                    <span className="xl:capitalize">disabled</span>
                </>
            );
        }
        if (client.portalEnabled && client.portalRegistered) {
            return (
                <>
                    <span className="xl:hidden">Client area</span>{' '}
                    <span className="xl:capitalize">enabled</span>
                </>
            );
        }
        if (
            client.portalEnabled &&
            !client.portalRegistered &&
            client.hasPortalPasswordInvite
        ) {
            return (
                <>
                    Invited <span className="xl:hidden">to client area</span>
                </>
            );
        }
        if (client.portalEnabled && !client.portalRegistered) {
            return (
                <>
                    Not registered{' '}
                    <span className="xl:hidden">for client area</span>
                </>
            );
        }

        return (
            <>
                <span className="xl:hidden">Client area</span>{' '}
                <span className="xl:capitalize">disabled</span>
            </>
        );
    }

    function getSecondaryContent() {
        if (client.portalEnabled && client.portalRegistered) {
            return `Last seen ${client.lastSeenAge ?? 'never'}`;
        }

        return 'N/A';
    }

    return (
        <ItemListTextCell
            icon={getIcon()}
            content={getContent()}
            secondaryContent={
                <span className="hidden xl:block">{getSecondaryContent()}</span>
            }
        />
    );
};

export const ClientListItemPortalStatusCell = observer(Component);
