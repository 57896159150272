import React, { FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Client } from '@/Models/Client';
import ItemListTitleCell from '@/Components/ItemList/TitleCell';
import ItemListActionCell from '@/Components/ItemList/ActionCell';
import { ClientAvatar, ReviewClientSignUpButton } from '@/Features/Clients';
import { getSimplifiedDate } from '@/Services/Date';
import { PrimaryButton } from '@/Components/Button';

interface Props {
    client: Client;
    className?: string;
}

const Component: FunctionComponent<Props> = ({ client, className }) => {
    const firstReview = useMemo(() => client.dataReviews[0], [client]);

    const subtitle = useMemo(() => {
        if (!firstReview) return null;

        return (
            <span className="text-gray-400">
                {firstReview.action === 'signed-up'
                    ? 'Signed up'
                    : 'Updated PAR-Q'}{' '}
                {getSimplifiedDate(firstReview.createdAt)}
            </span>
        );
    }, [firstReview]);

    if (!firstReview) return null;

    return (
        <div
            className={classNames(
                className,
                'grid-cols-[36px_auto_auto] xl:grid-cols-[36px_50%_minmax(min-content,auto)]'
            )}
        >
            <ClientAvatar
                client={client}
                className="row-span-3 xl:row-auto"
                withLink={false}
            />
            <ItemListTitleCell title={client.fullName} subtitle={subtitle} />
            <ItemListActionCell className="row-span-3 justify-end">
                {firstReview?.action === 'signed-up' && (
                    <ReviewClientSignUpButton client={client} />
                )}
                {firstReview?.action === 'parq' && (
                    <PrimaryButton href={`/clients/roster/${client.eid}/parq`}>
                        Review
                    </PrimaryButton>
                )}
            </ItemListActionCell>
        </div>
    );
};

export const ClientReviewListItem = observer(Component);
