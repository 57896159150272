import classNames from 'classnames';
import React, { FunctionComponent, HTMLProps } from 'react';

export type InfoType = FunctionComponent<HTMLProps<HTMLDivElement>>;

const Info: InfoType = ({ className, ...props }) => (
    <div className={classNames('form-field__info', className)} {...props} />
);

export default Info;
