import { View } from '.';
import { DateTime } from 'luxon';

export class Week extends View {
    static get nameSingular() {
        return 'Week';
    }

    static get namePlural() {
        return 'Weeks';
    }

    static createFromView(view) {
        return view.week;
    }

    constructor(calendar, date) {
        super(calendar, date);

        this.type = 'week';
    }

    get periodTitle() {
        return this.cache.add('Views.Week.periodTitle', () => {
            return this.date.toFormat("'Week' WW, kkkk");
        });
    }

    get active() {
        return this.calendar.getWeek(DateTime.local());
    }

    get previous() {
        return this.cache.add('Views.Week.previous', () => {
            return this.calendar.getWeek(this.date.minus({ weeks: 1 }));
        });
    }

    get next() {
        return this.cache.add('Views.Week.next', () => {
            return this.calendar.getWeek(this.date.plus({ weeks: 1 }));
        });
    }

    get day() {
        return this.cache.add('Views.Week.day', () => {
            if (this.isActive) {
                return this.calendar.getDay(DateTime.local());
            }

            return this.calendar.getDay(this.date);
        });
    }

    get week() {
        return this;
    }

    get isPopulated() {
        return this.cache.add('Views.Week.isPopulated', () => {
            let isPopulated = false;

            for (let day of this.days) {
                if (!day.isPopulated) {
                    isPopulated = false;
                    break;
                } else {
                    isPopulated = true;
                }
            }

            return isPopulated;
        });
    }

    get startDate() {
        return this.date.startOf('week');
    }

    get endDate() {
        return this.date.endOf('week');
    }

    get urlParameters() {
        return {
            year: this.date.toFormat('kkkk'),
            week: 'W' + this.date.toFormat('WW'),
        };
    }
}
