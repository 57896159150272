import { types } from 'mobx-state-tree';
import RootStore from '../Stores/RootStore';

export const ExpenseCategory = types
    .model('ExpenseCategory', {
        uuid: types.identifier,
        label: types.string,
        isDeleted: types.boolean,
    })

    .views((self) => ({}))

    .actions((self) => ({}));

export const ExpenseCategoryReference = types.reference(ExpenseCategory, {
    get(identifier) {
        return RootStore.stores.expenseCategoryStore.find(identifier) || null;
    },
    set(category) {
        return category.identifier;
    },
});
