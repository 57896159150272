import React, { FunctionComponent } from 'react';
import Icon from '../../Icon/Icon';
import classNames from 'classnames';
import { uiColors } from '../../../Theme';

interface Props {
    iconName: string;
    backgroundColor?: string;
    className?: string;
    width?: number;
    height?: number;
}

const ItemListItemIcon: FunctionComponent<Props> = ({
    iconName,
    backgroundColor = uiColors.button.icon,
    className,
    ...props
}) => {
    return (
        <div
            className={classNames(
                'mt-0.5 flex h-9 w-9 min-w-[36px] items-center justify-center self-start rounded-full xl:mt-0 xl:self-center',
                className
            )}
            style={{ backgroundColor }}
        >
            <Icon
                name={iconName}
                fill="#fff"
                width={16}
                height={16}
                {...props}
            />
        </div>
    );
};

export default ItemListItemIcon;
