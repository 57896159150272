import React, { FunctionComponent } from 'react';
import { Session } from '@/Models/Session';
import { Consultation } from '@/Models/Consultation';
import { Button, ResponsiveButton } from '../../Button';
import RootStore from '@/Stores/RootStore';
import { observer } from 'mobx-react';
import { uiColors } from '@/Theme';

interface Props {
    entry: Session | Consultation;
    isResponsive?: boolean;
    className?: string;
}

const EntryRemindButton: FunctionComponent<Props> = ({
    entry,
    isResponsive = false,
    className,
}) => {
    if (!entry.startInFuture) {
        return null;
    }

    function handleClick() {
        RootStore.stores.uiStateStore.pushModal('ENTRY_REMIND', {
            entry,
        });
    }

    const buttonProps = {
        type: 'button' as 'button' | 'submit' | undefined,
        onClick: handleClick,
        icon: 'Bell',
        fill: uiColors.positive.default,
        children: entry.reminderSent ? 'Reminded' : 'Remind',
        className,
    };

    return (
        <>
            {isResponsive && <ResponsiveButton {...buttonProps} />}
            {!isResponsive && <Button {...buttonProps} />}
        </>
    );
};

export default observer(EntryRemindButton);
