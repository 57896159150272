import React from 'react';

const Consultation = (props) => (
    <svg viewBox="0 0 100 100" {...props}>
        <path
            fill={props.fill}
            d="M50 0C22.375 0 0 22.375 0 50s22.375 50 50 50 50-22.375 50-50S77.625 0 50 0zm33.167 61.75c-4.542 15.583-17.792 26.917-33.5 26.917-15.75 0-29-11.334-33.5-26.959-4.959-.458-8.875-4.916-8.875-10.5 0-5.291 3.541-9.625 8.208-10.416 8.708-6.084 15.833-14.542 17.042-21.042v-.042c5.625 10.959 26.25 21.625 49.291 21.084l1.25-.125c5.334 0 9.625 4.75 9.625 10.583 0 5.75-4.25 10.458-9.541 10.5zM65 60a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm-30 0a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm26.506 10.525a17.118 17.118 0 0 1-11.527 4.488 17.118 17.118 0 0 1-11.528-4.488c-.943-.848-2.271-.648-3 .449-.728 1.097-.557 2.643.386 3.49A20.88 20.88 0 0 0 49.979 80c5.185 0 10.242-1.945 14.184-5.485.943-.848 1.114-2.394.386-3.491-.729-1.147-2.1-1.297-3.043-.499z"
        />
    </svg>
);

export default Consultation;
