import angular from 'angular';
import RootStore from '../../../../js/Stores/RootStore';
import CreateAction from '../../actions/CreateAction';

angular
    .module('component.schedule-add-target', [])

    .component('scheduleAddTarget', {
        bindings: {
            action: '=',
            calendar: '=',
        },
        require: {
            view: '^scheduleWeek',
        },
        template: `
            <div class='schedule-entry entry --target --positioned --days-[[ $ctrl.calendar.view.days.length ]] --left-[[ $ctrl.card.day.index ]] --top-[[ $ctrl.card.start ]] --height-[[ $ctrl.card.end - $ctrl.card.start ]]' ng-show='$ctrl.isVisible()' ng-cloak>
                <div class='entry__label'>
                    <span>[[ $ctrl.time ]]</span>
                </div>
            </div>
        `,
        controller: [
            '$element',
            '$scope',
            function ($element, $scope) {
                let $ctrl = this;
                let currentAction = false;
                let scrolled = false;
                let lastPageY = null;

                let sessionLength =
                    RootStore.stores.currentUserStore.organisation
                        .defaultEventLength / 15;
                let targetCalendar = $ctrl.calendar.currentCalendar;

                $ctrl.isVisible = () => {
                    if (!$ctrl.action.isTargetVisible) {
                        return false;
                    }

                    if ($ctrl.isHovering) {
                        return $ctrl.isAvailable && $ctrl.action.canPlaceCards;
                    }
                };

                $ctrl.$doCheck = () => {
                    if (
                        (!currentAction ||
                            currentAction !== $ctrl.action.constructor.name ||
                            targetCalendar !==
                                $ctrl.calendar.currentCalendar) &&
                        !$ctrl.action.isLoading &&
                        $ctrl.action instanceof CreateAction
                    ) {
                        sessionLength =
                            RootStore.stores.currentUserStore.organisation
                                .defaultEventLength / 15;
                        currentAction = $ctrl.action.constructor.name;
                        targetCalendar = $ctrl.calendar.currentCalendar;
                        $ctrl.updateCard();
                    }
                };

                $ctrl.eventToRange = (event) => {
                    let [posX, posY] = [event.pageX, event.pageY];
                    lastPageY = event.pageY;
                    let day =
                        $ctrl.calendar.view.days[$ctrl.view.pixelsToDays(posX)];
                    let start = $ctrl.view.pixelsToPeriods(posY);
                    let end =
                        start +
                        Math.min($ctrl.action.periodsAvailable, sessionLength);

                    return { day, start, end };
                };

                $ctrl.addCardIfAvailable = () => {
                    if (
                        $ctrl.action.isLoading ||
                        !$ctrl.isAvailable ||
                        !$ctrl.action.isTargetVisible
                    ) {
                        return;
                    }

                    $ctrl.addCard();
                };

                $ctrl.addCard = () => {
                    lastPageY = null;
                    $ctrl.view.addCard($ctrl.card.clone(true));

                    $ctrl.updateCard();
                    $ctrl.isAvailable = false;
                    delete $ctrl.day;
                    delete $ctrl.start;
                    delete $ctrl.end;

                    $scope.$apply();
                };

                $ctrl.updateCard = () => {
                    if (typeof $ctrl.action.createCard === 'function') {
                        $ctrl.card = $ctrl.action.createCard();
                    }
                };

                $ctrl.onMouseOver = () => {
                    $ctrl.isHovering = true;
                    $scope.$apply();
                };

                $ctrl.onMouseOut = () => {
                    $ctrl.isHovering = false;
                    $scope.$apply();
                };

                // $ctrl.onMouseMove = throttle((event) => {
                $ctrl.onMouseMove = (event) => {
                    if (
                        !$ctrl.action.isTargetVisible ||
                        lastPageY === event.pageY
                    ) {
                        return;
                    }

                    event.preventDefault();
                    event = event.originalEvent;

                    if (!$ctrl.card) {
                        $ctrl.updateCard();
                    }

                    let range = $ctrl.eventToRange(event);
                    let isAvailable = false;

                    // console.log(range);

                    while (range.end > range.start) {
                        if ($ctrl.card.canMoveToRange(range)) {
                            $ctrl.card.moveToRange(range);

                            isAvailable =
                                (angular.isUndefined(event.target.classList) ||
                                    (!event.target.classList.contains(
                                        'schedule-entry__handle'
                                    ) &&
                                        !event.target.classList.contains(
                                            'entry__place-shield'
                                        ))) &&
                                $ctrl.action.canPlaceCards;

                            break;
                        }

                        range.end--;
                    }

                    if (
                        $ctrl.isAvailable != isAvailable ||
                        $ctrl.day != $ctrl.card.day ||
                        $ctrl.start != $ctrl.card.start ||
                        $ctrl.end != $ctrl.card.end
                    ) {
                        $ctrl.isAvailable = isAvailable;
                        $ctrl.day = $ctrl.card.day;
                        $ctrl.start = $ctrl.card.start;
                        $ctrl.end = $ctrl.card.end;

                        if ($ctrl.isAvailable) {
                            $ctrl.time = $ctrl.view.periodsToTimeSummary(
                                $ctrl.card.start,
                                $ctrl.card.end
                            );
                        }

                        $scope.$apply();
                    }
                };
                // }, 5);

                $ctrl.onMouseUp = () => {
                    $ctrl.addCardIfAvailable();
                };

                $ctrl.onTouchMove = () => {
                    scrolled = true;
                };

                $ctrl.onTouchEnd = (event) => {
                    if (!scrolled && $ctrl.action.canPlaceCards) {
                        event.preventDefault();

                        if (
                            !event.target.classList.contains('schedule__day') ||
                            !$ctrl.action.isTargetVisible
                        ) {
                            return;
                        }

                        event =
                            angular.isDefined(
                                event.originalEvent.changedTouches
                            ) &&
                            angular.isDefined(
                                event.originalEvent.changedTouches[0]
                            )
                                ? event.originalEvent.changedTouches[0]
                                : event.originalEvent;

                        let range = $ctrl.eventToRange(event);
                        let isAvailable = false;

                        while (range.end > range.start) {
                            if ($ctrl.card.canMoveToRange(range)) {
                                $ctrl.card.moveToRange(range);

                                isAvailable = $ctrl.action.canPlaceCards;
                                break;
                            }

                            range.end--;
                        }
                        $ctrl.addCard();
                    }

                    scrolled = false;
                };

                function attachHandlers() {
                    $ctrl.view.dayWrapperElement.on(
                        'mouseover',
                        $ctrl.onMouseOver
                    );
                    $ctrl.view.dayWrapperElement.on(
                        'mouseout',
                        $ctrl.onMouseOut
                    );
                    $ctrl.view.dayWrapperElement.on(
                        'mousemove',
                        $ctrl.onMouseMove
                    );
                    $ctrl.view.dayWrapperElement.on('mouseup', $ctrl.onMouseUp);
                    $ctrl.view.dayWrapperElement.on(
                        'touchmove',
                        $ctrl.onTouchMove
                    );
                    $ctrl.view.dayWrapperElement.on(
                        'touchend',
                        $ctrl.onTouchEnd
                    );
                }

                function detachHandlers() {
                    $ctrl.view.dayWrapperElement.off(
                        'mouseover',
                        $ctrl.onMouseOver
                    );
                    $ctrl.view.dayWrapperElement.off(
                        'mouseout',
                        $ctrl.onMouseOut
                    );
                    $ctrl.view.dayWrapperElement.off(
                        'mousemove',
                        $ctrl.onMouseMove
                    );
                    $ctrl.view.dayWrapperElement.off(
                        'mouseup',
                        $ctrl.onMouseUp
                    );
                    $ctrl.view.dayWrapperElement.off(
                        'touchmove',
                        $ctrl.onTouchMove
                    );
                    $ctrl.view.dayWrapperElement.off(
                        'touchend',
                        $ctrl.onTouchEnd
                    );
                }

                $ctrl.isAvailable = false;
                $ctrl.isHovering = false;

                $ctrl.$onInit = () => {
                    detachHandlers();
                    attachHandlers();
                };

                $scope.$on('$destroy', function () {
                    detachHandlers();
                });

                $scope.$on('calendarViewChanged', () => {
                    detachHandlers();
                    attachHandlers();
                });
            },
        ],
    });
