import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import { PrimaryButton } from '@/Components/Button';
import { Client } from '@/Models/Client';
import ClientTransport from '@/Services/Transport/ClientTransport';

interface Props {
    client: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    const { uiStateStore, currentUserStore } = RootStore.stores;
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async () => {
        setIsLoading(true);

        await currentUserStore.refreshActiveClientCount();

        if (!currentUserStore.canIncreaseClientCount) {
            setIsLoading(false);

            uiStateStore.pushModal('ACCOUNT_UPGRADE');
            return;
        }

        const { data } = await ClientTransport.fetchSignUpInvite(client.eid);

        setIsLoading(false);

        uiStateStore.pushModal('CLIENT_DATA_REVIEW', {
            client,
            signUpInvite: data?.invite,
        });
    };

    return (
        <PrimaryButton onClick={handleClick} isLoading={isLoading}>
            Review
        </PrimaryButton>
    );
};

export const ReviewClientSignUpButton = observer(Component);
