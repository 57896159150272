import angular from 'angular';

angular
    .module('component.schedule-day', [])

    .component('scheduleDay', {
        require: {
            view: '^scheduleWeek',
        },
        controller: [
            '$element',
            function ($element) {
                let $ctrl = this;

                $ctrl.$onInit = () => {
                    $ctrl.view.addDayElement($element);
                };
            },
        ],
    });
