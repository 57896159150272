import BaseTransport from './BaseTransport';

class TaskTransport extends BaseTransport {
    async load() {
        return await this.get('/v1/tasks?v2');
    }

    async create(data) {
        return await this.post('/v1/tasks', data);
    }

    async update(uuid, data = {}) {
        return await this.put(`/v1/tasks/${uuid}`, data);
    }

    async remove(uuid) {
        return await this.delete(`/v1/tasks/${uuid}`);
    }

    async batchReorder(data) {
        return await this.put('/v1/tasks/reorder', data);
    }
}

export default new TaskTransport();
