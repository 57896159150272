import { Instance, types } from 'mobx-state-tree';

export interface CurrencyInstance extends Instance<typeof Currency> {}

export const Currency = types
    .model('Currency', {
        name: types.string,
        code: types.string,
        symbol: types.string,
        decimals: types.integer,
    })

    .views((self) => ({}))

    .actions((self) => ({}));
