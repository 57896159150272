import BaseTransport from './BaseTransport';

class FinanceTransport extends BaseTransport {
    async listTopDebtors(params = {}) {
        return await this.get('/v3/finances/top-debtors', params);
    }

    async listOldestDebts(params = {}) {
        return await this.get('/v3/finances/oldest-debt', params);
    }
}

export default new FinanceTransport();
