import angular from 'angular';

angular
    .module('component.wizard.backwards-button', [])

    .component('wizardBackwardsButton', {
        template: `<a class="button --text" ng-click="$ctrl.wizard.backwards()" ng-if="$ctrl.wizard.canNavigateBackwards()" ng-transclude>Back</a>`,
        transclude: true,
        require: {
            wizard: '^wizard',
        },
    });
