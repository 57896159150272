import React, { Fragment, FunctionComponent } from 'react';
import { Button, ClearButton } from '@/Components/Button';
import { ClassDate } from '@/Models/ClassDate';
import { Session } from '@/Models/Session';
import RootStore from '@/Stores/RootStore';
import useTeam from '@/Hooks/useTeam';
import { observer } from 'mobx-react';

interface Props {
    entry: ClassDate | Session;
    variant?: 'icon' | 'full';
    onAssignedUserChange?(assignedUser: string | undefined): void;
}

const Component: FunctionComponent<Props> = ({
    entry,
    variant = 'icon',
    onAssignedUserChange,
}) => {
    const { hasTeamMembers } = useTeam();

    const buttonParams = {
        icon: 'Account',
        onClick: handleClick,
    };

    function handleClick() {
        RootStore.stores.uiStateStore.pushModal('ASSIGN_TEACHER', {
            event: entry,
            onAssignedUserChange,
        });
    }

    if (!hasTeamMembers) return null;

    return (
        <>
            {variant === 'icon' && <Button {...buttonParams} />}
            {variant === 'full' && (
                <ClearButton {...buttonParams}>Teacher</ClearButton>
            )}
        </>
    );
};

export const ScheduleEventTeachersButton = observer(Component);
