import React from 'react';

const CustomPayments = (props) => (
    <svg viewBox="0 0 14 14" {...props}>
        <path
            fill={props.fill}
            d="M12.833 11.667v.933a1.4 1.4 0 01-1.4 1.4H1.4A1.4 1.4 0 010 12.6v-.933h12.833zm-1.4-7a1.4 1.4 0 011.4 1.4V10.5H0V6.067a1.4 1.4 0 011.4-1.4h10.033zM4.083 7H2.917c-.29 0-.53.212-.576.489l-.008.094V8.75c0 .29.212.53.489.576l.095.007h1.166c.29 0 .53-.211.576-.488l.008-.095V7.583A.583.583 0 004.083 7zm7-7a2.917 2.917 0 012.18 4.854 3.49 3.49 0 00-2.54-1.347L10.5 3.5H8.225A2.917 2.917 0 0111.083 0zm-5.25 1.167c.516 0 .993.167 1.38.45a4.05 4.05 0 00-.172 1.884L3.5 3.5a2.333 2.333 0 012.333-2.333z"
            fillRule="evenodd"
        />
    </svg>
);

export default CustomPayments;
