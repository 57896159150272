import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import RootStore from '@/Stores/RootStore';
import { currencyDisplay } from '@/Services/Currency';

interface Props {
    client: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    const { organisation } = RootStore.stores.currentUserStore;
    const owes = client.paymentStatus?.owes ?? 0;
    const total = client.paymentStatus.total ?? 0;

    return (
        <section className="grid auto-rows-min grid-cols-2 border-b border-t border-gray-200 bg-white xl:rounded-xl xl:border">
            <div className="ui-statistic border-r border-gray-200">
                <span className="ui-statistic__type">
                    {owes >= 0 ? 'Awaiting payment' : 'Overpaid'}
                </span>
                <span className="text-2xl font-bold">
                    {currencyDisplay(owes, organisation.locale.currency)}
                </span>
            </div>
            <div className="ui-statistic">
                <p className="ui-statistic__type">Lifetime value</p>
                <p className="text-2xl font-bold">
                    {currencyDisplay(total, organisation.locale.currency)}
                </p>
            </div>
            <NavLink
                to={`/finances/payments?client=${client!.identifier}`}
                className="col-span-2 border-t border-gray-200 py-6 text-center font-bold"
            >
                View all payments
            </NavLink>
        </section>
    );
};

export const ClientPaymentStatus = observer(Component);
