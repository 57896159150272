import React, { FunctionComponent } from 'react';
import { ReactComponent as UserIcon } from '../../../images/icon-account.svg';
import classNames from 'classnames';

interface Props {
    className?: string;
    iconClassName?: string;
}

const MissingUserAvatar: FunctionComponent<Props> = ({
    className = 'bg-gray-100',
    iconClassName = 'text-gray-300',
}) => {
    return (
        <span
            className={classNames(
                'flex h-full w-full items-center justify-center rounded-full',
                className
            )}
        >
            <UserIcon
                className={classNames(
                    'h-[50%] w-[50%] fill-current',
                    iconClassName
                )}
            />
        </span>
    );
};

export default MissingUserAvatar;
