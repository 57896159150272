import angular from 'angular';
import { react2angular } from 'react2angular';
import ConsultationEntryCard from '../../../js/Components/ConsultationEntryCard';

angular
    .module('component.react.consultation-entry-card', [])
    .component(
        'reactConsultationEntryCard',
        react2angular(ConsultationEntryCard)
    );
