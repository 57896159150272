import React, { FunctionComponent, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { FormikProps, withFormik } from 'formik';
import { StackableModalProps } from '@/Components/RootModal';
import Notification from '@/Services/Notification';
import { PrimaryButton } from '@/Components/Button';
import Modal from '@/Components/Modal';
import RootStore from '@/Stores/RootStore';
import { MessageFormValues, RecipientGroup } from '@/Features/Messages';
import TipTap from '@/Components/TipTap';
import FormField from '@/Components/FormField';
import FormClientSelectField from '@/Components/FormClientSelectField';
import queryClient from '@/Modules/QueryClient';
import FormTextField from '@/Domain/Form/FormTextField';
import { components, OptionProps } from 'react-select';
import { OptionTypeBase } from 'react-select/src/types';
import Notice from '@/Components/Notice/index';
import { AxiosError } from 'axios';
import handleServerValidation from '@/Utilities/handleServerValidation';
import { queryKeys } from '@/Services/QueryKeys';

interface Props extends StackableModalProps {
    isCreating: boolean;
    mapPropsToValues(): MessageFormValues;
    onSave(values: MessageFormValues, recipientGroup?: RecipientGroup): void;
    hasRecipientField?: boolean;
    recipientContent?: ReactNode;
}

const Option = (props: OptionProps<OptionTypeBase, true>) => {
    return (
        <div>
            <components.Option {...props}>{props.children}</components.Option>
            {props.isDisabled && (
                <div className="px-3 pb-3">
                    <Notice type="warning" className="">
                        No email address
                    </Notice>
                </div>
            )}
        </div>
    );
};

const Component: FunctionComponent<Props & FormikProps<MessageFormValues>> = (
    props
) => {
    const {
        hasRecipientField = true,
        recipientContent,
        values,
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
    } = props;

    return (
        <Modal
            stackProps={props.stackProps}
            title="New message"
            onSubmit={handleSubmit}
            rightAction={() => (
                <PrimaryButton type="submit" isLoading={isSubmitting}>
                    Send
                </PrimaryButton>
            )}
        >
            <fieldset className="dialog__panel">
                {hasRecipientField && (
                    <FormClientSelectField
                        name="recipients"
                        label="Recipients"
                        isRequired
                        fieldProps={{
                            // @ts-ignore
                            isMulti: true,
                            components: { Option },
                        }}
                        optionIsDisabled={(client) => !client.hasEmail}
                    />
                )}

                {recipientContent}

                <FormTextField name="subject" label="Subject" isRequired />

                <FormField>
                    <FormField.Label required>Content</FormField.Label>
                    <TipTap
                        content={values.content}
                        onChange={(content) =>
                            setFieldValue('content', content)
                        }
                        withLists={false}
                    />
                    {!!errors.content && (
                        <FormField.Error>
                            <p>{errors.content}</p>
                        </FormField.Error>
                    )}
                </FormField>
            </fieldset>
        </Modal>
    );
};

export const CreateUpdateMessageModal = withFormik<Props, MessageFormValues>({
    mapPropsToValues: (props) => props.mapPropsToValues(),
    handleSubmit: async (values, { props, setErrors }) => {
        try {
            await props.onSave(values, props.recipientGroup);
            await queryClient.invalidateQueries(queryKeys.messages.all);

            Notification.success('Message sent');
            RootStore.stores.uiStateStore.closeStack();
        } catch (e) {
            handleServerValidation(e as AxiosError, setErrors);
        }
    },
})(observer(Component));
