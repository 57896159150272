import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import { ClearButton } from '@/Components/Button';
import { Client } from '@/Models/Client';

interface Props {
    client: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    const handleClick = () => {
        RootStore.stores.uiStateStore.pushModal('CLIENT_SETTINGS', { client });
    };

    return (
        <ClearButton icon="Settings" onClick={handleClick}>
            Settings
        </ClearButton>
    );
};

export const ClientSettingsButton = observer(Component);
