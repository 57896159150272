import Calendar from '../Calendar/Calendar';

import angular from 'angular';

angular
    .module('service.calendar', [])

    .service('Calendar', [
        function () {
            return new Calendar();
        },
    ]);
