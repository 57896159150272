import React, { FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import { useQuery } from 'react-query';
import { OrganisationUser } from '@/Models/OrganisationUser';
import SelectField, { SelectFieldProps } from '@/Components/SelectField';
import withQueryClient from '@/HOCs/WithQueryClient';
import uniqBy from 'lodash-es/uniqBy';
import { queryKeys } from '@/Services/QueryKeys';
import UserSelectFieldOption from '@/Components/UsersSelectField/Option';
import UserSelectFieldSingleValue from '@/Components/UsersSelectField/SingleValue';
import { OptionTypeBase } from 'react-select/src/types';

interface Props extends SelectFieldProps {
    isMulti?: boolean;
    className?: string;
    value: string | string[];
    additionalOptions?: { label: string; value: string }[];
    placeholderSelectable?: boolean;
    unassignedOption?: boolean;
}

const UsersSelectField: FunctionComponent<Props> = ({
    isMulti,
    placeholder,
    placeholderSelectable = false,
    unassignedOption = false,
    ...props
}) => {
    const usersQuery = useQuery(queryKeys.teamMembers.list(), () =>
        RootStore.stores.usersStore.list()
    );

    const options = useMemo(() => {
        let initialOpts: OptionTypeBase[] = placeholderSelectable
            ? [{ value: undefined, label: placeholder }]
            : [];

        if (unassignedOption) {
            initialOpts.push({ value: 'unassigned', label: 'Unassigned' });
        }

        const opts: OptionTypeBase[] = uniqBy(
            usersQuery.data?.map((user: OrganisationUser) => ({
                value: user.uuid,
                label: user.initialed,
                user: user,
            })) ?? [],
            'value'
        );

        return [...initialOpts, ...opts];
    }, [usersQuery.data]);

    return (
        <SelectField
            options={[...(props.additionalOptions ?? []), ...options]}
            fieldProps={{
                isMulti,
            }}
            closeMenuOnSelect={!isMulti}
            isMulti={isMulti}
            components={{
                Option: UserSelectFieldOption,
                SingleValue: UserSelectFieldSingleValue,
            }}
            size="lg"
            {...props}
        />
    );
};

export default withQueryClient(observer(UsersSelectField));
