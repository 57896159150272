import angular from 'angular';

angular
    .module('component.wizard.header', [])

    .component('wizardHeader', {
        transclude: {
            action: '?action',
        },
        template: `
            <header class="wizard-header action-bar">
                <h2 class="action-bar__text-item" ng-transclude></h2>
                <div class="action-bar__group --align-right" ng-transclude="action"></div>
            </header>
        `,
    });
