import React, { FunctionComponent, useMemo } from 'react';
import { Tooltip } from '@/Components/Tooltip';
import { SchedulePlannedEvent } from '@/Features/Schedule';
import classNames from 'classnames';
import Alert, { AlertType } from '@/Components/Alert';

interface Props {
    event: SchedulePlannedEvent;
    view?: any;
    onViewEvent?(eventId: string): void;
}

export const ScheduleEventAvailabilityViewIcon: FunctionComponent<Props> = ({
    event,
    view,
    onViewEvent,
}) => {
    const alert: { type: AlertType; label: string } = useMemo(() => {
        if (!event.assignedUser || typeof event.isAvailable === 'undefined') {
            return {
                type: 'grey',
                label: 'Unknown',
            };
        }
        if (!event.isAvailable) {
            return {
                type: 'error',
                label: 'Unavailable',
            };
        }
        if (event.isOnHoliday) {
            return {
                type: 'warning',
                label: 'On Holiday',
            };
        }

        return {
            type: 'success',
            label: 'Available',
        };
    }, [event.assignedUser, event.isAvailable, event.isOnHoliday]);

    const handleViewClick = () => {
        if (typeof view !== 'undefined') {
            onViewEvent!(event.id);
        }
    };

    return (
        <div>
            <Tooltip label={alert.label}>
                <div className="h-[48px] w-[48px] min-w-[48px]">
                    <Alert
                        type={alert.type}
                        title=""
                        className={classNames(
                            'flex h-[48px] w-[48px] items-center justify-center !rounded-md !p-0',
                            {
                                'cursor-pointer': typeof view !== 'undefined',
                            }
                        )}
                        onClick={handleViewClick}
                    />
                </div>
            </Tooltip>
        </div>
    );
};
