import React, { ComponentPropsWithoutRef, FunctionComponent } from 'react';
import { Session } from '@/Models/Session';
import SessionUpdateButton from '@/Components/Session/UpdateButton';
import EntryRescheduleButton, {
    CalendarSetRescheduleAction,
} from '@/Components/EntryRescheduleButton';
import EntryRelinquishButton from '@/Components/EntryRelinquishButton';
import SessionCancelButton from '@/Components/Session/CancelButton';
import ManagePackButton from '@/Components/Session/ManagePackButton';
import ScheduleAddEntryDatesButton from '@/Components/Schedule/AddEntryDatesButton';
import { NewPopper } from '@/Components/NewPopper';
import useAuth from '@/Hooks/useAuth';
import {
    AvailableEntryClaimButton,
    ScheduleEventTeachersButton,
} from '@/Features/Schedule';

interface Props extends ComponentPropsWithoutRef<'p'> {
    session: Session;
    handleUpdated?(session: Session, otherSessions: Session[]): void;
    handleCancelled?(): void;
    rescheduleAction?: CalendarSetRescheduleAction;
    onAssignedUserChange?(assignedUser: string | undefined): void;
}

export const SessionOptionsMenu: FunctionComponent<Props> = ({
    session,
    handleUpdated,
    handleCancelled,
    rescheduleAction,
    onAssignedUserChange,
}) => {
    const { hasRole } = useAuth();

    return (
        <NewPopper placement="bottom-end">
            {() => (
                <div className="ui-button-list">
                    {hasRole('Owner') && (
                        <>
                            <SessionUpdateButton
                                session={session}
                                onSuccess={handleUpdated}
                            />
                            <ScheduleEventTeachersButton
                                entry={session}
                                variant="full"
                                onAssignedUserChange={onAssignedUserChange}
                            />
                            <EntryRescheduleButton
                                entry={session}
                                variant="full"
                                action={rescheduleAction}
                            />
                        </>
                    )}

                    <EntryRelinquishButton entry={session} variant="menu" />
                    <AvailableEntryClaimButton entry={session} />

                    {hasRole('Owner') && (
                        <>
                            <SessionCancelButton
                                sessionId={session.eid}
                                onSuccess={handleCancelled}
                            />

                            <ManagePackButton session={session} />

                            {session.sessionPack?.hasUnplanned && (
                                <ScheduleAddEntryDatesButton
                                    variant="menu"
                                    type="session"
                                    parentIdentifier={
                                        session.references.sessionPack
                                    }
                                />
                            )}
                        </>
                    )}
                </div>
            )}
        </NewPopper>
    );
};
