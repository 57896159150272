import React, { FunctionComponent } from 'react';
import { AlertType } from '@/Components/Alert';
import { alertColors } from '@/Services/Alert';
import { Icon } from '@mdi/react';
import {
    mdiAlert,
    mdiCheckCircle,
    mdiHelpCircle,
    mdiInformation,
} from '@mdi/js';
import classNames from 'classnames';

interface Props {
    alertType: AlertType;
    className?: string;
}

export const AlertIcon: FunctionComponent<Props> = ({
    alertType,
    className,
}) => {
    const styles = alertColors(alertType);

    function getPath(): string {
        switch (alertType) {
            case 'success':
                return mdiCheckCircle;
            case 'error':
                return mdiAlert;
            case 'warning':
                return mdiAlert;
            case 'grey':
                return mdiHelpCircle;
            default:
                return mdiInformation;
        }
    }

    return (
        <Icon
            path={getPath()}
            className={classNames('h-4 w-4', styles.svg, className)}
        />
    );
};
