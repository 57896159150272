import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import ItemListTextCell from '@/Components/ItemList/TextCell';
import Icon from '@/Components/Icon/Icon';
import { uiColors } from '@/Theme';
import { getSimplifiedDate } from '@/Services/Date';

interface Props {
    client?: Client;
}

const Component: FunctionComponent<Props> = ({ client }) => {
    return (
        <ItemListTextCell
            icon={
                <Icon
                    name="ClockFilled"
                    width={16}
                    height={16}
                    fill={
                        client.hasUpcomingActivity
                            ? uiColors.positive.default
                            : uiColors.warning.default
                    }
                />
            }
            className="min-w-0 flex-wrap lg:flex-nowrap"
            content={
                <>
                    <span className="xl:hidden">Next activity&nbsp;</span>
                    {client.hasUpcomingActivity
                        ? getSimplifiedDate(client.nextActive.date)!
                        : 'None'}
                </>
            }
            secondaryContent={
                client.hasUpcomingActivity ? client.nextActive.label : 'N/A'
            }
            secondaryContentClassName="truncate"
        />
    );
};

export const ClientListItemNextActivityCell = observer(Component);
