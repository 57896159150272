import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Client } from '@/Models/Client';
import classNames from 'classnames';
import { Tooltip } from '@/Components/Tooltip';
import {
    clientAnniversaryString,
    clientBirthdayString,
} from '@/Services/ClientOccasions';

interface Props {
    client?: Client;
    className?: string;
}

const Component: FunctionComponent<Props> = ({ client, className }) => {
    if (!client.isOccasion) {
        return null;
    }

    return (
        <Tooltip
            label={
                client.isBirthday
                    ? clientBirthdayString(client, false)
                    : clientAnniversaryString(client, false)
            }
        >
            <span
                className={classNames(
                    'z-10 inline-flex h-6 w-6 min-w-[24px] items-center justify-center rounded-full bg-white',
                    className
                )}
            >
                {client.isBirthday ? '🎂' : '🎉'}
            </span>
        </Tooltip>
    );
};

export const ClientOccasionIcon = observer(Component);
