import React, { FunctionComponent } from 'react';
import DOMPurify from 'dompurify';
import { EditingNotesProps } from '../Modal';
import { Button, WhiteButton } from '../../Button';
import RootStore from '../../../Stores/RootStore';
import ActionBar from '../../ActionBar';
import ActionBarGroup from '../../ActionBar/Group';
import { observer } from 'mobx-react';

interface Props extends EditingNotesProps {
    title?: string;
    label?: string;
    buttonClassName?: string;
    persistTitle?: boolean;
}

const NotesField: FunctionComponent<Props> = ({
    content,
    onSave,
    title = 'Notes',
    persistTitle = false,
    label = 'Notes',
    buttonClassName,
}) => {
    function handleClick() {
        RootStore.stores.uiStateStore.pushModal('NOTES', {
            content,
            onSave,
        });
    }

    if (content || persistTitle) {
        return (
            <div className="rounded-lg border border-dashed border-gray-200 bg-white">
                <ActionBar className="px-app border-b border-gray-200 py-3">
                    <ActionBarGroup grow>
                        <h2 className="ui-action-bar__title">{title}</h2>
                    </ActionBarGroup>
                    {content && (
                        <ActionBarGroup right>
                            <Button
                                type="button"
                                icon="Pencil"
                                onClick={handleClick}
                            />
                        </ActionBarGroup>
                    )}
                </ActionBar>
                {content && (
                    <div
                        className="prose max-w-none p-6"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(content, {}),
                        }}
                        data-testid="notes-content"
                    />
                )}
            </div>
        );
    }

    return (
        <WhiteButton
            size="full"
            icon="Plus"
            type="button"
            onClick={handleClick}
            style={{
                display: 'flex',
                minHeight: 60,
                borderStyle: 'dashed',
            }}
            className={buttonClassName}
            data-testid="add-notes-button"
        >
            {label}
        </WhiteButton>
    );
};

export default observer(NotesField);
