import { applySnapshot, flow, getSnapshot, types } from 'mobx-state-tree';
import { DateTimeType } from '../Types/DateTime';
import WithLegacyDates from './WithLegacyDates';
import { toLuxon } from '../Services/Date';
import DiaryEntryTransport from '../Services/Transport/DiaryEntryTransport';
import ScheduleEntry from './ScheduleEntry';

const DiaryEntryModel = types
    .model('DiaryEntry', {
        eid: types.string,
        type: types.literal('diary-entry'),
        identifier: types.identifier,
        resourceVersion: 2,
        label: types.string,
        start: DateTimeType,
        end: DateTimeType,
        notes: types.maybeNull(types.string),
        length: types.integer,
        isCancelled: false,
    })

    .preProcessSnapshot((snapshot) => {
        if (snapshot.resourceVersion === 1) {
            snapshot = {
                ...snapshot,
                start: toLuxon(snapshot.start_at),
                end: toLuxon(snapshot.end_at),
            };
        }

        return snapshot;
    })

    .views((self) => ({
        get humanEntityType() {
            return 'Appointment';
        },

        get scheduleResourceData() {
            return {
                id: self.eid,
                type: 'diary-entry',
                start_at: self.start,
                end_at: self.end,
                label: self.label,
            };
        },
    }))

    .actions((self) => ({
        update: flow(function* update(input = {}) {
            self.busy = true;

            const { data } = yield DiaryEntryTransport.update(self.eid, input);
            self.updateData({
                ...data.data,
                busy: false,
            });

            return self;
        }),

        updateData(data = {}) {
            applySnapshot(self, {
                ...getSnapshot(self),
                ...data,
            });
        },

        updateNotes: flow(function* updateNotes(notes = null) {
            return yield self.update({ notes });
        }),

        destroy: flow(function* destroy() {
            yield DiaryEntryTransport.destroy(self.eid);
        }),

        updateLabel(label) {
            self.label = label;
        },
    }));

export const DiaryEntry = types.compose(
    DiaryEntryModel,
    ScheduleEntry,
    WithLegacyDates
);
