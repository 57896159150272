import React from 'react';

const UIFilter = (props) => (
    <svg viewBox="0 0 14 14" {...props}>
        <path
            fill={props.fill}
            d="M12.967 0a1.032 1.032 0 01.772 1.715l-4.672 5.24v4.38l-2.37 2.363a1.036 1.036 0 01-1.764-.728V6.956L.26 1.716A1.028 1.028 0 011.034 0h11.933z"
        />
    </svg>
);

export default UIFilter;
