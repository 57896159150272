import React, { FunctionComponent } from 'react';
import Notification from '../../../Services/Notification';
import Modal from '@/Components/Modal';
import RootStore from '../../../Stores/RootStore';
import FormField from '@/Components/FormField';
import Checkbox from '@/Components/Checkbox';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react';
import { PrimaryButton } from '@/Components/Button';
import Notice from '@/Components/Notice';
import {
    ClientPortalInviteButton,
    ClientSettingsFormValues,
} from '@/Features/Clients';
import { Client } from '@/Models/Client';
import { StackableModalProps } from '@/Components/RootModal';
import { AxiosError } from 'axios';

interface Props extends StackableModalProps {
    client: Client;
}

const Component: FunctionComponent<
    Props & FormikProps<ClientSettingsFormValues>
> = ({ client, ...props }) => {
    const { currentUserStore } = RootStore.stores;
    const { portalEnabled: globalPortalEnabled, portalLoginEnabled } =
        currentUserStore.organisation;

    function getAction() {
        return client.hasPortalPasswordInvite || !client.portalRegistered ? (
            <ClientPortalInviteButton client={client} />
        ) : client.portalEnabled && portalLoginEnabled && !!client.lastSeen ? (
            `Last seen ${client.lastSeenAge}`
        ) : null;
    }

    const {
        values,
        setFieldValue,
        handleSubmit,
        handleChange,
        handleBlur,
        isSubmitting,
    } = props;

    return (
        <Modal
            stackProps={props.stackProps}
            title={`${client.firstName}'s settings`}
            rightAction={() => (
                <PrimaryButton isLoading={isSubmitting}>Save</PrimaryButton>
            )}
            onSubmit={handleSubmit}
        >
            <fieldset className="dialog__panel">
                <FormField className="--with-options">
                    <FormField.Label>
                        Send Automatic reminders to {client.firstName}
                    </FormField.Label>

                    <div className="form-field__options">
                        <div>
                            <input
                                id="automatic_reminders_yes"
                                className="radio"
                                type="radio"
                                onChange={() =>
                                    setFieldValue('automaticReminders', true)
                                }
                                checked={values.automaticReminders}
                            />
                            <label htmlFor="automatic_reminders_yes">Yes</label>
                        </div>
                        <div>
                            <input
                                id="automatic_reminders_no"
                                className="radio"
                                type="radio"
                                onChange={() =>
                                    setFieldValue('automaticReminders', false)
                                }
                                checked={!values.automaticReminders}
                            />
                            <label htmlFor="automatic_reminders_no">No</label>
                        </div>
                    </div>

                    <div className="form-field__info +mbottom-20">
                        <p>
                            Send a reminder to {client.firstName} a day before
                            each event.
                        </p>
                    </div>
                </FormField>

                <FormField className="--with-options">
                    <FormField.Label>Client area</FormField.Label>

                    {globalPortalEnabled && client.portalRegistered && (
                        <div className="form-field__options">
                            <div className="mb-2">
                                <Checkbox
                                    checked={values.portalEnabled}
                                    name="portalEnabled"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label="Access enabled"
                                />
                            </div>
                        </div>
                    )}
                    <div className="form-field__info +mbottom-20">
                        <p>
                            Allow {client.firstName} to log in to your client
                            area and Striive Client app.
                        </p>
                    </div>
                    {globalPortalEnabled && !client.portalRegistered && (
                        <>
                            <Notice className="mb-4" type="warning">
                                {client.firstName} has not yet added a password.
                            </Notice>
                            {getAction()}
                        </>
                    )}
                    {!globalPortalEnabled && (
                        <Notice type="warning">
                            Client area disabled in your brand settings.
                        </Notice>
                    )}
                </FormField>
            </fieldset>
        </Modal>
    );
};

export const ClientSettingsModal = withFormik<Props, ClientSettingsFormValues>({
    mapPropsToValues: ({ client }) => ({
        portalEnabled: client.portalEnabled,
        automaticReminders: client.automaticReminders,
    }),
    handleSubmit: async (values, { props, setErrors }) => {
        const { uiStateStore } = RootStore.stores;

        try {
            await props.client.update(values);
        } catch (e) {
            if ((e as AxiosError).response?.status === 422) {
                setErrors((e as AxiosError).response?.data.errors);
                return;
            }

            Notification.error();
            return;
        }
        //
        // if (typeof props.onUpdate === 'function') {
        //     props.onUpdate(client);
        // }

        Notification.success('Settings saved');

        uiStateStore.popModal();
    },
})(observer(Component));
