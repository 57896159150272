import RootStore from '../Stores/RootStore';

const locationKeyMap: { [key: string]: string } = {
    location: 'delivery.location',
    locationUrl: 'delivery.url',
    locationPassword: 'delivery.password',
};

export const remapLocationKeys = (updatePlanned?: string[]) => {
    if (typeof updatePlanned === 'undefined') {
        return updatePlanned;
    }

    ['location', 'locationUrl', 'locationPassword'].forEach((key: string) => {
        if (updatePlanned!.includes(key)) {
            updatePlanned = [
                ...updatePlanned!.filter((item: string) => item !== key),
                locationKeyMap[key],
            ];
        }
    });

    return updatePlanned;
};

export const openEntryViewModal = function (type: string, modalProps = {}) {
    let modal = '';

    switch (type) {
        case 'class-date':
            modal = 'CLASS_DATE_VIEW';
            break;

        case 'diary-entry':
            modal = 'DIARY_ENTRY_VIEW';
            break;

        case 'consultation':
            modal = 'CONSULTATIONS_VIEW';
            break;

        case 'session':
            modal = 'SESSION_VIEW';
            break;
    }

    RootStore.stores.uiStateStore.pushModal(modal, modalProps);
};
