import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import { Agreement } from '@/Models/Agreement';
import { ClearButton } from '@/Components/Button';

interface Props {
    agreement: Agreement;
}

const Component: FunctionComponent<Props> = ({ agreement }) => {
    const { uiStateStore } = RootStore.stores;

    const handleClick = () => {
        uiStateStore.pushModal('AGREEMENT_UPDATE', { agreement });
    };

    return (
        <ClearButton onClick={handleClick} icon="Pencil">
            Edit
        </ClearButton>
    );
};

export const UpdateAgreementButton = observer(Component);
