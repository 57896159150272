import React from 'react';

const UICross = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            fill={props.fill}
            d="M6 0c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6zm3.03 2.97a.75.75 0 00-1.06 0L6 4.939l-1.97-1.97-.084-.072-.102-.064A.75.75 0 002.97 4.03L4.939 6l-1.97 1.97-.072.084a.75.75 0 001.133.976L6 7.061l1.97 1.97.084.072A.75.75 0 009.03 7.97L7.061 6l1.97-1.97.072-.084a.75.75 0 00-.073-.976z"
            fillRule="evenodd"
        />
    </svg>
);

export default UICross;
