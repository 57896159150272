import React, { FunctionComponent } from 'react';
import { Client } from '../../Models/Client';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { ClientAvatar } from '@/Features/Clients';

interface Props {
    clients: Client[];
    className?: string;
}

const ImageClientGroup: FunctionComponent<Props> = ({ clients, className }) => {
    return (
        <div className={classNames('flex', className)}>
            {clients.map((client, index) => (
                <div
                    key={client.eid}
                    className={classNames({
                        '-mr-3': index + 1 < clients.length,
                    })}
                    style={{ zIndex: 10 - index }}
                >
                    <ClientAvatar
                        withLink
                        client={client}
                        className="rounded-full border-2 border-white"
                    />
                </div>
            ))}
        </div>
    );
};

export default observer(ImageClientGroup);
