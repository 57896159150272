import React, { ComponentPropsWithoutRef, FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { MenuButton, WhiteButton } from '@/Components/Button';
import { NewPopper } from '@/Components/NewPopper';
import { CancelSelectedClassDatesButton } from '@/Features/ClassDates';
import { Class } from '@/Models/Class';
import { uiColors } from '@/Theme';
import OptionsMenu from '@/Components/OptionsMenu';
import { ReactComponent as DeselectIcon } from '../../../../images/icons/deselect.svg';

interface Props extends ComponentPropsWithoutRef<'p'> {
    classContainer: Class;
    selected: string[];
    onClearSelected(): void;
    onCancelSuccess?(): void;
}

const Component: FunctionComponent<Props> = ({
    classContainer,
    selected,
    onClearSelected,
}) => {
    return (
        <div className="flex items-center gap-3">
            <p className="relative -top-px m-0 font-bold">
                {selected.length} selected
            </p>
            <NewPopper
                placement="bottom-start"
                anchor={<WhiteButton type="button" icon="DotsVertical" />}
            >
                {() => (
                    <OptionsMenu>
                        <MenuButton
                            icon={
                                <DeselectIcon className="h-4 w-4 stroke-current text-icon" />
                            }
                            fill={uiColors.negative.default}
                            className="min-w-[120px] !justify-start gap-1.5"
                            onClick={onClearSelected}
                        >
                            Clear selected
                        </MenuButton>
                        <CancelSelectedClassDatesButton
                            classContainer={classContainer}
                            selected={selected}
                            onSuccess={() => onClearSelected()}
                        />
                    </OptionsMenu>
                )}
            </NewPopper>
        </div>
    );
};

export const ClassDateSelectedOptionsMenu = observer(Component);
