import { getRoot, isValidReference, types } from 'mobx-state-tree';
import { DateTimeType } from '../Types/DateTime';
import { ClientReference } from './Client';
import { SessionPackReference } from './SessionPack';
import { ClassPassReference } from './ClassPass';
import { EnrolmentReference } from './Enrolment';
import { MembershipSubscriptionReference } from './MembershipSubscription';
import { formatDate } from '../Services/Date';
import { Currency } from './Currency';

export const Transaction = types
    .model('Transaction', {
        uuid: types.identifier,
        madeAt: DateTimeType,
        amount: types.number,
        method: types.string,
        isDeleted: types.boolean,
        reference: types.string,
        isViaStripe: types.boolean,

        currency: Currency,

        transactableType: types.enumeration([
            'sessionPack',
            'classPass',
            'enrolment',
            'membership',
        ]),
        sessionPack: types.maybe(types.late(() => SessionPackReference)),
        classPass: types.maybe(types.late(() => ClassPassReference)),
        enrolment: types.maybe(types.late(() => EnrolmentReference)),
        membership: types.maybe(
            types.late(() => MembershipSubscriptionReference)
        ),

        client: types.maybe(types.late(() => ClientReference)),
    })

    .views((self) => ({
        get transactable() {
            switch (self.transactableType) {
                case 'sessionPack':
                    return self.sessionPack;
                case 'classPass':
                    return self.classPass;
                case 'enrolment':
                    return self.enrolment;
                case 'membership':
                    return self.membership;
            }

            return null;
        },

        get isRefund() {
            return self.amount < 0;
        },

        get type() {
            return self.isRefund ? 'refund' : 'payment';
        },

        get sessionPackEid() {
            return isValidReference(() => self.sessionPack)
                ? self.sessionPack.eid
                : self.sessionPack;
        },

        get isForSessionPack() {
            return self.transactableType === 'sessionPack';
        },

        get isForEnrolment() {
            return self.transactableType === 'enrolment';
        },

        get madeAtFormatted() {
            return formatDate(self.madeAt);
        },
    }));

export const TransactionReference = types.maybeNull(
    types.reference(Transaction, {
        get(uuid, parent) {
            return getRoot(parent).stores.transactionStore.findOrFetch(uuid);
        },
        set({ uuid }) {
            return uuid;
        },
    })
);
