import qs from 'qs';
import { inUserZone } from '@/Services/Date';
import { DateTime } from 'luxon';

export type ScheduleViewType = 'week' | 'month' | 'day' | 'responsive';

export const MOBILE_THRESHOLD = 720;

/**
 * Get the browser width
 * @returns {number}
 */
export const getWidth = () =>
    Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    );

export const isMobile = () => getWidth() < MOBILE_THRESHOLD;

/**
 * Get the week schedule url params for the current time
 * @param date
 * @param otherParams
 * @returns {{week: string, year: number}}
 */
export const getWeekUrlParams = (
    date: DateTime | null = null,
    otherParams = {}
) => {
    const dateTime = date ?? inUserZone();
    const week = 'W' + String(dateTime?.weekNumber).padStart(2, '0');
    const year = dateTime?.weekYear;

    return {
        year,
        week,
        ...otherParams,
    };
};

/**
 * Get the month schedule url params for the current time
 * @param date
 * @param otherParams
 * @returns {{week: string, year: number}}
 */
export const getMonthUrlParams = (
    date: DateTime | null = null,
    otherParams = {}
) => {
    const dateTime = date ?? inUserZone();

    return {
        year: dateTime?.year,
        month: String(dateTime?.month).padStart(2, '0'),
        ...otherParams,
    };
};

/**
 * Get the human readable name for the given entry type
 * @param type
 * @returns {*}
 */
export const getEntryHumanName = (
    type: 'session' | 'consultation' | 'class-date' | 'holiday' | 'diary-entry'
) => {
    const map = {
        session: 'Session',
        consultation: 'Consultation',
        'class-date': 'Class',
        holiday: 'Holiday',
        'diary-entry': 'Appointment',
    };

    return map[type] || type;
};

export const getFormattedUrl = (
    date: DateTime | null = null,
    view: ScheduleViewType | null = null,
    params = {}
) => {
    let url = '/schedule';

    if (!view) {
        view = 'week';
    } else if (view === 'responsive') {
        view = isMobile() ? 'day' : 'week';
    }

    const dateTime = date ?? inUserZone();

    if (view === 'week') {
        const { year, week } = getWeekUrlParams(dateTime);
        url += `/${year}/${week}`;
    } else {
        const { year, month } = getMonthUrlParams(dateTime);
        url += `/${year}/${month}`;
    }

    if (view === 'day') {
        url += `/${String(dateTime?.day).padStart(2, '0')}`;
    }

    return `${url}?${qs.stringify(params)}`;
};
