import BaseTransport from './BaseTransport';

class SessionTransport extends BaseTransport {
    async list(params = {}) {
        return await this.get('/v3/sessions', params);
    }

    async fetch(eid, params = {}) {
        return await this.get(`/v3/sessions/${eid}`, params);
    }

    async update(eid, params = {}) {
        return await this.put(`/v3/sessions/${eid}`, params);
    }

    async updateNotes(eid, description) {
        return await this.put(`/v3/sessions/${eid}/notes`, { description });
    }

    async cancel(eid, params = {}) {
        return await this.put(`/v3/sessions/${eid}/cancel`, params);
    }

    async setAttendance(identifier, attendance, client = null) {
        return await this.put(`/v3/sessions/${identifier}/attendance`, {
            attendance,
            client,
        });
    }

    async updateDelivery(eid, data) {
        return await this.put(`/v3/sessions/${eid}/delivery`, data);
    }

    // New API
    async getCancellationData(eid: string) {
        return await this.get(`/v3/sessions/${eid}/cancellation-data`);
    }

    async assignUsers(
        eid: string,
        data: { assignedUsers: string[]; isAvailableToAssign: boolean }
    ) {
        return await this.post(`/v3/sessions/${eid}/assign-users`, data);
    }

    async claim(eid: string) {
        return await this.post(`/v3/sessions/${eid}/claim`);
    }

    async relinquish(eid: string) {
        return await this.post(`/v3/sessions/${eid}/relinquish`);
    }

    async sendReminder(eid: string, notify) {
        return await this.post(`/v3/sessions/${eid}/remind`, { notify });
    }
}

export default new SessionTransport();
