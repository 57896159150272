import React from 'react';

const CustomDashboard = (props) => (
    <svg viewBox="0 0 20 20" {...props}>
        <path
            fill={props.fill}
            d="M9 8v12H2a2 2 0 01-2-2V8h9zm11 7v3a2 2 0 01-2 2h-7v-5h9zM7 10H2v8h5v-8zm13-2v5h-9V8h9zm-2-8a2 2 0 012 2v4H0V2a2 2 0 012-2h16z"
            fillRule="evenodd"
        />
    </svg>
);

export default CustomDashboard;
