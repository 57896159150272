import BaseTransport from './BaseTransport';

class PackageTransport extends BaseTransport {
    async list(params = {}) {
        return await this.get('/v3/packages', params);
    }

    async fetch(uuid: string, params = {}) {
        return await this.get(`/v3/packages/${uuid}`, params);
    }

    async store(data = {}) {
        return await this.post('/v3/packages', data);
    }

    async sendPurchaseLink(uuid: string, client: string) {
        return await this.post(`/v3/packages/${uuid}/send-purchase-link`, {
            client,
        });
    }

    async update(uuid: string, data = {}) {
        return await this.put(`/v3/packages/${uuid}`, data);
    }

    async archive(uuid: string) {
        return await this.post(`/v3/packages/${uuid}/archive`);
    }

    async restore(uuid: string) {
        return await this.post(`/v3/packages/${uuid}/restore`);
    }
}

export default new PackageTransport();
