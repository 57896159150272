import React, { FunctionComponent, useEffect } from 'react';
import GistBubbleContext from '../Contexts/GistBubbleContext';
import { useLocation } from 'react-router';

interface Props {}

type GistBubbleState = 'show' | 'hide';

const GistChatBubbleProvider: FunctionComponent<Props> = ({ children }) => {
    const { pathname } = useLocation();

    function setChatState(state?: GistBubbleState) {
        if (typeof (window as any).gist?.chat === 'function') {
            (window as any).gist.chat(state);
        }
    }

    function handleBubbleReady() {
        setChatState(pathname.startsWith('/auth/sign') ? 'hide' : 'show');
    }

    useEffect(() => {
        handleBubbleReady();

        document.addEventListener('gistReady', handleBubbleReady);

        return () => {
            document.removeEventListener('gistReady', handleBubbleReady);
        };
    }, [handleBubbleReady]);

    const value = {
        show: () => setChatState('show'),
        hide: () => setChatState('hide'),
    };

    return (
        <GistBubbleContext.Provider value={value}>
            {children}
        </GistBubbleContext.Provider>
    );
};

export default GistChatBubbleProvider;
