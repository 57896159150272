import React, { FunctionComponent, useContext } from 'react';
import { observer } from 'mobx-react';
import { ClearButton } from '@/Components/Button';
import { PortalInvite } from '@/Models/PortalInvite';
import { uiColors } from '@/Theme';
import EventConfirmationModalContext from '@/Contexts/EventConfirmationModalContext';
import Notification from '@/Services/Notification';
import queryClient from '@/Modules/QueryClient';

interface Props {
    invite: PortalInvite;
}

const Component: FunctionComponent<Props> = ({ invite }) => {
    const confirmationContext = useContext(EventConfirmationModalContext);

    async function deleteInvite() {
        try {
            await invite.remove();
            await queryClient.invalidateQueries(['clients', 'invites']);
            Notification.success('Invite deleted');
        } catch (e) {
            console.log('error', e);
            Notification.error();
        }
    }

    function handleClick() {
        confirmationContext.requireConfirmation({
            content: () => (
                <p className="mb-2">
                    Are you sure you want to delete this invite?
                </p>
            ),
            onConfirmed: deleteInvite,
        });
    }

    return (
        <ClearButton
            onClick={handleClick}
            icon="Delete"
            fill={uiColors.negative.default}
        >
            Delete
        </ClearButton>
    );
};

export const DeleteClientPortalInviteButton = observer(Component);
