import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { MessageListItemContentCell } from '@/Features/Messages';
import ItemListTitleCell from '@/Components/ItemList/TitleCell';
import classNames from 'classnames';
import { Message } from '@/Models/Message';
import { ClientAvatar } from '@/Features/Clients';

interface Props {
    message: Message;
    className?: string;
}

const Component: FunctionComponent<Props> = ({ message, className }) => {
    return (
        <div
            className={classNames(
                className,
                'grid-cols-[36px_auto] xl:grid-cols-messages-xl'
            )}
        >
            <ClientAvatar
                client={message.client}
                className="row-span-3 xl:row-auto"
                withLink
            />
            <ItemListTitleCell
                url={message.client?.url}
                title={message.client?.initialed}
                subtitle={message.createdAtFormatted}
            />
            <MessageListItemContentCell message={message} />
        </div>
    );
};

export const MessageListItem = observer(Component);
