import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../Stores/RootStore';
import { PrimaryButton } from '@/Components/Button';

interface Props {
    action: any;
    view: any;
    calendar: any;
    disabled: boolean;
}

const Component: FunctionComponent<Props> = (props) => {
    const { uiStateStore } = RootStore.stores;

    // const dateCount = props.action.cards.length;
    // {countInflect(dateCount, 'new date', 'new dates')}
    const handleClick = () => {
        uiStateStore.pushModal('CLASSES_CREATE', {
            view: props.view,
            calendar: props.calendar,
            newDates: props.action.cards,
            scrollToDates: true,
        });
    };

    return (
        <PrimaryButton
            onClick={handleClick}
            data-resume-session-pack
            disabled={props.disabled}
            className="w-full"
        >
            <span>Review & save</span>
        </PrimaryButton>
    );
};

export const ScheduleFinishedPlacingClassDatesButton = observer(Component);
