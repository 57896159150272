import angular from 'angular';
import { react2angular } from 'react2angular';
import { ScheduleEntryPopper } from '@/Features/Schedule';

export default angular
    .module('component.react.schedule-entry-popper', [])
    .component(
        'reactScheduleEntryPopper',
        react2angular(ScheduleEntryPopper, [
            'isSelected',
            'isMoving',
            'view',
            'card',
            'calendar',
            'action',
            'onRemove',
        ])
    );
