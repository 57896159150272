import angular from 'angular';
import { react2angular } from 'react2angular';
import Loader from '../../../js/Components/Loader';

angular
    .module('component.react.loader', [])
    .component(
        'reactLoader',
        react2angular(Loader, ['withShroud', 'withPosition'])
    );
