import { QueryClient } from 'react-query';

export default new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: 1000 * 60 * 5,
            structuralSharing: false,
            refetchOnWindowFocus: true,
        },
    },
});
