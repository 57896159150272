import React, { FunctionComponent, useState } from 'react';
import { ClearButton } from '../../Button';
import RootStore from '../../../Stores/RootStore';
import { observer } from 'mobx-react';
import { Session } from '../../../Models/Session';
import Notification from '../../../Services/Notification';

interface Props {
    session: Session;
    onSuccess?(session: Session, otherSessions: Session[]): void;
}

const SessionUpdateButton: FunctionComponent<Props> = ({
    session,
    onSuccess,
}) => {
    const { uiStateStore, sessionPackStore } = RootStore.stores;
    const [busy, setBusy] = useState(false);

    async function handleClick() {
        setBusy(true);

        try {
            await sessionPackStore.fetch(session.references.sessionPack, {
                include: [
                    'sessions.delivery.locatable',
                    'sessions.position',
                    'sessions.clients',
                    'sessions.participation',
                    'participation',
                ],
            });
            uiStateStore.pushModal('SESSION_UPDATE', {
                session,
                onUpdate: onSuccess,
            });
        } catch (e) {
            Notification.error();
        }

        setBusy(false);
    }

    return (
        <ClearButton
            icon="Pencil"
            onClick={handleClick}
            isLoading={busy}
            disabled={session.endInPast()}
        >
            Edit
        </ClearButton>
    );
};

export default observer(SessionUpdateButton);
