import { ClassDateListFilter } from '@/Features/ClassDates';
import { EntityListInfiniteParams, EntityListParams } from '@/Types/Query';

export const keys = {
    all: ['classDates'] as const,
    lists: () => [...keys.all, 'list'] as const,
    listsInfinite: () => [...keys.all, 'listInfinite'] as const,
    list: (params: EntityListParams<ClassDateListFilter>) =>
        [...keys.lists(), params] as const,
    listInfinite: (params: EntityListInfiniteParams<ClassDateListFilter>) =>
        [...keys.listsInfinite(), params] as const,
    details: () => [...keys.all, 'detail'] as const,
    detail: (id: string) => [...keys.details(), id] as const,
};
