import React, { useMemo } from 'react';
import Select, {
    GroupTypeBase,
    OptionTypeBase,
    Props as SelectProps,
} from 'react-select';
import { uiColors } from '@/Theme';
import MultiValueContainer from '@/Components/SelectField/MultiValueContainer';
import MultiValueRemove from '@/Components/SelectField/MultiValueRemove';
import classNames from 'classnames';
import { CSSObject } from 'styled-components';

export interface SelectFieldProps<IsMulti extends boolean = false>
    extends Omit<SelectProps<any, IsMulti>, 'value' | 'isMulti'> {
    value?: readonly string[] | string | number | null | undefined;
    isMulti?: boolean;
    size?: 'default' | 'lg';
}

export interface SelectableOption {
    value: any;
    label: string;
}

const SelectField = <IsMulti extends boolean>({
    options,
    value,
    components,
    size,
    ...props
}: SelectFieldProps<IsMulti>) => {
    const currentValue = useMemo(() => {
        const flattened = options?.reduce(
            (acc: string[], option: OptionTypeBase) => {
                // If this is a group, flatten the options
                if (
                    (option as GroupTypeBase<OptionTypeBase>).options !==
                    undefined
                ) {
                    return [
                        ...acc,
                        ...(option as GroupTypeBase<OptionTypeBase>).options,
                    ];
                }

                return [...acc, option];
            },
            []
        );

        if (props.isMulti) {
            return flattened.filter((opt: OptionTypeBase) =>
                (value as string[])?.includes(opt.value)
            );
        }

        return (
            flattened.find((opt: OptionTypeBase) => opt.value === value) ?? null
        );
    }, [value, options]);

    return (
        <Select
            isClearable={false}
            isSearchable={false}
            components={{
                MultiValueContainer,
                MultiValueRemove,
                ...(components || {}),
            }}
            className={classNames(
                'ui-select-field__container',
                { multi: props.isMulti },
                props.className
            )}
            classNamePrefix="ui-select-field"
            isOptionDisabled={(opt: OptionTypeBase) => opt.disabled ?? false}
            value={currentValue}
            options={options}
            styles={{
                control: (base: CSSObject) => ({
                    ...base,
                    ...(props.isMulti || size === 'lg'
                        ? { minHeight: '48px' }
                        : {}),
                }),
                multiValueLabel: (base) => ({
                    ...base,
                    backgroundColor: 'white',
                }),
                multiValueRemove: (base) => ({
                    ...base,
                    color: uiColors.grey.mid,
                    backgroundColor: 'white',
                    ':hover': {
                        cursor: 'pointer',
                        color: uiColors.negative.default,
                        backgroundColor: 'white',
                    },
                }),
            }}
            {...props}
        />
    );
};

export default SelectField;
