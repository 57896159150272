import { Instance, types } from 'mobx-state-tree';
import { Image } from '@/Models/Image';

export const OrganisationUser = types
    .model('OrganisationUser', {
        uuid: types.string,
        identifier: types.identifier,
        type: types.literal('org-user'),

        // Safe data
        userEid: types.string,
        firstName: types.string,
        lastName: types.string,
        fullName: types.string,
        jobTitle: types.maybeNull(types.string),
        bio: types.maybeNull(types.string),
        roleName: types.string,
        status: types.enumeration(['active', 'archived']),
        image: types.maybeNull(Image),
        createdAt: types.string,

        calendarUrl: types.string,

        // Unsafe data
        email: types.maybe(types.string),
        mobilePhone: types.maybe(types.maybeNull(types.string)),
    })

    .views((self) => ({
        get initialed() {
            return `${self.firstName} ${self.lastName.charAt(0).toUpperCase()}`;
        },
    }));

export interface OrganisationUser extends Instance<typeof OrganisationUser> {}
