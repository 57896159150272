import React, { FunctionComponent, useMemo } from 'react';
import FormSelectField from '@/Components/FormSelectField';
import { FormFieldElementProps } from '@/Components/FormFieldElements';
import { useQuery } from 'react-query';
import DataTransport from '@/Services/Transport/DataTransport';
import { SelectFieldProps } from '@/Components/SelectField';

interface Props extends FormFieldElementProps {
    initialData?: string[];
    fieldProps?: SelectFieldProps;
}

interface Timezone {
    value: string;
    label: string;
}

const FormTimezoneSelectField: FunctionComponent<Props> = ({
    name = 'timezone',
    initialData,
    fieldProps,
    ...props
}) => {
    const { data, isLoading } = useQuery(
        ['data', 'timezones'],
        async () => {
            const { data } = await DataTransport.listTimezones();
            return data;
        },
        { initialData, enabled: !initialData }
    );

    const options = useMemo(() => {
        return (data ?? []).map(({ value, label }: Timezone) => ({
            value,
            label,
        }));
    }, [data]);

    return (
        <FormSelectField
            options={options}
            name={name}
            fieldProps={{
                isLoading,
                isSearchable: true,
                ...fieldProps,
            }}
            {...props}
        />
    );
};

export default FormTimezoneSelectField;
