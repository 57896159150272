import React from 'react';

const Clock = (props) => (
    <svg viewBox="0 0 100 100" width="100px" height="100px" {...props}>
        <path
            d="M49.95 0C22.35 0 0 22.4 0 50s22.35 50 49.95 50C77.6 100 100 77.6 100 50S77.6 0 49.95 0zM50 90c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40zm2.5-65H45v30l26.25 15.75L75 64.6 52.5 51.25V25z"
            fillRule="evenodd"
        />
    </svg>
);

export default Clock;
