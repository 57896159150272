import create from 'zustand';

type MicroState = {
    authenticated: boolean;
    setAuthenticated(authenticated: boolean): void;
};

export default create<MicroState>((set) => ({
    authenticated: window.App.isAuthenticated,
    setAuthenticated: (authenticated: boolean) => set({ authenticated }),
}));
