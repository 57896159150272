import BaseTransport from './BaseTransport';

class DiaryEntryTransport extends BaseTransport {
    async fetch(eid, params = {}) {
        return await this.get('/v3/diary-entries/' + eid, params);
    }

    async store(params = {}) {
        return await this.post('/v3/diary-entries', params);
    }

    async update(eid, params = {}) {
        return await this.put('/v3/diary-entries/' + eid, params);
    }

    async destroy(eid) {
        return await this.delete('/v3/diary-entries/' + eid);
    }
}

export default new DiaryEntryTransport();
