import { MultiValueRemoveProps } from 'react-select/src/components/MultiValue';
import { components, OptionTypeBase } from 'react-select';
import Icon from '@/Components/Icon/Icon';
import React from 'react';

const MultiValueRemove = (props: MultiValueRemoveProps<OptionTypeBase>) => {
    return (
        <components.MultiValueRemove {...props}>
            <Icon
                name="CloseCircle"
                width={16}
                height={16}
                className="color-current relative top-px fill-current"
            />
        </components.MultiValueRemove>
    );
};

export default MultiValueRemove;
