export default class ClassContainer {
    get nameSingular() {
        return 'class-date';
    }

    get namePlural() {
        return 'classes';
    }

    get titleSingular() {
        return 'Class date';
    }

    get titlePlural() {
        return 'Class dates';
    }
}
