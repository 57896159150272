import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import RootStore from '@/Stores/RootStore';
import { PrimaryButton, PrimaryResponsiveButton } from '@/Components/Button';

interface Props {
    email?: string;
    isResponsive?: boolean;
    variant?: 'default' | 'selectable';
    removeBeneath?: boolean;
}

const Component: FunctionComponent<Props> = ({
    email,
    variant = 'default',
    isResponsive = false,
    removeBeneath = false,
}) => {
    const { uiStateStore } = RootStore.stores;

    const handleClick = () => {
        uiStateStore.pushModal(
            'CLIENT_SEND_SIGN_UP_INVITE',
            { email },
            removeBeneath
        );
    };

    if (variant === 'selectable') {
        return (
            <a
                className="button --selectable-option mt-2"
                onClick={handleClick}
            >
                <span className="button__title">Send email invite</span>
                <span className="button__sub-title">
                    Your client will receive a link to enter their details
                    online.
                </span>
            </a>
        );
    }

    return (
        <>
            {!isResponsive && (
                <PrimaryButton icon="Plus" onClick={handleClick}>
                    Invite
                </PrimaryButton>
            )}
            {isResponsive && (
                <PrimaryResponsiveButton icon="Plus" onClick={handleClick}>
                    Invite
                </PrimaryResponsiveButton>
            )}
        </>
    );
};

export const SendClientSignUpInviteButton = observer(Component);
