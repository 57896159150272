import angular from 'angular';
import messaging from '@/Config/messaging';
import { possessive } from '@/Services/String';
import ClientTransport from '@/Services/Transport/ClientTransport';
import Notification from '@/Services/Notification';

const dialogTemplate = `
<dialog-wrapper confirmation>
    <div class="dialog__confirmation-content">
        <header>
            <p >You confirm you have read and understood all of [[ $ctrl.possessiveName ]] answers?</p>
        </header>
        <footer>
            <div class="action-bar --filled">
                <a class="button --positive --fill" load-button="$ctrl.confirming" ng-click="$ctrl.confirm()" ng-disabled="$ctrl.confirming">
                    <span class="text">Confirm</span>
                </a>
            </div>
            <div class="dialog__confirmation-cancel">
                <a class="button --text" ng-click="$ctrl.cancel()">No, take me back</a>
            </div>
        </footer>
    </div>
</dialog-wrapper>
`;

const ParqDataReviewDialog = {
    template: `
            <a class="button --react --positive">
                <span class="text">Confirm</span>
            </a>
        `,
    bindings: {
        client: '=',
        onSuccess: '=',
    },
    controller: [
        '$scope',
        '$element',
        'DialogService',
        function ($scope, $element, DialogService) {
            let $ctrl = this;
            $ctrl.confirming = false;
            $ctrl.possessiveName = possessive($ctrl.client.firstName);

            $ctrl.cancel = function () {
                DialogService.close();
            };

            $ctrl.open = function () {
                $ctrl.data = angular.copy($ctrl.client);

                DialogService.open($scope, dialogTemplate);
            };

            $ctrl.confirm = function () {
                if ($ctrl.confirming) {
                    return;
                }

                $ctrl.confirming = true;

                ClientTransport.completeDataReview(
                    $ctrl.client.eid,
                    'parq'
                ).then(
                    function () {
                        $ctrl.confirming = false;
                        Notification.success('PAR-Q confirmed.');

                        if (typeof $ctrl.onSuccess === 'function') {
                            $ctrl.onSuccess();
                        }

                        $ctrl.cancel();
                    },
                    function () {
                        $ctrl.confirming = false;
                        Notification.error(messaging.error.generic);
                    }
                );
            };

            $element.click($ctrl.open);
        },
    ],
};
angular
    .module('component.client-parq-data-review-dialog', [])
    .component('clientParqDataReviewDialog', ParqDataReviewDialog);

export default ParqDataReviewDialog;
