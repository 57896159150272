import {
    applySnapshot,
    destroy,
    flow,
    getRoot,
    getSnapshot,
    types,
} from 'mobx-state-tree';
import RootStore from './RootStore';
import SessionPackTransport from '../Services/Transport/SessionPackTransport';
import { SessionPack } from '../Models/SessionPack';

function formatIdentifier(identifier) {
    if (identifier.substr(0, 12) !== 'session-pack') {
        identifier = `session-pack-${identifier}`;
    }

    return identifier;
}

export const SessionPackStore = types
    .model('SessionPackStore', {
        sessionPacks: types.map(SessionPack),
    })

    .volatile((self) => ({
        inFlight: new Set(),
    }))

    .views((self) => {
        const fetchingSessionPack = async (identifier, params = {}) => {
            if (
                self.inFlight.has(identifier) ||
                self.sessionPacks.has(identifier)
            ) {
                return;
            }

            self.inFlight.add(identifier);

            try {
                await self.fetch(identifier, params);
            } catch (error) {
                console.log({ error });
            }

            self.inFlight.delete(identifier);
        };

        return {
            find(identifier) {
                return self.sessionPacks.get(formatIdentifier(identifier));
            },

            findOrFetch(identifier) {
                identifier = formatIdentifier(identifier);
                fetchingSessionPack(identifier);
                return self.sessionPacks.get(identifier);
            },

            get available() {
                return Array.from(self.sessionPacks.values()).filter(
                    (sessionPack) => !sessionPack.isDeleted
                );
            },

            get withUnplanned() {
                return self.available
                    .filter(
                        (sessionPack) =>
                            !sessionPack.isNew && sessionPack.unscheduled > 0
                    )
                    .sort((a, b) => (a.clientLabel > b.clientLabel ? 1 : -1));
            },

            get hasWithUnplanned() {
                return self.withUnplanned.length > 0;
            },

            get new() {
                return self.available.filter(({ isNew }) => isNew);
            },

            getWithinInterval(interval) {
                return self.available.filter((sessionPack) =>
                    interval.contains(sessionPack.purchasedAt)
                );
            },

            /**
             * Does the user have a sessionPack containing unplanned time for the given clients.
             * @param client
             */
            unplannedForClient(client) {
                return self.withUnplanned.filter((sessionPack) => {
                    return (
                        sessionPack.clientIdentifiers.sort().join(',') ===
                        client.eid
                    );
                });
            },
        };
    })

    .actions((self) => ({
        list: flow(function* list(params, withMeta = false) {
            const { data } = yield SessionPackTransport.list(params);
            const items = data.data.map((sessionPack) =>
                self.createOrUpdate(sessionPack)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        fetch: flow(function* fetch(identifier, params = {}) {
            const { data } = yield SessionPackTransport.fetch(
                identifier,
                params
            );
            return self.createOrUpdate(data.data);
        }),

        /**
         * Save the sessionPack
         */
        store: flow(function* store(values) {
            const { data } = yield SessionPackTransport.save({
                ...values,
                clients: values.clients.reduce((acc, client) => {
                    acc[client.eid] = values.costs[client.eid];
                    return acc;
                }, {}),
            });

            return self.createOrUpdate(data.data);
        }),

        createOrUpdate(sessionPackData) {
            const sessionPack = self.processNestedResources(sessionPackData);

            if (self.sessionPacks.has(sessionPack.identifier)) {
                const existing = self.sessionPacks.get(sessionPack.identifier);

                applySnapshot(existing, {
                    ...getSnapshot(existing),
                    ...sessionPack,
                });
            } else {
                self.sessionPacks.set(sessionPack.identifier, sessionPack);
            }

            return self.sessionPacks.get(sessionPack.identifier);
        },

        /**
         * @param sessionPackData¡
         * @returns {*}
         */
        processNestedResources(sessionPackData) {
            if (
                typeof sessionPackData.assignedUsers !== 'undefined' &&
                Array.isArray(sessionPackData.assignedUsers)
            ) {
                sessionPackData.assignedUsers =
                    sessionPackData.assignedUsers.map(
                        getRoot(self).stores.usersStore.processAsNested
                    );
            }

            return sessionPackData;
        },

        /**
         * Add the session pack to the store, and return the identifier
         * @param sessionPack
         * @returns {*}
         */
        processAsNested(sessionPack) {
            if (!!sessionPack?.identifier) {
                self.createOrUpdate(sessionPack);
                return sessionPack.identifier;
            }

            return sessionPack;
        },

        update(sessionPack) {
            sessionPack.assignedUsers =
                sessionPack.assignedUsers.map((u) => u.identifier || u) || [];
            let existing = self.find(sessionPack.identifier);

            if (existing) {
                applySnapshot(existing, sessionPack);
            } else {
                self.sessionPacks.set(sessionPack.identifier, sessionPack);
            }

            return self.find(sessionPack.identifier);
        },

        /**
         * Find and update the given class' delivery, if it exists in the store
         * @param uuid
         * @param delivery
         */
        updateDelivery(identifier, delivery) {
            const sessionPack = self.find(identifier);

            if (sessionPack) {
                sessionPack.updateDelivery(delivery);
            }
        },

        delete(identifier, deleteSessions = false, deleteTransactions = false) {
            let entry = self.find(identifier);

            if (!!entry) {
                if (deleteSessions) {
                    entry.sessions.forEach((session) => {
                        RootStore.stores.entryStore.delete(session.identifier);
                    });
                }
                if (deleteTransactions) {
                    RootStore.stores.transactionStore.deleteForSessionPack(
                        entry.eid
                    );
                }

                destroy(entry);
            }
        },

        deleteNew() {
            self.new.forEach(({ identifier }) => self.delete(identifier, true));
        },

        deleteNewSessions() {
            self.new.forEach((sessionPack) => (sessionPack.sessions = []));
        },
    }));
