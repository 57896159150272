import React from 'react';

const CustomPackages = (props) => (
    <svg viewBox="0 0 20 20" {...props}>
        <g fill={props.fill} fillRule="evenodd">
            <path d="M14 0a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h12zM5 9a2 2 0 100 4 2 2 0 000-4zm6 0a2 2 0 100 4 2 2 0 000-4zM5 3a2 2 0 100 4 2 2 0 000-4zm6 0a2 2 0 100 4 2 2 0 000-4z" />
            <path d="M18 4a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2h12a2 2 0 002-2V4z" />
        </g>
    </svg>
);

export default CustomPackages;
