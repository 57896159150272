import React from 'react';

const UISessionPackSimple = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            fill={props.fill}
            d="M10.4 0A1.6 1.6 0 0112 1.6v8.8a1.6 1.6 0 01-1.6 1.6H1.6A1.6 1.6 0 010 10.4V1.6A1.6 1.6 0 011.6 0h8.8zM3.25 2.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm0 4a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zm6.2-3.3a.55.55 0 110 1.1h-2.9a.55.55 0 110-1.1h2.9zM6.6 7.4h2.8a.6.6 0 010 1.2H6.6a.6.6 0 010-1.2z"
            fillRule="evenodd"
        />
    </svg>
);

export default UISessionPackSimple;
