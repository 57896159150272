import React, { FunctionComponent } from 'react';
import { PrimaryButton } from '@/Components/Button';
import RootStore from '@/Stores/RootStore';

interface Props {
    view: any;
    card: any;
}

export const SaveEntryRescheduleButton: FunctionComponent<Props> = ({
    view,
    card,
}) => {
    const { organisationUser } = RootStore.stores.currentUserStore.organisation;

    if (!card) {
        return null;
    }

    async function handleClick() {
        if (!view.action.hasUpdatedCard) {
            view.action.successNotification();
            view.closeAction();
            return;
        }

        RootStore.stores.uiStateStore.pushModal('SCHEDULE_ENTRY_RESCHEDULE', {
            scheduleId: card.scheduleId,
            calendarEvent: {
                start: card.entry.start_at,
                end: card.entry.end_at,
                type: card.entry.type,
                assignedUser:
                    card.entry.type === 'diary-entry'
                        ? organisationUser.uuid
                        : card.entry.assignedUser,
            },
            view,
        });
    }

    return <PrimaryButton onClick={handleClick}>Review & save</PrimaryButton>;
};
