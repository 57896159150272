import styled, { css } from 'styled-components';
import { fonts, uiColors } from '../../Theme';
import Icon from '../Icon/Icon';

export const StyledContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 64px;
    border-radius: 6px;

    ${(p) =>
        p.type === 'placeholder' &&
        css`
            background: ${uiColors.scheduleEntities.new};
        `}
    ${(p) =>
        p.type === 'session' &&
        css`
            background: ${uiColors.scheduleEntities.session};
        `}
    ${(p) =>
        p.type === 'consultation' &&
        css`
            background: ${uiColors.scheduleEntities.consultation};
        `}
    ${(p) =>
        p.type === 'diary' &&
        css`
            background: ${uiColors.scheduleEntities.diary};
        `}
    ${(p) =>
        p.type === 'class-date' &&
        css`
            background: ${uiColors.scheduleEntities.class};
        `}
    
    ${(p) =>
        !!p.link &&
        css`
            &:hover {
                cursor: pointer;
                opacity: 0.9;
            }
        `}
`;

export const StyledAvatarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: ${uiColors.scheduleEntities.consultationDark};
`;

export const StyledAvatarIcon = styled(Icon).attrs({
    width: 32,
    height: 32,
    fill: uiColors.scheduleEntities.consultation,
})``;

export const StyledContentContainer = styled.div`
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
    margin-left: 12px;

    ${(p) =>
        p.withAttendance &&
        css`
            margin-left: 18px;
        `}
`;

export const StyledSubtitle = styled.p`
    margin: 0;
    font-size: ${fonts.size(13)};
    color: rgba(255, 255, 255, 0.7);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
