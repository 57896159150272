import React, { cloneElement, ReactNode, useState } from 'react';
import {
    autoUpdate,
    flip,
    FloatingPortal,
    offset,
    Placement,
    shift,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
} from '@floating-ui/react-dom-interactions';
import { Transition } from '@headlessui/react';

interface Props {
    label: string;
    placement?: Placement;
    children: ReactNode;
}

export const Tooltip = ({ children, label, placement = 'top' }: Props) => {
    const [open, setOpen] = useState(false);

    const { x, y, reference, floating, strategy, context } = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        middleware: [offset(5), flip(), shift({ padding: 8 })],
        whileElementsMounted: autoUpdate,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useHover(context),
        useFocus(context),
        useRole(context, { role: 'tooltip' }),
        useDismiss(context),
    ]);

    return (
        <>
            {cloneElement(
                children,
                getReferenceProps({ ref: reference, ...children.props })
            )}
            <FloatingPortal>
                <Transition
                    as="div"
                    show={open}
                    className="fixed inset-0 z-40 flex pr-[64px]"
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    {...getFloatingProps({
                        ref: floating,
                        className:
                            'bg-white px-3 py-2 rounded shadow transition z-[11000]',
                        style: {
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                        },
                    })}
                >
                    {label}
                </Transition>
            </FloatingPortal>
        </>
    );
};
