import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

interface Props {
    content?: string | ReactNode;
    onClick?(): void;
    className?: string;
    style?: CSSProperties;
}

const SmallCaps: FunctionComponent<Props> = ({
    content,
    className,
    children,
    ...props
}) => {
    return (
        <p
            className={classNames('text-xs uppercase text-gray-500', className)}
            {...props}
        >
            {content ?? children}
        </p>
    );
};

export default observer(SmallCaps);
