// import Action from './Action';
import angular from 'angular';
import CreateAction from './CreateAction';
import Notification from '../../../js/Services/Notification';

export default class UpdateAction extends CreateAction {
    static get action() {
        return 'place';
    }

    static get identifierName() {
        return 'eid';
    }

    static get parentIdentifierName() {
        return '';
    }

    static canCreateFromParameters(parameters) {
        return (
            angular.isDefined(parameters.action) &&
            angular.isDefined(parameters.type) &&
            angular.isDefined(parameters[this.parentIdentifierName]) &&
            parameters.action === this.action &&
            parameters.type === this.type.nameSingular
        );
    }

    constructor(calendar, parameters = {}) {
        super(calendar, parameters);

        this.identifier = parameters[this.constructor.identifierName];
        this.parentIdentifier =
            parameters[this.constructor.parentIdentifierName];

        this.parameters[this.constructor.parentIdentifierName] =
            this.parentIdentifier;

        // if (!!parameters[this.constructor.identifierName]) {
        //     this.initialiseCards(parameters[this.constructor.identifierName]);
        // }
    }

    get isCreating() {
        return false;
    }

    get isUpdating() {
        return true;
    }

    get hasUnsavedChanges() {
        return !!this.calendar.newCards.length;
    }

    get isReady() {
        return this.hasUnsavedChanges;
    }

    get hidesExisting() {
        return this.newCards.length < this.cards.length;
    }

    cancel() {
        return new Promise((resolve) => {
            this.calendar.blurCards();
            this.calendar.removeItems((item) => !item.isNew);
            resolve();
        });
    }

    finalise() {
        return new Promise((resolve) => {
            this.calendar.blurCards();
            resolve();
        });
    }
}

export class ResultUpdated {}
export class ResultUpdatedError {
    constructor(errors) {
        this._errors = errors;
        Notification.error();
    }

    get errors() {
        return this._errors;
    }
}
