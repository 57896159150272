import React, { FunctionComponent } from 'react';

import Group from './Group';
import Item from './Item';
import Heading from './Heading';
import classNames from 'classnames';

interface Props {
    className?: string;
}

const ActionBar: FunctionComponent<Props> = ({ className, children }) => (
    <section className={classNames('ui-action-bar', className)}>
        {children}
    </section>
);

export default Object.assign(ActionBar, {
    Group,
    Item,
    Heading,
});
