import { destroy, flow, types } from 'mobx-state-tree';
import { Interval } from 'luxon';
import MetricTransport from '../Services/Transport/MetricTransport';
import { Metric } from '../Models/Metric';
import { uiColors } from '../Theme';

export const metricData = {
    'new-session-pack-value': {
        color: '#8884d8',
        label: 'Sales',
        isCurrency: true,
    },
    'money-taken': {
        color: '#82ca9d',
        label: 'Payments',
        isCurrency: true,
    },
    expenses: {
        color: '#e77f67',
        label: 'Expenses',
        isCurrency: true,
    },
    'new-session-pack-count': {
        color: '#e3f3fc',
        label: 'Session packs',
    },
    'classes-value': {
        color: '#ff0000',
        label: 'Classes',
        isCurrency: true,
    },
    'membership-value': {
        color: '#00ff00',
        label: 'Memberships',
        isCurrency: true,
    },
    'employed-value': {
        color: uiColors.scheduleEntities.class,
        label: 'Employed',
        isCurrency: true,
    },
};

export const MetricStore = types
    .model('MetricStore', {
        metrics: types.array(Metric),
    })

    .views((self) => ({
        exists(identifier) {
            return !!self.metrics.find(
                (metric) => metric.identifier === identifier
            );
        },
        get(identifier) {
            return self.metrics.find(
                (metric) => metric.identifier === identifier
            );
        },
        lines(lines, periodStart, periodEnd, delimiter = 'week') {
            let values = [];
            let stepInterval = {};
            stepInterval[delimiter] = 1;

            const dates = Interval.fromDateTimes(
                periodStart,
                periodEnd
            ).splitBy(stepInterval);

            lines = lines
                .filter((line) => self.exists(line))
                .map((line) => this.get(line));

            const dateDelimiterFormat =
                delimiter === 'week' ? 'kkkk-WW' : 'yyyy-MM';
            const dateLabelFormat = delimiter === 'week' ? "'W'W" : 'MMM yyyy';

            dates.forEach(({ s }) => {
                const dataDelimiter = s.toFormat(dateDelimiterFormat);

                let datePoint = {
                    name: s.toFormat(dateLabelFormat),
                };

                lines.forEach(({ identifier, line }) => {
                    let currentPoint = line.find(
                        (point) => point.delimiter === dataDelimiter
                    );
                    if (currentPoint) {
                        datePoint[identifier] = currentPoint.value;
                    }
                });

                values.push(datePoint);
            });

            return values;
        },

        getMetricData(identifier, type) {
            return {
                type,
                identifier,
                ...metricData[identifier],
            };
        },
    }))

    .actions((self) => ({
        updateOrCreate(metric) {
            if (self.exists(metric.identifier)) {
                let existing = self.get(metric.identifier);
                destroy(existing);
            }

            self.metrics.push(metric);
        },

        fetchValue: flow(function* fetchValue(params) {
            try {
                const { data } = yield MetricTransport.getValue(params);
                data.forEach((metric) => self.updateOrCreate(metric));
                // applySnapshot(self.metrics, data);
            } catch (e) {
                console.error('error: ', e);
            }
        }),

        isCurrency(metric) {
            return (
                typeof metricData[metric] !== 'undefined' &&
                metricData[metric].isCurrency
            );
        },
    }));
