import React, { forwardRef, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
    type?: 'text' | 'email' | 'tel' | 'password' | 'number';
    placeholder?: string;
    readOnly?: boolean;
    value?: string;
    className?: string;
    isDisabled?: boolean;
}

const TextInputField = forwardRef<HTMLInputElement, TextInputProps>(
    ({ className, isDisabled, ...props }, ref) => (
        <input
            className={classNames('input', className)}
            disabled={isDisabled || props.disabled}
            {...props}
            autoCorrect="off"
            ref={ref}
        />
    )
);

export default TextInputField;
