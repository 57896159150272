export const genderOptions = [
    {
        value: 'male',
        label: 'Male',
    },
    {
        value: 'female',
        label: 'Female',
    },
    {
        value: 'other',
        label: 'Other',
    },
    {
        value: 'undisclosed',
        label: 'Prefer not to say',
    },
];

export const paymentTypeOptions = [
    {
        value: 'payment',
        label: 'Payment',
    },
    {
        value: 'refund',
        label: 'Refund',
    },
];

export const paymentMethodOptions = [
    'Cash',
    'Card',
    'Bank transfer',
    'Cheque',
    'Direct Debit',
];

export const attendanceWindowPeriodOptions = [
    {
        value: 'day',
        label: 'Day',
    },
    {
        value: 'week',
        label: 'Week',
    },
    {
        value: 'month',
        label: 'Month',
    },
];

export const attendanceWindowAnchorOptions = [
    {
        value: 'purchase',
        label: 'of purchase',
    },
    {
        value: 'first-enrolment',
        label: 'of first use',
    },
];

export const monthOptions = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
];

const minutesVariants = Array(4)
    .fill(0)
    .map((item, index) => String(index * 15).padStart(2, '0'));

export const timeOptions = Array(24)
    .fill(0)
    .map((item, index) => String(index).padStart(2, '0'))
    .map((hours) => minutesVariants.map((minutes) => `${hours}:${minutes}`))
    .flat()
    .map((time) => ({
        label: time,
        value: time,
    }));
