import angular from 'angular';
import { react2angular } from 'react2angular';
import Icon from '../../../js/Components/Icon/Icon';

angular
    .module('component.react.icon', [])
    .component(
        'reactIcon',
        react2angular(Icon, ['name', 'width', 'height', 'fill'])
    );
