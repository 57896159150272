import { isValidReference, types } from 'mobx-state-tree';
import { Delivery } from './Delivery';

export default types
    .model({
        delivery: types.maybeNull(Delivery),
    })

    .views((self) => ({
        /**
         * Determine if the class has a valid location
         * @returns {boolean}
         */
        get hasLocation() {
            return (
                !!self.delivery &&
                isValidReference(() => self.delivery.location)
            );
        },

        /**
         * Get the name of the location
         * @returns {*}
         */
        get locationName() {
            return self.hasLocation
                ? self.delivery.location.name
                : 'None selected';
        },

        /**
         * Get the preposition for the location
         * @returns {*}
         */
        get locationPreposition() {
            return self.delivery.location.preposition;
        },

        get prefixedLocation() {
            if (!self.hasLocation) {
                return '';
            }

            return `${self.locationPreposition} ${self.delivery.location.name}`;
        },

        get deliverySaveData() {
            if (self.hasLocation) {
                return {
                    location: self.delivery.location.uuid,
                    url: self.delivery?.url,
                    password: self.delivery?.password,
                };
            }

            return null;
        },

        /**
         * Get the delivery values for use in formik mapPropsToValues
         * @returns {{locationUrl: *, locationPassword: *, location: *}}
         */
        get deliveryFormValues() {
            return {
                location: self.delivery?.location?.uuid ?? '',
                locationUrl: self.delivery?.url ?? '',
                locationPassword: self.delivery?.password ?? '',
            };
        },
    }))

    .actions((self) => ({
        updateDelivery(delivery) {
            self.delivery = delivery?.location
                ? Delivery.create(delivery)
                : undefined;
        },
    }));
