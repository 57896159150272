import ReactModal from 'react-modal';
import styled, { css } from 'styled-components';
import { uiColors } from '../../Theme';
import { minWidth } from '../../Theme/breakpoints';

export const StyledOverlay = styled(ReactModal).attrs(({ style, ...props }) => {
    const overlay = {
        zIndex: 10000,
        opacity: 1,
        top: 0,
        ...(props.isHidden ? { display: 'none' } : {}),
    };

    if (!props.displayOverlay) {
        overlay.background = 'none';
    }

    return {
        style: {
            overlay,
            ...style,
        },
    };
})`
    // .content
    outline: none;
    margin-top: 0;
    transition: margin-top 200ms, transform 200ms;

    ${(p) => css`
        //transform: scale(${1 + p.stackPosition / 50});
        margin-top: ${(p.stackPosition || 0) * 10}px;
    `}
`;

export const StyledContentSection = styled.section`
    margin-bottom: 40px;
`;

export const StyledNotice = styled.section`
    display: flex;
    justify-content: center;
    margin: -16px -16px 16px;
    padding: 16px;
    background: #fff;
    border-bottom: 1px solid ${uiColors.grey.light};

    @media (${minWidth('tabletSmall')}) {
        margin: -20px -20px 20px;
        padding: 16px 20px;
    }
`;
