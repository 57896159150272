import React, { ComponentType } from 'react';
import EventConfirmationModalContextProvider from '@/Providers/EventConfirmationModalContextProvider';

const withConfirmationModalContext = (Component: ComponentType<any>) => {
    return function WrapperComponent(props: any) {
        return (
            <EventConfirmationModalContextProvider>
                <Component {...props} />
            </EventConfirmationModalContextProvider>
        );
    };
};

export default withConfirmationModalContext;
