import React, { FunctionComponent, ReactNode } from 'react';
import get from 'lodash-es/get';
import { Observer, observer } from 'mobx-react';
import EntryCard from '../EntryCard/EntryCard';
import EntryRemindButton from '../Entry/RemindButton';
import SessionAttendanceButtons from '../SessionAttendanceButtons';
import { formatDate, formatTime } from '../../Services/Date';
import { Session } from '@/Models/Session';
import { SessionOptionsMenu } from '@/Features/Sessions';
import NoticeTag from '@/Components/NoticeTag';

interface Props {
    session: Session;
    titleType?: 'label' | 'datetime';
    subtitle?: string;
    subtitleTypes?: ('date' | 'time' | 'location' | 'assignedUsers')[];
    accessory?(): ReactNode;
    withAvatar?: boolean;
    withLink?: boolean;
    forceDisplayAttendance?: boolean;
    withReminderAccessory?: boolean;
    withOptionsAccessory?: boolean;
    withAttendanceAccessory?: boolean;
    displayAttendance?: boolean;
    onRemoved?(): void;
    onUpdated?(): void;
    onViewClosed?(): void;
    className?: string;
}

const SessionEntryCard: FunctionComponent<Props> = ({
    withAvatar,
    withLink,
    titleType = 'label',
    subtitle,
    subtitleTypes,
    session,
    accessory,
    forceDisplayAttendance = false,
    ...props
}) => {
    const getAvatar = () => {
        if (!withAvatar) return null;

        // @deprecated
        let url = get(session, 'clients[0].image.urls.small', null);

        if (url) return url;
        return get(session, 'clients.data[0].image.data.urls.small', null);
    };

    const getAccessory = () => {
        if (forceDisplayAttendance && session.attendance === 'forfeited') {
            return () => <NoticeTag type="error" text="Forfeited" />;
        } else if (typeof accessory === 'function') {
            return accessory();
        } else if (props.withReminderAccessory === true) {
            return () => (
                <Observer>
                    {() => <EntryRemindButton entry={session} isResponsive />}
                </Observer>
            );
        } else if (props.withOptionsAccessory === true) {
            return () => <SessionOptionsMenu session={session} />;
        } else if (props.withAttendanceAccessory === true) {
            return () => <SessionAttendanceButtons session={session} />;
        }

        return null;
    };

    return (
        <EntryCard
            entry={session}
            withLocation
            withDuration
            withAvatar={withAvatar}
            avatar={getAvatar()}
            type="session"
            title={
                <>
                    {titleType === 'label' && <span>{session.label}</span>}
                    {titleType === 'datetime' &&
                        `${formatDate(session.start, true, true)} (${formatTime(
                            session.start
                        )})`}
                </>
            }
            subtitle={subtitle}
            subtitleTypes={subtitleTypes}
            withLink={withLink}
            accessory={getAccessory()}
            attendanceStatus={session.attendance}
            handleUpdate={props.onUpdated}
            handleViewClosed={props.onViewClosed}
        />
    );
};

export default observer(SessionEntryCard);
