import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FormikProps, withFormik } from 'formik';
import { StackableModalProps } from '@/Components/RootModal';
import Notification from '@/Services/Notification';
import { PrimaryButton, TextButton } from '@/Components/Button';
import Modal from '@/Components/Modal';
import RootStore from '@/Stores/RootStore';
import FormField from '@/Components/FormField';
import FormTextField from '@/Domain/Form/FormTextField';
import { genderOptions } from '@/Config/options';
import FormSelectField from '@/Components/FormSelectField';
import FormDateFieldGroup from '@/Components/FormDateFieldGroup';
import FormDateField from '@/Components/FormDateField';
import FormAgreementSelectField from '@/Components/FormAgreementSelectField';
import FormCheckboxField from '@/Components/FormCheckboxField';
import { ClientFormValues } from '@/Features/Clients';
import FormCountrySelectField from '@/Components/FormCountrySelectField';
import queryClient from '@/Modules/QueryClient';
import { AxiosError } from 'axios';
import Notice from '@/Components/Notice';
import handleServerValidation from '@/Utilities/handleServerValidation';
import { queryKeys } from '@/Services/QueryKeys';
import Avatar from '@/Components/Avatar';
import ImageUploadZone, { UploadedFile } from '@/Components/ImageUploadZone';
import { Client } from '@/Models/Client';
import FormTimezoneSelectField from '@/Components/FormTimezoneSelectField';
import classNames from 'classnames';
import FormTextAreaField from '@/Components/FormTextAreaField';

interface Props extends StackableModalProps {
    isCreating: boolean;
    client?: Client;
    mapPropsToValues(): ClientFormValues;
    onSave(values: ClientFormValues): void;
    closeStackOnCreate?: boolean;
}

const Component: FunctionComponent<Props & FormikProps<ClientFormValues>> = (
    props
) => {
    const { uiStateStore, agreementStore, currentUserStore } = RootStore.stores;
    const { values, isCreating, handleSubmit, isSubmitting, setFieldValue } =
        props;
    const [previewUrl, setPreviewUrl] = useState<string | undefined>(
        props.client?.image?.urls.original
    );

    const handleUploaded = (file: UploadedFile, preview: string) => {
        Notification.success('Profile image uploaded. Please save to confirm.');

        setPreviewUrl(preview);
        props.setFieldValue('image', file);
    };

    useEffect(() => {
        if (!values.hasPreviousSignedAgreement) {
            setFieldValue('agreement', '');
        }
    }, [values.hasPreviousSignedAgreement]);

    return (
        <Modal
            stackProps={props.stackProps}
            title={isCreating ? 'Add client' : 'Update client details'}
            onSubmit={handleSubmit}
            leftAction={() => (
                <TextButton
                    type="button"
                    onClick={() => uiStateStore.popModal()}
                >
                    Cancel
                </TextButton>
            )}
            rightAction={() => (
                <PrimaryButton
                    type="submit"
                    isLoading={isSubmitting}
                    data-testid="save-client-button"
                >
                    Save
                </PrimaryButton>
            )}
            data-testid="create-update-client-modal"
        >
            <fieldset className="dialog__panel">
                <h2 className="ui-action-bar__title mb-2">Profile picture</h2>

                <div className="flex items-center gap-4">
                    {!!previewUrl && (
                        <Avatar
                            src={previewUrl}
                            alt={values.firstName}
                            className="h-[90px] w-[90px] min-w-[90px] object-cover"
                        />
                    )}
                    <ImageUploadZone
                        onUploaded={handleUploaded}
                        onPreview={setPreviewUrl}
                    />
                </div>
            </fieldset>

            <fieldset className="dialog__panel">
                <h2 className="ui-action-bar__title mb-2">Basic details</h2>

                <FormField.Row>
                    <FormTextField
                        name="firstName"
                        label="First name"
                        isRequired
                    />
                    <FormTextField
                        name="lastName"
                        label="Last name"
                        isRequired
                    />
                </FormField.Row>

                <FormField.Row>
                    <FormDateFieldGroup name="dob" label="Date of birth" />
                    <FormSelectField
                        name="gender"
                        label="Gender"
                        options={genderOptions}
                        fieldProps={{ isClearable: true }}
                    />
                </FormField.Row>

                <FormField.Row>
                    <FormTextField
                        name="email"
                        label="Email"
                        info="For sending schedules, receipts, etc."
                        isRequired
                    />
                    <FormTextField
                        name="mobilePhone"
                        label="Mobile phone"
                        info="For sending reminders, cancellations, etc."
                    />
                </FormField.Row>

                <FormField.Row className="!mb-0">
                    <FormDateFieldGroup
                        name="signedUpAt"
                        label="Client since"
                        className="!mb-0"
                    />
                    <FormField />
                </FormField.Row>
            </fieldset>

            {isCreating && (
                <>
                    <fieldset className="dialog__panel">
                        <h2 className="ui-action-bar__title mb-2">Agreement</h2>

                        <FormField
                            className={classNames({
                                '!mb-0': !values.hasPreviousSignedAgreement,
                            })}
                        >
                            <FormCheckboxField
                                name="hasPreviousSignedAgreement"
                                label="This client has previously signed an agreement"
                                isDisabled={!agreementStore.hasAvailable}
                            />

                            {!agreementStore.hasAvailable && (
                                <Notice type="warning" className="mt-2">
                                    You have not created any agreement templates
                                    yet. Save this client and go to the
                                    Agreements section to create an agreement.
                                </Notice>
                            )}
                        </FormField>

                        {values.hasPreviousSignedAgreement && (
                            <FormField.Row className="!mb-0">
                                <FormAgreementSelectField
                                    name="agreement"
                                    label="Signed agreement"
                                />
                                <FormDateField
                                    name="agreementAcceptedAt"
                                    label="'Signed on' date"
                                />
                            </FormField.Row>
                        )}
                    </fieldset>

                    {currentUserStore.organisation.portalEnabled &&
                        currentUserStore.organisation.portalLoginEnabled && (
                            <fieldset className="dialog__panel">
                                <h2 className="ui-action-bar__title mb-2">
                                    Client area
                                </h2>

                                <FormField>
                                    <FormCheckboxField
                                        name="sendClientAreaInvite"
                                        label="Send invite to client area and app"
                                    />

                                    <FormField.Children
                                        isHidden={!values.sendClientAreaInvite}
                                    >
                                        <FormTextAreaField
                                            name="clientAreaInviteMessage"
                                            label="Optionally include a message"
                                            isDisabled={
                                                !values.sendClientAreaInvite
                                            }
                                        />
                                    </FormField.Children>
                                </FormField>
                            </fieldset>
                        )}
                </>
            )}

            {!isCreating && (
                <fieldset className="dialog__panel">
                    <h2 className="ui-action-bar__title mb-2">Address</h2>

                    <FormField.Row>
                        <FormTextField name="address1" label="Address 1" />
                        <FormTextField name="address2" label="Address 2" />
                    </FormField.Row>
                    <FormField.Row>
                        <FormTextField name="city" label="City" />
                        <FormTextField name="postCode" label="Postcode / zip" />
                    </FormField.Row>
                    <FormField.Row>
                        <FormCountrySelectField
                            name="country"
                            label="Country"
                        />
                        <FormTimezoneSelectField
                            name="timezone"
                            label="Timezone"
                        />
                    </FormField.Row>
                </fieldset>
            )}
        </Modal>
    );
};

export const CreateUpdateClientModal = withFormik<Props, ClientFormValues>({
    mapPropsToValues: (props) => props.mapPropsToValues(),
    handleSubmit: async (values, { props, setErrors }) => {
        const { uiStateStore } = RootStore.stores;

        try {
            await props.onSave(values);
            await queryClient.invalidateQueries(queryKeys.clients.all);

            Notification.success(
                `Client ${props.isCreating ? 'added' : 'updated'}`
            );

            if (props.closeStackOnCreate) {
                uiStateStore.closeStack();
            } else {
                uiStateStore.popModal();
            }
        } catch (e) {
            console.log(e);
            handleServerValidation(e as AxiosError, setErrors);
        }
    },
})(observer(Component));
