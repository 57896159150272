import { types } from 'mobx-state-tree';
import { DateTimeType } from '@/Types/DateTime';

export const ClientAgreement = types.model('ClientAgreement', {
    name: types.string,
    content: types.string,
    clientName: types.string,
    organisationName: types.string,
    acceptedAt: DateTimeType,
    isArchived: types.boolean,
    isExpired: types.boolean,
    signature: types.maybeNull(types.string),
});
