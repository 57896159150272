import { applySnapshot, flow, getSnapshot, types } from 'mobx-state-tree';
import { DateTimeType } from '../Types/DateTime';
import WithLegacyDates from './WithLegacyDates';
import { LocationReference } from './Location';
import ScheduleEntry from './ScheduleEntry';
import { communicationWarning as communicationWarningString } from '../Services/String';
import WithLocation from './WithLocation';
import { toLuxon } from '../Services/Date';
import ConsultationTransport from '../Services/Transport/ConsultationTransport';
import { deliveryFromFormValues } from '../Services/Delivery';

const ConsultationModel = types
    .model('Consultation', {
        eid: types.string,
        type: types.literal('consultation'),
        identifier: types.identifier,
        resourceVersion: 2,
        start: DateTimeType,
        end: DateTimeType,
        length: types.integer,
        label: types.string,
        firstName: types.string,
        lastName: types.string,
        email: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        notes: types.maybeNull(types.string),
        reminderSent: false,
        remindersEnabled: types.boolean,
        isCancelled: false,
        location: types.maybeNull(LocationReference),
        dismissedAt: types.maybeNull(DateTimeType),
        cancelledAt: types.maybeNull(DateTimeType),
    })

    .preProcessSnapshot((snapshot) => {
        if (snapshot.resourceVersion === 1) {
            snapshot = {
                ...snapshot,
                delivery: snapshot.delivery?.location
                    ? snapshot.delivery
                    : null,
                start: toLuxon(snapshot.start_at),
                end: toLuxon(snapshot.end_at),
            };
        }

        return {
            ...snapshot,
            location: !!snapshot.location ? snapshot.location.uuid : null,
        };
    })

    .views((self) => ({
        get humanEntityType() {
            return 'Consultation';
        },

        /**
         * Legacy column name
         * @returns {*}
         */
        get first_name() {
            return self.firstName;
        },

        /**
         * Legacy column name
         * @returns {*}
         */
        get last_name() {
            return self.lastName;
        },

        /**
         * Legacy column name
         * @returns {*}
         */
        get fullName() {
            return `${self.firstName} ${self.lastName}`;
        },

        get notificationIdentifier() {
            return self.eid;
        },

        get hasEmail() {
            return !!self.email;
        },

        get hasPhone() {
            return !!self.phone;
        },

        get hasSmsPhone() {
            return !!self.phone;
        },

        get smsPhone() {
            return self.phone;
        },

        /**
         * Get a warning string about missing communication methods
         * @param email
         * @param phone
         * @returns {string}
         */
        communicationWarning(email = true, phone = true) {
            const emailMissing = email && !self.hasEmail;
            const phoneMissing = phone && !self.hasSmsPhone;

            return communicationWarningString(emailMissing, phoneMissing);
        },
    }))

    .actions((self) => ({
        update: flow(function* update(input = {}) {
            self.busy = true;

            const { data } = yield ConsultationTransport.update(self.eid, {
                ...getSnapshot(self),
                ...input,
            });

            self.updateData({
                ...data.data,
                busy: false,
            });

            return self;
        }),

        updateNotes: flow(function* updateNotes(notes = null) {
            return yield self.update({ notes });
        }),

        cancel: flow(function* cancel(params = {}) {
            yield ConsultationTransport.cancel(self.eid, params);
        }),

        updateData(data = {}) {
            applySnapshot(self, {
                ...getSnapshot(self),
                ...data,
            });
        },

        setFormData(values) {
            self.updateData(values);
            self.updateDelivery(deliveryFromFormValues(values));
        },

        setReminderSent() {
            self.reminderSent = true;
        },
    }));

export const Consultation = types.compose(
    ConsultationModel,
    ScheduleEntry,
    WithLegacyDates,
    WithLocation
);
