import BaseTransport from './BaseTransport';

class SessionPackTransport extends BaseTransport {
    async list(params) {
        return await this.get('/v3/session-packs', params);
    }

    async fetch(identifier, params = {}) {
        return await this.get(`/v3/session-packs/${identifier}`, params);
    }

    async fetchTransactionData(eid) {
        return await this.get(`/v3/session-packs/${eid}/transaction-data`);
    }

    async save(data) {
        return await this.post('/v3/session-packs', data);
    }

    async update(eid, data = {}) {
        return await this.put(`/v3/session-packs/${eid}`, data);
    }

    async remove(eid, data = {}) {
        return await this.post(`/v3/session-packs/${eid}/delete`, data);
    }

    async addSessions(eid, entries, teamNotifications, clientNotifications) {
        return await this.post(`/v3/session-packs/${eid}/add-sessions`, {
            entries,
            teamNotifications,
            clientNotifications,
        });
    }

    async dismissOverpaymentAlert(eid, client) {
        return await this.post(`/v3/session-packs/${eid}/dismiss-overpayment`, {
            client,
        });
    }

    async end(eid, costs) {
        return await this.post(`/v3/session-packs/${eid}/end`, { costs });
    }

    async sendSchedule(eid, clients) {
        return await this.post(`/v3/session-packs/${eid}/schedule`, {
            clients,
        });
    }

    async sendStatement(eid, clients) {
        return await this.post(`/v3/session-packs/${eid}/statement`, {
            clients,
        });
    }

    async getCountsForSessionPack(eid) {
        return await this.get(`/v3/session-packs/${eid}/counts`);
    }
}

export default new SessionPackTransport();
