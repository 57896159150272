import React, { FunctionComponent, useContext } from 'react';
import { Button } from '@/Components/Button';
import { observer } from 'mobx-react';
import { Consultation } from '@/Models/Consultation';
import EventConfirmationModalContext from '@/Contexts/EventConfirmationModalContext';
import RootStore from '@/Stores/RootStore';
import Notification from '@/Services/Notification';
import { uiColors } from '@/Theme';

interface Props {
    consultation: Consultation;
    type: 'cancelled' | 'dismiss';
    onDismiss?(): void;
}

const Component: FunctionComponent<Props> = ({
    consultation,
    type,
    onDismiss,
}) => {
    const confirmationContext = useContext(EventConfirmationModalContext);

    async function dismissConsultation() {
        try {
            // if (type === 'delete') {
            //     await RootStore.stores.entryStore.deleteConsultation(
            //         consultation.eid
            //     );
            // } else {
            await RootStore.stores.entryStore.dismissConsultation(
                consultation.eid
            );
            // }

            if (typeof onDismiss === 'function') {
                onDismiss();
            }
        } catch (e) {
            Notification.error(
                'There was a problem trying to dismiss your consultation. Please try again.'
            );

            return;
        }

        Notification.success('Consultation dismissed');
    }

    const handleDismiss = () => {
        confirmationContext.requireConfirmation({
            content: () => (
                <p>
                    Are you sure you want to remove this consultation from the
                    list?
                </p>
            ),
            onConfirmed: dismissConsultation,
            isPositive: false,
        });
    };

    return (
        <Button
            icon="Delete"
            fill={uiColors.negative.default}
            onClick={handleDismiss}
        />
    );
};

export const DismissConsultationButton = observer(Component);
