import angular from 'angular';
import { react2angular } from 'react2angular';
import { ScheduleActionPanel } from '@/Features/Schedule';

angular
    .module('component.react.schedule-action-panel', [])
    .component(
        'reactScheduleActionPanel',
        react2angular(ScheduleActionPanel, [
            'view',
            'action',
            'calendar',
            'dateCount',
            'newDateCount',
            'totalPeriods',
            'hasUpdatedCard',
        ])
    );
