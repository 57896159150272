import { createContext } from 'react';

interface ContextState {
    show(): void;
    hide(): void;
}

const GistBubbleContext = createContext<ContextState | undefined>(undefined);

export default GistBubbleContext;
