import React from 'react';

const CustomMemberships = (props) => (
    <svg viewBox="0 0 20 20" {...props}>
        <path
            fill={props.fill}
            d="M18.542.09a.5.5 0 01.357.85l-1.82 1.853.244.256c3.649 3.983 3.568 10.279-.243 14.158-1.416 1.442-3.098 2.252-4.868 2.793v-2.703c1.062-.36 2.213-.99 3.098-1.892 2.92-2.973 2.92-7.837 0-10.81l-1.798 1.83a.5.5 0 01-.857-.35V1.09a1 1 0 011-1zM7.788 0v2.703c-1.062.36-2.213.99-3.098 1.892-2.92 2.973-2.92 7.837 0 10.81l1.798-1.83a.5.5 0 01.857.35v4.985a1 1 0 01-1 1H1.458a.5.5 0 01-.357-.85l1.82-1.853c-3.894-3.964-3.894-10.45 0-14.414C4.335 1.35 6.017.54 7.787 0z"
        />
    </svg>
);

export default CustomMemberships;
