import React, { FunctionComponent } from 'react';
import { Button } from '../../Button';
import RootStore from '../../../Stores/RootStore';
import { ClassDate } from '../../../Models/ClassDate';
import useAuth from '@/Hooks/useAuth';

interface Props {
    classDate: ClassDate;
}

const ClassDateEnrolButton: FunctionComponent<Props> = ({ classDate }) => {
    const { hasRole } = useAuth();

    if (!classDate.isPrivate || !hasRole('Owner')) {
        return null;
    }

    const handleClick = () => {
        RootStore.stores.uiStateStore.pushModal('CLASS_DATE_ENROLMENT_CREATE', {
            classDate,
        });
    };

    return <Button icon="AccountPlus" onClick={handleClick} />;
};

export default ClassDateEnrolButton;
