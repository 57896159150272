import RescheduleAction from './RescheduleAction.js';
import ClassContainer from './ClassContainer';
import { ResultRescheduled, ResultRescheduledError } from './RescheduleAction';
import ClassDateTransport from '../../../js/Services/Transport/ClassDateTransport';

class RescheduleClass extends RescheduleAction {
    static get type() {
        return new ClassContainer();
    }

    static get identifierName() {
        return 'uuid';
    }

    static get parentIdentifierName() {
        return 'class';
    }

    get calendarTitle() {
        return 'Reschedule class';
    }

    get parent() {
        return this.classContainer;
    }

    processCardUsing(card) {
        card.isGhost =
            !card.isNew && card.entry.parent !== this.parentIdentifier;

        return card;
    }

    save(
        events,
        teamNotifications,
        classNotifications,
        makeUnassignedAvailable
    ) {
        return new Promise((resolve) => {
            ClassDateTransport.reschedule(this.card.uuid, {
                event: events[0],
                teamNotifications,
                classNotifications,
                makeUnassignedAvailable,
            })
                .then(({ data }) => {
                    this.calendar.populateCards(data.classDates);

                    resolve(new ResultRescheduled(this.type));
                })
                .catch((error) => {
                    resolve(
                        new ResultRescheduledError(
                            error?.response?.data?.errors
                        )
                    );
                });
        });
    }
}

export default RescheduleClass;
