import { Card, Mark } from '../Items';
import { Cache } from '../Cache';
import { DateTime } from 'luxon';

export class View {
    static createFromView(view) {
        return new View(view.calendar, view.date);
    }

    constructor(calendar, date) {
        this.cache = new Cache();
        this.calendar = calendar;
        this.date = date;
        this.type = 'empty';
    }

    get periodTitle() {
        return 'Empty';
    }

    get periodInformation() {
        return this.cache.add('Views.View.periodEntities', () => {
            return this.cards.filter((card) => {
                return (
                    card.is(['session', 'class-date']) &&
                    this.includesItem(card)
                );
            });
        });
    }

    get nameSingular() {
        return this.constructor.nameSingular;
    }

    get namePlural() {
        return this.constructor.namePlural;
    }

    get active() {
        return this;
    }

    get previous() {
        return this;
    }

    get next() {
        return this;
    }

    get days() {
        return this.cache.add('Views.View.days', () => {
            return this.calendar.getDaysInRange(this.startDate, this.endDate);
        });
    }

    get weeks() {
        return this.cache.add('Views.View.weeks', () => {
            return this.calendar.getWeeksInRange(this.startDate, this.endDate);
        });
    }

    get data() {
        return this.cache.add('Views.View.items', () => {
            return this.calendar.getItemsInRange(this.startDate, this.endDate);
        });
    }

    get cards() {
        return this.cache.add('Views.View.cards', () => {
            return this.data.filter((item) => {
                return item instanceof Card;
            });
        });
    }

    get marks() {
        return this.cache.add('Views.View.marks', () => {
            return this.data.filter((item) => {
                return item instanceof Mark;
            });
        });
    }

    get startDate() {
        return this.date;
    }

    get endDate() {
        return this.date;
    }

    get isActive() {
        let date = DateTime.local();

        return date >= this.startDate && date <= this.endDate;
    }

    get urlParameters() {
        return {};
    }

    is(type) {
        if (type instanceof Function) {
            return this instanceof type;
        }

        return this.type == type;
    }

    show() {
        this.calendar.view = this;
    }

    populate(action, force = false, populateAdjacent = false) {
        return this.calendar.populate(
            action,
            this.startDate,
            this.endDate,
            force,
            populateAdjacent
        );
    }

    clearCache(force = false) {
        this.cache.clear();
        this.calendar.clearCacheByView(this, force);
    }

    includesItem(item) {
        return item.startDate >= this.startDate && item.endDate <= this.endDate;
    }

    includingItem(item) {
        let direction = item.startDate > this.startDate ? 'next' : 'previous';
        let view = this;
        let limit = 10000;

        while (!view.includesItem(item)) {
            view = view[direction];

            if (limit-- < 0) {
                throw 'Recursion limit reached in Views.View.includingItem.';
            }
        }

        return view;
    }
}
