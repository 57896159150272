import { flow, Instance, types } from 'mobx-state-tree';
import UserTransport from '../Services/Transport/UserTransport';
import { Image } from './Image';
import { Locale } from '@/Models/Locale';
import { Organisation } from '@/Models/Organisation';

export interface UserInstance extends Instance<typeof User> {}

export const User = types
    .model('User', {
        eid: types.string,
        identifier: types.identifier,
        firstName: types.string,
        lastName: types.string,
        email: types.string,
        newEmail: types.maybeNull(types.string),
        mobilePhone: types.maybeNull(types.string),
        gender: types.maybeNull(types.string),
        activated: types.boolean,
        image: types.maybeNull(Image),
        calendarUrl: types.string,
        signUpState: '',

        locale: types.maybeNull(Locale),

        auth: types.model({
            roles: types.array(types.string),
            permissions: types.array(types.string),
            providers: types.array(types.string),
            hasPassword: types.boolean,
        }),

        organisations: types.array(Organisation),

        // Preferences
        dailyEmailOccurrence: types.array(types.string),

        // Tracking
        signInCount: types.integer,
        hasDismissed: types.maybeNull(types.array(types.string)),

        archivedAt: types.maybeNull(types.string),
    })

    .views((self) => ({
        get userEid() {
            return self.eid;
        },

        get fullName() {
            return `${self.firstName} ${self.lastName}`;
        },

        get hasSocialAuth() {
            return (
                self.auth.providers.includes('google') ||
                self.auth.providers.includes('facebook')
            );
        },

        get ownedOrganisations() {
            return self.organisations.filter(
                (organisation) => organisation.isOwner
            );
        },

        get hasOwnedOrganisations() {
            return this.ownedOrganisations.length > 0;
        },
    }))

    .actions((self) => {
        const updateProperty = (property: string, value: any) => {
            // @ts-ignore
            self[property] = value;
        };

        return {
            updateProperty,
            resetCalendarUrl: flow(function* resetCalendarUrl() {
                const { data } = yield UserTransport.resetCalendarUrl();
                updateProperty('calendarUrl', data.url);
            }),
        };
    });
