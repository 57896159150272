import { flow, getRoot, Instance, types } from 'mobx-state-tree';
import RootStore from '../Stores/RootStore';
import { DateTimeType } from '../Types/DateTime';
import { formatDate } from '@/Services/Date';
import AgreementTransport from '@/Services/Transport/AgreementTransport';

export interface AgreementInstance extends Instance<typeof Agreement> {}

export const Agreement = types
    .model('Agreement', {
        eid: types.string,
        type: types.literal('agreement'),
        identifier: types.identifier,
        resourceVersion: types.integer,
        name: types.string,
        content: types.string,
        isDefault: types.boolean,
        isArchived: types.boolean,
        isDeleted: types.boolean,

        archivedAt: types.maybeNull(DateTimeType),
        createdAt: DateTimeType,
    })

    .preProcessSnapshot((snapshot) => {
        const newSnapshot = {
            ...snapshot,
        };

        if (snapshot.resourceVersion === 1) {
            newSnapshot.isDefault = newSnapshot.default;
            newSnapshot.isDeleted = newSnapshot.deleted;
            newSnapshot.createdAt = newSnapshot.created_at;
        }

        return newSnapshot;
    })

    .views((self) => ({
        // Legacy properties
        get default() {
            return self.isDefault;
        },
        get deleted() {
            return self.isDeleted;
        },

        get createdAtFormatted() {
            return formatDate(self.createdAt);
        },

        get url() {
            return `/organisation/agreements/${self.eid}`;
        },
    }))

    .actions((self) => ({
        update: flow(function* update(input = {}) {
            const { data } = yield AgreementTransport.update(self.eid, input);
            getRoot(self).stores.agreementStore.createOrUpdate(data.data);
        }),
    }));

export const AgreementReference = types.reference(Agreement, {
    get(identifier) {
        return RootStore.stores.agreementStore.find(identifier) || null;
    },
    set(agreement) {
        return agreement.identifier;
    },
});
