import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { messages } from '@/Config/validation';
import { Client } from '@/Models/Client';
import { StackableModalProps } from '@/Components/RootModal';
import { WIDTHS } from '@/Components/Modal';
import ConfirmationModal from '@/Components/ConfirmationModal';
import FormAgreementSelectField from '@/Components/FormAgreementSelectField';
import RootStore from '@/Stores/RootStore';
import ClientTransport from '@/Services/Transport/ClientTransport';
import Notification from '@/Services/Notification';
import { AxiosError } from 'axios';
import Notice from '@/Components/Notice/index';
import handleServerValidation from '@/Utilities/handleServerValidation';

interface FormValues {
    documentType: 'agreement' | 'parq';
    agreement: string;
}

interface Props extends StackableModalProps {
    client: Client;
    documentType: 'agreement' | 'parq';
}

const validationSchema = Yup.object().shape({
    agreement: Yup.string().when('documentType', {
        is: 'agreement',
        then: Yup.string().required(messages.required),
    }),
});

const Component: FunctionComponent<Props & FormikProps<FormValues>> = ({
    client,
    documentType,
    ...otherProps
}) => {
    const { isValid, handleSubmit, values } = otherProps;

    return (
        <ConfirmationModal
            width={WIDTHS.narrow}
            onConfirmed={handleSubmit}
            shouldAutoClose={false}
            content={() => (
                <>
                    <p>
                        Send {client.firstName}
                        {documentType === 'agreement'
                            ? ' an agreement to sign'
                            : ' a PAR-Q to complete'}
                    </p>
                </>
            )}
            fieldContent={() => (
                <>
                    {documentType === 'agreement' && (
                        <FormAgreementSelectField
                            name="agreement"
                            label="Choose an agreement to sign"
                            isRequired
                            withCreateButton
                        />
                    )}
                    {!client.hasEmail && (
                        <div className="my-7 flex justify-center">
                            <Notice type="warning">
                                No email address for {client.firstName}
                            </Notice>
                        </div>
                    )}
                </>
            )}
            isPositive
            stackProps={otherProps.stackProps}
            confirmText="Send"
            isDisabled={!client.hasEmail || !isValid}
        />
    );
};

export const UpdateClientDocumentsModal = withFormik<Props, FormValues>({
    validationSchema,
    validateOnMount: true,
    mapPropsToValues: (props) => ({
        documentType: props.documentType,
        agreement: RootStore.stores.agreementStore.default?.eid ?? '',
        method: props.client.hasEmail ? 'link' : 'screen',
    }),
    handleSubmit: async (values, { props, setErrors }) => {
        try {
            const args = [
                props.client.eid,
                props.documentType,
                props.documentType === 'agreement'
                    ? { agreement: values.agreement }
                    : {},
            ];

            await ClientTransport.sendDocumentInviteLink(...args);
            Notification.success('Client invite sent');

            RootStore.stores.uiStateStore.closeStack();
        } catch (e) {
            handleServerValidation(e as AxiosError, setErrors);
        }
    },
})(observer(Component));
