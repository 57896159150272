import angular from 'angular';

angular
    .module('resource.diary-entry', [])

    .service('DiaryEntry', [
        '$resource',
        'TransformerService',
        function ($resource, TransformerService) {
            var DiaryEntry = $resource(
                '/v1/diary-entries/:eid',
                { eid: '@eid' },
                {
                    query: {
                        isArray: true,
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                    get: {
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                    update: {
                        method: 'PUT',
                        transformResponse:
                            TransformerService.defaultTransformer,
                    },
                }
            );

            return DiaryEntry;
        },
    ]);
