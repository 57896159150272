import React, {
    FunctionComponent,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import { observer } from 'mobx-react';
import withQueryClient from '@/HOCs/WithQueryClient';
import { NewPopper } from '@/Components/NewPopper';
import {
    ScheduleEntryPopperContentDatePicker,
    ScheduleEntryPopperContentRepeat,
    ScheduleEntryPopperContentUserSelect,
    ScheduleEntryPopperOptions,
} from '@/Features/Schedule';
import classNames from 'classnames';
import useTeam from '@/Hooks/useTeam';
import RescheduleAction from '../../../../assets/js/actions/RescheduleAction';
import { isMobile } from '@/Services/Schedule';

interface Props {
    isSelected: boolean;
    isMoving: boolean;
    view: any;
    card: any;
    calendar: any;
    action: any;
    onRemove(): void;
}

export type ScheduleEntryPopperActionType =
    | 'options'
    | 'move-date'
    | 'change-teacher'
    | 'repeat'
    | undefined;

const Component: FunctionComponent<Props> = ({
    isSelected,
    isMoving,
    view,
    card,
    calendar,
    action,
    onRemove,
}) => {
    const { hasTeamMembers } = useTeam();
    const [paddingTop, setPaddingTop] = useState(0);
    const [popperDisplay, setPopperDisplay] =
        useState<ScheduleEntryPopperActionType>('options');
    const [transitioning, setTransitioning] = useState(false);

    useLayoutEffect(() => {
        const offset =
            document.getElementById('schedule-scrollable')?.offsetTop ?? 0;
        setPaddingTop(offset + (isMobile() ? 300 : 240));
    }, []);

    function handleActionChange(action: ScheduleEntryPopperActionType) {
        setTransitioning(true);

        setTimeout(() => {
            setPopperDisplay(action);
            setTimeout(() => setTransitioning(false), 150);
        }, 150);
    }

    const shouldDisplayRepeat = card.isRepeatable;
    const shouldDisplayUserSelect =
        hasTeamMembers && card.type !== 'diary-entry';
    const shouldDisplayRemove = !(action instanceof RescheduleAction);

    const actionCount = useMemo(() => {
        let count = 1;

        if (shouldDisplayRepeat) {
            count++;
        }

        if (shouldDisplayUserSelect) {
            count++;
        }

        if (shouldDisplayRemove) {
            count++;
        }

        return count;
    }, [
        popperDisplay,
        shouldDisplayRepeat,
        shouldDisplayUserSelect,
        shouldDisplayRemove,
    ]);

    if (!isSelected || isMoving) {
        return null;
    }

    return (
        <NewPopper
            placement="top"
            initialOpen
            isClosable={false}
            anchor={<div className="pointer-events-none absolute inset-0" />}
            paperClassName={classNames(
                'absolute overflow-hidden z-[100] top-0 left-0 h-[56px] transition-[height,width] duration-150',
                {
                    'w-[56px]': actionCount === 1,
                    'w-[104px]': actionCount === 2,
                    'w-[152px]': actionCount === 3,
                    'w-[200px]': actionCount === 4,
                    '!h-[376px] !w-[282px]': popperDisplay === 'move-date',
                    '!h-[260px] !w-[220px]': popperDisplay === 'change-teacher',
                    '!h-[208px] !w-[250px]': popperDisplay === 'repeat',
                }
            )}
            floatingClassName={classNames(
                'h-[56px] z-[100] transition-[left] duration-150',
                {
                    'w-[56px]': actionCount === 1,
                    'w-[104px]': actionCount === 2,
                    'w-[152px]': actionCount === 3,
                    'w-[200px]': actionCount === 4,
                    '!w-[282px]': popperDisplay === 'move-date',
                    '!w-[220px]': popperDisplay === 'change-teacher',
                    '!w-[250px]': popperDisplay === 'repeat',
                }
            )}
            withPortal
            flipOptions={{
                padding: { top: paddingTop, left: 0, bottom: 0, right: 0 },
            }}
            wrapperClassName="z-[60]"
        >
            {() => (
                <div
                    className={classNames(
                        'opacity-100 transition-opacity duration-150',
                        {
                            '!opacity-0': transitioning,
                        }
                    )}
                >
                    {popperDisplay === 'options' && (
                        <ScheduleEntryPopperOptions
                            shouldDisplayRepeat={shouldDisplayRepeat}
                            shouldDisplayUserSelect={shouldDisplayUserSelect}
                            shouldDisplayRemove={shouldDisplayRemove}
                            onOptionClick={handleActionChange}
                            onRemoveClick={onRemove}
                        />
                    )}

                    {popperDisplay === 'move-date' && (
                        <ScheduleEntryPopperContentDatePicker
                            card={card}
                            view={view}
                            action={action}
                            calendar={calendar}
                            onDisplayChange={handleActionChange}
                        />
                    )}
                    {popperDisplay === 'change-teacher' && (
                        <ScheduleEntryPopperContentUserSelect
                            card={card}
                            view={view}
                            action={action}
                            onDisplayChange={handleActionChange}
                        />
                    )}

                    {popperDisplay === 'repeat' && (
                        <ScheduleEntryPopperContentRepeat
                            card={card}
                            view={view}
                            calendar={calendar}
                            onDisplayChange={handleActionChange}
                        />
                    )}
                </div>
            )}
        </NewPopper>
    );
};

export const ScheduleEntryPopper = withQueryClient(observer(Component));
