import { Instance, types } from 'mobx-state-tree';

export interface ImageInstance extends Instance<typeof Image> {}

export const Image = types.model('Image', {
    type: types.literal('image'),
    urls: types.model({
        original: types.string,
        small: types.string,
    }),
});
