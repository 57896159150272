import React, { Fragment, FunctionComponent } from 'react';
import {
    PrimaryButton,
    PrimaryResponsiveButton,
} from '../../../Components/Button';
import RootStore from '../../../Stores/RootStore';

// Props are type any because they are coming from AngularJS
interface Props {
    view?: any;
    calendar?: any;
    responsive?: boolean;
    onSuccess?(): any;
}

const ScheduleAddEventButton: FunctionComponent<Props> = ({
    view,
    calendar,
    onSuccess,
    responsive = false,
}) => {
    const handleClick = () => {
        RootStore.stores.uiStateStore.pushModal('SCHEDULE_ADD_EVENT', {
            view,
            calendar,
            onCancel: handleClick,
            onSuccess,
        });
    };

    const buttonProps = {
        icon: 'Plus',
        children: 'Event',
        onClick: handleClick,
        'data-testid': 'add-event-button',
    };

    return (
        <Fragment>
            {responsive && <PrimaryResponsiveButton {...buttonProps} />}
            {!responsive && <PrimaryButton {...buttonProps} />}
        </Fragment>
    );
};

export default ScheduleAddEventButton;
