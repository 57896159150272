import BaseTransport from './BaseTransport';

class PortalInviteTransport extends BaseTransport {
    async list(params = {}) {
        return await this.get('/v3/portal-invites', params);
    }

    async destroy(uuid) {
        return await this.delete(`/v3/portal-invites/${uuid}`);
    }

    async resend(uuid) {
        return await this.post(`/v3/portal-invites/${uuid}/resend`);
    }

    async password(clientEid) {
        return await this.post(`/v3/clients/${clientEid}/invite`);
    }
}

export default new PortalInviteTransport();
