import React, { FunctionComponent, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import Alert from '@/Components/Alert';
import PlanScheduleEventsContext from '@/Contexts/PlanScheduleEventsContext';
import { TextButton } from '@/Components/Button';
import useScheduleEventAvailabilityWarning, {
    ScheduleEventAvailabilityWarningEventType,
} from '@/Hooks/useScheduleEventAvailabilityWarning';

interface Props {
    eventType: ScheduleEventAvailabilityWarningEventType;
}

const Component: FunctionComponent<Props> = ({ eventType }) => {
    const { hasConflicts, hasHolidays } = useContext(PlanScheduleEventsContext);
    const { title, body } = useScheduleEventAvailabilityWarning(
        eventType,
        hasConflicts,
        hasHolidays
    );
    const [isReadingMore, setIsReadingMore] = useState(false);

    if (!hasConflicts && !hasHolidays) {
        return null;
    }

    return (
        <Alert
            type="warning"
            title={title}
            smallTitle
            subtitle={isReadingMore ? body : undefined}
            rightAction={
                <TextButton
                    className="!h-auto"
                    type="button"
                    onClick={() => setIsReadingMore(!isReadingMore)}
                >
                    {isReadingMore ? 'Hide' : 'Show'} more
                </TextButton>
            }
        />
    );
};

export const ScheduleEventAvailabilityListAlerts = observer(Component);
