import React, { FunctionComponent } from 'react';
import { AlertType } from '@/Components/Alert';
import { AlertIcon } from '@/Components/AlertIcon';
import classNames from 'classnames';
import { alertColors } from '@/Services/Alert';

interface Props {
    type: AlertType;
    text?: string;
    withIcon?: boolean;
    className?: string;
}

const NoticeTag: FunctionComponent<Props> = ({
    children,
    type = 'warning',
    text,
    withIcon = true,
    className,
}) => {
    const styles = alertColors(type);

    return (
        <div className={classNames('flex', className)}>
            <div
                className={classNames(
                    'flex items-center rounded-md p-1.5 px-3',
                    styles.wrapper
                )}
            >
                {withIcon && <AlertIcon alertType={type} className="mr-1.5" />}
                <div className="text-sm">{text ?? children}</div>
            </div>
        </div>
    );
};

export default NoticeTag;
