import { ClassDate } from '@/Models/ClassDate';
import { Class } from '@/Models/Class';

export function classCostString(model: ClassDate | Class) {
    if (model.isPrivate && model.singleEnrolmentEnabled) {
        return `${model.simplifiedEnrolmentCost} per person`;
    }

    if (model.isPrivate && !model.singleEnrolmentEnabled) {
        return 'Packages only';
    }

    return `${model.simplifiedFee} per class`;
}
