import React, { FunctionComponent, useContext } from 'react';
import RootStore from '@/Stores/RootStore';
import Notification from '@/Services/Notification';
import { Button, PrimaryButton } from '@/Components/Button';
import { Client } from '@/Models/Client';
import { observer } from 'mobx-react';
import EventConfirmationModalContext from '@/Contexts/EventConfirmationModalContext';
import { possessive } from '@/Services/String';

interface Props {
    client: Client;
    className?: string;
}

const Component: FunctionComponent<Props> = ({ client, className }) => {
    const confirmationContext = useContext(EventConfirmationModalContext);
    const { uiStateStore } = RootStore.stores;
    const invitable = !!client.email;

    async function sendInvite() {
        try {
            await client.inviteToPortal();
        } catch (e) {
            console.log(e);
            Notification.error();
            return;
        }

        Notification.success('Client area invite sent');

        uiStateStore.closeStack();
    }

    function handleClick(isResend = false) {
        confirmationContext.requireConfirmation({
            content: () => (
                <>
                    {invitable && !isResend && (
                        <p>
                            Invite {client.firstName} to set a password to begin
                            using your client area and the Striive Client app?
                        </p>
                    )}
                    {invitable && isResend && (
                        <p>
                            Are you sure you want to resend{' '}
                            {possessive(client.firstName)} invite to set a
                            password and start using your client area?
                        </p>
                    )}
                    {!invitable && (
                        <p>
                            To invite {client.firstName} you'll need to add
                            their email address.
                        </p>
                    )}
                </>
            ),
            onConfirmed: sendInvite,
            isDisabled: !invitable,
            isPositive: true,
        });
    }

    return (
        <>
            {!client.hasPortalPasswordInvite && (
                <PrimaryButton
                    type="button"
                    icon="EmailPlus"
                    onClick={() => handleClick(false)}
                    className={className}
                >
                    Invite
                </PrimaryButton>
            )}
            {client.hasPortalPasswordInvite && (
                <Button
                    type="button"
                    onClick={() => handleClick(true)}
                    className={className}
                >
                    Resend
                </Button>
            )}
        </>
    );
};

export const ClientPortalInviteButton = observer(Component);
