import angular from 'angular';
import Auth from '../../../js/Modules/Auth';
import Config from '../../../js/Config';
import cookie from 'cookie';

angular
    .module('auth', [])

    .factory('apiInterceptor', [
        '$q',
        function ($q) {
            let exceptions = [/^https?:\/\//];

            return {
                /**
                 * Append authorisation headers
                 * @param config
                 * @returns {*}
                 */
                request: function (config) {
                    let exception = false;

                    angular.forEach(exceptions, (pattern) => {
                        let matches = config.url.match(pattern);
                        if (!!matches && matches.length) exception = true;
                    });

                    if (!exception) {
                        config.url = `${Config.apiUrl}${config.url}`;
                    }

                    return config;
                },
            };
        },
    ])

    .factory('authInterceptor', [
        '$q',
        function ($q) {
            return {
                /**
                 * Handle token refreshing, and session resuming
                 * @param response
                 * @returns {*}
                 */
                responseError: function (response) {
                    if (response.status === 401 || response.status === 419) {
                        return Auth.goToSignIn();
                    }

                    return $q.reject(response);
                },
            };
        },
    ])

    .factory('XSRFInterceptor', [
        function () {
            return {
                /**
                 * Append authorisation headers
                 * @param config
                 * @returns {*}
                 */
                request: function (config) {
                    const cookies = cookie.parse(document.cookie);

                    if (!!cookies['XSRF-TOKEN']) {
                        config.headers['X-XSRF-TOKEN'] = cookies['XSRF-TOKEN'];
                    }
                    return config;
                },
            };
        },
    ]);
