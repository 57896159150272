import BaseTransport from './BaseTransport';

class ConsultationTransport extends BaseTransport {
    async getConsultation(eid) {
        return await this.get(`/v3/consultations/${eid}`);
    }

    async list(params = {}) {
        return await this.get('/v3/consultations', params);
    }

    async store(data) {
        return await this.post(`/v3/consultations`, data);
    }

    async update(eid, params = {}) {
        return await this.put(`/v3/consultations/${eid}`, params);
    }

    async dismiss(eid) {
        return await this.put(`/v3/consultations/${eid}/dismiss`);
    }

    async updateDelivery(eid, data) {
        return await this.put(`/v3/consultations/${eid}/delivery`, data);
    }

    async cancel(eid, params = {}) {
        return await this.put(`/v3/consultations/${eid}/cancel`, params);
    }

    async deleteConsultation(eid) {
        return await this.delete(`/v3/consultations/${eid}`);
    }
}

export default new ConsultationTransport();
