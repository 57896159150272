import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { filterXSS } from 'xss';
import RootStore from '@/Stores/RootStore';
import { formatDate, inUserZone } from '@/Services/Date';
import { Agreement } from '@/Models/Agreement';
import { Client } from '@/Models/Client';
import { ClientAgreement } from '@/Models/ClientAgreement';
import ActionBar from '@/Components/ActionBar';

interface Props {
    client?: Client;
    agreement: Agreement | ClientAgreement;
    tense?: 'past' | 'present';
}

const Component: FunctionComponent<Props> = ({
    client,
    agreement,
    tense = 'present',
}) => {
    function getEntity() {
        return (
            agreement.organisationName ??
            RootStore.stores.currentUserStore.organisation.name
        );
    }

    function getClient() {
        if (typeof agreement.clientName !== 'undefined') {
            return agreement.clientName;
        }
        if (typeof client !== 'undefined') {
            return client.fullName;
        }

        return '[client name]';
    }

    function getDate() {
        const date =
            typeof agreement !== 'undefined'
                ? agreement.acceptedAt
                : inUserZone();

        return formatDate(date);
    }

    function getContent() {
        return filterXSS(agreement.content);
    }

    return (
        <section className="border-b border-t border-gray-200 bg-white xl:rounded-lg xl:border">
            <ActionBar className="border-b border-gray-200 px-3 py-4 xl:px-4">
                <p>
                    This agreement {tense === 'present' ? 'is' : 'was'} made
                    between <strong>{getEntity()}</strong> and{' '}
                    <strong>{getClient()}</strong> on {getDate()}
                </p>
            </ActionBar>
            <div
                className="prose max-w-none p-4 xl:p-10"
                dangerouslySetInnerHTML={{ __html: getContent() }}
            />

            {!!agreement.signature && (
                <div className="p-4 xl:px-10">
                    <p className="font-bold">Signed by {getClient()}</p>
                    <img
                        src={agreement.signature}
                        alt={`Signature of ${getClient()}`}
                        className="max-w-[300px]"
                    />
                </div>
            )}
        </section>
    );
};

export const ClientAgreementBody = observer(Component);
