import angular from 'angular';
import { ResizeSensor } from 'css-element-queries';
import qs from 'qs';
import history from '@/Modules/History';
import RootStore from '@/Stores/RootStore';
import { periodsToTimeSummary, toLuxon } from '@/Services/Date';
import { getFormattedUrl, isMobile } from '@/Services/Schedule';
import createAction from '../../../actions/';
import CreateSessionPack from '../../../actions/CreateSessionPack';
import CreateClass from '../../../actions/CreateClass';

import '../../../components/schedule-navigation/schedule-navigation';
import '../../../components/schedule-day/schedule-day';
import '../../../components/schedule-week-holiday/schedule-week-holiday';
import '../../../components/schedule-add-target/schedule-add-target';
import '../../../components/schedule-entry/schedule-entry';
import '../../../react/react-schedule-date-picker';
import '../../../react/react-schedule-action-panel';
import '../../../react/react-schedule-assigned-user-select';
import { DateTime } from 'luxon';
import CreateDiary from '../../../actions/CreateDiary';
import RescheduleDiary from '../../../actions/RescheduleDiary';
import Notification from '@/Services/Notification';
import queryClient from '@/Modules/QueryClient';
import { queryKeys } from '@/Services/QueryKeys';
import UpdateSessionPack from '../../../actions/UpdateSessionPack';
import UpdateClass from '../../../actions/UpdateClass';

const ScheduleView = {
    template: `
        <div class='ui-page ui-schedule-week --[[ $ctrl.calendar.view.type ]]'>
    <header class='sticky top-[64px] z-[70] border-b border-gray-200 bg-white pt-4 2xl:top-0 2xl:pt-6'  data-schedule-header>
        <div class='px-4 2xl:px-8'>
            <section class='flex flex-wrap'>
                <div class='flex flex-1 flex-col'>
                    <h2 class='flex min-h-[40px] items-center text-xl font-bold xl:text-2xl whitespace-nowrap'>[[ $ctrl.action.calendarTitle || 'Calendar' ]]</h2>
                </div>
                <react-schedule-action-panel
                    view='$ctrl'
                    action='$ctrl.action'
                    calendar='$ctrl.calendar'
                    date-count='$ctrl.action.cards.length'
                    new-date-count='$ctrl.action.newCards.length'
                    total-periods='$ctrl.action.getAvailablePeriods()'
                    has-updated-card='$ctrl.action.hasUpdatedCard'
                    ng-class="{'w-full': $ctrl.action.isAvailable}"
                ></react-schedule-action-panel>
            </section>
            <section class='flex flex-col xl:flex-row xl:space-y-0 xl:pb-0 space-y-3 pt-3 pb-4'>
                <div class='flex-1'>
                    <schedule-navigation calendar='$ctrl.calendar' action='$ctrl.action'></schedule-navigation>
                </div>
                
                <react-schedule-assigned-user-select
                    enabled='$ctrl.currentCalendarSwitchEnabled'
                    on-change='$ctrl.setCurrentCalendar'    
                    selected='$ctrl.calendar.currentCalendar'
                    class='flex-1 block xl:max-w-[280px]'></react-schedule-assigned-user-select>
            </section>
    
            <section class='ui-schedule__header__days --week' ng-if="$ctrl.calendar.view.is('week')">
                <div>
                    <div ng-repeat='day in $ctrl.calendar.view.days' ng-class="{'--is-today': day.isToday}">
                    [[ day.date.toFormat('ccc d LLL') ]]
                    </div>
                </div>
            </section>
        </div>
    </header>

    <div class='ui-page__content'>
        <section ng-init='$ctrl.setScrollableElement()' id='schedule-scrollable'>
            <section ng-init='$ctrl.setContentElement()' id='schedule-content'
                     class='schedule week disable-select --type-week relative'>
                <div class='fixed inset-0 z-[100] top-[64px] 2xl:top-0 2xl:left-[256px]' ng-if='!$ctrl.initialised || $ctrl.action.isLoading || $ctrl.isLoading' ng-cloak>
                    <react-loader with-shroud='true' with-position='true'></react-loader>
                </div>

                <div class='schedule__week'>
                    <div ng-init='$ctrl.setDayWrapperElement()' id='schedule-day-wrapper' class='schedule__week__days' ng-class="{'--has-periods': $ctrl.hasWorkingPeriods}"
                         style='position: relative' data-testid='schedule-day-wrapper'>
                        <div class='schedule__periods'>
                            <div class='schedule__period [[ period.classes ]]' ng-style='period.styles' ng-repeat='period in $ctrl.workingPeriods'></div>
                        </div>
                        <div class='schedule__grid'></div>
                        <div class='schedule__day'ng-repeat='day in $ctrl.calendar.view.days'></div>

                        <schedule-week-holiday ng-repeat='holiday in $ctrl.calendar.view.marks'
                                               calendar='$ctrl.calendar' holiday='holiday'
                                               offset-top='$ctrl.dayWrapperTopOffset'
                                               ng-if='$ctrl.calendar.view.isPopulated'></schedule-week-holiday>
                        <schedule-entry
                                ng-repeat='card in $ctrl.calendar.view.cards'
                                calendar='$ctrl.calendar'
                                action='$ctrl.action'
                                schedule-card='card'
                                delivery='card.entry.delivery'
                                is-selected='card.isSelected'
                        ></schedule-entry>
                        <schedule-add-target calendar='$ctrl.calendar' action='$ctrl.action' ng-if='$ctrl.action' ng-cloak></schedule-add-target>
                    </div>
                    <div class='schedule__week__times --left' ng-show='$ctrl.twelveHour' ng-cloak>
                        <div>1 AM</div>
                        <div>2 AM</div>
                        <div>3 AM</div>
                        <div>4 AM</div>
                        <div>5 AM</div>
                        <div>6 AM</div>
                        <div>7 AM</div>
                        <div>8 AM</div>
                        <div>9 AM</div>
                        <div>10 AM</div>
                        <div>11 AM</div>
                        <div>12 PM</div>
                        <div>1 PM</div>
                        <div>2 PM</div>
                        <div>3 PM</div>
                        <div>4 PM</div>
                        <div>5 PM</div>
                        <div>6 PM</div>
                        <div>7 PM</div>
                        <div>8 PM</div>
                        <div>9 PM</div>
                        <div>10 PM</div>
                        <div>11 PM</div>
                    </div>
                    <div class='schedule__week__times --right' ng-show='$ctrl.twelveHour' ng-cloak>
                        <div>1 AM</div>
                        <div>2 AM</div>
                        <div>3 AM</div>
                        <div>4 AM</div>
                        <div>5 AM</div>
                        <div>6 AM</div>
                        <div>7 AM</div>
                        <div>8 AM</div>
                        <div>9 AM</div>
                        <div>10 AM</div>
                        <div>11 AM</div>
                        <div>12 PM</div>
                        <div>1 PM</div>
                        <div>2 PM</div>
                        <div>3 PM</div>
                        <div>4 PM</div>
                        <div>5 PM</div>
                        <div>6 PM</div>
                        <div>7 PM</div>
                        <div>8 PM</div>
                        <div>9 PM</div>
                        <div>10 PM</div>
                        <div>11 PM</div>
                    </div>
                    <div class='schedule__week__times --left' ng-show='!$ctrl.twelveHour' ng-cloak>
                        <div>01<span class='long'>:00</span></div>
                        <div>02<span class='long'>:00</span></div>
                        <div>03<span class='long'>:00</span></div>
                        <div>04<span class='long'>:00</span></div>
                        <div>05<span class='long'>:00</span></div>
                        <div>06<span class='long'>:00</span></div>
                        <div>07<span class='long'>:00</span></div>
                        <div>08<span class='long'>:00</span></div>
                        <div>09<span class='long'>:00</span></div>
                        <div>10<span class='long'>:00</span></div>
                        <div>11<span class='long'>:00</span></div>
                        <div>12<span class='long'>:00</span></div>
                        <div>13<span class='long'>:00</span></div>
                        <div>14<span class='long'>:00</span></div>
                        <div>15<span class='long'>:00</span></div>
                        <div>16<span class='long'>:00</span></div>
                        <div>17<span class='long'>:00</span></div>
                        <div>18<span class='long'>:00</span></div>
                        <div>19<span class='long'>:00</span></div>
                        <div>20<span class='long'>:00</span></div>
                        <div>21<span class='long'>:00</span></div>
                        <div>22<span class='long'>:00</span></div>
                        <div>23<span class='long'>:00</span></div>
                    </div>
                    <div class='schedule__week__times --right' ng-show='!$ctrl.twelveHour' ng-cloak>
                        <div>01<span class='long'>:00</span></div>
                        <div>02<span class='long'>:00</span></div>
                        <div>03<span class='long'>:00</span></div>
                        <div>04<span class='long'>:00</span></div>
                        <div>05<span class='long'>:00</span></div>
                        <div>06<span class='long'>:00</span></div>
                        <div>07<span class='long'>:00</span></div>
                        <div>08<span class='long'>:00</span></div>
                        <div>09<span class='long'>:00</span></div>
                        <div>10<span class='long'>:00</span></div>
                        <div>11<span class='long'>:00</span></div>
                        <div>12<span class='long'>:00</span></div>
                        <div>13<span class='long'>:00</span></div>
                        <div>14<span class='long'>:00</span></div>
                        <div>15<span class='long'>:00</span></div>
                        <div>16<span class='long'>:00</span></div>
                        <div>17<span class='long'>:00</span></div>
                        <div>18<span class='long'>:00</span></div>
                        <div>19<span class='long'>:00</span></div>
                        <div>20<span class='long'>:00</span></div>
                        <div>21<span class='long'>:00</span></div>
                        <div>22<span class='long'>:00</span></div>
                        <div>23<span class='long'>:00</span></div>
                    </div>
                </div>
            </section>
        </section>
    </div>
</div>
    `,
    bindings: {
        view: '=',
        params: '=',
        actionName: '=',
    },
    controller: [
        '$document',
        '$element',
        '$rootScope',
        '$scope',
        '$timeout',
        '$window',
        '$q',
        'Calendar',
        function (
            $document,
            $element,
            $rootScope,
            $scope,
            $timeout,
            $window,
            $q,
            Calendar
        ) {
            let $ctrl = this;
            let resizeEnd;

            let currentActionName = $ctrl.actionName;

            const isTwelveHour =
                RootStore.stores.currentUserStore.user.locale.timeFormat ===
                '12h';

            $ctrl.twelveHour =
                RootStore.stores.currentUserStore.user.locale.timeFormat ===
                '12h';

            const urlParams = {
                ...$ctrl.params,
                ...qs.parse(window.location.search, {
                    ignoreQueryPrefix: true,
                }),
            };

            $scope.$watch('$ctrl.actionName', (newActionName) => {
                if (newActionName !== currentActionName) {
                    $ctrl.initialise($ctrl.params);
                    currentActionName = newActionName;
                }
            });

            function handlePopulateError(error) {
                if (
                    error.response.status === 403 &&
                    $ctrl.calendar.currentCalendar !==
                        RootStore.stores.currentUserStore.organisation
                            ?.organisationUser?.uuid
                ) {
                    Notification.error('This calendar is no longer available.');
                    queryClient.invalidateQueries(queryKeys.teamMembers.list());
                    $ctrl.setCurrentCalendar(
                        RootStore.stores.currentUserStore.organisation
                            ?.organisationUser?.uuid
                    );
                } else {
                    Notification.error();
                }
            }

            $ctrl.populateMark = (holiday) => {
                $timeout(() => {
                    $ctrl.calendar.populateMark(holiday);
                });
            };

            $ctrl.setCurrentCalendar = (selected) => {
                $timeout(() => {
                    $ctrl.isLoading = true;
                    $ctrl.calendar.clearCache();
                    $ctrl.calendar.setCurrentCalendar(selected);
                    $scope.$apply();
                });

                $timeout(() => {
                    $ctrl.calendar.view
                        .populate($ctrl.action, true, true)
                        .then(() => {
                            $scope.$apply();
                        })
                        .catch((error) => {
                            handlePopulateError(error);
                        });

                    $ctrl.isLoading = false;
                });
            };

            $ctrl.setAssignedUsers = (selected) => {
                $ctrl.calendar.setAssignedUsers(selected);
            };

            $ctrl.setContentElement = () => {
                $ctrl.contentElement = $element.find('#schedule-content');

                $timeout(() => {
                    $ctrl.calculateScrollbarWidth();
                });
            };

            $ctrl.setDayWrapperElement = () => {
                $ctrl.dayWrapperElement = $element.find(
                    '#schedule-day-wrapper'
                );

                new ResizeSensor($ctrl.dayWrapperElement, function () {
                    clearTimeout(resizeEnd);
                    resizeEnd = $timeout(() => {
                        $ctrl.calculateStaticValues();
                        $ctrl.calculateWorkingPeriods();
                    }, 500);
                });
            };

            $ctrl.setScrollableElement = () => {
                $ctrl.scrollableElement = $element.find('#schedule-scrollable');
            };

            $ctrl.calculateStaticValues = () => {
                clearTimeout(resizeEnd);

                if (!$ctrl.dayWrapperElement) {
                    return;
                }

                let dayWrapper =
                    $ctrl.dayWrapperElement[0].getBoundingClientRect();
                let days = $ctrl.calendar.view.days.length;

                $ctrl.dayWidth = dayWrapper.width / days;
                $ctrl.dayHeight = dayWrapper.height;
                $ctrl.dayWrapperLeftOffset = dayWrapper.left;

                const dayHeader = document.querySelector(
                    '[data-schedule-header]'
                );
                $ctrl.dayWrapperTopOffset =
                    dayHeader.getBoundingClientRect().height +
                    dayHeader.getBoundingClientRect().top;

                $ctrl.calculateScrollbarWidth();
            };

            $ctrl.calculateScrollbarWidth = () => {
                let content = $ctrl.contentElement[0].getBoundingClientRect();
                let scrollable =
                    $ctrl.scrollableElement[0].getBoundingClientRect();

                $ctrl.scrollbarWidth = scrollable.width - content.width;
            };

            $ctrl.calculateWorkingPeriods = (setScroll = false) => {
                const view = $ctrl.calendar.view;

                $ctrl.hasWorkingPeriods =
                    RootStore.stores.currentUserStore.organisation
                        .workingPeriods.length > 0;

                const workingPeriods =
                    RootStore.stores.currentUserStore.organisation.workingPeriods.filter(
                        (period) => {
                            return (
                                view.type === 'week' ||
                                (view.type === 'day' &&
                                    period.dayOfWeek - 1 === view.weekday)
                            );
                        }
                    );

                $ctrl.workingPeriods = workingPeriods.map((period) => {
                    return {
                        styles: {
                            width: `${$ctrl.dayWidth}px`,
                            top: `${
                                (period.startMinutes / 15) * $ctrl.periodPixels
                            }px`,
                            left:
                                view.type === 'day'
                                    ? 0
                                    : $ctrl.dayWidth * (period.dayOfWeek - 1) +
                                      'px',
                            height: `${
                                ((period.endMinutes - period.startMinutes) /
                                    15) *
                                $ctrl.periodPixels
                            }px`,
                        },
                        classes: `absolute bg-white`,
                    };
                });

                if (setScroll && $ctrl.hasWorkingPeriods) {
                    const lowest = Math.min(
                        ...workingPeriods.map((period) => period.startMinutes)
                    );

                    $timeout(function () {
                        window.scrollTo(
                            0,
                            Math.max(((lowest || 0) / 60) * 100 - 50, 0)
                        );
                    });
                }
            };

            $ctrl.setAction = (action) => {
                $ctrl.action = action;
                $ctrl.isLoading = true;

                $timeout(async () => {
                    console.log('set action', action.constructor.name);

                    try {
                        await $ctrl.action.prepare();
                    } catch (error) {
                        console.log(error);
                        Notification.error();
                    }

                    const isDiaryAction =
                        action instanceof CreateDiary ||
                        action instanceof RescheduleDiary;
                    $ctrl.currentCalendarSwitchEnabled = !isDiaryAction;

                    if (isDiaryAction) {
                        $ctrl.setCurrentCalendar(
                            RootStore.stores.currentUserStore.organisation
                                ?.organisationUser?.uuid
                        );
                    }

                    try {
                        if (
                            urlParams.calendar &&
                            urlParams.calendar !==
                                $ctrl.calendar.currentCalendar
                        ) {
                            $ctrl.calendar.setCurrentCalendar(
                                urlParams.calendar
                            );
                        }
                        await $ctrl.calendar.populate(
                            $ctrl.action,
                            $ctrl.calendar.view.startDate,
                            $ctrl.calendar.view.endDate,
                            false,
                            true
                        );

                        await $ctrl.action.postPopulate();
                    } catch (error) {
                        console.log('error', error);
                    }

                    if (
                        $ctrl.action instanceof CreateSessionPack ||
                        $ctrl.action instanceof CreateClass
                    ) {
                        RootStore.stores.uiStateStore.closeStack();
                    }

                    if (
                        $ctrl.action instanceof UpdateSessionPack ||
                        $ctrl.action instanceof UpdateClass
                    ) {
                        const assignedUser =
                            $ctrl.action.parent.assignedUsers?.[0];

                        if (assignedUser) {
                            $ctrl.setCurrentCalendar(assignedUser?.uuid);
                        }
                    }

                    $ctrl.isLoading = false;
                    $ctrl.calculateStaticValues();
                    $ctrl.calculateWorkingPeriods();

                    $scope.$apply();
                });
            };

            $ctrl.resetAction = (callback) => {
                console.log('reset action');
                $ctrl.setAction(createAction($ctrl.calendar, true), true);

                history.replace(window.location.pathname);

                $timeout(() => {
                    $ctrl.currentCalendarSwitchEnabled = true;

                    if (typeof callback === 'function') {
                        callback();
                    }

                    $scope.$apply();
                });
            };

            $ctrl.closeAction = () => {
                $ctrl.action.finalise();
                $ctrl.resetAction();
            };

            $ctrl.cancelAction = () => {
                $ctrl.action.cancel();
                $ctrl.resetAction();
            };

            $ctrl.pixelsToDays = (xPos) => {
                xPos = xPos - $ctrl.dayWrapperLeftOffset;

                return Math.min(
                    6,
                    Math.max(0, Math.floor(xPos / $ctrl.dayWidth))
                );
            };

            $ctrl.pixelsToPeriods = (yPos) => {
                yPos =
                    yPos -
                    $ctrl.dayWrapperTopOffset +
                    $ctrl.scrollableElement[0].scrollTop;

                return Math.min(
                    96,
                    Math.max(
                        0,
                        (yPos - (yPos % $ctrl.periodPixels)) /
                            $ctrl.periodPixels
                    )
                );
            };

            $ctrl.periodsToTimeSummary = (start, end) => {
                let hours = Math.floor(start / 4);
                let ampm = '';

                if (isTwelveHour) {
                    ampm = hours >= 12 ? 'PM' : 'AM';
                    hours = hours % 12;

                    if (hours === 0) {
                        hours = 12;
                    }
                } else {
                    hours = hours.toString().padStart(2, '0');
                }

                let minutes = ('' + (start % 4) * 15).padStart(2, '0');
                let length = periodsToTimeSummary(end - start);

                return `${hours}:${minutes} ${ampm} - ${length}`;
            };

            $ctrl.addCard = (card) => {
                RootStore.stores.uiStateStore.setPopperOpen(false);
                $ctrl.action.beginAddingCard(card);
                $ctrl.action.addCard(card);
                $ctrl.selectCard(card);
                $ctrl.action.endAddingCard(card);
            };

            $ctrl.findCard = (internalId) => {
                return $ctrl.action.findCard(internalId);
            };

            $ctrl.removeCard = (card) => {
                $timeout(() => {
                    $ctrl.action.beginRemovingCard(card);
                    $ctrl.action.removeCard(card);
                    $ctrl.action.endRemovingCard(card);
                });

                RootStore.stores.uiStateStore.setPopperOpen(false);
            };

            $ctrl.selectCard = (card) => {
                if (!card.isSelected) {
                    let view = $ctrl.calendar.view;

                    if (!view.includesItem(card)) {
                        $ctrl.show(view.includingItem(card), () => {
                            $ctrl.selectCard(card);
                        });

                        return;
                    }

                    let cardTop = card.start * $ctrl.periodPixels;
                    let cardBottom = card.end * $ctrl.periodPixels;
                    let cardHeight = cardBottom - cardTop;
                    let viewHeight =
                        window.innerHeight - $ctrl.dayWrapperTopOffset;
                    let viewTop = window.scrollY;
                    let viewBottom = viewTop + viewHeight;
                    let cardCentered = (viewHeight + cardHeight) / 2;

                    if (cardTop < viewTop || cardBottom > viewBottom) {
                        window.scrollTo(
                            0,
                            Math.max(0, cardBottom - cardCentered)
                        );
                    }

                    if (card.isSelectable) {
                        card.select();
                        $scope.$apply();
                    } else {
                        $ctrl.calendar.deselectAllCards();
                    }
                }
            };

            $ctrl.updateDeliveries = (entries, delivery) => {
                $timeout(() => {
                    entries.forEach((entry) => {
                        const card = $ctrl.findCard(entry);
                        card.entry = angular.extend(card.entry, { delivery });
                    });
                });

                $scope.$apply();
            };

            // $ctrl.showWeek = (week) => {
            //     history.push(
            //         `/schedule/${week.date.isoWeekYear()}/W${week.date.isoWeek()}`
            //     );
            // };

            $ctrl.show = (view, callback) => {
                if (view.type === 'week') {
                    history.push(
                        `/schedule/${view.urlParameters.year}/${view.urlParameters.week}${window.location.search}`
                    );
                }

                $timeout(async () => {
                    view.show();

                    $scope.$broadcast('calendarViewChanged');

                    $ctrl.action.onDateChange($ctrl.calendar.date);
                });

                $timeout(async () => {
                    await $ctrl.calendar.view.populate(
                        $ctrl.action,
                        false,
                        true
                    );

                    $ctrl.calculateWorkingPeriods();

                    if (typeof callback === 'function') {
                        callback();
                    }

                    $scope.$apply();
                });

                return Promise.resolve();
            };

            $ctrl.applyScope = (timeoutCallback) => {
                if (typeof timeoutCallback === 'function') {
                    $timeout(timeoutCallback);
                } else {
                    $scope.$apply();
                }
            };

            $ctrl.initialise = (nextParams = {}) => {
                $ctrl.calendar.setCurrentCalendar(
                    RootStore.stores.currentUserStore.organisation
                        ?.organisationUser?.uuid
                );

                let view;

                if ($ctrl.view === 'day') {
                    view = $ctrl.calendar.getDay(
                        DateTime.fromFormat(
                            `${$ctrl.params.year}-${$ctrl.params.month}-${$ctrl.params.day}`,
                            'yyyy-MM-dd'
                        )
                    );
                } else if ($ctrl.view === 'week') {
                    view = $ctrl.calendar.getWeek(
                        DateTime.fromFormat(
                            `${$ctrl.params.year} ${$ctrl.params.week}`,
                            "kkkk 'W'WW"
                        )
                    );
                }

                view.show();

                $ctrl.setAction(createAction($ctrl.calendar, nextParams));

                $timeout(() => {
                    $ctrl.calculateWorkingPeriods(true);
                    $ctrl.initialised = true;
                });
            };

            if (isMobile() && $ctrl.view === 'week') {
                const parsedDate = DateTime.fromFormat(
                    `${$ctrl.params.year}-${$ctrl.params.week}`,
                    "kkkk-'W'WW"
                );
                const { year, week, ...params } = $ctrl.params;

                history.replace(getFormattedUrl(parsedDate, 'day', params));
                return;
            } else if (!isMobile() && $ctrl.view === 'day') {
                const parsedDate = DateTime.fromFormat(
                    `${urlParams.year}-${urlParams.month}-${urlParams.day}`,
                    'yyyy-MM-dd'
                );

                history.replace(getFormattedUrl(toLuxon(parsedDate), 'week'));
                return;
            }

            $ctrl.initialised = false;
            $ctrl.element = $element;
            $ctrl.periodPixels = 25;

            $ctrl.calendar = Calendar;
            $ctrl.calendar.reset();

            $ctrl.action = createAction($ctrl.calendar, $ctrl.params);

            $ctrl.initialise($ctrl.params);
        },
    ],
};

angular
    .module('section.schedule.week', [
        'component.schedule-navigation',
        'component.schedule-day',
        'component.schedule-week-holiday',
        'component.schedule-add-target',
        'component.schedule-entry',
        'component.react.schedule-action-panel',
        'component.react.schedule-assigned-user-select',
    ])

    .component('scheduleWeek', ScheduleView);

export default ScheduleView;
