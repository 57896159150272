import angular from 'angular';

angular
    .module('component.dialog-wrapper', [])

    .component('dialogWrapper', {
        template: `
            <div class="dialog__overlay" ng-class="{'--no-transition': $ctrl.isStatic}">
                <div class="dialog__wrapper" ng-class="{'--wide': $ctrl.isWide, '--confirmation': $ctrl.confirmation}">
                    <div class="dialog__content" ng-class="{'--new-ui': $ctrl.newUi, '--confirmation': $ctrl.confirmation}" ng-transclude></div>
                </div>
            </div>
        `,
        transclude: true,
        controller: [
            '$attrs',
            function ($attrs) {
                this.isBottomAligned = angular.isDefined($attrs.alignBottom);
                this.isTopAligned = angular.isDefined($attrs.alignTop);
                this.isWide = angular.isDefined($attrs.wide);
                this.isStatic = angular.isDefined($attrs.static);
                this.newUi = angular.isDefined($attrs.newUi);
                this.confirmation = angular.isDefined($attrs.confirmation);
            },
        ],
    });
