import angular from 'angular';

angular
    .module('component.wizard.cancel-button', [])

    .component('wizardCancelButton', {
        template: `<a class="button --text" ng-click="$ctrl.wizard.cancel()" ng-transclude>Cancel</a>`,
        transclude: true,
        require: {
            wizard: '^wizard',
        },
    });
