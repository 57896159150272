import RescheduleAction from './RescheduleAction.js';
import Diary from './Diary.js';

export default class RescheduleDiary extends RescheduleAction {
    static get type() {
        return new Diary();
    }

    get calendarTitle() {
        return 'Reschedule appointment';
    }
}
