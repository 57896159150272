import React, { FunctionComponent, useMemo } from 'react';
import { TextButton } from '@/Components/Button';
import classNames from 'classnames';

interface Props {
    href: string;
    position?: 'form' | 'nav' | 'custom';
    className?: string;
    withText?: boolean | string;
}

export const HelpButton: FunctionComponent<Props> = ({
    href,
    position = 'custom',
    className,
    withText = true,
}) => {
    const buttonProps = useMemo(() => {
        if (position !== 'nav') {
            return {};
        }

        return {
            fill: '#fff',
            className:
                'w-7 h-7 mr-2 rounded-full hover:bg-white hover:bg-opacity-20',
        };
    }, [position]);

    return (
        <div
            className={classNames(
                {
                    '-mb-4 -mt-3 flex justify-end md:px-2 lg:px-6':
                        position === 'form',
                },
                className
            )}
        >
            <TextButton
                icon="HelpCircle"
                href={href}
                target="_blank"
                {...buttonProps}
            >
                {!!withText && (
                    <span>
                        {typeof withText === 'string' ? withText : 'Need help?'}
                    </span>
                )}
            </TextButton>
        </div>
    );
};
