import { getRoot, Instance, types } from 'mobx-state-tree';
import { Image } from './Image';

export interface OrganisationBaseInstance
    extends Instance<typeof Organisation> {}

export const Organisation = types
    .model('Organisation', {
        uuid: types.identifier,
        name: types.string,
        isActive: types.boolean,
        tradingName: types.maybeNull(types.string),
        displayName: types.string,

        logo: types.maybeNull(Image),

        jobTitle: types.maybeNull(types.string),
        isOwner: false,
    })

    .views((self) => ({
        get isCurrent() {
            return (
                self.uuid ===
                getRoot(self).stores.currentUserStore.organisation?.uuid
            );
        },
    }));
