import BaseTransport from './BaseTransport';

class EmployerTransport extends BaseTransport {
    async list(params = {}) {
        return await this.get('/v3/employers', params);
    }

    async store(data) {
        return await this.post('/v3/employers', data);
    }

    async update(uuid, data) {
        return await this.put(`/v3/employers/${uuid}`, data);
    }

    async destroy(uuid) {
        return await this.delete(`/v3/employers/${uuid}`);
    }
}

export default new EmployerTransport();
