import React, { FunctionComponent } from 'react';
import FormFieldElements, { FormFieldElementProps } from '../FormFieldElements';
import TextAreaField from '../TextAreaField';
import { FieldProps } from 'formik';

interface Props extends FormFieldElementProps {
    placeholder?: string;
    isDisabled?: boolean;
}

const FormTextAreaField: FunctionComponent<Props> = ({
    placeholder,
    isDisabled,
    ...props
}) => {
    return (
        <FormFieldElements {...props}>
            {({ field, meta }: FieldProps) => (
                <TextAreaField
                    placeholder={placeholder}
                    isDisabled={isDisabled}
                    {...field}
                />
            )}
        </FormFieldElements>
    );
};

export default FormTextAreaField;
