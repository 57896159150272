import { applySnapshot, flow, getRoot, types } from 'mobx-state-tree';
import { Package } from '../Models/Package';
import PackageTransport from '../Services/Transport/PackageTransport';

export const PackageStore = types
    .model('PackageStore', {
        packages: types.map(Package),
    })

    .views((self) => ({
        find(uuid) {
            return self.packages.get(uuid);
        },

        get available() {
            return Array.from(self.packages.values())
                .filter((pack) => !pack.isArchived)
                .sort((a, b) => a.name.localeCompare(b.name));
        },

        get hasAvailable() {
            return self.available.length > 0;
        },

        get classPasses() {
            return self.available.filter(
                (pack) => pack.packageType === 'class' && !pack.hasExpired
            );
        },

        get sessionPacks() {
            return self.available.filter(
                (pack) => pack.packageType === 'session'
            );
        },
    }))

    .actions((self) => ({
        list: flow(function* list(params = {}, withMeta = false) {
            const { data } = yield PackageTransport.list(params);
            const items = data.data.map((packageModel) =>
                self.createOrUpdate(packageModel)
            );

            return withMeta ? { data: items, meta: data.meta } : items;
        }),

        fetch: flow(function* fetch(uuid, params = {}) {
            const { data } = yield PackageTransport.fetch(uuid, params);
            return self.createOrUpdate(data.data);
        }),

        store: flow(function* store(packageData) {
            const { data } = yield PackageTransport.store(packageData);
            return self.createOrUpdate(data.data);
        }),

        update: flow(function* update(uuid, packageData) {
            const { data } = yield PackageTransport.update(uuid, packageData);
            return self.createOrUpdate(data.data);
        }),

        createOrUpdate(packageData) {
            const packageModel = self.processNestedResources(packageData);

            if (self.packages.has(packageModel.uuid)) {
                const existing = self.packages.get(packageModel.uuid);
                applySnapshot(existing, {
                    ...existing,
                    ...packageData,
                });
            } else {
                self.packages.set(packageModel.uuid, packageModel);
            }

            return self.packages.get(packageModel.uuid);
        },

        /**
         * @param packageData¡
         * @returns {*}
         */
        processNestedResources(packageData) {
            if (typeof packageData.assignedUser?.uuid !== 'undefined') {
                packageData.assignedUser = getRoot(
                    self
                ).stores.usersStore.processAsNested(packageData.assignedUser);
            }

            return packageData;
        },
    }));
