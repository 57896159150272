import React from 'react';

const CustomMessages = (props) => (
    <svg viewBox="0 0 20 20" {...props}>
        <g fill={props.fill} fillRule="evenodd">
            <path d="M0 2a2 2 0 012-2h11a2 2 0 012 2v7a2 2 0 01-2 2H4l-4 4V2z" />
            <path d="M17 5v6a2 2 0 01-2 2H5v1a2 2 0 002 2h9l4 4V7a2 2 0 00-2-2h-1z" />
        </g>
    </svg>
);

export default CustomMessages;
